import React from 'react';

import { Launch } from '@carbon/icons-react';
import { Card } from '@varicent/components';
import { useHistory } from 'react-router-dom';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { GetTenantWideInfo_getUserSpec_membershipSpecs } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccessibleTenantsList.module.pcss';

const b = block(style);

interface AccessibleTenantsListProps {
  membershipSpecs: GetTenantWideInfo_getUserSpec_membershipSpecs[];
}

const AccessibleTenantsList: React.FC<AccessibleTenantsListProps> = ({
  membershipSpecs
}: AccessibleTenantsListProps) => {
  const history = useHistory();

  const handleTenantSelection = (member: GetTenantWideInfo_getUserSpec_membershipSpecs) => {
    history.push(`/${member.tenantSlug}`);
  };

  return (
    <div className={b('tenantsListWrapper')}>
      <div className={b('tenantsListHeader')}>{formatMessage('TENANTS_LIST_HEADER')}</div>
      {membershipSpecs.map((member) => (
        <div key={`tenant-card-${member.tenantSlug}`} data-testid="tenant-card">
          <Card className={b('tenantCard')} key={member.tenantGlobalId}>
            <div>{member.tenantName}</div>
            <TextButton
              data-testid="open-tenant-button"
              type={'button'}
              icon={<Launch />}
              text={formatMessage('OPEN')}
              testId={'open-tenant-button'}
              onClick={() => handleTenantSelection(member)}
            />
          </Card>
        </div>
      ))}
    </div>
  );
};

export default AccessibleTenantsList;
