import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetBattlecardsForEmbeddedMap, GetBattlecardsForEmbeddedMapVariables } from 'app/graphql/generated/apolloTypes';

export const GET_BATTLECARDS_FOR_EMBEDDED_MAP = gql`
  query GetBattlecardsForEmbeddedMap($input: GetDeploymentModelSpecInput!) {
    getDeploymentModelSpec(input: $input) {
      deploymentModelId
      deploymentModelName
      battlecards {
        battlecardId
        battlecardInfoLevel
        battlecardName
        battlecardType
        localCurrencyCode
        territoryGroupTypes {
          hierarchyType
          territoryGroupId
          territoryGroupOwnerMemberId
        }
        quotaComponents {
          quotaComponentId
          quotaComponentName
          battlecardQuotaComponentId
        }
        files {
          fileId
          fileName
        }
      }
    }
  }
`;

export const useGetBattleCardsForEmbeddedMap = createUseQuery<
  GetBattlecardsForEmbeddedMap,
  GetBattlecardsForEmbeddedMapVariables
>(GET_BATTLECARDS_FOR_EMBEDDED_MAP);
