import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetFieldTotals, GetFieldTotalsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_FIELD_TOTALS = gql`
  query GetFieldTotals(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $territoryGroupId: Int
    $battlecardId: Int
    $startRow: Int
    $endRow: Int
    $fieldIds: [Int]!
    $rangeFieldInput: GetRangeFieldInput
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        territoryGroupId: $territoryGroupId
        battlecardId: $battlecardId
        startRow: $startRow
        endRow: $endRow
        fieldIds: $fieldIds
        rangeFieldInput: $rangeFieldInput
      }
    ) {
      fieldTotals {
        fieldId
        fieldValue
      }
    }
  }
`;

export const useGetFieldTotalsLazy = createUseLazyQuery<GetFieldTotals, GetFieldTotalsVariables>(GET_FIELD_TOTALS);
