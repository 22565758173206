import React from 'react';

import { Menu, MenuItem } from '@blueprintjs/core';
import { Add, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { useData } from 'app/contexts/dataProvider';

import { DataSheetDrawerFormType, DataSheetDrawerState } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const SheetsAddFieldButton: React.FC = () => {
  const { setDataSheetDrawerState, setDataSheetDrawerFormType, setSheetDefinition } = useData();

  const AddMenuContent = (
    <Menu>
      <MenuItem
        data-testid={'sheets-add-new-field-button'}
        text={formatMessage('NEW_FIELD')}
        onClick={() => {
          setDataSheetDrawerState(DataSheetDrawerState.OPEN);
          setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
          setSheetDefinition(null);
        }}
      />
      <MenuItem
        data-testid={'sheets-add-existing-field-button'}
        text={formatMessage('EXISTING_FIELD')}
        onClick={() => {
          setDataSheetDrawerState(DataSheetDrawerState.OPEN);
          setDataSheetDrawerFormType(DataSheetDrawerFormType.EXISTING);
          setSheetDefinition(null);
        }}
      />
    </Menu>
  );

  return (
    <Popover content={AddMenuContent} placement="bottom">
      <TextButton
        testId={'quota-add-field'}
        type="button"
        text={formatMessage('ADD')}
        intent="primary"
        icon={<Add />}
        large={false}
        rightIcon={<ChevronDown />}
      />
    </Popover>
  );
};

export default SheetsAddFieldButton;
