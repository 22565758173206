import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTerritoryGroupTypes, GetTerritoryGroupTypesVariables } from 'app/graphql/generated/apolloTypes';

export const GET_TERRITORY_GROUP_TYPES_FOR_MAP_SETTINGS = gql`
  query GetTerritoryGroupTypes($input: GetDeploymentModelSpecInput!) {
    getDeploymentModelSpec(input: $input) {
      battlecards {
        territoryGroupTypes {
          hierarchyRootName
          hierarchyType
        }
      }
    }
  }
`;

export const useGetTerritoryGroupTypesForMap = createUseQuery<GetTerritoryGroupTypes, GetTerritoryGroupTypesVariables>(
  GET_TERRITORY_GROUP_TYPES_FOR_MAP_SETTINGS
);
