import React, { useEffect } from 'react';

import { Card, IllustrationFileBox } from '@varicent/components';

import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import UserMenu from 'app/components/UserMenu/UserMenu';

import AccessibleTenantsList from 'app/containers/App/AccessibleTenantsList/AccessibleTenantsList';

import { useUserMenu } from 'app/contexts/userMenuProvider';

import { GetUserSpec_getUserSpec_membershipSpecs } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';
import { clearImpersonationToken } from 'utils/ImpersonationLocalStorage';
import { formatMessage } from 'utils/messages/utils';

import varicentLogo from 'assets/svgs/varicentlogo.svg';

import style from './WelcomePage.module.pcss';

const b = block(style);

interface WelcomePageProps {
  membershipSpecs: GetUserSpec_getUserSpec_membershipSpecs[];
}

const WelcomePage: React.FC<WelcomePageProps> = ({ membershipSpecs }) => {
  const isTenantListEmpty = membershipSpecs?.length === 0;
  const chooseTenantText = isTenantListEmpty
    ? formatMessage('EMPTY_TENANT_LIST_MESSAGE')
    : formatMessage('CHOOSE_A_TENANT');

  const { setOnExitImpersonationMenuItemClick } = useUserMenu();

  useEffect(() => {
    const redirectUrl = `/welcome`;

    const handleExitImpersonation = () => {
      clearImpersonationToken();
      window.location.href = redirectUrl;
    };

    setOnExitImpersonationMenuItemClick(() => () => handleExitImpersonation());
  }, []);

  return (
    <div className={b()}>
      <CyclesPageHeader
        logoPath="/"
        logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
        rightActionItems={[<UserMenu key="user-menu" />]}
        hideActions
      />

      <div className={b('welcomePageWrapper')}>
        <div className={b('welcomePageCardWrapper')}>
          <Card className={b('welcomePageCard')}>
            <div data-testid="welcome-title">
              <h2 className={b('welcomePageTitle')}>{formatMessage('WELCOME_BACK')}</h2>
            </div>
            <div>
              <IllustrationFileBox size={192} />
            </div>
            <div data-testid="welcome-message" className={b('welcomePageTextWrapper')}>
              <p className={b('text')}>{chooseTenantText}</p>
            </div>
            {!isTenantListEmpty && <AccessibleTenantsList membershipSpecs={membershipSpecs} />}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
