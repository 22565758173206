import { updateRowItem, constructRowItem } from 'app/components/AdvancedGrid/GridHelpers/QuotaGrid/QuotaGridHelper';

import {
  GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution,
  GetTerritoryQuota_getTerritoryQuota_periodicTerritories
} from 'app/graphql/generated/apolloTypes';

import { HierarchyType, QuotaGridMeasureColumn } from 'app/models';

// this function is shared between building the grid rows data for territory quota distribution (using response of GetTerritoryQuotaDistribution) and
// the pinned bottom row data for territory quota distribution (using response of GetTerritoryQuota)
export const buildHierarchyQuotaDistributionData = (
  data:
    | GetTerritoryQuotaDistribution_getTerritoryQuota_periodicQuotaDistribution[]
    | GetTerritoryQuota_getTerritoryQuota_periodicTerritories[],
  quotaComponentId: number
) => {
  const gridData = [];

  // Initialize gridColumns array to contain the measure columns. All periodicity breakdown columns is not added at first
  // periodicity columns will be added into gridColumns inplace in the call of constructRowItem
  const gridColumns: QuotaGridMeasureColumn[] = data?.[0]?.measures
    ? data[0].measures.map((m) => ({ measureName: m.measureName }))
    : [];

  data.forEach((breakdown, index) => {
    // hierarchies will be undefined when generating pinned bottom row data
    const { measures, hierarchies, ruleId } = breakdown;

    // build empty rowItem for each territory
    let rowItem = {};
    gridColumns.forEach((measure) => {
      rowItem[measure.measureName] = {};
    });

    // inside this loop, it will go over each measure and adding periodicity breakdown column into gridColumns
    // so when we are generating data for the second row, gridColumns will have all the periodicity breakdown columns for each measure
    measures.forEach((measure) => {
      // Add current measure data into rowItem (no breakdown at this point)
      // If index === 0 (ie: looking at the first territory), it will update the gridColumns array in place to include the periodicity breakdown columns
      constructRowItem(rowItem, measure, gridColumns, quotaComponentId, index, true);
    });

    // Add periodicity breakdowns for all measures into rowItem
    updateRowItem(rowItem, gridColumns, quotaComponentId);

    // only add hierarchy breakdown columns when generating grid rows data
    if (hierarchies) {
      const hierarchyBreakdownNameAndKey = {};
      const customerAccountHierarchy = hierarchies.find(
        (hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy
      );
      const customHierarchy1 = hierarchies.filter(
        (hierarchy) => hierarchy.hierarchyType !== HierarchyType.CustomerAccountHierarchy
      )[0];
      const customHierarchy2 = hierarchies.filter(
        (hierarchy) => hierarchy.hierarchyType !== HierarchyType.CustomerAccountHierarchy
      )[1];

      // since we only support at most 2 hierarchies to breakdown the quota
      // if quota breakdown by hierarchy setting includes customer account hierarchy, then the api response will have customerAccountHierarchy and customHierarchy1 with valid data
      if (customerAccountHierarchy) {
        hierarchies.forEach((hierarchy) => {
          const name = `${hierarchy.rootName}_name`;
          const key = `${hierarchy.rootKey}_key`;
          if (hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy) {
            hierarchyBreakdownNameAndKey[name] = customerAccountHierarchy.name;
            hierarchyBreakdownNameAndKey[key] = customerAccountHierarchy.key;
            hierarchyBreakdownNameAndKey['customerAccountHierarchyId'] = customerAccountHierarchy.hierarchyId;
          } else {
            hierarchyBreakdownNameAndKey[name] = customHierarchy1.name;
            hierarchyBreakdownNameAndKey[key] = customHierarchy1.key;
            hierarchyBreakdownNameAndKey['customHierarchyId_1'] = customHierarchy1.hierarchyId;
          }
        });
      } else {
        // if quota breakdown by hierarchy setting does not includes customer account hierarchy, then the api response will have customHierarchy1 and customHierarchy2 with valid data
        hierarchies.forEach((hierarchy, index) => {
          const name = `${hierarchy.rootName}_name`;
          const key = `${hierarchy.rootKey}_key`;
          hierarchyBreakdownNameAndKey[name] = index === 0 ? customHierarchy1.name : customHierarchy2.name;
          hierarchyBreakdownNameAndKey[key] = index === 0 ? customHierarchy1.key : customHierarchy2.key;
        });
        hierarchyBreakdownNameAndKey['customHierarchyId_1'] = customHierarchy1.hierarchyId;
        hierarchyBreakdownNameAndKey['customHierarchyId_2'] = customHierarchy2?.hierarchyId;
      }

      rowItem = { ...rowItem, ...hierarchyBreakdownNameAndKey };
    }

    gridData.push({ ...rowItem, ruleId });
  });

  return gridData;
};

export default buildHierarchyQuotaDistributionData;
