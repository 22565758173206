import React from 'react';

import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import {
  ChevronDown,
  Close,
  Cursor_1 as Cursor,
  LassoPolygon,
  SendToBack,
  ShapeUnite,
  CircleFilled,
  Incomplete,
  CircleDash
} from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Popover from 'components/Popover/Popover';

import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';

import { MapSelectionRestriction, MapSelectionTarget, MapSelectionTool } from 'app/models';

import block from 'utils/bem-css-modules';
import { isTogglePointer } from 'utils/helpers/territoryMapUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import style from './SelectionToolSettingsBarV2.module.pcss';

const b = block(style);

const LASSO_RESTRICTION_OPTIONS = [
  {
    key: MapSelectionRestriction.assigned,
    geoTooltipText: formatMessage('ASSIGNED_TOOLTIP'),
    accountTooltipText: formatMessage('ASSIGNED_TOOLTIP_ACCOUNTS'),
    icon: <Incomplete />
  },
  {
    key: MapSelectionRestriction.all,
    accountTooltipText: formatMessage('ALL_TOOLTIP_ACCOUNTS'),
    geoTooltipText: formatMessage('ALL_TOOLTIP'),
    icon: <CircleFilled />
  },
  {
    key: MapSelectionRestriction.unassigned,
    accountTooltipText: formatMessage('UNASSIGNED_TOOLTIP_ACCOUNTS'),
    geoTooltipText: formatMessage('UNASSIGNED_TOOLTIP'),
    icon: <CircleDash />
  }
];

const LASSO_TOOL_OPTIONS = [
  { key: MapSelectionTool.addLasso, tooltipText: formatMessage('ADD_TO_SELECTION'), icon: <ShapeUnite /> },
  { key: MapSelectionTool.removeLasso, tooltipText: formatMessage('REMOVE_FROM_SELECTION'), icon: <SendToBack /> }
];

export interface SelectionToolSettingsBarV2Props {
  selectedTool: MapSelectionTool;
  lassoSelectionRestriction: MapSelectionRestriction;
  selectionTarget: MapSelectionTarget;
  isDrawing: boolean;
  disabled: boolean;
  onCancelLasso: () => void;
  onChooseSelectionTool: (selectionTool: MapSelectionTool) => void;
  onChooseLassoRestriction: (selection: MapSelectionRestriction) => void;
}

const SelectionToolSettingsBarV2: React.FC<SelectionToolSettingsBarV2Props> = ({
  selectedTool,
  lassoSelectionRestriction,
  selectionTarget,
  isDrawing,
  disabled,
  onCancelLasso,
  onChooseSelectionTool,
  onChooseLassoRestriction
}) => {
  const isSelectionEnabled = useIsMapCapable(MapCapability.SELECT);
  const isViewUnassignedEnabled = useIsMapCapable(MapCapability.VIEW_UNASSIGNED);

  const isActiveToolPointer = isTogglePointer(selectedTool);
  const isActiveToolLasso = !isTogglePointer(selectedTool);

  const renderLassoMenu = () => (
    <Menu>
      <div className={b('lassoMenu')}>
        {LASSO_RESTRICTION_OPTIONS.map((option) => (
          <MenuItem
            data-testid={`lasso-restriction-menu-item-${option.key}`}
            text={
              <IconButton
                type="button"
                testId={`${option.key}-button`}
                icon={option.icon}
                intent="none"
                minimal
                tooltipText={
                  selectionTarget === MapSelectionTarget.accounts ? option.accountTooltipText : option.geoTooltipText
                }
                toolTipPlacement="bottom"
              />
            }
            onClick={() => onChooseLassoRestriction(option.key)}
            key={option.key}
            active={option.key === lassoSelectionRestriction}
            shouldDismissPopover={false}
          />
        ))}
        <MenuDivider />
        {LASSO_TOOL_OPTIONS.map((option) => (
          <MenuItem
            data-testid={`lasso-tool-menu-item-${option.key}`}
            icon={
              <IconButton
                type="button"
                testId={`${option.key}-button`}
                icon={option.icon}
                intent="none"
                minimal
                tooltipText={option.tooltipText}
                toolTipPlacement="bottom"
              />
            }
            onClick={() => onChooseSelectionTool(option.key)}
            key={option.key}
            active={option.key === selectedTool}
            shouldDismissPopover={false}
          />
        ))}
      </div>
    </Menu>
  );

  const ensureLassoActive = () => {
    // Switch to the generic add-lasso only when the user isn't already using one of the lasso tools
    if (selectedTool === MapSelectionTool.togglePointer) onChooseSelectionTool(MapSelectionTool.addLasso);
  };

  return (
    <div className={b('selectionToolContainer')} data-testid="selection-tool-picker">
      <IconButton
        onClick={() => onChooseSelectionTool(MapSelectionTool.togglePointer)}
        active={isActiveToolPointer}
        type="button"
        icon={<Cursor />}
        testId={'toggle-pointer-button'}
        disabled={disabled || !isSelectionEnabled}
        tooltipText={formatMessage('SELECT_TOOLTIP')}
        toolTipPlacement="bottom"
      />
      <div className={b('lassoBtns')}>
        <div
          className={b('lassoSelectBtn', {
            hasDropdown: isDrawing || (selectionTarget !== MapSelectionTarget.territories && isViewUnassignedEnabled)
          })}
        >
          <IconButton
            onClick={ensureLassoActive}
            active={isActiveToolLasso}
            type="button"
            icon={<LassoPolygon />}
            testId={'lasso-button'}
            disabled={disabled || !isSelectionEnabled}
            tooltipText={formatMessage('LASSO_TOOLTIP')}
            toolTipPlacement="bottom"
          />
          {isDrawing && (
            <div className={b('lassoActionBtn')}>
              <IconButton
                onClick={onCancelLasso}
                active={isActiveToolLasso}
                type="button"
                icon={<Close />}
                testId={'cancel-lasso-button'}
                tooltipText={formatMessage('CANCEL_LASSO')}
                toolTipPlacement="bottom"
              />
            </div>
          )}
        </div>
        {!isDrawing && selectionTarget !== MapSelectionTarget.territories && isViewUnassignedEnabled && (
          <Popover disabled={disabled} content={renderLassoMenu()} placement={'bottom-end'} minimal={true}>
            <div className={b('lassoActionBtn')}>
              <IconButton
                onClick={ensureLassoActive}
                active={isActiveToolLasso}
                type="button"
                icon={<ChevronDown />}
                testId={'lasso-dropdown-button'}
                disabled={disabled || !isSelectionEnabled}
                tooltipText={
                  selectionTarget === MapSelectionTarget.accounts
                    ? formatMessage('LASSO_DROPDOWN_TOOLTIP_ACCOUNTS')
                    : formatMessage('LASSO_DROPDOWN_TOOLTIP')
                }
                toolTipPlacement="bottom"
              />
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default SelectionToolSettingsBarV2;
