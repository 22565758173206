import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetPinSets, GetPinSetsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_PIN_SETS = gql`
  query GetPinSets($battlecardId: Int!, $deploymentModelId: Int!) {
    getDeploymentModelSpec(input: { battlecardId: $battlecardId, deploymentModelId: $deploymentModelId }) {
      deploymentModelId
      battlecards {
        battlecardId
        pinSets {
          pinSetId
          pinSetName
          battlecardId
          locationGroupId
          icon
          color
        }
      }
    }
  }
`;

export const useGetPinSets = createUseQuery<GetPinSets, GetPinSetsVariables>(GET_PIN_SETS);
