import { useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { BCInfoLevelEnum } from 'app/graphql/generated/apolloTypes';

import useMemberId from './useMemberId';

export const useCanSeeAllRulesOnMap = (): boolean => {
  const { selectedBattleCard, selectedTerritoryGroupTypeId, selectedTerritoryGroupId } = useMapContextRedistributor();
  const memberId = useMemberId();

  const tgtOwnerId = useMemo(() => {
    const geoTgt = selectedBattleCard?.territoryGroupTypes.find(
      (tgt) => tgt.territoryGroupId === selectedTerritoryGroupTypeId
    );
    if (!geoTgt) return null;

    return geoTgt.territoryGroupOwnerMemberId;
  }, [selectedBattleCard, selectedTerritoryGroupTypeId]);

  if (!selectedBattleCard || !memberId || !selectedTerritoryGroupId || !selectedTerritoryGroupTypeId) {
    return false;
  }

  if (selectedTerritoryGroupTypeId !== selectedTerritoryGroupId) {
    return false;
  }

  const isOwnerOfTgt = tgtOwnerId === memberId;

  return selectedBattleCard.battlecardInfoLevel === BCInfoLevelEnum.all || isOwnerOfTgt;
};
