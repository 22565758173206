import { useMemo } from 'react';

import {
  DeploymentModelTypeEnum,
  GetUserSpec,
  GetUserSpec_getUserSpec_membershipSpecs,
  GetUserSpec_getUserSpec_membershipSpecs_planningCycles,
  GetUserSpec_getUserSpec_membershipSpecs_planningCycles_deploymentModels,
  GetUserTenant,
  GetUserTenant_getUserSpec_membershipSpecs
} from 'app/graphql/generated/apolloTypes';

import { EmbeddedMapSlugs } from './useEmbeddedMapSlugs';

type BreakdownTenantSpec = GetUserTenant_getUserSpec_membershipSpecs | GetUserSpec_getUserSpec_membershipSpecs;
export interface UserSpecBreakdown {
  tenantSpec: BreakdownTenantSpec;
  planningCycleSpec: GetUserSpec_getUserSpec_membershipSpecs_planningCycles;
  deploymentModelSpec: GetUserSpec_getUserSpec_membershipSpecs_planningCycles_deploymentModels;
}

export const useBreakdownUserSpec = (
  userSpec: GetUserSpec | GetUserTenant | null,
  slugs: EmbeddedMapSlugs
): UserSpecBreakdown => {
  const tenantSpec = useMemo(
    (): BreakdownTenantSpec =>
      userSpec?.getUserSpec.membershipSpecs.find((spec) => spec.tenantSlug === slugs.tenantSlug),
    [userSpec, slugs.tenantSlug]
  );

  const planningCycleSpec = useMemo(() => {
    if (!tenantSpec) return null;
    if (!('planningCycles' in tenantSpec)) return null;
    if (!tenantSpec.planningCycles) return null;

    return slugs.planningCycleSlug
      ? tenantSpec.planningCycles.find((pc) => pc.planningCycleSlug === slugs.planningCycleSlug)
      : findFallbackPc(tenantSpec.planningCycles);
  }, [tenantSpec, slugs.planningCycleSlug]);

  const deploymentModelSpec = useMemo(
    () =>
      planningCycleSpec?.deploymentModels.find(
        (dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Plan && dm.isPublished
      ),
    [planningCycleSpec]
  );

  return useMemo(
    () => ({ tenantSpec, planningCycleSpec, deploymentModelSpec }),
    [tenantSpec, planningCycleSpec, deploymentModelSpec]
  );
};

export const findFallbackPc = <
  PlanningCycleType extends {
    planningCycleStartDate: string;
    planningCycleArchived: boolean;
  }
>(
  planningCycle: PlanningCycleType[]
): PlanningCycleType =>
  planningCycle
    .filter((pc) => !pc.planningCycleArchived)
    .sort((a, b) => b.planningCycleStartDate.localeCompare(a.planningCycleStartDate))[0];
