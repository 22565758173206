import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';

import { useGetSymonPipeConfigurationStatus } from 'app/components/DataPanel/ConfigurationsPanel/hooks/useSymonPipeConfigurationStatus';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { useScope } from 'app/contexts/scopeProvider';

import {
  FieldNameInput,
  GetDataSheets_getDeploymentModelSpec_dataSheets,
  GetFileUploadProgressVariables,
  GetSheetDefinitions_getDeploymentModelSpec,
  GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions,
  GetSymonPipeConfigurationVariables,
  ISheetDefinitionDef,
  ISheetRoleDef
} from 'app/graphql/generated/apolloTypes';
import { useGetFileUploadProgress } from 'app/graphql/hooks/useGetFileUploadProgress';
import { GET_SHEET_DEFINITIONS } from 'app/graphql/queries/getSheetDefinitions';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  ADMIN,
  BaseContext,
  DataMappingSelectMenuItem,
  DataPanelViews,
  DataSheet,
  DataSheetDrawerFormType,
  DataSheetDrawerState,
  FileUploadResult,
  MappingFields,
  TablesTab,
  UserManagementTabNames
} from 'app/models';

export interface DataContextValues extends BaseContext {
  allDataSheets: DataSheet[];
  setAllDataSheets: Dispatch<SetStateAction<DataSheet[]>>;
  sheetDefinitions: (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null)[] | null;
  setSheetDefinitions: Dispatch<
    SetStateAction<(GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null)[] | null>
  >;
  sheetDefinition: GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null;
  setSheetDefinition: Dispatch<
    SetStateAction<GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null>
  >;
  selectedSheet: GetDataSheets_getDeploymentModelSpec_dataSheets | null;
  setSelectedSheet: Dispatch<SetStateAction<GetDataSheets_getDeploymentModelSpec_dataSheets | null>>;
  sheetDefinitionsLoading: boolean;
  setSheetDefinitionsLoading: Dispatch<SetStateAction<boolean>>;
  sheetLookupMap: Record<string, ISheetDefinitionDefWithMap>;
  setSheetLookupMap: Dispatch<SetStateAction<Record<string, ISheetDefinitionDefWithMap>>>;
  getSheetDefinitions: (deploymentModelId: number, sheetId: number, isTQM?: boolean) => Promise<void>;
  permissionGridData: Record<string, string | Record<string, string>>[];
  setPermissionGridData: Dispatch<SetStateAction<Record<string, string | Record<string, string>>[]>>;
  selectedTable: FileUploadResult | null;
  setSelectedTable: Dispatch<SetStateAction<FileUploadResult | null>>;
  fileUploadInProgress: FileUploadResult[] | [];
  setFileUploadInProgress: Dispatch<SetStateAction<FileUploadResult[] | []>>;
  resetValues: () => void;
  resetFileUploadValues: () => void;
  columnParamsLookupMap: Record<string, ICellRendererParams>;
  setColumnParamsLookupMap: Dispatch<SetStateAction<Record<string, ICellRendererParams>>>;
  pollForProcessingStatus: (options?: QueryLazyOptions<GetFileUploadProgressVariables>) => void;
  pollForSymonPipeProcessingStatus: (options?: QueryLazyOptions<GetSymonPipeConfigurationVariables>) => void;
  mappedFields: DataMappingSelectMenuItem[];
  setMappedFields: Dispatch<SetStateAction<DataMappingSelectMenuItem[]>>;
  pollingTokenId;
  setPollingTokenId;
  selectedConfiguration;
  setSelectedConfiguration;
  shouldRefetchConfigurations: boolean;
  setShouldRefetchConfigurations: Dispatch<SetStateAction<boolean>>;
  missingRequiredFields: MappingFields[];
  setMissingRequiredFields: Dispatch<SetStateAction<MappingFields[]>>;
  shouldShowMappingError: boolean;
  setShouldShowMappingError: Dispatch<SetStateAction<boolean>>;
  isAllColumnsMapped: boolean;
  setIsAllColumnsMapped: Dispatch<SetStateAction<boolean>>;
  mappingProperties: FieldNameInput[];
  setMappingProperties: Dispatch<SetStateAction<FieldNameInput[]>>;
  selectedUserTab: UserManagementTabNames;
  setSelectedUserTab: Dispatch<SetStateAction<UserManagementTabNames>>;
  selectedTablesTab: TablesTab;
  setSelectedTableViewTab: Dispatch<SetStateAction<TablesTab>>;
  selectedDataView: DataPanelViews;
  setSelectedDataView: Dispatch<SetStateAction<DataPanelViews>>;
  dataSheetDrawerState: DataSheetDrawerState;
  setDataSheetDrawerState: Dispatch<SetStateAction<DataSheetDrawerState>>;
  dataSheetDrawerFormType: DataSheetDrawerFormType;
  setDataSheetDrawerFormType: Dispatch<SetStateAction<DataSheetDrawerFormType>>;
  isLookupRowSubmitting: boolean;
  setIsLookupRowSubmitting: Dispatch<SetStateAction<boolean>>;
  sheetMeasuresIdLookupMap: Map<string, number>;
  sheetMeasuresNameLookupMap: Map<number, string>;
}

interface ISheetDefinitionDefWithMap extends ISheetDefinitionDef {
  permissionLookupMap: Record<string, ISheetRoleDefWithACLId>;
}

interface ISheetRoleDefWithACLId extends ISheetRoleDef {
  sheetACLId: number;
}

export const DataContext = React.createContext<DataContextValues | null>(null);
DataContext.displayName = 'DataContext';

export const DataProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  // Overview
  const [selectedDataView, setSelectedDataView] = useState<DataPanelViews>(DataPanelViews.DATA_OVERVIEW);
  const [selectedUserTab, setSelectedUserTab] = useState<UserManagementTabNames>(UserManagementTabNames.USERS);
  const [dataSheetDrawerState, setDataSheetDrawerState] = useState<DataSheetDrawerState>(DataSheetDrawerState.CLOSE);
  const [dataSheetDrawerFormType, setDataSheetDrawerFormType] = useState<DataSheetDrawerFormType>(
    DataSheetDrawerFormType.NEW
  );

  // Sheets
  const [allDataSheets, setAllDataSheets] = useState<DataSheet[]>([]);
  const [sheetDefinitions, setSheetDefinitions] = useState<
    (GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null)[] | null
  >([]);
  const [sheetDefinition, setSheetDefinition] =
    useState<GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions | null>(null);
  const [sheetDefinitionsLoading, setSheetDefinitionsLoading] = useState<boolean>(false);
  const [sheetLookupMap, setSheetLookupMap] = useState<Record<string, ISheetDefinitionDefWithMap>>();
  const [selectedSheet, setSelectedSheet] = useState<GetDataSheets_getDeploymentModelSpec_dataSheets | null>(null);
  const [permissionGridData, setPermissionGridData] = useState(null);
  const [missingRequiredFields, setMissingRequiredFields] = useState<MappingFields[]>([]);
  const [sheetMeasuresIdLookupMap, setSheetMeasuresIdLookupMap] = useState<Map<string, number>>();
  const [sheetMeasuresNameLookupMap, setSheetMeasuresNameLookupMap] = useState<Map<number, string>>();

  // Tables
  const [selectedTable, setSelectedTable] = useState<FileUploadResult | null>(null);
  const [columnParamsLookupMap, setColumnParamsLookupMap] = useState<Record<string, ICellRendererParams>>({});
  const [selectedTablesTab, setSelectedTableViewTab] = useState<TablesTab>(TablesTab.ALL);
  const [isLookupRowSubmitting, setIsLookupRowSubmitting] = useState(false);

  // File uploading to symon in progress
  const [fileUploadInProgress, setFileUploadInProgress] = useState<FileUploadResult[]>([]);

  // Data mapping
  const [mappedFields, setMappedFields] = useState<DataMappingSelectMenuItem[]>([]);
  const [mappingProperties, setMappingProperties] = useState<FieldNameInput[]>([]);
  const [shouldShowMappingError, setShouldShowMappingError] = useState<boolean>(false);
  const [isAllColumnsMapped, setIsAllColumnsMapped] = useState<boolean>(false);

  // Configurations
  const [pollingTokenId, setPollingTokenId] = useState(null);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const [shouldRefetchConfigurations, setShouldRefetchConfigurations] = useState(false);

  const { selectedPlanningCycle } = useScope();

  // Resetting selected table when switching PCs
  useEffect(() => {
    setSelectedTable(null);
  }, [selectedPlanningCycle?.id]);

  useEffect(() => {
    if (selectedDataView === DataPanelViews.DATA_OVERVIEW) {
      setSelectedTable(null);
    }
  }, [selectedDataView]);

  const buildPermissionGridData = (sheetDefinitionList: ISheetDefinitionDef[]) => {
    let result;
    if (sheetDefinitionList) {
      const roleMap = {};
      // setup initial role map
      sheetDefinitionList[0]?.quotaComponents?.[0]?.roles?.forEach((role) => {
        roleMap[role.roleName || ADMIN] = {
          roleName: role.roleName || ADMIN,
          roleId: role.roleId
        };
      });

      sheetDefinitionList.forEach((sheetDefinitionItem) => {
        const measureName = sheetDefinitionItem.measureName;
        sheetDefinitionItem?.quotaComponents?.[0]?.roles?.forEach((role) => {
          roleMap[role.roleName || ADMIN] = {
            ...roleMap[role.roleName || ADMIN],
            ...{ [`${measureName}_permissions`]: { visible: role.visible, editable: role.editable } },
            ...{ [`${measureName}_sheetACLId`]: role['sheetACLId'] }
          };
        });
      });

      result = Object.values(roleMap).map((permissionData) => {
        return permissionData;
      });
    }
    setPermissionGridData(result);
  };

  const createSheetLookupMap = (sheetDefinitionList: ISheetDefinitionDef[]) => {
    return sheetDefinitionList.reduce((acc, curr) => {
      const newSheetDefinition = {
        measureId: curr.measureId,
        measureName: curr.measureName,
        sheetDefinitionId: curr.sheetDefinitionId,
        sheetDefinitionType: curr.sheetDefinitionType
      };
      acc[curr.measureName] = newSheetDefinition;
      return acc;
    }, {});
  };

  const { pollForProcessingStatus } = useGetFileUploadProgress({ fileUploadInProgress, setFileUploadInProgress });

  const { pollForSymonPipeProcessingStatus } = useGetSymonPipeConfigurationStatus(setShouldRefetchConfigurations);

  const getSheetDefinitions = async (deploymentModelId: number, sheetId: number, isTQM = false) => {
    setSheetDefinitionsLoading(true);
    try {
      const sheets: ApolloQueryResult<{
        getDeploymentModelSpec: GetSheetDefinitions_getDeploymentModelSpec;
        // eslint-disable-next-line no-restricted-syntax
      }> = await apolloClient.query({
        query: GET_SHEET_DEFINITIONS,
        variables: { deploymentModelId, sheetId, isTQM },
        fetchPolicy: 'network-only'
      });
      const sheetDefinitionList = sheets?.data?.getDeploymentModelSpec?.dataSheets?.[0]?.sheetDefinitions;
      setSheetDefinitions(sheetDefinitionList);
      const newSheetLookupMap = createSheetLookupMap(sheetDefinitionList);
      setSheetLookupMap(newSheetLookupMap);
      buildPermissionGridData(sheetDefinitionList);
      const sheetMeasuresIdLookupMap = new Map();
      const sheetMeasuresNameLookupMap = new Map();
      sheetDefinitionList.forEach((sheetDefinition) => {
        const { measureName, measureId } = sheetDefinition;
        sheetMeasuresIdLookupMap.set(measureName, measureId);
        sheetMeasuresNameLookupMap.set(measureId, measureName);
      });
      setSheetMeasuresIdLookupMap(sheetMeasuresIdLookupMap);
      setSheetMeasuresNameLookupMap(sheetMeasuresNameLookupMap);
    } catch (error) {
      console.log(error);
      setSheetDefinitionsLoading(false);
    } finally {
      setSheetDefinitionsLoading(false);
    }
  };

  const resetFileUploadValues = () => {
    setSelectedTable(null);
    setColumnParamsLookupMap({});
    setMappedFields([]);
    setMissingRequiredFields([]);
    setMappingProperties([]);
    setIsAllColumnsMapped(false);
    setShouldShowMappingError(false);
  };

  const resetValues = () => {
    setAllDataSheets([]);
    setSheetDefinitions([]);
    setSheetDefinition(null);
    setSheetDefinitionsLoading(false);
    setSheetLookupMap(null);
    setSelectedSheet(null);
    setPermissionGridData(null);
    setSelectedTable(null);
    setColumnParamsLookupMap({});
    setMappedFields([]);
    setPollingTokenId(null);
    setSelectedConfiguration(null);
    setShouldRefetchConfigurations(false);
    setMissingRequiredFields([]);
    setMappingProperties([]);
    setIsAllColumnsMapped(false);
    setShouldShowMappingError(false);
    setSelectedUserTab(UserManagementTabNames.USERS);
    setSelectedTableViewTab(TablesTab.ALL);
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
    setDataSheetDrawerState(DataSheetDrawerState.CLOSE);
    setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
    setIsLookupRowSubmitting(false);
    setSheetMeasuresIdLookupMap(null);
    setSheetMeasuresNameLookupMap(null);
  };
  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      allDataSheets,
      setAllDataSheets,
      sheetDefinitions,
      setSheetDefinitions,
      sheetDefinition,
      setSheetDefinition,
      selectedSheet,
      setSelectedSheet,
      sheetLookupMap,
      setSheetLookupMap,
      sheetDefinitionsLoading,
      setSheetDefinitionsLoading,
      getSheetDefinitions,
      permissionGridData,
      setPermissionGridData,
      selectedTable,
      setSelectedTable,
      fileUploadInProgress,
      setFileUploadInProgress,
      columnParamsLookupMap,
      setColumnParamsLookupMap,
      pollForProcessingStatus,
      mappedFields,
      setMappedFields,
      pollingTokenId,
      setPollingTokenId,
      selectedConfiguration,
      setSelectedConfiguration,
      pollForSymonPipeProcessingStatus,
      shouldRefetchConfigurations,
      setShouldRefetchConfigurations,
      missingRequiredFields,
      setMissingRequiredFields,
      shouldShowMappingError,
      setShouldShowMappingError,
      isAllColumnsMapped,
      setIsAllColumnsMapped,
      mappingProperties,
      setMappingProperties,
      selectedUserTab,
      setSelectedUserTab,
      selectedTablesTab,
      setSelectedTableViewTab,
      selectedDataView,
      setSelectedDataView,
      dataSheetDrawerState,
      setDataSheetDrawerState,
      dataSheetDrawerFormType,
      setDataSheetDrawerFormType,
      isLookupRowSubmitting,
      setIsLookupRowSubmitting,
      sheetMeasuresIdLookupMap,
      sheetMeasuresNameLookupMap,
      resetValues,
      resetFileUploadValues
    }),
    [
      allDataSheets,
      sheetDefinitions,
      sheetDefinition,
      selectedSheet,
      sheetLookupMap,
      sheetDefinitionsLoading,
      permissionGridData,
      selectedTable,
      setSelectedTable,
      columnParamsLookupMap,
      fileUploadInProgress,
      mappedFields,
      pollingTokenId,
      selectedConfiguration,
      shouldRefetchConfigurations,
      missingRequiredFields,
      shouldShowMappingError,
      isAllColumnsMapped,
      mappingProperties,
      selectedUserTab,
      selectedTablesTab,
      dataSheetDrawerState,
      dataSheetDrawerFormType,
      selectedDataView,
      isLookupRowSubmitting,
      sheetMeasuresIdLookupMap,
      sheetMeasuresNameLookupMap
    ]
  );

  // Return the interface that we want to expose to our other components
  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

// Custom hook to read these values from
export const useData = (): DataContextValues => useContextSafe(DataContext);
