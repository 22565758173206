import React from 'react';

// TODO TQP-9477 change implementation to use showToast
// eslint-disable-next-line no-restricted-imports
import { Position, Toaster } from '@blueprintjs/core';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';

import PostCoinsortProgressToast from 'app/components/PostCoinsortProgressToast/PostCoinsortProgressToast';

import { useUser } from 'app/core/userManagement/userProvider';

import { PCJobType } from 'app/graphql/generated/apolloTypes';

import { formatMessage } from 'utils/messages/utils';

const PlanningCycleToaster: React.FC = () => {
  const { pcJobsInProgress } = useUser();

  const generateToastHeading = (jobType: PCJobType): string => {
    switch (jobType) {
      case PCJobType.PLANNING_CYCLE_CLONE: {
        return formatMessage('PLANNING_CYCLE_PROGRESS_HEADING');
      }
      case PCJobType.START_DATE_CHANGE: {
        return formatMessage('PLANNING_CYCLE_START_DATE_PROGRESS_HEADING');
      }
      default:
        throw new Error('Should never get here');
    }
  };

  const generateStatusMessage = (jobType: PCJobType, pcName: string): string => {
    switch (jobType) {
      case PCJobType.PLANNING_CYCLE_CLONE: {
        return formatMessage('PLANNING_CYCLE_COPY_PROGRESS_DESCRIPTION', { name: pcName });
      }
      case PCJobType.START_DATE_CHANGE: {
        return formatMessage('PLANNING_CYCLE_START_DATE_PROGRESS_DESCRIPTION', { name: pcName });
      }

      default:
        throw new Error('Should never get here');
    }
  };

  return (
    <Toaster position={Position.TOP_RIGHT}>
      {pcJobsInProgress &&
        pcJobsInProgress.map((pc) => {
          return pc.jobType === PCJobType.START_DATE_CHANGE ? (
            <PostCoinsortProgressToast
              data-testid="planning-cycle-toast"
              key={pc.planningCycleId}
              job={pc}
              title={generateToastHeading(pc.jobType)}
              progressDescription={generateStatusMessage(pc.jobType, pc.planningCycleName)}
            />
          ) : (
            <JobProgressStatusToast
              data-testid="planning-cycle-toast"
              key={pc.planningCycleId}
              jobDetail={{
                planningCycleId: pc.planningCycleId,
                planningCycleName: pc.planningCycleName,
                jobType: pc.jobType
              }}
              title={generateToastHeading(pc.jobType)}
              progressDescription={generateStatusMessage(pc.jobType, pc.planningCycleName)}
              onPollSuccess={() => {
                // TODO use success callback instead of hardcoding logic in component
                return;
              }}
              onPollError={() => {
                // TODO use success callback instead of hardcoding logic in component
                return;
              }}
            />
          );
        })}
    </Toaster>
  );
};

export default PlanningCycleToaster;
