import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { Form, Formik } from 'formik';

import BattleCardPanelPage from 'app/components/BattleCardDiagram/Cards/BattleCardPanelPage/BattleCardPanelPage';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useLocalization } from 'app/contexts/localizationProvider';

import { UpsertNewBusinessTarget, UpsertNewBusinessTargetVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_NEW_BUSINESS_TARGET } from 'app/graphql/mutations/upsertNewBusinessTarget';

import useShowToast from 'app/hooks/useShowToast';

import { BattleCardData, BattleCardMeasure } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './BattleCardPanel.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

const ITEMS_PER_PANEL_PAGE = 6;

const slicePageItems = (currentPanelPage, data = []) => {
  const indexOfLastItemOnPanelPage = currentPanelPage * ITEMS_PER_PANEL_PAGE;
  const indexOfFirstItemOnPanelPage = indexOfLastItemOnPanelPage - ITEMS_PER_PANEL_PAGE;

  return data.slice(indexOfFirstItemOnPanelPage, indexOfLastItemOnPanelPage).reduce((result, item, index) => {
    result[index] = item;
    return result;
  }, []);
};

const getPaginationDots = (panelData: Record<number, BattleCardMeasure[]> | BattleCardMeasure[], isRootCard) => {
  let totalPageNumbers;
  const paginationDots = [];

  if (isRootCard && panelData) {
    totalPageNumbers = Math.ceil(Object.values(panelData)[0]?.length / ITEMS_PER_PANEL_PAGE);
  } else if (panelData) {
    totalPageNumbers = Math.ceil(Object.keys(panelData).length / ITEMS_PER_PANEL_PAGE);
  }

  if (totalPageNumbers) {
    for (let i = 1; i <= totalPageNumbers; i++) {
      paginationDots.push(i);
    }
  }

  return paginationDots;
};

const getCurrentPanelPageItems = (
  panelData: Record<number, BattleCardMeasure[]> | BattleCardMeasure[],
  isRootCard,
  currentPanelPage
) => {
  let currentPanelPageItems;

  if (isRootCard && panelData) {
    // assign page items for root card
    panelData = panelData as Record<number, BattleCardMeasure[]>;
    currentPanelPageItems = {};
    Object.entries(panelData).forEach(([key, value]) => {
      currentPanelPageItems[key] = slicePageItems(currentPanelPage, value);
    });
  } else if (panelData) {
    // assign page items for non-root cards
    panelData = panelData as BattleCardMeasure[];
    currentPanelPageItems = slicePageItems(currentPanelPage, panelData);
  }

  return currentPanelPageItems;
};

const getInitialFormValues = (
  panelData: Record<number, BattleCardMeasure[]> | BattleCardMeasure[],
  isRootCard,
  selectedQuotaComponentId
) => {
  const initialFormValues = {};
  if (isRootCard) {
    Object.entries(panelData).forEach(([key, value]) => {
      initialFormValues[`newBusinessTarget-${key}`] = value[0]?.measureValue;
    });
  } else {
    panelData = panelData as BattleCardMeasure[];
    initialFormValues[`newBusinessTarget-${selectedQuotaComponentId}`] =
      panelData?.length && panelData[0]?.measureValue;
  }

  return initialFormValues;
};

interface BattleCardPanelProps {
  cardData: BattleCardData;
  panelData: Record<number, BattleCardMeasure[]> | BattleCardMeasure[];
  isCurrencyConversionValid: boolean;
}

const BattleCardPanel: React.FC<BattleCardPanelProps> = ({
  cardData,
  panelData,
  isCurrencyConversionValid
}: BattleCardPanelProps) => {
  useEffect(() => {
    if (cardData) {
      setCurrentPanelPage(1);
    }
  }, [cardData]);

  const { localCurrencyCode, isLocalCurrencyMode, isRootCard, battlecardId } = cardData;
  const { setShouldRefetchBattleCardDataImmediately, selectedQuotaComponentId } = useBattleCard();
  const { defaultReportingCurrency } = useLocalization();

  const [currentPanelPage, setCurrentPanelPage] = useState(1);
  const currentPanelPageItems = getCurrentPanelPageItems(panelData, isRootCard, currentPanelPage);

  const showToast = useShowToast();
  const initialFormValues = getInitialFormValues(panelData, isRootCard, selectedQuotaComponentId);

  const [upsertNewBusinessTarget] = useMutation<UpsertNewBusinessTarget, UpsertNewBusinessTargetVariables>(
    UPSERT_NEW_BUSINESS_TARGET,
    {
      onCompleted() {
        showToast(formatMessage('UPDATE_NEW_BUSINESS_TARGET_SUCCESS'), 'success');
        setShouldRefetchBattleCardDataImmediately(true);
      },
      onError({ graphQLErrors, networkError }) {
        showToast(formatMessage('UPDATE_NEW_BUSINESS_TARGET_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const leftArrow = () => {
    const prevPanelPage = currentPanelPage - 1;
    const leftArrowButton =
      prevPanelPage === 0 ? (
        ''
      ) : (
        <ChevronLeft
          size={24}
          className={b('arrow')}
          onClick={() => setCurrentPanelPage(prevPanelPage)}
          data-testid="left-arrow-button"
        />
      );
    return <div className={b('arrowContainer')}>{leftArrowButton}</div>;
  };

  const rightArrow = () => {
    const maxPanelPage = Math.max(...paginationDots);
    const nextPanelPage = currentPanelPage + 1;
    const rightArrowButton =
      nextPanelPage > maxPanelPage ? (
        ''
      ) : (
        <ChevronRight
          size={24}
          className={b('arrow')}
          onClick={() => setCurrentPanelPage(nextPanelPage)}
          data-testid="right-arrow-button"
        />
      );
    return <div className={b('arrowContainer')}>{rightArrowButton}</div>;
  };

  const handleSubmit = (values, quotaComponentId) => {
    const newBusinessTarget = values[`newBusinessTarget-${quotaComponentId}`];

    upsertNewBusinessTarget({
      variables: {
        battlecardId: +battlecardId,
        quotaComponentId,
        newBusinessTarget: newBusinessTarget === '' ? 0 : newBusinessTarget
      }
    });
  };

  const battleCardCurrency = isLocalCurrencyMode ? localCurrencyCode : defaultReportingCurrency;

  const paginationDots = getPaginationDots(panelData, isRootCard);

  return (
    <div className={b()} data-testid="battle-card-panel">
      <div className={b({ fullHeight: paginationDots.length > 1 })}>
        <div className={b('panel', { root: isRootCard })}>
          {leftArrow()}
          <Formik
            initialValues={initialFormValues}
            onSubmit={null}
            validationSchema={validationsSchema}
            enableReinitialize
          >
            <Form className={b('form')}>
              <BattleCardPanelPage
                isEditingEnabled={!isLocalCurrencyMode}
                isCurrencyConversionValid={isCurrencyConversionValid}
                currency={battleCardCurrency}
                panelData={currentPanelPageItems}
                isRootCard={isRootCard}
                data-testid="battle-card-panel-page"
                handleSubmit={handleSubmit}
              />
            </Form>
          </Formik>
          {rightArrow()}
        </div>
      </div>
      <ul className={b('pagination')}>
        {paginationDots.length > 1 &&
          paginationDots.map((number) => (
            <li key={number} className={b('paginationDots')} onClick={() => setCurrentPanelPage(number)}>
              <div className={b('circle', { fill: number === currentPanelPage })} />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default BattleCardPanel;
