import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetMappingFields, GetMappingFieldsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_MAPPING_FIELDS = gql`
  query GetMappingFields($planningCycleId: Int!, $fileType: FileTypeEnum!, $rootHierarchyId: Int, $battlecardId: Int) {
    getMappingFields(
      input: {
        planningCycleId: $planningCycleId
        fileType: $fileType
        rootHierarchyId: $rootHierarchyId
        battlecardId: $battlecardId
      }
    ) {
      colRequired
      columnNameInDB
      dataType
      displayMappingFieldName
      fieldType
      idAssociatedToFieldType
      valueRequired
    }
  }
`;

export const useGetMappingFields = createUseQuery<GetMappingFields, GetMappingFieldsVariables>(GET_MAPPING_FIELDS);
