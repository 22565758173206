import React, { useMemo } from 'react';

import { GridReadyEvent, RowNode } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CollectionFilter, GridFields, GridHeaders, NamedHierarchy, RuleForMap } from 'app/models';

import block from 'utils/bem-css-modules';

import MapGridHeader from './MapGridHeader';
import { MAP_GRID_HEADER_HEIGHT, MAP_GRID_ROW_HEIGHT } from './TerritoryMapGrid';
import style from './TerritoryMapGrid.module.pcss';

const b = block(style);

const territoryGroupGridColumnDefs = [
  {
    headerName: '',
    field: GridFields.TERRITORY_GROUP_NAME,
    flex: 1,
    rowGroup: true,
    hide: true
  },
  {
    headerName: '',
    minWidth: 30,
    maxWidth: 30
  },
  {
    headerName: GridHeaders.TERRITORY_GROUP,
    field: GridFields.LEAF_TERRITORY_ID,
    flex: 1
  },
  {
    headerName: '',
    field: GridFields.LEAF_TERRITORY_NAME,
    flex: 1
  }
];

const ALL_ROWS_EXPANDED = -1;

interface TerritoryGroupMapRowData {
  [GridFields.TERRITORY_GROUP_NAME]: string;
  [GridFields.LEAF_TERRITORY_NAME]?: string;
  [GridFields.LEAF_TERRITORY_ID]?: string;
}

export interface TerritoryGroupMapGridProps {
  loading: boolean;
  gridHeight: number;
  gridWidth: number;
  isExternalFilterPresent: () => boolean;
  doesExternalFilterPass: (node: RowNode) => boolean;
  territoryRules: RuleForMap[];
  handleOnGridReady: (e: GridReadyEvent) => void;
  shouldShowMapGridHeader: boolean;
  mapVisualizationSettingsOnClick: () => void;
  onSearchIconClick: () => void;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
}

const GroupRowInnerRenderer = (props) => {
  return (
    <div>
      <span className={b('colorIndicator')} style={{ background: props.colorRecord[props.value] }} />
      {props.value}
    </div>
  );
};

const TerritoryGroupMapGrid: React.FC<TerritoryGroupMapGridProps> = ({
  loading,
  gridHeight,
  gridWidth,
  isExternalFilterPresent,
  doesExternalFilterPass,
  handleOnGridReady,
  territoryRules,
  shouldShowMapGridHeader,
  mapVisualizationSettingsOnClick,
  onSearchIconClick,
  customHierarchies,
  customHierarchyFilter
}) => {
  const { groupLookup, groupLevelList, territoryGroupLevel } = useDedicatedMapProvider();

  const territoryGroupRowData = useMemo(() => {
    const rowData = new Array<TerritoryGroupMapRowData>();
    const seenGroupsIds = new Set<number>();

    for (const rule of territoryRules) {
      const group = groupLookup.get(rule.territoryGroupId);
      if (!group) {
        console.warn('Missing TG for rule', { rule, groupLookup });
        continue;
      }
      rowData.push({
        [GridFields.TERRITORY_GROUP_NAME]: group.name,
        [GridFields.LEAF_TERRITORY_NAME]: rule.territoryName,
        [GridFields.LEAF_TERRITORY_ID]: rule.territoryId
      });
      seenGroupsIds.add(group.territoryGroupId);
    }

    // Add a dummy-row for all empty groups
    groupLevelList[territoryGroupLevel]?.groupsOnLevel.forEach((group) => {
      if (seenGroupsIds.has(group.territoryGroupId)) return;
      rowData.push({
        [GridFields.TERRITORY_GROUP_NAME]: group.name
      });
    });

    return JSON.stringify(rowData);
  }, [territoryRules, groupLookup]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1
    };
  }, []);

  const colorRecord = useMemo(() => {
    const record: Record<string, string> = {};
    groupLookup.forEach((group) => {
      if (!group) return;
      record[group.name] = group.color;
    });
    return record;
  }, [groupLookup]);

  const groupRowRendererParams = useMemo(() => {
    return {
      innerRenderer: 'groupRowInnerRenderer',
      suppressCount: true,
      colorRecord
    };
  }, [colorRecord]);

  return (
    <>
      {shouldShowMapGridHeader && (
        <MapGridHeader
          onClick={mapVisualizationSettingsOnClick}
          onSearchIconClick={onSearchIconClick}
          customHierarchyFilter={customHierarchyFilter}
          customHierarchies={customHierarchies}
        />
      )}
      <AdvancedGrid
        gridHeight={gridHeight}
        gridWidth={gridWidth}
        columnDefs={territoryGroupGridColumnDefs}
        data={territoryGroupRowData}
        gridProps={{
          suppressCellSelection: true,
          isExternalFilterPresent,
          doesExternalFilterPass,
          onGridReady: handleOnGridReady,
          rowHeight: MAP_GRID_ROW_HEIGHT,
          headerHeight: MAP_GRID_HEADER_HEIGHT
        }}
        data-testid="territory-groups-map-grid"
        showGridLoading={loading}
        animateRows={true}
        groupDefaultExpanded={ALL_ROWS_EXPANDED}
        groupRowRendererParams={groupRowRendererParams}
        frameworkComponents={{
          groupRowInnerRenderer: GroupRowInnerRenderer
        }}
        defaultColDef={defaultColDef}
        groupDisplayType={'groupRows' as const}
      />
    </>
  );
};

export default TerritoryGroupMapGrid;
