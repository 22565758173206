import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Placement } from '@blueprintjs/popover2';
import { ChevronDown, SubtractAlt } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import DataTypeTag from 'app/components/DataTypeTag/DataTypeTag';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { DataMappingSelectMenuItem, EXCLUDE_COLUMN, SELECT } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataMappingSelectMenu.module.pcss';

const b = block(style);

interface DataMappingSelectMenuProps {
  items: DataMappingSelectMenuItem[];
  minimal?: boolean;
  defaultSelection?: DataMappingSelectMenuItem;
  placement?: Placement;
  placeHolderText?: string;
  disabledItems?: DataMappingSelectMenuItem[];
  supportReset?: boolean;
  setValue?: (item: DataMappingSelectMenuItem) => void;
  setDisabledItems?: Dispatch<SetStateAction<DataMappingSelectMenuItem[]>>;
  showLeftIcon?: boolean;
  shouldShowMappingError?: boolean;
  supportMultiSections?: boolean;
  shouldShowExcludeColumn?: boolean;
}

const MenuItems = ({
  item,
  disabledItems,
  setDisabledItems,
  selectedItem,
  setValue,
  setSelectedItem,
  showLeftIcon
}) => {
  const itemDisabled = !!disabledItems.find((i) => i.key === item.key);

  const isUnmapped = item.key === SELECT || item.key === EXCLUDE_COLUMN;

  const handleItemSelect = (item) => {
    // remove the previous selection from disabledItems
    if (disabledItems && setDisabledItems) {
      const newDisabledItems = selectedItem ? disabledItems.filter((i) => i.key !== selectedItem.key) : disabledItems;
      if (isUnmapped) {
        setDisabledItems([...newDisabledItems]);
      } else {
        setDisabledItems([...newDisabledItems, item]);
      }
    }
    // set ag-grid value
    if (setValue) {
      setValue(item.key === SELECT ? { key: '', value: '' } : { ...item, autoMatched: false });
    }
    setSelectedItem(item.key === SELECT ? null : { ...item, autoMatched: false });
  };

  return (
    <div className={b('menuItem', { disabled: itemDisabled })} key={item.key}>
      <MenuItem
        key={item.key}
        data-testid="data-mapping-select-menu-item"
        onClick={() => handleItemSelect(item)}
        text={item.value}
        disabled={itemDisabled}
        icon={!isUnmapped && showLeftIcon && <DataTypeTag dataType={item.properties?.['dataType']} />}
      />
    </div>
  );
};

const MenuContent = ({
  supportMultiSections,
  items,
  disabledItems,
  setDisabledItems,
  selectedItem,
  setSelectedItem,
  setValue,
  showLeftIcon,
  shouldShowExcludeColumn
}) => {
  const [isColumnSubsetMappingOn] = useTreatment(SplitFeatures.COLUMN_SUBSET_MAPPING);

  const menuItemsProps = {
    disabledItems,
    setDisabledItems,
    selectedItem,
    setValue,
    setSelectedItem,
    showLeftIcon
  };

  const EXCLUDE_COLUMN_OPTION = {
    key: EXCLUDE_COLUMN,
    value: (
      <div className={b('rightIcon')}>
        {formatMessage('EXCLUDE_COLUMN')} <SubtractAlt />
      </div>
    ),
    properties: {
      colRequired: false
    }
  };

  return (
    <div data-testid="data-mapping-select-menu-content" className={b('menu')}>
      <Menu>
        {/* This is a very specific case for the column matching menu in the data mapping drill-in component. */}
        {/* If we have more consumers that require multiple sections in the future, we can refactor this part. */}
        {supportMultiSections ? (
          <>
            {isColumnSubsetMappingOn && shouldShowExcludeColumn ? (
              <div className={b('staticSection')}>
                <MenuItems item={EXCLUDE_COLUMN_OPTION} {...menuItemsProps} />
              </div>
            ) : (
              <MenuItems item={items[0]} {...menuItemsProps} />
            )}
            <MenuDivider title={formatMessage('REQUIRED')} className={b('sectionHeader')} />
            {items
              .filter((item) => item.properties?.['colRequired'])
              ?.map((item) => {
                return <MenuItems key={item.key} item={item} {...menuItemsProps} />;
              })}
            <MenuDivider title={formatMessage('OPTIONAL')} className={b('sectionHeader')} />
            {items
              .filter((item) => !item.properties?.['colRequired'] && item.key !== SELECT)
              ?.map((item) => {
                return <MenuItems key={item.key} item={item} {...menuItemsProps} />;
              })}
          </>
        ) : (
          items.map((item) => {
            const itemDisabled = !!disabledItems.find((i) => i.key === item.key);
            return (
              <div className={b('menuItem', { disabled: itemDisabled })} key={item.key}>
                {<MenuItems key={item.key} item={item} {...menuItemsProps} />}
              </div>
            );
          })
        )}
      </Menu>
    </div>
  );
};

export const DataMappingSelectMenu: React.FC<DataMappingSelectMenuProps> = ({
  items,
  minimal = false,
  defaultSelection = null,
  placement = 'bottom',
  placeHolderText = formatMessage('SELECT'),
  disabledItems,
  supportReset = false,
  setValue,
  setDisabledItems,
  showLeftIcon = false,
  shouldShowMappingError = false,
  supportMultiSections = false,
  shouldShowExcludeColumn = true
}: DataMappingSelectMenuProps) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem(defaultSelection);
  }, [defaultSelection]);

  if (supportReset) {
    items = [{ key: SELECT, value: formatMessage('SELECT') }, ...items];
  }

  return (
    <div
      data-testid="data-mapping-select-menu"
      className={b('textButton', {
        error: shouldShowMappingError && selectedItem?.value === '',
        autoMatched: selectedItem?.autoMatched
      })}
    >
      <Popover
        content={
          <MenuContent
            supportMultiSections={supportMultiSections}
            items={items}
            disabledItems={disabledItems}
            setDisabledItems={setDisabledItems}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setValue={setValue}
            showLeftIcon={showLeftIcon}
            shouldShowExcludeColumn={shouldShowExcludeColumn}
          />
        }
        placement={placement}
        minimal={minimal}
      >
        <TextButton
          type="button"
          text={selectedItem?.value || placeHolderText}
          rightIcon={<ChevronDown />}
          className={b('button')}
          testId={'data-mapping-select-menu-text-button'}
        />
      </Popover>
    </div>
  );
};

export default DataMappingSelectMenu;
