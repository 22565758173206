import { BCMapLevelEnum } from 'app/graphql/generated/apolloTypes';

import { BattlecardType, MapLevelType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export const cardTypeSelectionDropDownData = [
  { key: formatMessage('PRIMARY_TEAM'), value: BattlecardType.PrimaryTeam },
  { key: formatMessage('DIRECT_OVERLAY'), value: BattlecardType.DirectOverlay },
  { key: formatMessage('ROLL_UP'), value: BattlecardType.Rollup }
];

export const cardTypeSelectionDropDownDataRollup = [
  { key: formatMessage('BATTLECARD_TYPE_PRIMARY'), value: BattlecardType.PrimaryTeam },
  { key: formatMessage('BATTLECARD_TYPE_ROLL_UP'), value: BattlecardType.Rollup }
];

export const cardTypeSelectionDropDownDataPrimaryTeam = [
  { key: formatMessage('DIRECT_OVERLAY'), value: BattlecardType.DirectOverlay }
];

export const cardTypeSelectionDropDownDataMapLevel = [
  { key: MapLevelType.ZIP_OR_POSTAL, value: BCMapLevelEnum.zipPostal },
  { key: MapLevelType.STATE_OR_PROVINCE, value: BCMapLevelEnum.stateProvince },
  { key: MapLevelType.UNDECIDED, value: BCMapLevelEnum.undecided }
];
