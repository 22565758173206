import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetHierarchyForField, GetHierarchyForFieldVariables } from 'app/graphql/generated/apolloTypes';

export const GET_HIERARCHY_FOR_FIELD = gql`
  query GetHierarchyForField($deploymentModelId: Int!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId }) {
      pinnedHierarchies {
        hierarchyId
        hierarchyType
        name
        customProperties
      }
    }
  }
`;

export const useGetHierarchyForField = createUseQuery<GetHierarchyForField, GetHierarchyForFieldVariables>(
  GET_HIERARCHY_FOR_FIELD
);
