import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { publishFileToDB, publishFileToDBVariables } from 'app/graphql/generated/apolloTypes';

export const PUBLISH_FILE_TO_DB = gql`
  mutation publishFileToDB($input: UpsertMappingInput!) {
    publishFileToDB(input: $input) {
      fieldName
      value {
        id
        type
      }
    }
  }
`;

export const usePublishFileToDb = createUseMutation<publishFileToDB, publishFileToDBVariables>(PUBLISH_FILE_TO_DB);
