import React, { forwardRef, useImperativeHandle, useState, KeyboardEvent } from 'react';

import { ICellEditorParams, RowNode } from '@ag-grid-community/core';

import EditableCurrencyCellRenderer from 'app/components/AdvancedGrid/CellRenderers/EditableCurrencyCellRenderer/EditableCurrencyCellRenderer';

import { CurrencyCode } from 'app/models';

interface CurrencyCellEditorProps extends ICellEditorParams {
  currency: CurrencyCode;
  handleOnBlur: (currentValue: string) => void;
  onCellClick?: (data: RowNode) => void;
  value: string;
}

export default forwardRef((props: CurrencyCellEditorProps, ref) => {
  const { value, currency, data, handleOnBlur, onCellClick } = props;
  const [currentValue, setCurrentValue] = useState<string>(props.value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });

  const handleCurrentCell = () => {
    !!onCellClick && onCellClick(data);
  };

  const handleBlur = () => {
    handleCurrentCell();
    handleOnBlur(currentValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      handleCurrentCell();
      handleOnBlur(currentValue);
    }
  };

  return (
    <EditableCurrencyCellRenderer
      onKeyDown={handleKeyDown}
      value={currentValue}
      currency={currency}
      onChange={setCurrentValue}
      onBlur={handleBlur}
    />
  );
});
