import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetJobProgress, GetJobProgressVariables } from 'app/graphql/generated/apolloTypes';

export const GET_JOB_PROGRESS = gql`
  query GetJobProgress($input: JobInput!) {
    getJobProgress(input: $input) {
      pcCloneJobOutput {
        pcCloneStatus
        numberOfTables
        numberOfTablesProcessed
      }
      territoryRuleDeleteJobOutput {
        territoryRulesDeleteStatus
        message
      }
      allocateTopDownJobOutput {
        status
      }
    }
  }
`;

export const useGetJobProgressLazy = createUseLazyQuery<GetJobProgress, GetJobProgressVariables>(GET_JOB_PROGRESS);
