import { QueryTuple, useLazyQuery } from '@apollo/client';

import { GetSymonPipeExportNodes, GetSymonPipeExportNodesVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_SYMON_PIPE_EXPORT_NODES } from 'app/graphql/queries/getSymonPipeExportNodes';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useGetSymonPipeExportNodes = (): QueryTuple<GetSymonPipeExportNodes, GetSymonPipeExportNodesVariables> =>
  useLazyQuery<GetSymonPipeExportNodes, GetSymonPipeExportNodesVariables>(GET_SYMON_PIPE_EXPORT_NODES, {
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('GET_SYMON_EXPORT_NODES_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });
