import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTerritoryGroupsForMap, GetTerritoryGroupsForMapVariables } from 'app/graphql/generated/apolloTypes';

export const QUERY_GET_TERRITORY_GROUPS_FOR_MAP = gql`
  query GetTerritoryGroupsForMap($battlecardId: Int!, $quotaComponentId: Int!) {
    getAllTerritoryGroups(battlecardId: $battlecardId, quotaComponentId: $quotaComponentId) {
      allTerritoryGroups {
        hierarchyTopId
        hierarchyType
        territoryGroupId
        territoryGroupParentId
        territoryGroupName
        owner {
          firstName
          lastName
        }
      }
    }
  }
`;

export const useGetTerritoryGroupsForMap = createUseQuery<GetTerritoryGroupsForMap, GetTerritoryGroupsForMapVariables>(
  QUERY_GET_TERRITORY_GROUPS_FOR_MAP
);
