import React from 'react';

import { OverflowMenuVertical } from '@carbon/icons-react';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import SheetsAddFieldCellRenderer from 'app/components/AdvancedGrid/CellRenderers/SheetsAddFieldCellRenderer/SheetsAddFieldCellRenderer';

import { GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';

import style from './SheetsFieldHeaderCellRenderer.module.pcss';

const b = block(style);

interface SheetsFieldHeaderCellRendererParams {
  header: string;
  sheetDefinition?: GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions;
}

export const SheetsFieldHeaderCellRenderer: React.FC<SheetsFieldHeaderCellRendererParams> = ({
  header,
  sheetDefinition
}: SheetsFieldHeaderCellRendererParams) => {
  return (
    <div className={b()} data-testid="quota-sheet-header-cell-renderer">
      <EllipsisText text={header} className={b('headerTextContainer')} />
      <SheetsAddFieldCellRenderer
        popoverTarget={<OverflowMenuVertical size={24} data-testid="menu-icon" tabIndex="0" />}
        sheetDefinition={sheetDefinition}
      />
    </div>
  );
};

export default SheetsFieldHeaderCellRenderer;
