import { GetSheetMeasures_getDeploymentModelSpec_sheetMeasures } from 'app/graphql/generated/apolloTypes';

import { MeasureType } from 'app/models';

export const getMeasureByType = <T extends { measureName: MeasureType | string }>(
  measures: T[],
  measureType: MeasureType
): T => measures?.find((measure) => measure.measureName === measureType);

export const getAllocatedMeasureId = (measures: GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[]): number => {
  const allocatedMeasure = measures.find((measure) => measure.measureName === MeasureType.ALLOCATE_TD);
  return allocatedMeasure?.measureId;
};
