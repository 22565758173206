import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetPinnedHierarchies, GetPinnedHierarchiesVariables } from 'app/graphql/generated/apolloTypes';

export const GET_PINNED_HIERARCHIES = gql`
  query GetPinnedHierarchies($deploymentModelId: Int!, $battlecardId: Int, $includeFirstLevelMemberCount: Boolean!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId, battlecardId: $battlecardId }) {
      pinnedHierarchies {
        hasChildren
        hierarchyId
        hierarchyType
        key
        name
        planningCycleId
        version
        firstLevelMemberCount @include(if: $includeFirstLevelMemberCount)
      }
    }
  }
`;

export const useGetPinnedHierarchies = createUseQuery<GetPinnedHierarchies, GetPinnedHierarchiesVariables>(
  GET_PINNED_HIERARCHIES
);
