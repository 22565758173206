import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Column, GridApi, SortChangedEvent } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import isequal from 'lodash.isequal';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { formatFilterForRequest } from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleHelpers';
import TerritoryQuotaDrillInGridHeader from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaDrillInGrid/TerritoryQuotaDrillInGridHeader/TerritoryQuotaDrillInGridHeader';

import { CELL_HEIGHT, MONTH_PRESET_ARRAY } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE } from 'app/global/variables';

import {
  GetDataSheets_getDeploymentModelSpec_dataSheets,
  SortModel,
  GetTerritoryQuotaInput
} from 'app/graphql/generated/apolloTypes';
import { useGetAccountQuotaRedirectDistributionLazy } from 'app/graphql/queries/getAccountQuotaRedirectDistribution';
import { useGetAccountQuotaRedirectDistributionTotalsLazy } from 'app/graphql/queries/getAccountQuotaRedirectDistributionTotals';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { DefaultSheetName, FilterChangeInput, FilterInput, MonthMap, SheetType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryQuotaDrillInGrid.module.pcss';
import buildTerritoryQuotaDrillInColumnDefs from './TerritoryQuotaDrillInGridColumnDef';
import { formatTerritoryQuotaDrillInData, generateMonthlyBreakdownColumns } from './TerritoryQuotaDrillInUtil';

const b = block(style);

const TerritoryQuotaDrillInGrid: React.FC = () => {
  const containerRef = useRef(null);
  const { selectedQuotaDrillInTerritory } = useGrid();
  const { selectedBattleCardId, battleCardLookupMap, selectedQuotaComponentId } = useBattleCard();
  const { selectedDeploymentModelId } = useScope();
  const { getSheetDefinitions, sheetDefinitions, allDataSheets, setSelectedSheet, sheetDefinitionsLoading } = useData();
  const { accountQuotaTotal, accountQuotaMonthlyBreakdownTotals } = selectedQuotaDrillInTerritory;
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const { defaultReportingCurrency } = useLocalization();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const [sortModel, setSortModel] = useState<SortModel>({ sortModel: [] });
  const showToast = useShowToast();
  const [isManageQBTSortFilterEnabled] = useTreatment(SplitFeatures.MANAGE_QBT_SORT_FILTER);
  const [savedFilterModel, setSavedFilterModel] = useState<FilterInput>({});

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isBattleCardSelected = !selectedPillId || selectedPillIdPlanTargets === 'battlecard';
  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const defaultAccountQuotaDistribution = allDataSheets.filter(
    (sheet) =>
      sheet.sheetType === SheetType.ACCOUNT_QUOTA_DISTRIBUTION_SHEET &&
      sheet.sheetName === DefaultSheetName.ACCOUNT_QUOTA_DISTRIBUTION_SHEET
  )?.[0];

  useEffect(() => {
    if (accountQuotaTotal && gridApi && !isManageQBTSortFilterEnabled) {
      const pinnedData = [
        {
          quota: accountQuotaTotal,
          ...accountQuotaMonthlyBreakdownTotals
        }
      ];
      gridApi.setPinnedBottomRowData(pinnedData);
    }
  }, [accountQuotaTotal, gridApi, selectedQuotaDrillInTerritory]);

  useEffect(() => {
    if (defaultAccountQuotaDistribution) {
      setSelectedSheet(defaultAccountQuotaDistribution as GetDataSheets_getDeploymentModelSpec_dataSheets);
      getSheetDefinitions(selectedDeploymentModelId, defaultAccountQuotaDistribution?.sheetId, true);
    }
  }, [allDataSheets]);

  const { selectedPlanningCycle } = useScope();
  const gridWidth = containerRef?.current?.offsetWidth;
  const gridHeight = containerRef?.current?.offsetHeight;

  const quotaDistributionSheetId = allDataSheets.find(
    (sheet) => sheet.sheetType === SheetType.ACCOUNT_QUOTA_DISTRIBUTION_SHEET
  )?.sheetId;

  const getQueryVariables = (): GetTerritoryQuotaInput => {
    let variables;

    if (
      isBattleCardSelected &&
      selectedBattleCardId &&
      (!selectedPillId || selectedPillId === 'battlecard') &&
      selectedQuotaComponentId
    ) {
      //Request when a battle card is selected
      variables = getSelectedBattleCardVariables();
    } else if (selectedBattleCardId && selectedPillId && selectedQuotaComponentId) {
      //Request when a pill is selected
      variables = getSelectedTerritoryPillVariables();
    } else if (!selectedBattleCardId && selectedPillId && selectedQuotaComponentId) {
      //Request when in the contributor view
      variables = getContributorViewVariables();
    }
    return variables;
  };

  const getSelectedBattleCardVariables = () => {
    const battlecardId = +selectedBattleCardId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      battlecardId,
      quotaComponentId,
      sheetId: quotaDistributionSheetId,
      ruleId: selectedQuotaDrillInTerritory.ruleId
    };
  };

  const getSelectedTerritoryPillVariables = () => {
    const battlecardId = +selectedBattleCardId;
    const territoryGroupId = +selectedPillId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      battlecardId,
      quotaComponentId,
      territoryGroupId,
      sheetId: quotaDistributionSheetId,
      ruleId: selectedQuotaDrillInTerritory.ruleId
    };
  };

  const getContributorViewVariables = () => {
    const territoryGroupId = +selectedPillId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      quotaComponentId,
      territoryGroupId,
      sheetId: quotaDistributionSheetId,
      ruleId: selectedQuotaDrillInTerritory.ruleId
    };
  };

  const accountRuleGridInput = useMemo(() => {
    const variables = getQueryVariables();
    return {
      ...variables,
      filters: formatFilterForRequest(savedFilterModel),
      sorting: sortModel,
      startRow: 1,
      endRow: BLOCK_SIZE
    };
  }, [
    selectedBattleCardId,
    selectedQuotaComponentId,
    savedFilterModel,
    sortModel,
    quotaDistributionSheetId,
    selectedQuotaDrillInTerritory.ruleId
  ]);
  const accountRuleGridInputRef = useAsLiveRef(accountRuleGridInput);

  const [getAccountQuotaRedirectDistribution, { fetchMore: fetchMoreAccountRedirectQuotaDistribution, error }] =
    useGetAccountQuotaRedirectDistributionLazy({
      variables: {
        input: accountRuleGridInput
      },
      fetchPolicy: 'network-only',
      onError() {
        showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
      }
    });

  const [getAccountQuotaRedirectDistributionTotals, { data: accountRedirectQuotaTotal }] =
    useGetAccountQuotaRedirectDistributionTotalsLazy({
      variables: {
        input: accountRuleGridInput
      },
      fetchPolicy: 'network-only',
      onError() {
        showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
      }
    });

  useEffect(() => {
    const variables = getQueryVariables();
    getAccountQuotaRedirectDistributionTotals({
      variables: {
        input: {
          ...variables,
          filters: formatFilterForRequest(savedFilterModel)
        }
      }
    });
  }, [
    selectedBattleCardId,
    selectedQuotaComponentId,
    savedFilterModel,
    quotaDistributionSheetId,
    selectedQuotaDrillInTerritory.ruleId
  ]);

  const total = accountRedirectQuotaTotal?.getTerritoryQuota?.accountRedirectQuotaDistributionTotals;

  useEffect(() => {
    if (total && gridApi && isManageQBTSortFilterEnabled) {
      const accountQuotaMonthlyBreakdownTotalsCalculated = {};
      MONTH_PRESET_ARRAY.forEach((month) => {
        accountQuotaMonthlyBreakdownTotalsCalculated[month] = 0;
      });

      total.measureValueBreakdown.forEach((measureValueBreakdown) => {
        const month = measureValueBreakdown.periodStartDate.split('-')[1];
        const key = MonthMap[+month];
        if (MONTH_PRESET_ARRAY.includes(key)) {
          accountQuotaMonthlyBreakdownTotalsCalculated[key] += measureValueBreakdown.measureValue;
        }
      });
      const pinnedData = [
        {
          quota: total.measureValue,
          ...accountQuotaMonthlyBreakdownTotalsCalculated
        }
      ];
      gridApi?.setPinnedBottomRowData(pinnedData);
    }
  }, [total, gridApi]);

  const handleOnGridReady = (gridEvent) => {
    setGridApi(gridEvent.api);
  };

  const monthlyBreakdownColumnsBasedOnPcDuration = generateMonthlyBreakdownColumns(selectedPlanningCycle);

  const gridProps = useMemo(
    (): AgGridReactProps => ({
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial',
      rowHeight: CELL_HEIGHT,
      cacheBlockSize: BLOCK_SIZE,
      serverSideDatasource: {
        getRows: async (params) => {
          try {
            const { startRow, endRow } = params.request;
            const { data } =
              endRow === BLOCK_SIZE
                ? await getAccountQuotaRedirectDistribution()
                : await fetchMoreAccountRedirectQuotaDistribution({
                    variables: {
                      input: {
                        ...accountRuleGridInputRef.current,
                        startRow: startRow + 1,
                        endRow
                      }
                    }
                  });
            params.success({
              rowData: formatTerritoryQuotaDrillInData(
                data.getTerritoryQuota.accountRedirectQuotaDistribution,
                monthlyBreakdownColumnsBasedOnPcDuration
              ),
              rowCount: data.getTerritoryQuota.accountRedirectQuotaDistributionCount
            });
          } catch {
            params.fail();
          }
        }
      }
    }),
    [accountRuleGridInput]
  );

  const handleSortChanged = (event: SortChangedEvent) => {
    const columns: Column[] = event.columnApi.getAllColumns();
    const sortedColumns: Column[] = Object.values(columns).filter((column) => {
      return column['sort'];
    });
    const sortModel: SortModel = { sortModel: [] };
    sortedColumns.forEach((column) => {
      sortModel.sortModel.push({ colId: column['colId'], sort: column['sort'] });
    });

    setSortModel(sortModel);
  };

  const headerClassNames = b('header');
  const cellClassNames = b('rightAlignedCell');
  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;

  const draftAccountFilters = useRef<FilterInput>({});

  const handleFilterChange = (updatedFilter: FilterChangeInput) => {
    const { filterType, type, filter, colId } = updatedFilter;
    draftAccountFilters.current = {
      ...draftAccountFilters.current,
      [colId]: {
        filterType,
        type,
        filter,
        filterTo: null
      }
    };
  };

  const handleFilterApply = () => {
    if (!isequal(draftAccountFilters.current, savedFilterModel)) {
      setSavedFilterModel(draftAccountFilters.current);
      gridApi?.onFilterChanged();
    }
  };

  const handleClearAll = () => {
    draftAccountFilters.current = {};
    handleFilterApply();
  };

  const activeFiltersCount = useMemo(
    () => Object.values(savedFilterModel).filter(({ filter }) => !!filter || filter === 0).length,
    [savedFilterModel]
  );

  const shouldShowEmptyGrid = error || !selectedQuotaDrillInTerritory.ruleId || !quotaDistributionSheetId;

  return (
    <div className={b('advancedGridWrapper')} ref={containerRef} data-testid="territory-quota-drill-in">
      {isManageQBTSortFilterEnabled && (
        <TerritoryQuotaDrillInGridHeader
          accountFilters={draftAccountFilters.current}
          activeFiltersCount={activeFiltersCount}
          onFilterChange={handleFilterChange}
          onFilterApply={handleFilterApply}
          onClearAll={handleClearAll}
          data-testid="territory-quota-drill-in-grid-header"
        />
      )}
      {shouldShowEmptyGrid ? (
        <div className={b('gridOverlayContainer')} data-testid="no-data-overlay">
          <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
        </div>
      ) : (
        <AdvancedGrid
          gridProps={gridProps}
          gridWidth={gridWidth}
          gridHeight={gridHeight}
          columnDefs={buildTerritoryQuotaDrillInColumnDefs(
            selectedQuotaComponentId,
            sheetDefinitions,
            headerClassNames,
            monthlyBreakdownColumnsBasedOnPcDuration,
            cellClassNames,
            currency,
            isManageQBTSortFilterEnabled
          )}
          onSortChanged={handleSortChanged}
          showGridLoading={sheetDefinitionsLoading}
          data-testid="territory-quota-drill-in-grid"
          onGridReady={handleOnGridReady}
        />
      )}
    </div>
  );
};

// Used to ensure the value of the variables used in the `getRows` calls are up to date despite not being able to update the callback itself
function useAsLiveRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}

export default TerritoryQuotaDrillInGrid;
