import { useRef, useState } from 'react';

import { GetUserSpec } from 'app/graphql/generated/apolloTypes';
import { useGetUserSpec } from 'app/graphql/queries/getUserSpec';

// The user spec query would ordinarily re-fetch whenever the apollo-link changes
// This hook prevents that to avoid a circular dependency
export const useGetUserSpecExactlyOnce = (): { userSpec: GetUserSpec | null; loading: boolean | null } => {
  const hasFinished = useRef(false);
  const [userSpec, setUserSpec] = useState<GetUserSpec | null>(null);
  const { loading } = useGetUserSpec({
    skip: hasFinished.current,
    onCompleted: (userSpec) => {
      hasFinished.current = true;
      setUserSpec(userSpec);
    },
    onError: () => {
      hasFinished.current = true;
    }
  });
  return { userSpec, loading };
};
