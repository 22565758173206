import { useEffect } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { GetUserSpec, GetUserTenant } from 'app/graphql/generated/apolloTypes';

import { PeriodicityType } from 'app/models';

import { useBreakdownUserSpec, UserSpecBreakdown } from './useBreakdownUserSpec';
import { EmbeddedMapSlugs } from './useEmbeddedMapSlugs';

export const useSetupScopeForEmbeddedMap = (
  userSpec: GetUserSpec | GetUserTenant | null,
  slugs: EmbeddedMapSlugs
): void => {
  const specBreakdown = useBreakdownUserSpec(userSpec, slugs);
  useWriteToScopeProvider(specBreakdown);
};

const useWriteToScopeProvider = ({ tenantSpec, planningCycleSpec, deploymentModelSpec }: UserSpecBreakdown): void => {
  const { setSelectedTenant, setSelectedPlanningCycle, setSelectedDeploymentModelId } = useScope();
  useEffect(() => {
    setSelectedTenant(
      tenantSpec
        ? {
            id: tenantSpec.tenantId,
            globalId: tenantSpec.tenantGlobalId,
            slug: tenantSpec.tenantSlug
          }
        : null
    );
  }, [tenantSpec]);

  useEffect(() => {
    setSelectedPlanningCycle(
      planningCycleSpec
        ? {
            id: planningCycleSpec.planningCycleId,
            slug: planningCycleSpec.planningCycleSlug,
            periodicity: PeriodicityType[planningCycleSpec.planningCyclePeriodicity.toUpperCase()],
            planningCycleDuration: planningCycleSpec.planningCycleDuration,
            planningCycleStartDate: planningCycleSpec.planningCycleStartDate,
            planningCycleName: planningCycleSpec.planningCycleName
          }
        : null
    );
  }, [planningCycleSpec]);

  useEffect(() => {
    setSelectedDeploymentModelId(deploymentModelSpec ? deploymentModelSpec.deploymentModelId : null);
  }, [deploymentModelSpec]);
};
