import { ColDef } from '@ag-grid-community/core';

import ExtraItemTagCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExtraItemTagCellRenderer/ExtraItemTagCellRenderer';
import IconButtonCellRenderer from 'app/components/AdvancedGrid/CellRenderers/IconButtonCellRenderer/IconButtonCellRenderer';
import { RuleCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/RuleCellRenderer/RuleCellRenderer';
import { SelectAllCheckboxHeaderCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/SelectAllCheckboxHeaderCellRenderer/SelectAllCheckboxHeaderCellRenderer';
import TerritoryGroupRenderer from 'app/components/AdvancedGrid/CellRenderers/TerritoryGroupRenderer/TerritoryGroupRenderer';
import { TextRenderer } from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';
import { formatDataForExtraTagCellRenderer } from 'app/components/AdvancedGrid/GridHelper';

import { MEASURES_GRID_COLUMN_WIDTH_ICON_SMALL } from 'app/global/variables';

import { GetTerritoryRules_getTerritoryRules_territoryRules } from 'app/graphql/generated/apolloTypes';

import {
  IconButtonCellRendererType,
  UserRoleType,
  GridHeaders,
  GridFields,
  SelectedCell,
  TerritoryGridRow
} from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface TerritoryColumnDefParams {
  data: GetTerritoryRules_getTerritoryRules_territoryRules[];
  activityDrillIn: (rowData: TerritoryGridRow) => void;
  onEditTerritory: (rowData: TerritoryGridRow) => void;
  userRole: UserRoleType;
  selectedCell: SelectedCell;
  isBulkDeleteTerOn?: boolean;
}

const buildTerritoryColumnDef = (territoryColumnDefParams: TerritoryColumnDefParams): ColDef[] => {
  const { activityDrillIn, onEditTerritory, userRole, selectedCell, isBulkDeleteTerOn } = territoryColumnDefParams;

  const getCellStyle = (params) => {
    if (selectedCell && params.rowIndex === selectedCell.rowIndex && params.colDef.field === selectedCell.column) {
      return { 'background-color': 'rgb(43,79,244)', color: 'white' };
    } else {
      return { 'background-color': 'transparent', color: 'rgb(var(--color-gray-2))' };
    }
  };

  const getAdminMenuColumns = () => {
    return [
      {
        width: MEASURES_GRID_COLUMN_WIDTH_ICON_SMALL,
        pinned: 'right',
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned) {
            return {
              frameworkComponent: '',
              params
            };
          } else {
            return {
              frameworkComponent: IconButtonCellRenderer,
              params: {
                onEditCellClick: onEditTerritory,
                iconCellRendererType: IconButtonCellRendererType.EDIT_TERRITORY
              }
            };
          }
        }
      }
    ];
  };

  const columnDefs: ColDef[] = [
    {
      headerName: formatMessage('TERRITORY_ID'),
      field: GridFields.TERRITORY_ID,
      pinned: 'left',
      sortable: true,
      flex: 1,
      minWidth: 200,
      maxWidth: null,
      checkboxSelection: true,
      cellRendererFramework: TextRenderer,
      cellRendererParams: {
        onCellClick: activityDrillIn
      },
      headerComponentFramework: isBulkDeleteTerOn ? SelectAllCheckboxHeaderCellRenderer : null,
      headerComponentParams: {
        displayName: GridHeaders.TERRITORY_ID
      },
      cellStyle: (params) => getCellStyle(params)
    },
    {
      headerName: formatMessage('TERRITORY_NAME'),
      field: GridFields.TERRITORY_NAME,
      pinned: 'left',
      sortable: true,
      flex: 1,
      minWidth: 200,
      maxWidth: null,
      cellRendererFramework: TextRenderer,
      cellStyle: (params) => getCellStyle(params)
    },
    {
      headerName: formatMessage('TERRITORY_GROUP'),
      field: GridFields.TERRITORY_GROUP,
      pinned: 'left',
      flex: 1,
      minWidth: 200,
      maxWidth: null,
      cellRendererFramework: TerritoryGroupRenderer,
      cellStyle: (params) => getCellStyle(params)
    },
    {
      headerName: formatMessage('SELLER'),
      field: GridFields.SELLER,
      flex: 1,
      pinned: 'left',
      minWidth: 250,
      maxWidth: null,
      cellRendererFramework: ExtraItemTagCellRenderer,
      cellRendererParams: (params) => {
        const formatSellerName = (seller) => seller && `${seller.firstName} ${seller.lastName}`;
        return formatDataForExtraTagCellRenderer(params, GridFields.OWNERS, formatSellerName);
      },
      cellStyle: (params) => getCellStyle(params)
    },
    {
      headerName: formatMessage('TERRITORY_RULE'),
      field: GridFields.TERRITORY_RULE,
      flex: 1,
      minWidth: 200,
      maxWidth: null,
      cellRendererFramework: RuleCellRenderer,
      cellStyle: (params) => getCellStyle(params)
    }
  ];
  // eslint-disable-next-line no-restricted-syntax
  if (userRole === UserRoleType.ADMIN) {
    return [...columnDefs, ...getAdminMenuColumns()];
  }
  return columnDefs;
};

export default buildTerritoryColumnDef;
