import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { StartFileProcess, StartFileProcessVariables } from 'app/graphql/generated/apolloTypes';

export const START_FILE_PROCESS = gql`
  mutation StartFileProcess($input: FileProcessInput!) {
    startFileProcess(input: $input) {
      fileId
    }
  }
`;

export const useStartFileProcess = createUseMutation<StartFileProcess, StartFileProcessVariables>(START_FILE_PROCESS);
