import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { RequestDeploymentModelClone, RequestDeploymentModelCloneVariables } from 'app/graphql/generated/apolloTypes';

export const REQUEST_DEPLOYMENT_MODEL_CLONE = gql`
  mutation RequestDeploymentModelClone(
    $deploymentModelId: Int!
    $clonedDeploymentModelType: DeploymentModelTypeEnum
    $clonedDeploymentModelName: String
  ) {
    requestDeploymentModelClone(
      input: {
        deploymentModelId: $deploymentModelId
        clonedDeploymentModelType: $clonedDeploymentModelType
        clonedDeploymentModelName: $clonedDeploymentModelName
      }
    ) {
      messageId
    }
  }
`;

export const useRequestDeploymentModelClone = createUseMutation<
  RequestDeploymentModelClone,
  RequestDeploymentModelCloneVariables
>(REQUEST_DEPLOYMENT_MODEL_CLONE);
