import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { ApolloError, ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetSheetMeasures_getDeploymentModelSpec_sheetMeasures,
  GetSheetMeasures_getDeploymentModelSpec
} from 'app/graphql/generated/apolloTypes';
import { QUERY_ALL_TERRITORY_GROUPS_MEASURE } from 'app/graphql/queries/getAllTerritoryGroupsMeasure';
import { GET_MEASURES } from 'app/graphql/queries/getMeasures';
import { GET_SHEET_MEASURES } from 'app/graphql/queries/getSheetMeasures';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  PlanTargetsMeasure,
  TerritoryGroupWithMeasuresNodeData,
  AllPlanTargetsData,
  BattleCardTarget,
  BaseContext
} from 'app/models';

import { sortByPrecedence } from 'utils/helpers/index';
import { getAllocatedMeasureId } from 'utils/helpers/measureUtils';

export type AllocateJobInProgress = {
  battlecardId: number;
  quotaComponentId: number;
  jobStatus: string;
  selectedPillId?: number | null;
};

export interface PlanTargetsContextValues extends BaseContext {
  setSelectedPillIdPlanTargets: Dispatch<SetStateAction<string>>;
  selectedPillIdPlanTargets: string;

  planTargetsLookupMap: Record<string, Record<string, TerritoryGroupWithMeasuresNodeData>>;

  planTargetsTreeLookupMap: Record<string, TerritoryGroupWithMeasuresNodeData[]>;
  battleCardAllocationLookupMap: Record<string, BattleCardTarget>;

  tgExpandedLookupMap: Record<string, boolean>;
  setTgExpandedCollapsed: (territoryGroupId: string, isExpanded: boolean) => void;

  measuresData: PlanTargetsMeasure[];

  measuresLoading: boolean;

  getMeasures: (planningCycleId: number) => Promise<PlanTargetsMeasure[]>;
  sheetMeasuresData: GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[];

  // force a refetch of plan targets values
  getPlanTargets: (
    battleCardId: string,
    quotaComponentId: number,
    planningCycleId: number,
    deploymentModelId: number
  ) => Promise<void>;
  planTargetsLoadingLookupMap: Record<string, boolean>;
  planTargetsErrorLookupMap: Record<string, readonly ApolloError[]>;

  // callback to call when plan targets are updated
  onUpdatePlanTargets: (...args: []) => void;
  setOnUpdatePlanTargets: Dispatch<SetStateAction<(...args: []) => void>>;
  topdownAllocationJobStatusLookupMap: AllocateJobInProgress[];
  setTopdownAllocationJobStatusLookupMap: Dispatch<SetStateAction<AllocateJobInProgress[] | null>>;
  resetValues: () => void;
}

export const PlanTargetsContext = React.createContext<PlanTargetsContextValues | null>(null);
PlanTargetsContext.displayName = 'PlanTargetsContext';

export const PlanTargetsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [selectedPillIdPlanTargets, setSelectedPillIdPlanTargets] = useState<string | null>(null);

  const [planTargetsLookupMap, setPlanTargetsLookupMap] =
    useState<Record<string, Record<string, TerritoryGroupWithMeasuresNodeData>>>();
  const [planTargetsTreeLookupMap, setPlanTargetsTreeLookupMap] =
    useState<Record<string, TerritoryGroupWithMeasuresNodeData[]>>();
  const [battleCardAllocationLookupMap, setBattleCardAllocationLookupMap] =
    useState<Record<string, BattleCardTarget>>();

  const [tgExpandedLookupMap, setTGExpandedLookupMap] = useState<Record<string, boolean>>({});

  const [measuresData, setMeasuresData] = useState<PlanTargetsMeasure[]>();
  const [sheetMeasuresData, setSheetMeasuresData] = useState<
    GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[] | null
  >();
  const [measuresLoading, setMeasuresLoading] = useState<boolean>(false);

  const [planTargetsLoadingLookupMap, setPlanTargetsLoadingLookupMap] = useState<Record<string, boolean>>();
  const [planTargetsErrorLookupMap, setPlanTargetsErrorLookupMap] = useState<Record<string, readonly ApolloError[]>>();

  const [onUpdatePlanTargets, setOnUpdatePlanTargets] = useState<(...args: []) => void | null>();
  const [topdownAllocationJobStatusLookupMap, setTopdownAllocationJobStatusLookupMap] = useState<
    AllocateJobInProgress[]
  >([]);

  const createLookupMap = (flatList, allocatedMeasureId, battleCardId, quotaComponentId) => {
    const map = flatList.reduce((acc, curr) => {
      const reshapedNode = {
        name: curr.territoryGroupName,
        territoryGroupId: curr.territoryGroupId,
        // if parentId is null, keep it as null; otherwise must cast parentId to string
        territoryGroupParentId: !curr.territoryGroupParentId
          ? curr.territoryGroupParentId
          : curr.territoryGroupParentId.toString(),
        precedence: curr.precedence,
        measures: curr.measures,
        measureId: allocatedMeasureId,
        battleCardId,
        quotaComponentId,
        children: []
      };
      acc[curr.territoryGroupId] = reshapedNode;
      return acc;
    }, {});

    return map;
  };

  const getMeasures = async (planningCycleId: number) => {
    const measures: ApolloQueryResult<{
      getMeasures: PlanTargetsMeasure[];
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_MEASURES,
      variables: { planningCycleId },
      fetchPolicy: 'network-only'
    });
    const measuresData = measures?.data?.getMeasures;
    setMeasuresData(measuresData);
    setMeasuresLoading(measures?.loading);
    return measuresData;
  };

  const getSheetMeasures = async (deploymentModelId: number, quotaComponentId: number, battlecardId: number) => {
    const sheetMeasures: ApolloQueryResult<{
      getDeploymentModelSpec: GetSheetMeasures_getDeploymentModelSpec;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_SHEET_MEASURES,
      variables: { deploymentModelId, quotaComponentId, battlecardId },
      fetchPolicy: 'network-only'
    });

    const measures = sheetMeasures?.data?.getDeploymentModelSpec?.sheetMeasures;
    setSheetMeasuresData(measures);
    return measures;
  };

  const getPlanTargets = async (battleCardId, quotaComponentId, planningCycleId, deploymentModelId) => {
    if (!battleCardId || !quotaComponentId || !planningCycleId || !deploymentModelId) {
      return;
    }
    setPlanTargetsLoadingLookupMap({ ...planTargetsLoadingLookupMap, [battleCardId]: true });
    setPlanTargetsErrorLookupMap({ ...planTargetsErrorLookupMap, [battleCardId]: null });

    try {
      // get sheet measures
      let sheetMeasures;
      if (deploymentModelId) {
        sheetMeasures = await getSheetMeasures(deploymentModelId, quotaComponentId, battleCardId);
      }

      let allocatedMeasureId;
      if (sheetMeasures) {
        allocatedMeasureId = getAllocatedMeasureId(sheetMeasures);
      }

      // get plan targets
      const planTargets: ApolloQueryResult<{
        getAllTerritoryGroupsMeasure: AllPlanTargetsData;
        // eslint-disable-next-line no-restricted-syntax
      }> = await apolloClient.query({
        query: QUERY_ALL_TERRITORY_GROUPS_MEASURE,
        variables: {
          battlecardId: +battleCardId,
          measureId: allocatedMeasureId ?? null,
          quotaComponentId
        },
        fetchPolicy: 'network-only'
      });

      if (planTargets) {
        // create tree and lookup map for this battleCard
        const planTargetsTree = [];
        const planTargetsMap = createLookupMap(
          planTargets.data.getAllTerritoryGroupsMeasure.territoryGroups,
          allocatedMeasureId,
          battleCardId,
          quotaComponentId
        );
        for (const key of Object.keys(planTargetsMap)) {
          const parentId = planTargetsMap[key].territoryGroupParentId;
          if (parentId) {
            planTargetsMap[parentId].children.push(planTargetsMap[key]);
          } else {
            planTargetsTree.push(planTargetsMap[key]);
          }
        }

        planTargetsTree.sort(sortByPrecedence);
        setPlanTargetsTreeLookupMap({ ...planTargetsTreeLookupMap, [battleCardId]: planTargetsTree });

        setPlanTargetsLookupMap({ ...planTargetsLookupMap, [battleCardId]: planTargetsMap });

        setBattleCardAllocationLookupMap({
          ...battleCardAllocationLookupMap,
          [battleCardId]: planTargets.data.getAllTerritoryGroupsMeasure.battlecard
        });
      }
    } catch (error) {
      console.log(error);
      setPlanTargetsErrorLookupMap({ ...planTargetsErrorLookupMap, [battleCardId]: error });
    } finally {
      setPlanTargetsLoadingLookupMap({ ...planTargetsLoadingLookupMap, [battleCardId]: false });
    }

    try {
      // get measures
      const measures = await getMeasures(planningCycleId);

      setMeasuresData(measures);
    } catch (error) {
      console.log(error);
      setPlanTargetsErrorLookupMap({ ...planTargetsErrorLookupMap, [battleCardId]: error });
    } finally {
      setPlanTargetsLoadingLookupMap({ ...planTargetsLoadingLookupMap, [battleCardId]: false });
    }
  };

  const setTgExpandedCollapsed = (territoryGroupId: string, isExpanded: boolean) => {
    setTGExpandedLookupMap({ ...tgExpandedLookupMap, [territoryGroupId]: isExpanded });
  };

  const resetValues = () => {
    setSelectedPillIdPlanTargets(null);
    setPlanTargetsLookupMap(null);
    setOnUpdatePlanTargets(null);
    setBattleCardAllocationLookupMap(null);
    setMeasuresData(null);
    setMeasuresLoading(false);
    setSheetMeasuresData(null);
    setPlanTargetsErrorLookupMap(null);
    setPlanTargetsLoadingLookupMap(null);
    setPlanTargetsTreeLookupMap(null);
    setSelectedPillIdPlanTargets(null);
    setTGExpandedLookupMap({});
    setTopdownAllocationJobStatusLookupMap([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      setSelectedPillIdPlanTargets,
      selectedPillIdPlanTargets,

      planTargetsLookupMap,
      planTargetsTreeLookupMap,
      battleCardAllocationLookupMap,
      tgExpandedLookupMap,
      setTgExpandedCollapsed,
      measuresData,
      measuresLoading,

      sheetMeasuresData,

      getMeasures,
      getPlanTargets,
      planTargetsLoadingLookupMap,
      planTargetsErrorLookupMap,

      onUpdatePlanTargets,
      setOnUpdatePlanTargets: (callback) => {
        // in order to store functions with the useState hook, we must wrap them in an anonymous function
        // see here https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        setOnUpdatePlanTargets(() => callback);
      },
      topdownAllocationJobStatusLookupMap,
      setTopdownAllocationJobStatusLookupMap,
      resetValues
    }),
    [
      selectedPillIdPlanTargets,
      planTargetsLookupMap,
      planTargetsTreeLookupMap,
      battleCardAllocationLookupMap,
      tgExpandedLookupMap,
      measuresData,
      measuresLoading,
      sheetMeasuresData,
      planTargetsLoadingLookupMap,
      planTargetsErrorLookupMap,
      topdownAllocationJobStatusLookupMap,
      onUpdatePlanTargets
    ]
  );

  // Return the interface that we want to expose to our other components
  return <PlanTargetsContext.Provider value={values}>{children}</PlanTargetsContext.Provider>;
};

// Custom hook to read these values from
export const usePlanTargets = (): PlanTargetsContextValues => useContextSafe(PlanTargetsContext);
