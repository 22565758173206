// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../global/classes.module.pcss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ccnQN {\n  display: flex;\n  cursor: pointer;\n}\n\n  .KZW2W {\n  }\n\n  /* !important is needed in this section to override BP !important on button background and fill color */\n\n  .YeYPz {\n      background-color: rgb(var(--color-cobalt-5)) !important;\n    }\n\n  /* stylelint-disable plugin/selector-bem-pattern */\n\n  .YeYPz svg {\n        fill: rgb(var(--color-cobalt-3)) !important;\n      }\n\n  .ccnQN .helpMenu a {\n    text-decoration: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HelpMenu": "ccnQN",
	"HelpMenu_helpBtn": "KZW2W " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["header-menu-btn"] + "",
	"HelpMenu_helpBtn__open": "YeYPz"
};
export default ___CSS_LOADER_EXPORT___;
