import React from 'react';

import { Locked } from '@carbon/icons-react';
import { Text } from '@varicent/components';
import { Popup } from 'react-map-gl';

import Icon from 'components/Icon/Icon';

import { CombinedRuleId, CustomerTooltip } from 'app/models';

import block from 'utils/bem-css-modules';
import { getAccountSingularRuleId } from 'utils/helpers/territoryMapUtils';
import { formatCurrency, formatMessage } from 'utils/messages/utils';

import style from './AccountTooltip.module.pcss';

const b = block(style);

export interface AccountTooltipProps {
  accountTooltip: CustomerTooltip;
  currency: string;
  lockedRuleIds: Set<number>;
}

const AccountTooltip: React.FC<AccountTooltipProps> = ({
  accountTooltip,
  currency,
  lockedRuleIds
}: AccountTooltipProps) => {
  const getPinTooltipTitle = (): string => {
    if (accountTooltip.isModifierAccount) {
      return `${accountTooltip.accountName} (${formatMessage('OVERRIDE')})`;
    }
    return accountTooltip.accountName;
  };

  const getPinTooltipText = (): string => {
    if (accountTooltip.territoryId) {
      return `${accountTooltip.territoryId} (${accountTooltip.territoryName})`;
    } else if (accountTooltip.isGeoOverassigned) {
      return formatMessage('ACCOUNT_TOOLTIP_MULTIPLE_TERRITORIES_MESSAGE', {
        geoCount: accountTooltip.geoOverassignmentCount
      });
    }
    return formatMessage('UNASSIGNED_PARENTHESES');
  };

  const getClusterTooltipText = ({
    combinedRuleId,
    territoryId,
    territoryName,
    isClusterContainsOverassigned,
    isClusterContainsUnassigned
  }: CustomerTooltip): string => {
    if (isClusterContainsOverassigned) {
      return formatMessage('OVERASSIGNED_CLUSTER_TOOLTIP');
    } else if (combinedRuleId === CombinedRuleId.UNASSIGNED) {
      return formatMessage('UNASSIGNED_CLUSTER_TOOLTIP');
    } else if (isClusterContainsUnassigned) {
      return formatMessage('MIXED_CLUSTER_WITH_UNASSIGNED_TOOLTIP');
    } else if (combinedRuleId === CombinedRuleId.MIXED) {
      return formatMessage('MIXED_CLUSTER_TOOLTIP');
    } else {
      return formatMessage('ASSIGNED_CLUSTER_TOOLTIP', { territoryId, territoryName });
    }
  };

  return (
    <div data-testid="account-tooltip">
      <Popup
        className={b('accountTooltipWrapper')}
        anchor="bottom"
        closeButton={false}
        closeOnClick={false}
        longitude={accountTooltip.x}
        latitude={accountTooltip.y}
        offset={[0, -10]}
      >
        <div className={b('tooltipContainer')}>
          {lockedRuleIds.has(getAccountSingularRuleId(accountTooltip)) && (
            <div data-testid="locked-account-tooltip-item" className={b('lockIcon')}>
              <Icon icon={<Locked size={20} color="white" />} />
            </div>
          )}
          {accountTooltip.combinedRuleId ? (
            <div className={b('tooltipBody')} data-testid="cluster-tooltip-body">
              {getClusterTooltipText(accountTooltip)}
            </div>
          ) : (
            <div data-testid="pin-tooltip-body">
              <Text data-testid="tooltip-body" className={b('tooltipTitle')}>
                {getPinTooltipTitle()}
              </Text>
              <div className={b('tooltipBody')}>{getPinTooltipText()}</div>
              {!accountTooltip.isGeoOverassigned && (
                <div className={b('tooltipBody')}>
                  {formatMessage('PRIOR_YEAR_ACTUAL')}
                  {formatCurrency(accountTooltip.measureValue, currency)}
                </div>
              )}
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
};

export default AccountTooltip;
