import React, { useState } from 'react';

import { Classes, Menu } from '@blueprintjs/core';
import { Help } from '@carbon/icons-react';
import { Link } from 'react-router-dom';

import IconButton from 'components/Buttons/IconButton/IconButton';
import Popover from 'components/Popover/Popover';

import block from 'utils/bem-css-modules';
import { config } from 'utils/config';
import { formatMessage } from 'utils/messages/utils';

import style from './HelpMenu.module.pcss';

const b = block(style);

const modifiers = {
  arrow: {
    enabled: false
  }
};

const HelpMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover
      content={
        <Menu data-testid="help-menu-items">
          <Link
            to={{ pathname: config.DOCS_SITE_URL! }}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="documentation-link"
          >
            <div className={Classes.MENU_ITEM}>{formatMessage('DOCUMENTATION')}</div>
          </Link>
        </Menu>
      }
      placement={'bottom-end'}
      modifiers={modifiers}
      onOpened={() => setIsOpen(true)}
      onClosed={() => setIsOpen(false)}
      portalClassName="helpMenu"
    >
      <div className={b()}>
        <IconButton
          type="button"
          icon={<Help size={24} />}
          className={b('helpBtn', { open: isOpen })}
          testId={'help-menu-button'}
          title={formatMessage('HELP')}
        />
      </div>
    </Popover>
  );
};

export default HelpMenu;
