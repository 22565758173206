import React, { useEffect, useState } from 'react';

import { Classes } from '@blueprintjs/core';
import { Field, useFormikContext } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { ISheetDefinitionDef } from 'app/graphql/generated/apolloTypes';
import { useGetMeasures } from 'app/graphql/queries/getMeasures';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { SheetsAddFieldFormValues } from './SheetsAddFieldForm';
import style from './SheetsExistingFieldFormFields.module.pcss';

const b = block(style);

const compareKeys = (a, b): number => {
  return a.key.localeCompare(b.key);
};

export const SheetsExistingFieldFormFields: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<SheetsAddFieldFormValues>();
  const { selectedPlanningCycle } = useScope();
  const { sheetDefinitions } = useData();
  const [dropDownData, setDropDownData] = useState<Record<string, unknown>[]>([]);
  const [selectedMeasureData, setSelectedMeasureData] = useState<ISheetDefinitionDef>(null);
  const { loading, data, refetch } = useGetMeasures({
    variables: { planningCycleId: selectedPlanningCycle.id }
  });

  // populate and format dropdown list with measure data
  useEffect(() => {
    // filter measures on the current sheet and TQM measures
    const filteredMeasures = data?.getMeasures.filter((measure) => {
      for (const item of sheetDefinitions) {
        if (item.measureId === measure.measureId) {
          return false;
        }
      }
      return measure.isTQM === false; // can't be simplified because isTQM can be null
    });

    // format into record
    const formattedMeasures: Record<string, unknown>[] = [];
    filteredMeasures.forEach((measure) => {
      formattedMeasures.push({
        key: measure.measureName,
        value: measure.measureId
      });
    });

    // sort alphabetically
    formattedMeasures.sort((a, b) => compareKeys(a, b));
    setDropDownData(formattedMeasures);
  }, [data]);

  // when measureName is changed, update relevant fields
  useEffect(() => {
    const selectedMeasure = values.measureName?.['value'];
    const measureData = data?.getMeasures.find((measure) => measure.measureId === selectedMeasure);
    setSelectedMeasureData(measureData);
    if (!!measureData) {
      setFieldValue('measureFormatType', {
        key: measureData.measureFormatType,
        value: measureData.measureFormatType
      });
      setFieldValue('measureFieldType', {
        key: measureData.measureFieldType,
        value: measureData.measureFieldType
      });
      setFieldValue('measureId', measureData.measureId);
    }
  }, [values.measureName, data]);

  const hasValidElements = dropDownData.length !== 0;

  // refetch if sheetDefinitions change
  useEffect(() => {
    refetch();
  }, [sheetDefinitions]);

  return (
    <div data-testid="existing-field-form">
      <div className={b('formInput')}>
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('COLUMN_NAME')}</label>
        <Field
          name="measureName"
          theme="default"
          component={SelectMenu}
          placeHolderText={hasValidElements ? formatMessage('SELECT') : formatMessage('NO_EXISTING_MEASURES')}
          items={loading ? [] : dropDownData}
          shouldValidateOnTouch={false}
          loading={loading}
          disabled={!hasValidElements}
        />
      </div>
      <div className={b('formInput')}>
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('SOURCE')}</label>
        <span className={!!selectedMeasureData ? null : b('autoValue')}>
          {!!selectedMeasureData ? selectedMeasureData.measureFieldType : formatMessage('SELECT_COLUMN_NAME')}
        </span>
      </div>
      <br />
      <div className={b('formInput')}>
        <label className={`${b('label')} ${Classes.LABEL}`}>{formatMessage('FORMAT')}</label>
        <span className={!!selectedMeasureData ? null : b('autoValue')}>
          {!!selectedMeasureData ? selectedMeasureData.measureFormatType : formatMessage('SELECT_COLUMN_NAME')}
        </span>
      </div>
    </div>
  );
};

export default SheetsExistingFieldFormFields;
