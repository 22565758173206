import {
  GetAllBattleCards_getDeploymentModelSpec_battlecards,
  GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes
} from 'app/graphql/generated/apolloTypes';

import { BattlecardType } from 'app/models';

export interface BcTreeNode {
  hierarchy: string[];
  battlecardName: string;
  battlecardType: BattlecardType | string;
  sheets: {
    sheetId: number | null;
    sheetName: string | null;
  }[];
  territoryGroupId: number | null;
  battlecardId: number | null;
  referenceThisSheet: boolean;
}

const buildTerritoryGroupTreeData = (
  territoryGroupList: GetAllBattleCards_getDeploymentModelSpec_battlecards_territoryGroupTypes[],
  hierarchyData: string[],
  treeData: BcTreeNode[],
  battlecardId: number,
  referenceThisSheet: boolean
) => {
  territoryGroupList?.forEach((territoryGroup) => {
    treeData.push({
      hierarchy: [...hierarchyData, territoryGroup.hierarchyRootName],
      battlecardName: territoryGroup.hierarchyRootName,
      battlecardType: 'Level',
      sheets: territoryGroup.sheets,
      territoryGroupId: territoryGroup.territoryGroupId,
      battlecardId,
      referenceThisSheet
    });
  });
};

export function buildBattleCardReferencedTreeData(
  battleCards: GetAllBattleCards_getDeploymentModelSpec_battlecards[],
  battleCardList: number[],
  hierarchyData: string[],
  treeData: BcTreeNode[],
  sheetId: number
): BcTreeNode[] {
  battleCardList.forEach((battleCardId) => {
    const battleCard = battleCards.find((card) => card.battlecardId === battleCardId);
    const referenceThisSheet = battleCard?.sheets?.findIndex((sheet) => sheet.sheetId === sheetId) >= 0 ? true : false;

    if (battleCard) {
      // push the battle card to the tree
      treeData.push({
        hierarchy: [...hierarchyData, battleCard.battlecardName],
        battlecardName: battleCard.battlecardName,
        battlecardType: battleCard.battlecardType,
        sheets: battleCard.sheets,
        territoryGroupId: null,
        battlecardId: battleCard.battlecardId,
        referenceThisSheet
      });

      // push child battle cards to the tree
      if (battleCard?.children?.length > 0) {
        buildBattleCardReferencedTreeData(
          battleCards,
          battleCard.children,
          [...hierarchyData, battleCard.battlecardName],
          treeData,
          sheetId
        );
      }

      // push territory group types to the tree
      if (battleCard?.territoryGroupTypes?.length > 0) {
        buildTerritoryGroupTreeData(
          battleCard.territoryGroupTypes,
          [...hierarchyData, battleCard.battlecardName],
          treeData,
          battleCard.battlecardId,
          referenceThisSheet
        );
      }
    }
  });
  return treeData;
}
