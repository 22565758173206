import gql from 'graphql-tag';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { ExchangeTemporaryToken } from 'app/graphql/generated/apolloTypes';

export const EXCHANGE_TEMPORARY_TOKEN = gql`
  mutation ExchangeTemporaryToken($input: ExchangeTemporaryAuthTokenInput!) {
    exchangeTemporaryAuthToken(input: $input) {
      exchangeToken
      expiresAt
    }
  }
`;

export const performTokenExchange = (input: {
  tempToken: string;
  tenantSlug: string;
}): Promise<{ data?: ExchangeTemporaryToken }> =>
  // eslint-disable-next-line no-restricted-syntax
  apolloClient.mutate({
    mutation: EXCHANGE_TEMPORARY_TOKEN,
    variables: { input: { tenantSlug: input.tenantSlug } },
    context: { tempToken: input.tempToken }
  });
