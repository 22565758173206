import React, { useMemo } from 'react';

import { Tag } from '@varicent/components';

import { GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects } from 'app/graphql/generated/apolloTypes';

import { AqgTerritoryKind } from 'app/models';

import { getLocalDateString } from 'utils/helpers/dateHelpers';
import { getHighlightCssPropertyName } from 'utils/helpers/highlightHelpers';
import { formatMessage } from 'utils/messages/utils';

interface AccountTerritoryStatusCellRendererProps {
  kind: AqgTerritoryKind;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  redirectStartDate: string;
  redirectEndDate: string;
  isAccountStatus: boolean;
}

enum StatusStyle {
  CURRENT_TERRITORY = 'currentTerritory',
  GAP = 'gap',
  SCHEDULED_MOVE = 'scheduledMove',
  PREVIOUS_MOVE = 'previousMove'
}

const AccountTerritoryStatusCellRenderer: React.FC<AccountTerritoryStatusCellRendererProps> = ({
  kind,
  redirects,
  redirectStartDate,
  redirectEndDate,
  isAccountStatus
}) => {
  const currentDate = getLocalDateString();
  const getTerritoryStatus = () => {
    switch (kind) {
      case AqgTerritoryKind.EXPANDED_SOURCE:
        return {
          statusText: formatMessage('ORIGINAL_TERRITORY'),
          statusStyle: StatusStyle.PREVIOUS_MOVE
        };
      case AqgTerritoryKind.MOVE_AWAY:
      case AqgTerritoryKind.MOVE_BACK:
        if (redirectEndDate < currentDate) {
          return {
            statusText: formatMessage('PREVIOUS_TERRITORY'),
            statusStyle: StatusStyle.PREVIOUS_MOVE
          };
        } else if (redirectStartDate > currentDate) {
          return {
            statusText: formatMessage('SCHEDULED_MOVE'),
            statusStyle: StatusStyle.SCHEDULED_MOVE
          };
        }
        return {
          statusText: formatMessage('CURRENT_TERRITORY'),
          statusStyle: StatusStyle.CURRENT_TERRITORY
        };
      case AqgTerritoryKind.UNASSIGNED:
        return {
          statusText: formatMessage('GAP_DETECTED'),
          statusStyle: StatusStyle.GAP
        };
      default:
        return null;
    }
  };

  const getScheduledMoveCount = (
    redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[]
  ): number => {
    // Target rule check present to exclude unassigned gaps from redirect count
    const scheduledRedirects = redirects.filter((redirect) => redirect.targetRule && redirect.startDate > currentDate);
    return scheduledRedirects.length;
  };

  const getAccountStatus = () => {
    const scheduledMoveCount = getScheduledMoveCount(redirects);
    switch (scheduledMoveCount) {
      case 0:
        return {
          statusText: '',
          statusStyle: ''
        };
      case 1:
        return {
          statusText: formatMessage('ONE_SCHEDULED_MOVE'),
          statusStyle: StatusStyle.SCHEDULED_MOVE
        };
      default:
        return {
          statusText: formatMessage('SCHEDULED_MOVES_WITH_COUNT', { totalCount: scheduledMoveCount }),
          statusStyle: StatusStyle.SCHEDULED_MOVE
        };
    }
  };

  const status = useMemo(() => {
    if (isAccountStatus) {
      return getAccountStatus();
    } else {
      return getTerritoryStatus();
    }
  }, [redirects, kind, redirectStartDate, redirectEndDate, isAccountStatus]);

  const getTagClassName = () => {
    let precedence;
    switch (status.statusStyle) {
      case StatusStyle.SCHEDULED_MOVE:
        precedence = 1;
        break;
      case StatusStyle.CURRENT_TERRITORY:
        precedence = 2;
        break;
      case StatusStyle.GAP:
        precedence = 3;
        break;
    }
    return precedence ? getHighlightCssPropertyName(precedence) : '';
  };

  return (
    <>
      {status?.statusText && (
        <div data-testid="account-territory-status-cell-renderer">
          <Tag className={getTagClassName()} round>
            {status.statusText}
          </Tag>
        </div>
      )}
    </>
  );
};

export default AccountTerritoryStatusCellRenderer;
