import React, { useState, useMemo, Dispatch, SetStateAction, MutableRefObject } from 'react';

import { GetSheetMeasures_getDeploymentModelSpec_sheetMeasures } from 'app/graphql/generated/apolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { QuotaComponent, BattleCardInfo, BaseContext, CustomHeader, HierarchySpec, SectionName } from 'app/models';

import { useApplyCustomHeader } from './ApolloHeaderProvider';

export interface BattleCardContextValues extends BaseContext {
  expandedBattleCardId: string;
  setExpandedBattleCardId: Dispatch<SetStateAction<string> | null>;
  selectedBattleCardId: string;
  setSelectedBattleCardId: Dispatch<SetStateAction<string | null>>;
  exitingBattleCardId: string;
  setExitingBattleCardId: Dispatch<SetStateAction<string | null>>;
  sectionName: SectionName | null;
  setSectionName: Dispatch<SetStateAction<SectionName | null>>;
  cardIsExpanded: boolean;
  setCardIsExpanded: Dispatch<SetStateAction<boolean | null>>;
  cardBox: { top: 0; left: 0; bottom: 0; right: 0; height: 0 };
  setCardBox: Dispatch<SetStateAction<DOMRect>>;
  quotaComponentList: QuotaComponent[];
  setQuotaComponentList: Dispatch<SetStateAction<QuotaComponent[] | null>>;
  canvasRef: MutableRefObject<HTMLDivElement>;
  setCanvasRef: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
  battleCardLookupMap: Record<string, BattleCardInfo>;
  setBattleCardLookupMap: Dispatch<SetStateAction<Record<string, BattleCardInfo>>>;
  shouldRefetchBattleCardDataOnNextRender: boolean;
  setShouldRefetchBattleCardDataOnNextRender: Dispatch<SetStateAction<boolean>>;
  shouldRefetchBattleCardDataImmediately: boolean;
  setShouldRefetchBattleCardDataImmediately: Dispatch<SetStateAction<boolean>>;
  selectedQuotaComponentId: number;
  setSelectedQuotaComponentId: Dispatch<SetStateAction<number | null>>;
  isBattleCardDataLoading: boolean;
  setIsBattleCardDataLoading: Dispatch<SetStateAction<boolean>>;
  resetValues: () => void;
  selectedBattleCardMeasures: GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[];
  setSelectedBattleCardMeasures: Dispatch<SetStateAction<GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[]>>;
  pendingSelectedBattleCardId: string;
  setPendingSelectedBattleCardId: Dispatch<SetStateAction<string | null>>;
  resetExpandedView: boolean;
  setResetExpandedView: Dispatch<SetStateAction<boolean | null>>;
  quotaBreakdownHierarchies: HierarchySpec[];
  setQuotaBreakdownHierarchies: Dispatch<SetStateAction<HierarchySpec[]>>;
}

export const BattleCardContext = React.createContext<BattleCardContextValues | null>(null);
BattleCardContext.displayName = 'BattleCardContext';

export const BattleCardProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [selectedBattleCardId, setSelectedBattleCardId] = useState<string | null>(null);
  const [expandedBattleCardId, setExpandedBattleCardId] = useState<string | null>(null);
  const [exitingBattleCardId, setExitingBattleCardId] = useState<string | null>(null);
  const [sectionName, setSectionName] = useState<SectionName | null>(null);
  const [cardIsExpanded, setCardIsExpanded] = useState(false);
  const [cardBox, setCardBox] = useState(null);
  // TODO TQP-1435 Need to handle the apollo call of getQuotaComponent when we do the refactor later
  const [quotaComponentList, setQuotaComponentList] = useState<QuotaComponent[] | null>(null);
  const [canvasRef, setCanvasRef] = useState(null);
  const [battleCardLookupMap, setBattleCardLookupMap] = useState<Record<string, BattleCardInfo>>({});
  const [shouldRefetchBattleCardDataOnNextRender, setShouldRefetchBattleCardDataOnNextRender] =
    useState<boolean>(false);
  const [shouldRefetchBattleCardDataImmediately, setShouldRefetchBattleCardDataImmediately] = useState<boolean>(true);
  const [selectedQuotaComponentId, setSelectedQuotaComponentId] = useState<number | null>(null);
  const [isBattleCardDataLoading, setIsBattleCardDataLoading] = useState<boolean>(false);
  const [selectedBattleCardMeasures, setSelectedBattleCardMeasures] = useState<
    GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[] | null
  >(null);
  const [pendingSelectedBattleCardId, setPendingSelectedBattleCardId] = useState<string | null>(null);
  const [resetExpandedView, setResetExpandedView] = useState(false);
  const [quotaBreakdownHierarchies, setQuotaBreakdownHierarchies] = useState<HierarchySpec[]>([]);

  const resetValues = () => {
    setSelectedBattleCardId(null);
    setExpandedBattleCardId(null);
    setExitingBattleCardId(null);
    setSectionName(null);
    setCardIsExpanded(null);
    setCardBox(null);
    setQuotaComponentList(null);
    setCanvasRef(null);
    setBattleCardLookupMap({});
    setShouldRefetchBattleCardDataOnNextRender(false);
    setShouldRefetchBattleCardDataImmediately(true);
    setSelectedQuotaComponentId(null);
    setIsBattleCardDataLoading(false);
    setSelectedBattleCardMeasures(null);
    setPendingSelectedBattleCardId(null);
    setResetExpandedView(false);
    setQuotaBreakdownHierarchies([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(() => {
    return {
      expandedBattleCardId,
      setExpandedBattleCardId,
      selectedBattleCardId,
      setSelectedBattleCardId,
      exitingBattleCardId,
      setExitingBattleCardId,
      sectionName,
      setSectionName,
      cardIsExpanded,
      setCardIsExpanded,
      cardBox,
      setCardBox,
      quotaComponentList,
      setQuotaComponentList,
      canvasRef,
      setCanvasRef,
      battleCardLookupMap,
      setBattleCardLookupMap,
      shouldRefetchBattleCardDataOnNextRender,
      setShouldRefetchBattleCardDataOnNextRender,
      shouldRefetchBattleCardDataImmediately,
      setShouldRefetchBattleCardDataImmediately,
      selectedQuotaComponentId,
      setSelectedQuotaComponentId,
      resetValues,
      isBattleCardDataLoading,
      setIsBattleCardDataLoading,
      selectedBattleCardMeasures,
      setSelectedBattleCardMeasures,
      pendingSelectedBattleCardId,
      setPendingSelectedBattleCardId,
      resetExpandedView,
      setResetExpandedView,
      quotaBreakdownHierarchies,
      setQuotaBreakdownHierarchies
    };
  }, [
    expandedBattleCardId,
    selectedBattleCardId,
    exitingBattleCardId,
    sectionName,
    cardIsExpanded,
    cardBox,
    quotaComponentList,
    canvasRef,
    battleCardLookupMap,
    shouldRefetchBattleCardDataOnNextRender,
    shouldRefetchBattleCardDataImmediately,
    selectedQuotaComponentId,
    isBattleCardDataLoading,
    selectedBattleCardMeasures,
    pendingSelectedBattleCardId,
    resetExpandedView,
    quotaBreakdownHierarchies
  ]);

  // This legacy usage is needed until all mutations move to apollo factory functions
  // eslint-disable-next-line deprecation/deprecation
  useApplyCustomHeader(CustomHeader.BATTLE_CARD_ID, selectedBattleCardId);

  // Return the interface that we want to expose to our other components
  return <BattleCardContext.Provider value={values}>{children}</BattleCardContext.Provider>;
};

// Custom hook to read these values from
export const useBattleCard = (): BattleCardContextValues => useContextSafe(BattleCardContext);
