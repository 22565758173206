import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { useLazyQuery } from '@apollo/client';
import { HTMLHeading } from '@varicent/components';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import buildActivityFilesPanelColumnDef from 'app/components/AdvancedGrid/GridHelpers/ActivityFilesPanel/ActivityFilesPanelColumnDef';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE } from 'app/global/variables';

import { FileTypeEnum, GetFileList, GetFileListVariables, LookupTypeEnum } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_FILE_LIST } from 'app/graphql/queries/getFileList';

import useTreatment from 'app/hooks/useTreatment';

import { DataPanelViews, FileStatus, FileType, TablesTab } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './PublishedTable.module.pcss';

const b = block(style);

interface PublishedTableProps {
  shouldRefetch: boolean;
  setSelectedDataView: Dispatch<SetStateAction<DataPanelViews>>;
  setShouldRefetch: (value: boolean) => void;
}
const PublishedTable: React.FC<PublishedTableProps> = ({
  shouldRefetch,
  setSelectedDataView,
  setShouldRefetch
}: PublishedTableProps) => {
  const tablesLoadingContainerRef = useRef(null);
  const containerRef = useRef(null);
  const { setSelectedTable, selectedTablesTab } = useData();
  const { selectedPlanningCycle } = useScope();
  const [isTQMOn] = useTreatment(SplitFeatures.TQM);

  const [blockSize, setBlockSize] = useState(BLOCK_SIZE);

  // set rowRef using tablesGridContainerRef
  // used for displaying loading states for specific cells in ag-grid
  const rowRef = {
    current: {
      ...containerRef?.current,
      offsetHeight: CELL_HEIGHT,
      offsetWidth: containerRef?.current?.offsetWidth
    }
  };

  useEffect(() => {
    if (containerRef?.current?.offsetHeight) {
      setBlockSize(Math.round((containerRef.current.offsetHeight / CELL_HEIGHT) * 2));
    }
  }, [containerRef]);

  const shouldFilterByActivity =
    selectedTablesTab === TablesTab.ACTIVITY_PUBLISHED ||
    selectedTablesTab === TablesTab.ACTIVITY ||
    selectedTablesTab === TablesTab.ALL;

  const [getPublishedFiles, { data, loading: loadingFileList, fetchMore }] = useLazyQuery<
    GetFileList,
    GetFileListVariables
  >(GET_FILE_LIST, {
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      tableInput: {
        startRow: 1,
        endRow: blockSize,
        ...(shouldFilterByActivity && {
          uploadFilter: {
            fileTypes: [FileTypeEnum.Activity],
            statuses: [FileStatus.COMPLETED]
          }
        }),
        ...((selectedTablesTab === TablesTab.LOOKUP || (selectedTablesTab === TablesTab.ALL && isTQMOn)) && {
          lookupFilter: {
            lookupTypes: [LookupTypeEnum.Ramp, LookupTypeEnum.Seasonality]
          }
        })
      }
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data) {
        setShouldRefetch(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  useEffect(() => {
    getPublishedFiles();
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      getPublishedFiles();
    }
  }, [shouldRefetch]);

  const files = data?.getPlanningCycleSpec?.tables?.tableList || [];
  const totalCount = data?.getPlanningCycleSpec?.tables?.totalNumOfTables || 0;

  const onTableSelectionChanged = (params) => {
    const tableGridApi = params?.api as GridApi;
    const selectedRow = tableGridApi.getSelectedRows();

    // If the file is in progress, it means it is being uploaded to Symon, so we don't want user to click into the file details until it's completed
    if (selectedRow?.[0].status === FileStatus.IN_PROGRESS_GET_FILE) {
      return;
    }

    if (selectedRow?.[0].tableType === FileType.LOOKUP) {
      setSelectedDataView(DataPanelViews.LOOKUP_TABLE_DETAIL);
    } else {
      setSelectedDataView(DataPanelViews.TABLE_DETAIL);
    }

    setSelectedTable(selectedRow?.[0]);
  };

  const fileListPanelGridProps = {
    defaultColDef: {
      resizable: true
    },
    rowModelType: 'infinite',
    cacheBlockSize: blockSize,
    cacheOverflowSize: 2,
    infiniteInitialRowCount: totalCount,
    maxBlocksInCache: 10,
    getRowNodeId: (row) => row.tableId,
    datasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        if (params?.endRow === blockSize) {
          // no need to query for the first block as we already fetched it in the initial load
          params?.successCallback(files, totalCount);
        } else {
          // ag-grid starts row count from 0 and the backend pagination starts from 1
          if (getPaginationCheck(params.startRow, totalCount)) {
            const fetchMoreFiles = await fetchMore<GetFileList, GetFileListVariables>({
              variables: {
                planningCycleId: selectedPlanningCycle?.id,
                tableInput: {
                  startRow: params?.startRow + 1,
                  endRow: params?.endRow,
                  ...(shouldFilterByActivity && {
                    uploadFilter: {
                      fileTypes: [FileTypeEnum.Activity],
                      statuses: [FileStatus.COMPLETED]
                    }
                  }),
                  ...((selectedTablesTab === TablesTab.LOOKUP || (selectedTablesTab === TablesTab.ALL && isTQMOn)) && {
                    lookupFilter: {
                      lookupTypes: [LookupTypeEnum.Ramp, LookupTypeEnum.Seasonality]
                    }
                  })
                }
              }
            });
            params?.successCallback(
              fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.tableList,
              fetchMoreFiles?.data?.getPlanningCycleSpec?.tables?.totalNumOfTables
            );
          }
        }
      }
    }
  };

  return (
    <div className={b()} data-testid="published-table">
      {selectedTablesTab !== TablesTab.LOOKUP && (
        <div className={b('headingContainer')} data-testid="published-table-heading">
          <HTMLHeading tagLevel={'h5'} bold text={formatMessage('PUBLISHED')} />
        </div>
      )}
      <div className={b('tablesGrid')} ref={tablesLoadingContainerRef}>
        <div className={`ag-theme-alpine ${b('grid')}`} ref={containerRef}>
          {loadingFileList || files?.length > 0 ? (
            <>
              <AdvancedGrid
                gridProps={fileListPanelGridProps}
                columnDefs={buildActivityFilesPanelColumnDef(totalCount, rowRef)}
                rowSelection="single"
                noDataMessage={formatMessage('NO_ACTIVITY_FILES')}
                data-testid="tables-published-grid"
                showGridLoading={loadingFileList}
                onSelectionChanged={onTableSelectionChanged}
                gridWidth={tablesLoadingContainerRef?.current?.offsetWidth}
                gridHeight={tablesLoadingContainerRef?.current?.offsetHeight}
              />
            </>
          ) : (
            <div className={b('noData')} data-testid="tables-published-grid-empty">
              {formatMessage('EMPTY_GRID')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishedTable;
