import React, { useState } from 'react';

import { WatsonHealthStackedMove, Filter, Search, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import AccountQuotaTGTFilter from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaTGTFilter';

import { GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders } from 'app/graphql/generated/apolloTypes';

import useCanUser from 'app/hooks/useCanUser';

import { FilterChangeInput, FilterInput, NamedTgt } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import AccountReassignDialog from './AccountReassignDialog';
import AccountRuleFilter from './AccountRuleFilter';
import style from './AccountRuleHeader.module.pcss';

const b = block(style);

export interface AccountRuleHeaderProps {
  territoryGroupTypes: NamedTgt[];
  currentTerritoryGroupTypeId: number;
  onTerritoryGroupTypeChange: (territoryGroupType: number) => void;
  isReassignEnabled: boolean;
  selectedAccountsList: number[];
  onDialogSubmit: () => void;
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  onFilterApply: () => void;
  dynamicColumns: GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders[];
  accountFilters: FilterInput;
  activeFiltersCount: number;
  onClearAll: () => void;
}

const AccountRuleHeader: React.FC<AccountRuleHeaderProps> = ({
  territoryGroupTypes,
  onTerritoryGroupTypeChange,
  currentTerritoryGroupTypeId,
  isReassignEnabled,
  selectedAccountsList,
  onDialogSubmit,
  onFilterChange,
  onFilterApply,
  dynamicColumns,
  accountFilters,
  activeFiltersCount,
  onClearAll
}) => {
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);

  const canViewUnassigned = useCanUser(UserAction.ACCOUNT_RULE_GRID_VIEW_UNASSIGNED);

  const handleDialogClose = () => setIsReassignDialogOpen(false);
  const handleDialogSubmit = () => {
    setIsReassignDialogOpen(false);
    onDialogSubmit();
  };

  const filterButtonText = (
    <div>
      {formatMessage('FILTERS')}
      {!!activeFiltersCount && <span className={b('filterCount')}>{activeFiltersCount}</span>}
    </div>
  );

  return (
    <>
      <header className={b()}>
        <div className={b('container')}>
          <div className={b('tgtWrapper')}>
            <AccountQuotaTGTFilter
              territoryGroupTypes={territoryGroupTypes}
              onTerritoryGroupTypeChange={onTerritoryGroupTypeChange}
              currentTerritoryGroupType={currentTerritoryGroupTypeId}
              shouldIncludeUnassignedItem={canViewUnassigned}
              icon={<Search />}
            />
            <Popover
              content={
                <AccountRuleFilter
                  dynamicColumns={dynamicColumns}
                  onFilterChange={onFilterChange}
                  accountFilters={accountFilters}
                  onFilterApply={onFilterApply}
                />
              }
              placement={'bottom-start'}
              onClosed={onFilterApply}
            >
              <TextButton
                text={filterButtonText}
                icon={<Filter size={20} />}
                type="button"
                testId="filter-button"
                minimal
                large={false}
                className={b('filterButton')}
                rightIcon={<ChevronDown size={20} />}
              />
            </Popover>
            {!!activeFiltersCount && (
              <TextButton
                text={formatMessage('CLEAR_ALL')}
                type="button"
                testId="clear-all-button"
                minimal
                large={false}
                className={b('filterButton')}
                onClick={onClearAll}
              />
            )}
          </div>
          {!!currentTerritoryGroupTypeId && (
            <CanUser
              perform={UserAction.ACCOUNT_RULE_GRID_REASSIGN}
              yes={
                <TextButton
                  text={formatMessage('MOVE')}
                  icon={<WatsonHealthStackedMove />}
                  type="button"
                  testId="move-button"
                  large={false}
                  intent="primary"
                  disabled={!isReassignEnabled}
                  onClick={() => setIsReassignDialogOpen(true)}
                />
              }
            />
          )}
        </div>
      </header>
      {isReassignDialogOpen && (
        <AccountReassignDialog
          currentTerritoryGroupTypeId={currentTerritoryGroupTypeId}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
          sourceHierarchyIds={selectedAccountsList}
          data-testid="account-reassign-dialog"
        />
      )}
    </>
  );
};

export default AccountRuleHeader;
