import { gql } from '@apollo/client';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetTerritoryRulesForAccountMove,
  GetTerritoryRulesForAccountMoveVariables
} from 'app/graphql/generated/apolloTypes';

export const GET_TERRITORY_RULES_FOR_ACCOUNT_MOVE = gql`
  query GetTerritoryRulesForAccountMove(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $searchInput: GetTerritoryRulesSearchInput
    $territoryGroupId: Int
    $startRow: Int
    $endRow: Int
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        territoryGroupId: $territoryGroupId
        startRow: $startRow
        endRow: $endRow
        searchInput: $searchInput
      }
    ) {
      territoryRules {
        ruleId
        territoryId
        territoryName
        effectiveDate
        endDate
      }
      totalCount
    }
  }
`;

export const useGetTerritoryRulesForAccountMoveLazy = createUseLazyQuery<
  GetTerritoryRulesForAccountMove,
  GetTerritoryRulesForAccountMoveVariables
>(GET_TERRITORY_RULES_FOR_ACCOUNT_MOVE);
