import { ApolloOverrides } from 'app/graphql/apolloTypeOverrides';
import { GetSymonPipeConfigurations, GetSymonPipeConfigurationsVariables } from 'app/graphql/generated/apolloTypes';
import { useGetSymonPipeConfigurationsLazy } from 'app/graphql/queries/getSymonPipeConfigurations';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useSymonPipeConfigurations = (): ApolloOverrides.LazyQueryResultTuple<
  GetSymonPipeConfigurations,
  GetSymonPipeConfigurationsVariables
> =>
  useGetSymonPipeConfigurationsLazy({
    fetchPolicy: 'network-only',
    onError() {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('SYMON_PIPE_CONFIGURATIONS_ERROR'), 'danger');
    }
  });
