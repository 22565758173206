import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetMemberCount, GetMemberCountVariables } from 'app/graphql/generated/apolloTypes';

export const GET_MEMBER_COUNT = gql`
  query GetMemberCount($endRow: Int!, $startRow: Int!, $tenantId: Int!) {
    getMemberList(input: { endRow: $endRow, startRow: $startRow, tenantId: $tenantId }) {
      totalCount
    }
  }
`;

export const useGetMemberCountLazy = createUseLazyQuery<GetMemberCount, GetMemberCountVariables>(GET_MEMBER_COUNT);
