import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpdateActivityFiles, UpdateActivityFilesVariables } from 'app/graphql/generated/apolloTypes';

export const UPDATE_ACTIVITY_FILES = gql`
  mutation UpdateActivityFiles($battlecardId: Int!, $deploymentModelId: Int!, $fileIds: [String]!) {
    updateActivityFiles(
      input: { battlecardId: $battlecardId, deploymentModelId: $deploymentModelId, fileIds: $fileIds }
    ) {
      battlecardId
      fileId
      fileName
    }
  }
`;

export const useUpdateActivityFiles = createUseMutation<UpdateActivityFiles, UpdateActivityFilesVariables>(
  UPDATE_ACTIVITY_FILES
);
