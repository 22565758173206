import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import Fallback from './Fallback';

interface ErrorBoundaryProps {
  children: React.ReactChild;
}

const GlobalErrorBoundary = ({ children }: ErrorBoundaryProps): JSX.Element => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

export default GlobalErrorBoundary;
