import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { GridApi, RowNode, ServerSideStoreType } from '@ag-grid-community/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import isequal from 'lodash.isequal';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import dataTrayHelper from 'app/components/AdvancedGrid/GridHelpers/DataTray/dataTrayHelper';
import buildTerritoryColumnDef from 'app/components/AdvancedGrid/GridHelpers/TerritoryGrid/TerritoryGridColumnDef';
import { formatRowData } from 'app/components/AdvancedGrid/GridHelpers/TerritoryGrid/territoryGridUtils';
import { formatFilterForRequest } from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleHelpers';
import UpsertTerritoryRuleView from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleView';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE, HIERARCHY_PREVIEW_COUNT } from 'app/global/variables';

import { GetTerritoryRules, GetTerritoryRulesVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';
import { GET_TERRITORY_RULES, useGetTerritoryRules } from 'app/graphql/queries/getTerritoryRules';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { FilterInput, GridFields, HierarchyQuerySpec, TerritoryGridRow } from 'app/models';

import block from 'utils/bem-css-modules';
import { getLeafNodes } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import gridErrorImage from 'assets/pngs/401.png';

import style from './TerritoryGrid.module.pcss';
import TerritoryGridHeader from './TerritoryGridHeader';

const b = block(style);

interface TerritoryGridProps {
  selectedNodes: null;
  setSelectedNodes: (nodes: RowNode) => void;
  setMoveTerritoriesTGTypes: Dispatch<SetStateAction<Array<number>>>;
  onIsFilterPresentChange: (isFilterActive: boolean) => void;
}

const TerritoryGrid: React.FC<TerritoryGridProps> = ({
  setSelectedNodes,
  setMoveTerritoriesTGTypes,
  onIsFilterPresentChange
}) => {
  const {
    refreshGrid,
    setRefreshGrid,
    setShowAggregatedActivities,
    selectedRowData,
    setSelectedRowData,
    setSelectedTerritory,
    selectedCell,
    setSelectedCell,
    showUpsertTerritoryRuleView,
    setShowUpsertTerritoryRuleView,
    setSelectedTerritoryRules,
    sortModel,
    setSortModel,
    isBulkDeleteChecked,
    setIsBulkDeleteChecked,
    bulkDeleteTerritoryJobKey,
    bulkDeleteExclusionIds,
    setBulkDeleteExclusionIds,
    bulkDeleteInclusionIds,
    setBulkDeleteInclusionIds
  } = useGrid();
  const { territoryRuleId, setTerritoryGroupId } = useDataTray();
  const { userRole } = useUser();
  const { selectedQuotaComponentId, selectedBattleCardId, selectedBattleCardMeasures } = useBattleCard();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR, tdrTreeLookupMap } = useTerritoryDefineAndRefine();
  const { selectedPlanningCycle } = useScope();
  const [isBulkDeleteTerOn] = useTreatment(SplitFeatures.BULK_DELETION_TERRITORIES);
  const showToast = useShowToast();

  const [territoryGroupTypeLookup, setTerritoryGroupTypeLookup] = useState<Record<string, string>>({});
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAllTerritoryRuleCount] = useState(null);
  const [initialSortState] = useState(sortModel);
  const [currentFetchRowIndex, setcurrentFetchRowIndex] = useState(0);
  const [savedFilterModel, setSavedFilterModel] = useState<FilterInput>({});

  const draftTerritoryGridFilters = useRef<FilterInput>({});
  const containerRef = useRef(null);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isBattleCardSelected = !selectedPillId || selectedPillIdPlanTargets === 'battlecard';
  const getTerritoryRulesVariables = useMemo(
    (): GetTerritoryRulesVariables => ({
      quotaComponentId: selectedQuotaComponentId,
      territoryGroupId: +selectedPillId || null,
      battlecardId: isBattleCardSelected ? +selectedBattleCardId : null,
      measureId: selectedBattleCardMeasures?.[0]?.measureId || 0,
      sorting: sortModel,
      searchInput: {
        filters: formatFilterForRequest(savedFilterModel)
      },
      dimensionsAndModifiersLimit: HIERARCHY_PREVIEW_COUNT
    }),
    [
      selectedQuotaComponentId,
      selectedPillId,
      isBattleCardSelected,
      selectedBattleCardId,
      sortModel,
      selectedBattleCardMeasures,
      savedFilterModel
    ]
  );
  const territoryRulesInputRef = useAsLiveRef(getTerritoryRulesVariables);

  const {
    data: rootHierarchiesData,
    loading: rootHierarchiesDataLoading,
    error: getRootHierarchiesError
  } = useQuery<HierarchyQuerySpec>(GET_ROOT_HIERARCHIES, {
    variables: { planningCycleId: selectedPlanningCycle?.id },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
    }
  });

  const [
    getTerritoryRules,
    { data: territoryRulesData, loading: territoryRulesDataLoading, fetchMore, error: getTerritoryRulesError }
  ] = useLazyQuery<GetTerritoryRules, GetTerritoryRulesVariables>(GET_TERRITORY_RULES, {
    fetchPolicy: 'no-cache',
    variables: {
      startRow: 1,
      endRow: BLOCK_SIZE,
      ...getTerritoryRulesVariables,
      sorting: initialSortState // ensure the reference to the initialSortState is stable to prevent the initial getTerritoryRules query from firing off more than once
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    }
  });

  const [territoryIdToEdit, setTerritoryIdToEdit] = useState(null);

  const [getTerritoryRuleToEdit, { loading: territoryRuleToEditDataLoading }] = useGetTerritoryRules({
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    },
    onCompleted(data) {
      const territoryRules = data?.getTerritoryRules?.territoryRules || [];
      if (territoryRules.length > 0) {
        const formattedRows = formatRowData(territoryRules);
        setSelectedRowData(formattedRows[0]);
        setShowUpsertTerritoryRuleView(true);
      }
    }
  });

  useEffect(() => {
    if (territoryIdToEdit) {
      const variables = {
        startRow: 1,
        endRow: 1,
        ...getTerritoryRulesVariables,
        dimensionsAndModifiersLimit: null,
        searchInput: {
          filters: formatFilterForRequest({
            [GridFields.TERRITORY_ID]: {
              filterType: 'text',
              type: 'equals',
              filter: territoryIdToEdit,
              filterTo: 'string'
            }
          })
        }
      };

      getTerritoryRuleToEdit({
        fetchPolicy: 'no-cache',
        variables
      });
    }
  }, [territoryIdToEdit]);

  const activeFiltersCount = useMemo(
    () => Object.values(savedFilterModel).filter(({ filter }) => !!filter || filter === 0).length,
    [savedFilterModel]
  );

  useEffect(() => {
    onIsFilterPresentChange(activeFiltersCount > 0);
  }, [activeFiltersCount]);

  const hasError = getTerritoryRulesError || getRootHierarchiesError;
  const isLoading =
    rootHierarchiesDataLoading ||
    territoryRulesDataLoading ||
    !!bulkDeleteTerritoryJobKey ||
    territoryRuleToEditDataLoading;
  const territoryRules = territoryRulesData?.getTerritoryRules?.territoryRules || [];
  const territoryRuleCount = territoryRulesData?.getTerritoryRules?.totalCount || 0;
  const territoryRuleMeasureValueMean = territoryRulesData?.getTerritoryRules?.measureValueMean || 0;
  const territoryRuleMeasureValueSD = territoryRulesData?.getTerritoryRules?.measureValueStandardDeviation || 0;
  const shouldShowNoRowsOverlay = territoryRuleCount === 0 && !isLoading && !activeFiltersCount;

  const handleFilterChange = (updatedFilter) => {
    const { filterType, type, filter, colId } = updatedFilter;
    draftTerritoryGridFilters.current = {
      ...draftTerritoryGridFilters.current,
      [colId]: {
        filterType,
        type,
        filter,
        filterTo: null
      }
    };
  };
  const handleClearAll = () => {
    draftTerritoryGridFilters.current = {};
    handleFilterApply();
  };

  const handleFilterApply = () => {
    if (!isequal(draftTerritoryGridFilters.current, savedFilterModel)) {
      setSavedFilterModel(draftTerritoryGridFilters.current);
      setSelectedNodes(null);
    }
  };

  const handleFilterClear = (colId: string) => {
    const { [colId]: omitted, ...newFilters } = draftTerritoryGridFilters.current;
    draftTerritoryGridFilters.current = newFilters;
    handleFilterApply();
  };

  const handleCellClicked = (gridEvent) => {
    setSelectedCell({
      rowIndex: gridEvent.rowIndex,
      value: gridEvent.value,
      column: gridEvent.colDef.field,
      ruleId: String(gridEvent.data?.ruleId)
    });
    setTerritoryGroupId(gridEvent.data?.territoryGroupId?.toString());
  };

  const handleCellMouseOver = (gridEvent) => {
    const nodeData = gridEvent.node?.data;
    if (nodeData) {
      nodeData.isSelectedOrHovered = true;
      gridEvent.api.refreshCells({ force: true, rowNodes: [gridEvent.node], columns: [GridFields.TERRITORY_RULE] });
    }
  };

  const handleCellMouseOut = (gridEvent) => {
    const nodeData = gridEvent.node?.data;
    const selectedNodesIdSet = new Set(gridEvent.api.getSelectedNodes().map((node) => node.data.ruleId));
    const isNodeSelected = selectedNodesIdSet.has(nodeData.ruleId);
    if (nodeData && !isNodeSelected) {
      nodeData.isSelectedOrHovered = false;
      gridEvent.api.refreshCells({ force: true, rowNodes: [gridEvent.node], columns: [GridFields.TERRITORY_RULE] });
    }
  };

  const handleSelectionChanged = (gridEvent) => {
    const nodes = gridEvent.api.getSelectedNodes();
    const formattedNodes = nodes ? nodes.map((node) => ({ data: node.data })) : [];
    const selectedTGTypes = [];
    formattedNodes.forEach((node) => {
      const tgId = node.data?.territoryGroupId;
      const tgType = territoryGroupTypeLookup[tgId];
      // set this data to correctly render the style for territory rule column
      node.data.isSelectedOrHovered = true;

      if (selectedTGTypes.indexOf(tgType) === -1) {
        selectedTGTypes.push(tgType);
      }
    });

    const notSelectedNodeId = [];
    gridEvent.api.forEachNode((node) => {
      if (node.data && !node.isSelected()) {
        notSelectedNodeId.push(node.data.ruleId);
        node.data.isSelectedOrHovered = false;
      }
    });

    setMoveTerritoriesTGTypes(selectedTGTypes);
    setSelectedNodes(formattedNodes);
    setBulkDeleteExclusionIds(notSelectedNodeId);
  };

  useEffect(() => {
    setSelectedNodes(null);
    setMoveTerritoriesTGTypes([]);
    setShowUpsertTerritoryRuleView(false);
  }, [selectedBattleCardId]);

  const handleGridReady = (gridEvent) => {
    setGridApi(gridEvent.api);
  };

  // listen to the checked state that is controlled by header checkbox cell renderer
  // on check/uncheck, select/deselect all rows that inside the grid
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const allRowNodes = [];
    gridApi.forEachNode((node) => {
      if (node.data) allRowNodes.push(node);
    });
    const rowNodeCount = allRowNodes.length;

    if (isBulkDeleteChecked) {
      // only call node.setSelected(true) for the last row node inside the grid to trigger the grid onSelectionChanged event
      // call node.setSelected(true, false, true) to only check the row and suppress onSelectionChanged event
      allRowNodes.forEach((node, index) => {
        if (index + 1 === rowNodeCount) {
          node.setSelected(true);
        } else {
          node.setSelected(true, false, true);
        }
      });
      setBulkDeleteInclusionIds(allRowNodes.map((node) => node.data.ruleId));
    } else {
      allRowNodes.forEach((node, index) => {
        if (index + 1 === rowNodeCount) {
          node.setSelected(false);
        } else {
          node.setSelected(false, false, true);
        }
      });
      setBulkDeleteInclusionIds([]);
    }
  }, [isBulkDeleteChecked, gridApi]);

  // whenever there is new data load into the grid, and user have checked select all, check all new rows that rendered
  useEffect(() => {
    if (!gridApi) {
      return;
    }
    // ag grid SSRM has a configuration maxConcurrentDatasourceRequests (default value is 2)
    // hence only if the grid data is more than 3 times the cacheBlockSize, and the user scroll down the grid, it will load new data
    // 3 because when the grid is ready, the first block is already rendered, hence 2+1
    if (currentFetchRowIndex >= 3 * BLOCK_SIZE && isBulkDeleteChecked) {
      const allRowNodes = [];
      gridApi.forEachNode((node) => {
        if (node.data) allRowNodes.push(node);
      });
      const nodesToBeChecked = allRowNodes.filter(
        (node) =>
          !bulkDeleteInclusionIds.includes(node.data.ruleId) && !bulkDeleteExclusionIds.includes(node.data.ruleId)
      );
      const toBeCheckedLength = nodesToBeChecked.length;
      // only call node.setSelected(true) for the last row node inside the grid to trigger the grid onSelectionChanged event
      // call node.setSelected(true, false, true) to only check the row and suppress onSelectionChanged event
      nodesToBeChecked.forEach((node, index) => {
        if (index + 1 === toBeCheckedLength) {
          node.setSelected(true);
        } else {
          node.setSelected(true, false, true);
        }
      });
      setBulkDeleteInclusionIds((prev) => [...prev, ...nodesToBeChecked.map((node) => node.data.ruleId)]);
    }
  }, [currentFetchRowIndex, gridApi]);

  const gridProps = {
    headerHeight: CELL_HEIGHT,
    rowHeight: CELL_HEIGHT,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: false,
    suppressCellSelection: true,
    onGridReady: handleGridReady,
    onCellClicked: handleCellClicked,
    onSelectionChanged: handleSelectionChanged,
    onCellMouseOver: handleCellMouseOver,
    onCellMouseOut: handleCellMouseOut,
    cacheBlockSize: BLOCK_SIZE,
    serverSideDatasource: {
      getRows: async (params) => {
        // ag-grid uses 0-based indexing, but the backend uses 1-based indexing
        const startRow = params?.request?.startRow + 1;
        const endRow = params?.request?.endRow;
        const hasUserNotScrolled = endRow === BLOCK_SIZE;
        const hasSortModelBeenSet = !!params?.request?.sortModel?.[0];
        const isInitialSortModel = isequal(params?.request?.sortModel?.[0], sortModel);
        const canUseInitialData = hasUserNotScrolled && (!hasSortModelBeenSet || isInitialSortModel);
        const newSortModel = hasSortModelBeenSet ? { ...params?.request?.sortModel?.[0] } : sortModel;

        if (canUseInitialData) {
          // data from the initial query is sufficient when the user has not scrolled
          const rowData = formatRowData(
            territoryRules,
            territoryRuleMeasureValueMean,
            territoryRuleMeasureValueSD,
            startRow
          );
          params?.success({ rowData, rowCount: territoryRuleCount });
        } else {
          const fetchMoreTerritoryRules = await fetchMore<GetTerritoryRules, GetTerritoryRulesVariables>({
            variables: {
              ...territoryRulesInputRef.current,
              startRow,
              endRow,
              sorting: newSortModel
            }
          });
          const moreTerritoryRules = fetchMoreTerritoryRules?.data?.getTerritoryRules?.territoryRules || [];
          const rowData = formatRowData(
            moreTerritoryRules,
            territoryRuleMeasureValueMean,
            territoryRuleMeasureValueSD,
            startRow
          );
          params?.success({ rowData, rowCount: territoryRuleCount });
        }
        setSortModel(newSortModel);
        setcurrentFetchRowIndex(params?.request?.startRow);
      }
    }
  };

  useEffect(() => {
    // fetch territory rules
    getTerritoryRules();
    // reset bulk delete territory states
    setSelectedNodes(null);
    setIsBulkDeleteChecked(false);
    setBulkDeleteExclusionIds([]);
    setBulkDeleteInclusionIds([]);
    setcurrentFetchRowIndex(0);
  }, [selectedBattleCardId, selectedPillId, selectedQuotaComponentId]);

  // refresh the grid
  useEffect(() => {
    if (refreshGrid) {
      getTerritoryRules();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  // when a territory row is selected, set the territory rule count
  useEffect(() => {
    setAllTerritoryRuleCount(territoryRuleCount);
  }, [territoryRulesData, territoryRuleId]);

  useEffect(() => {
    setAllTerritoryRuleCount(null);
  }, [selectedBattleCardId]);

  useEffect(() => {
    if (territoryRules.length > 0) {
      setSelectedTerritoryRules(territoryRules);
    }
  }, [territoryRulesData]);

  useEffect(() => {
    if (tdrTreeLookupMap) {
      const tgTypeLookup = {};
      const tdrTree = tdrTreeLookupMap[selectedBattleCardId] || [];
      const leafNodes = [];
      tdrTree.forEach((treeNode) => {
        tgTypeLookup[treeNode.territoryGroupId] = treeNode.hierarchyTopId;
        getLeafNodes(treeNode, leafNodes);
      });
      leafNodes.forEach((node) => {
        tgTypeLookup[node.territoryGroupId] = node.hierarchyTopId;
      });

      setTerritoryGroupTypeLookup(tgTypeLookup);
    }
  }, [tdrTreeLookupMap, selectedBattleCardId]);

  const renderUpsertTerritoryRuleView = () => {
    return (
      <UpsertTerritoryRuleView
        isEditMode={!!selectedRowData}
        data={{
          territoryName: selectedRowData?.[GridFields.TERRITORY_NAME],
          ruleId: selectedRowData?.['ruleId'],
          ruleDefinition: selectedRowData?.[GridFields.TERRITORY_RULE],
          territoryId: selectedRowData?.[GridFields.TERRITORY_ID],
          territoryGroupId: String(selectedRowData?.['territoryGroupId']),
          inheritsFrom: selectedRowData?.['inheritsFrom'],
          effectiveDate: selectedRowData?.['effectiveDate'],
          endDate: selectedRowData?.['endDate']
        }}
        onBackButtonClick={() => {
          setShowUpsertTerritoryRuleView(false);
          setTerritoryIdToEdit(null);
          setSelectedRowData(null);
        }}
        rootHierarchies={rootHierarchiesData?.getRootHierarchies}
        isLoading={rootHierarchiesDataLoading}
        hasError={!!getRootHierarchiesError}
        data-testid="upsert-territory-rule-view"
      />
    );
  };

  const canShowContent = !hasError && !shouldShowNoRowsOverlay;

  return showUpsertTerritoryRuleView ? (
    renderUpsertTerritoryRuleView()
  ) : (
    // render the territory grid content
    <div className={b('gridWrapper')} ref={containerRef}>
      {canShowContent ? (
        <TerritoryGridHeader
          onFilterChange={handleFilterChange}
          onFilterApply={handleFilterApply}
          data-testid="territory-grid-header"
          activeFiltersCount={activeFiltersCount}
          territoryFilters={draftTerritoryGridFilters.current}
          onClearAll={handleClearAll}
          onClearField={handleFilterClear}
        />
      ) : null}
      <div className={b('advancedGridWrapper')}>
        {hasError ? (
          <div className={b('gridOverlayContainer')} data-testid="no-territories-error-overlay">
            <img className={b('gridOverlayImage')} src={gridErrorImage} alt="" />
            <div className={b('gridOverlayText')}>{formatMessage('TERRITORY_GRID_ERROR')}</div>
          </div>
        ) : null}
        {shouldShowNoRowsOverlay ? (
          <div className={b('gridOverlayContainer')} data-testid="no-territories-overlay">
            <div className={b('gridOverlayText')}>{formatMessage('NO_GRID_TERRITORIES_ADDED')}</div>
          </div>
        ) : null}
        {canShowContent ? (
          <AdvancedGrid
            data-testid="territory-grid"
            gridProps={gridProps}
            columnDefs={buildTerritoryColumnDef({
              data: territoryRules,
              activityDrillIn: (rowData) =>
                dataTrayHelper.onShowActivities(rowData, setSelectedTerritory, setShowAggregatedActivities),
              onEditTerritory: (rowData) => {
                setTerritoryIdToEdit(rowData[GridFields.TERRITORY_ID]);
              },
              userRole,
              selectedCell,
              isBulkDeleteTerOn
            })}
            gridHeight={containerRef?.current?.offsetHeight}
            gridWidth={containerRef?.current?.offsetWidth}
            showGridLoading={isLoading}
            suppressMultiSort
            getRowNodeId={(row: TerritoryGridRow) => `R::${row.ruleId}`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TerritoryGrid;

// Used to ensure the value of the variables used in the `getRows` calls are up to date despite not being able to update the callback itself
function useAsLiveRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}
