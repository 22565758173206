import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { GetBattleCardCanvas_getDeploymentModelSpec_battlecards } from 'app/graphql/generated/apolloTypes';
import { GET_BATTLE_CARD_CANVAS } from 'app/graphql/queries/getBattleCardCanvas';
import { GET_MEASURES } from 'app/graphql/queries/getMeasures';

import {
  BattleCardData,
  BattleCardInfo,
  BattleCardMeasure,
  BattlecardType,
  BusinessTargetMeasure,
  GetMeasures,
  HierarchySpec,
  MeasureFormatType
} from 'app/models/index';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface BattleCard {
  data: BattleCardData;
  children: BattleCard[];
}

export const getBattleCardCanvas = async (
  deploymentModelId: number,
  isReportingCurrency = true,
  suppressCache = false,
  isTQM = false
): Promise<GetBattleCardCanvas_getDeploymentModelSpec_battlecards[]> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    const battleCardCanvas = await apolloClient.query({
      query: GET_BATTLE_CARD_CANVAS,
      notifyOnNetworkStatusChange: true,
      variables: { deploymentModelId, reportingCurrency: isReportingCurrency, isTQM },
      fetchPolicy: suppressCache ? 'network-only' : 'cache-first'
    });

    return battleCardCanvas?.data?.getDeploymentModelSpec?.battlecards;
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('BATTLECARD_CANVAS_ERROR'), 'danger');
    return [];
  }
};

export const getMeasures = async (planningCycleId: number, suppressCache = false): Promise<GetMeasures[]> => {
  try {
    // eslint-disable-next-line no-restricted-syntax
    const measures = await apolloClient.query({
      query: GET_MEASURES,
      notifyOnNetworkStatusChange: true,
      variables: { planningCycleId },
      fetchPolicy: suppressCache ? 'network-only' : 'cache-first'
    });

    return measures?.data?.getMeasures;
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('BATTLECARD_MEASURES_ERROR'), 'danger');
    return [];
  }
};

export const mapChildren = (
  battleCardList: GetBattleCardCanvas_getDeploymentModelSpec_battlecards[],
  isLocalCurrencyMode: boolean,
  cardId: number
): BattleCard => {
  const card: BattleCard = {
    data: {
      battlecardId: '',
      battlecardInfoLevel: null,
      battlecardName: '',
      battlecardParentId: null,
      battlecardType: null,
      conversionRate: 0,
      localCurrencyCode: null,
      reportingCurrencyCode: null,
      isLocalCurrencyMode,
      invalidConversionReason: null,
      deploymentModelId: 0,
      deploymentModelName: '',
      planningCycleId: 0,
      planningCycleName: '',
      isRootCard: false,
      globalMeasures: {},
      quotaComponents: []
    },
    children: []
  };

  //Add card measures to cardData
  const battleCard = battleCardList?.length && battleCardList.find((bc) => bc.battlecardId === cardId);
  if (battleCard) {
    card.data.battlecardId = battleCard.battlecardId.toString();
    card.data.battlecardInfoLevel = battleCard.battlecardInfoLevel;
    card.data.battlecardName = battleCard.battlecardName;
    card.data.isRootCard = battleCard.battlecardParentId === null;
    card.data.battlecardType = battleCard.battlecardType as BattlecardType;
    card.data.conversionRate = battleCard.conversionRate;
    card.data.localCurrencyCode = battleCard.localCurrencyCode;
    card.data.reportingCurrencyCode = battleCard.reportingCurrencyCode;
    card.data.invalidConversionReason = battleCard.invalidConversionReason;
    card.data.quotaComponents = battleCard.quotaComponents;

    battleCard.quotaComponents.forEach((quotaComponent) => {
      const qcId = quotaComponent.quotaComponentId;
      card.data.globalMeasures[qcId] = [];

      const newBusinessTarget: BattleCardMeasure = {
        isEditable: true,
        measureName: BusinessTargetMeasure.NEW_BUSINESS_TARGET,
        measureFormatType: MeasureFormatType.CURRENCY,
        measureValue: quotaComponent?.newBusinessTarget
      };
      card.data.globalMeasures[qcId].push(newBusinessTarget);

      if (battleCard.battlecardType === BattlecardType.Rollup) {
        const allocatedBusinessTarget: BattleCardMeasure = {
          isEditable: false,
          measureName: BusinessTargetMeasure.ALLOCATED_BUSINESS_TARGET,
          measureFormatType: MeasureFormatType.CURRENCY,
          measureValue: quotaComponent?.allocatedBusinessTarget
        };
        const unallocatedBusinessTarget: BattleCardMeasure = {
          isEditable: false,
          measureName: BusinessTargetMeasure.UNALLOCATED_BUSINESS_TARGET,
          measureFormatType: MeasureFormatType.CURRENCY,
          measureValue: quotaComponent?.unallocatedBusinessTarget
        };
        card.data.globalMeasures[qcId].push(allocatedBusinessTarget, unallocatedBusinessTarget);
      }

      const modifiedCardMeasures = quotaComponent?.measures.map((cardMeasure) => {
        return {
          isEditable: false,
          measureName: cardMeasure.measureName,
          measureFormatType: cardMeasure.measureFormatType,
          measureValue: cardMeasure.measureValue
        };
      });
      card.data.globalMeasures[qcId].push(...modifiedCardMeasures);
    });

    for (let i = 0; i < battleCard.children.length; i++) {
      card.children.push(mapChildren(battleCardList, isLocalCurrencyMode, battleCard.children[i]));
    }
  }
  return card;
};

export const createLookupMap = (
  flatList: GetBattleCardCanvas_getDeploymentModelSpec_battlecards[]
): Record<number, BattleCardInfo> => {
  const record: Record<number, BattleCardInfo> = {};
  flatList?.forEach((bc) => {
    record[bc.battlecardId] = { ...bc, battlecardType: bc.battlecardType as BattlecardType };
  });

  flatList?.forEach((parentBc) => {
    parentBc.children?.forEach(
      (childBattlecardId) =>
        (record[childBattlecardId].battleCardParentType = parentBc.battlecardType as BattlecardType)
    );
  });
  return record;
};

// returns true if a battlecard exists with an invalid currency conversion, and false otherwise
export const checkForInvalidCurrencyConversion = (battleCardLookupMap: Record<number, BattleCardInfo>): boolean => {
  for (const battleCardData of Object.values(battleCardLookupMap)) {
    if (battleCardData.invalidConversionReason) {
      return true;
    }
  }
  return false;
};

// return an array of currency codes that is ordered based on the most "used" currencies
// ("in use" means a currency is a local currency on a battlecard) - note: the defaultReportingCurrency
// is always the first entry of the returned array, even if some currencies are more "frequent" than it
export const getAllCurrenciesInUse = (
  battleCardLookupMap: Record<number, BattleCardInfo>,
  defaultReportingCurrency: string
): string[] => {
  const currencyFrequencyMap: Record<string, number> = {
    [defaultReportingCurrency]: Number.POSITIVE_INFINITY // ensure the defaultReportingCurrency always appears first in the returned array
  };

  // populate the currencyFrequencyMap
  Object.values(battleCardLookupMap).forEach((battleCardData) => {
    const { localCurrencyCode } = battleCardData;

    if (!localCurrencyCode) {
      return;
    }

    if (currencyFrequencyMap[localCurrencyCode]) {
      currencyFrequencyMap[localCurrencyCode] += 1;
    } else {
      currencyFrequencyMap[localCurrencyCode] = 1;
    }
  });

  const entriesSortedByFrequency = Object.entries(currencyFrequencyMap).sort(
    ([, frequencyA], [, frequencyB]) => frequencyB - frequencyA
  );

  // Discard the frequency, return only the currency code
  return entriesSortedByFrequency.map(([currencyCode]) => currencyCode);
};

export const getBattlecardQuotaBreakdownHierarchies = (
  bcQuotaDistributionHierarchyIds: number[],
  hierarchies: HierarchySpec[]
): HierarchySpec[] | null[] => {
  if (bcQuotaDistributionHierarchyIds?.length === 0 || !bcQuotaDistributionHierarchyIds) return [];
  const rootHierarchiesMap = new Map(
    hierarchies.map((hierarchy) => {
      const { rootKey, rootName, rootHierarchyId, hierarchyType, numOfMembers } = hierarchy;
      return [
        rootHierarchyId,
        {
          rootKey,
          rootName,
          rootHierarchyId,
          hierarchyType,
          numOfMembers
        }
      ];
    })
  );

  return bcQuotaDistributionHierarchyIds.map((hierarchyId) => rootHierarchiesMap.get(hierarchyId));
};
