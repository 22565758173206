import React, { Dispatch, SetStateAction } from 'react';

import { GridApi, ICellRendererParams } from '@ag-grid-community/core';

import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';

import { useScope } from 'app/contexts/scopeProvider';

import {
  GetAllHierarchiesVariables,
  GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies,
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
} from 'app/graphql/generated/apolloTypes';
import { GET_ALL_HIERARCHIES } from 'app/graphql/queries/getAllHierarchies';
import { GET_PINNED_HIERARCHIES } from 'app/graphql/queries/getPinnedHierarchies';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import { formatMessage } from 'utils/messages/utils';

import { useDeleteHierarchy } from './hooks/useDeleteHierarchy';

interface ConfirmDeleteHierarchyModalProps {
  isMultiDelete?: boolean;
  selectedNode: ICellRendererParams;
  setSelectedNode: Dispatch<SetStateAction<ICellRendererParams>>;
  rootHierarchy;
  showDeleteHierarchyDialog: boolean;
  setShowDeleteHierarchyDialog: Dispatch<SetStateAction<boolean>>;
  setInitialBlock: Dispatch<
    SetStateAction<
      | GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies
      | GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies
      | GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
    >
  >;
  gridApi?: GridApi;
  multiSelectedNodes?: ICellRendererParams['node'][];
  setMultiSelectedNodes?: Dispatch<SetStateAction<ICellRendererParams['node'][]>>;
  searchString?: string;
  getAllHierarchiesVariables?: GetAllHierarchiesVariables;
  setSearchString?: (value) => void;
}

export const ConfirmDeleteHierarchyModal: React.FC<ConfirmDeleteHierarchyModalProps> = ({
  isMultiDelete = false,
  selectedNode,
  rootHierarchy,
  showDeleteHierarchyDialog,
  setShowDeleteHierarchyDialog,
  setSelectedNode,
  setInitialBlock,
  gridApi,
  multiSelectedNodes,
  setMultiSelectedNodes,
  searchString = '',
  getAllHierarchiesVariables,
  setSearchString
}: ConfirmDeleteHierarchyModalProps) => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();

  const [deleteHierarchy, { loading: deletingHierarchy }] = useDeleteHierarchy({
    isMultiDelete,
    setShowDeleteHierarchyDialog,
    rootHierarchyName: rootHierarchy?.rootName,
    setSelectedNode,
    selectedNode,
    setInitialBlock,
    gridApi,
    multiSelectedNodes,
    setMultiSelectedNodes,
    searchString,
    setSearchString
  });

  let titleText: string;

  if (isMultiDelete) {
    // for multi-select, put node count in title
    titleText =
      multiSelectedNodes?.length > 1
        ? formatMessage('DELETE_HIERARCHY_MEMBERS_WITH_VALUE', { value: multiSelectedNodes?.length })
        : formatMessage('DELETE_HIERARCHY_MEMBER_WITH_VALUE', { value: multiSelectedNodes?.length });
  } else {
    // for single-select, put node name in title
    titleText = formatMessage('DELETE_HIERARCHY_MEMBER_WITH_VALUE', { value: `"${selectedNode?.data?.name}"` });
  }

  const bodyText =
    isMultiDelete && multiSelectedNodes?.length > 1
      ? formatMessage('DELETE_HIERARCHY_MEMBERS_CONFIRMATION_MESSAGE')
      : formatMessage('DELETE_HIERARCHY_MEMBER_CONFIRMATION_MESSAGE');

  const hierarchyIds = multiSelectedNodes?.map((multiNode) => {
    return multiNode.data.hierarchyId;
  });

  const refetchQueries = [
    {
      query: GET_ROOT_HIERARCHIES,
      variables: { planningCycleId: selectedPlanningCycle?.id }
    },
    {
      query: GET_PINNED_HIERARCHIES,
      variables: { deploymentModelId: selectedDeploymentModelId, includeFirstLevelMemberCount: false }
    }
  ];

  if (searchString !== '' && getAllHierarchiesVariables) {
    refetchQueries.push({
      query: GET_ALL_HIERARCHIES,
      variables: getAllHierarchiesVariables
    });
  }

  return (
    <div data-testid="confirm-delete-hierarchy-modal">
      <ConfirmDeleteModal
        isOpen={showDeleteHierarchyDialog}
        onConfirmDelete={(e) => {
          e.stopPropagation();
          deleteHierarchy({
            variables: {
              hierarchyIds: isMultiDelete ? hierarchyIds : [selectedNode?.data?.hierarchyId],
              hierarchyType: rootHierarchy?.hierarchyType,
              planningCycleId: selectedPlanningCycle?.id
            },
            awaitRefetchQueries: true,
            refetchQueries
          });
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setShowDeleteHierarchyDialog(false);
        }}
        bodyText={bodyText}
        titleText={titleText}
        isSubmitting={deletingHierarchy}
      />
    </div>
  );
};

export default ConfirmDeleteHierarchyModal;
