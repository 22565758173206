import React, { useCallback } from 'react';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { usePublishDeploymentModel } from 'app/graphql/mutations/publishDeploymentModel';
import { GET_DEPLOYMENT_MODEL_SUMMARIES } from 'app/graphql/queries/getDeploymentModelSummaries';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

export const usePublishAndApplyScenario = () => {
  const { userPlanningCycles, setUserPlanningCycles } = useUser();
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const applyPublishToUserPlanningCycles = (publishedDmId: number) => {
    const newPcs = userPlanningCycles.map((oldPc) => {
      if (!oldPc.deploymentModels?.find((dm) => dm.deploymentModelId === publishedDmId)) return oldPc;
      return {
        ...oldPc,
        deploymentModels: oldPc.deploymentModels?.map((oldDm) => ({
          ...oldDm,
          isPublished: oldDm.deploymentModelId === publishedDmId
        }))
      };
    });
    setUserPlanningCycles(newPcs);
  };

  const [publishScenario] = usePublishDeploymentModel({
    onCompleted(data) {
      showToast(
        <ToastMessage
          title={formatMessage('SCENARIO_PUBLISHED')}
          message={formatMessage('SCENARIO_PUBLISHED_MESSAGE', {
            name: data.updateDeploymentModel.deploymentModelName
          })}
        />,
        'success'
      );
      applyPublishToUserPlanningCycles(data.updateDeploymentModel.deploymentModelId);
    },
    onError() {
      showToast(formatMessage('SCENARIO_PUBLISHED_FAILURE'), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_DEPLOYMENT_MODEL_SUMMARIES,
        variables: {
          planningCycleId: selectedPlanningCycle?.id
        }
      }
    ]
  });

  return useCallback(
    (deploymentModelId: number) =>
      publishScenario({
        variables: {
          deploymentModelId
        }
      }),
    []
  );
};
