import React from 'react';

import { useBattleCard } from 'app/contexts/battleCardProvider';

import { BattleCardMeasure } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './BattleCardPanelPage.module.pcss';
import MeasureColumn from './MeasureColumn/MeasureColumn';

const b = block(style);

interface BattleCardPanelPageProps {
  isEditingEnabled: boolean;
  isCurrencyConversionValid: boolean;
  currency: string;
  panelData: Record<number, BattleCardMeasure[]> | BattleCardMeasure[];
  isRootCard: boolean;
  handleSubmit: (values: unknown, quotaComponentId: number) => void;
}

const BattleCardPanelPage: React.FC<BattleCardPanelPageProps> = ({
  panelData,
  isRootCard,
  isEditingEnabled = true,
  isCurrencyConversionValid = false,
  currency = 'USD',
  handleSubmit
}: BattleCardPanelPageProps) => {
  const { selectedQuotaComponentId } = useBattleCard();

  return (
    <div className={b()} data-testid="battle-card-panel-page">
      <ul className={b('lineItemsBattleCardRefactor', { isRootCard })}>
        {(() => {
          if (isRootCard && panelData) {
            return (
              <>
                {Object.entries(panelData).map(([key, value], index) => {
                  return (
                    <div className={b('columnWrapper')} key={index}>
                      <MeasureColumn
                        panelData={value}
                        isEditingEnabled={isEditingEnabled}
                        isCurrencyConversionValid={isCurrencyConversionValid}
                        isRootCard={isRootCard}
                        currency={currency}
                        quotaComponentId={+key}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  );
                })}
              </>
            );
          } else {
            return (
              <MeasureColumn
                panelData={panelData as BattleCardMeasure[]}
                isEditingEnabled={isEditingEnabled}
                isCurrencyConversionValid={isCurrencyConversionValid}
                isRootCard={isRootCard}
                currency={currency}
                quotaComponentId={selectedQuotaComponentId}
                handleSubmit={handleSubmit}
              />
            );
          }
        })()}
      </ul>
    </div>
  );
};

export default BattleCardPanelPage;
