import { useState, useMemo, useEffect, useCallback } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import {
  BCInfoLevelEnum,
  GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards
} from 'app/graphql/generated/apolloTypes';
import { useGetBattleCardsForEmbeddedMap } from 'app/graphql/queries/getBattlecardsForEmbeddedMap';

import { HierarchyType, MapContextBattleCard } from 'app/models';

interface BcQcSelection {
  isLoading: boolean;
  availableBattleCards: MapContextBattleCard[];
  selectedBattleCard: MapContextBattleCard | null;
  selectedQuotaComponentId: number | null;
  setSelectedBattleCardId: (bcId: number) => void;
  setSelectedQuotaComponentId: (qcId: number) => void;
}

interface BcQc {
  selectedBattleCardId: number | null;
  selectedQuotaComponentId: number | null;
}

export const useEmbeddedMapBcQcSelection = (): BcQcSelection => {
  const [{ selectedBattleCardId, selectedQuotaComponentId }, setState] = useState<BcQc>({
    selectedBattleCardId: null,
    selectedQuotaComponentId: null
  });

  const { selectedDeploymentModelId } = useScope();

  const { data: bcData, loading: isBattlecardsLoading } = useGetBattleCardsForEmbeddedMap({
    skip: !selectedDeploymentModelId,
    variables: { input: { deploymentModelId: selectedDeploymentModelId } }
  });

  const availableBattleCards = useMemo(
    () => bcData?.getDeploymentModelSpec.battlecards.filter((bc) => isBcMapAvailable(bc)) ?? [],
    [bcData]
  );

  const selectedBattleCard = useMemo(
    () => selectedBattleCardId && availableBattleCards.find((bc) => bc.battlecardId === selectedBattleCardId),
    [availableBattleCards, selectedBattleCardId]
  );

  const selectedQuotaComponent = useMemo(
    () =>
      selectedQuotaComponentId &&
      selectedBattleCard &&
      selectedBattleCard.quotaComponents.find((qc) => qc.quotaComponentId === selectedQuotaComponentId),
    [selectedBattleCard, selectedQuotaComponentId]
  );

  useEffect(() => {
    // Setup initial selection
    setState((oldState) => {
      if (selectedBattleCard && selectedQuotaComponent) return oldState;
      const bcToSelect = selectedBattleCard ?? availableBattleCards[0];
      const qcToSelect = selectedQuotaComponent ?? bcToSelect?.quotaComponents[0];
      return {
        selectedBattleCardId: bcToSelect?.battlecardId ?? null,
        selectedQuotaComponentId: qcToSelect?.quotaComponentId ?? null
      };
    });
  }, [availableBattleCards]);

  const setSelectedBattleCardId = useCallback(
    (battleCardId: number) =>
      setState((oldState) => {
        const bcToSelect = availableBattleCards.find((bc) => bc.battlecardId === battleCardId);
        if (!bcToSelect) return oldState;

        const prevQc = bcToSelect.quotaComponents.find(
          (qc) => qc.quotaComponentId === oldState.selectedQuotaComponentId
        );
        const qcToSelect = prevQc ?? bcToSelect.quotaComponents[0];
        return {
          selectedBattleCardId: bcToSelect.battlecardId,
          selectedQuotaComponentId: qcToSelect?.quotaComponentId ?? null
        };
      }),
    [availableBattleCards]
  );
  const setSelectedQuotaComponentId = useCallback(
    (quotaComponentId: number) =>
      setState((oldState) => {
        const bcToSelect = availableBattleCards.find((bc) => bc.battlecardId === oldState.selectedBattleCardId);
        if (!bcToSelect) return oldState;

        const qcToSelect = bcToSelect.quotaComponents.find((qc) => qc.quotaComponentId === quotaComponentId);
        if (!qcToSelect) return oldState;

        return {
          selectedBattleCardId: bcToSelect.battlecardId,
          selectedQuotaComponentId: qcToSelect.quotaComponentId
        };
      }),
    [availableBattleCards]
  );

  return {
    isLoading: isBattlecardsLoading || !selectedDeploymentModelId,
    availableBattleCards,
    selectedBattleCard,
    selectedQuotaComponentId,
    setSelectedBattleCardId,
    setSelectedQuotaComponentId
  };
};

const canViewTerritoryGroupsForBc = (infoLevel: BCInfoLevelEnum) => infoLevel && infoLevel !== BCInfoLevelEnum.hidden;

const hasGeoTgt = (tgts: { hierarchyType: HierarchyType | string }[]) =>
  tgts.some((tgt) => tgt.hierarchyType === HierarchyType.GeographicTerritoryHierarchy);

const isBcMapAvailable = (bc: GetBattlecardsForEmbeddedMap_getDeploymentModelSpec_battlecards) =>
  canViewTerritoryGroupsForBc(bc.battlecardInfoLevel) &&
  hasGeoTgt(bc.territoryGroupTypes) &&
  bc.quotaComponents.length > 0;
