import React, { useEffect } from 'react';

import { Tag, PopoverInteractionKind } from '@blueprintjs/core';
import { UserAvatar, WarningAltFilled, Maximize, Minimize, Account, Money, ParentChild } from '@carbon/icons-react';
import { Tag as VaricentTag } from '@varicent/components';

import BattleCardIcon from 'components/BattleCardIcon/BattleCardIcon';
import IconButton from 'components/Buttons/IconButton/IconButton';
import Icon from 'components/Icon/Icon';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';

import TerritoryHoverToolbar from 'app/components/TerritoriesAndPlanTargets/TerritoryHoverToolbar/TerritoryHoverToolbar';
import { getIcon } from 'app/components/TerritoriesAndPlanTargets/territoryThemes';
import {
  getTotalChildActivityCount,
  getTotalChildNodeCount
} from 'app/components/TerritoriesAndPlanTargets/territoryUtils';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useData } from 'app/contexts/dataProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';

import { GetFieldTotalsVariables } from 'app/graphql/generated/apolloTypes';
import { useGetSheetDefinitions } from 'app/graphql/hooks/useGetSheetDefinitions';
import { useGetFieldTotalsLazy } from 'app/graphql/queries/getFieldTotals';

import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import {
  PillLevel,
  UserRoleType,
  Owner,
  CategoryName,
  BattleCardTarget,
  TerritoryGroupWithMeasuresNodeData,
  ExpandedTerritoryGroupDefineAndRefinePillData,
  DeploymentModelPhase,
  TabIds,
  DefaultSheetName,
  SheetType,
  SellerSheetGridColumnName
} from 'app/models';

import block from 'utils/bem-css-modules';
import { getHighlightCssPropertyValue, getHighlightCssPropertyName } from 'utils/helpers/highlightHelpers';
import { formatMessage, formatNumber } from 'utils/messages/utils';

import TargetComparison from './TargetComparison';
import style from './TerritoryPill.module.pcss';

const b = block(style);

interface PillOverlayProps {
  animate: boolean;
  isShown: boolean;
}

const PillOverlay: React.FC<PillOverlayProps> = ({ animate, isShown }: PillOverlayProps) => {
  return <div className={b('overlay', { dim: isShown, enterAnimation: animate })} />;
};
interface ActivityCountTagProps {
  activityCount: number;
  isSelected: boolean;
  onClick: (event: React.MouseEvent) => void;
  className: string;
}

const ActivityCountTag: React.FC<ActivityCountTagProps> = ({
  activityCount,
  isSelected,
  onClick,
  className
}: ActivityCountTagProps) => (
  <VaricentTag
    icon={<Icon icon={<Account />} />}
    onClick={onClick}
    className={`${className} ${b('activityCount', { selected: isSelected })}`}
    data-testid="territory-pill-activity-count"
  >
    {activityCount}
  </VaricentTag>
);

interface TerritoryPillProps {
  nodeData: BattleCardTarget | TerritoryGroupWithMeasuresNodeData | ExpandedTerritoryGroupDefineAndRefinePillData;
  quotaEntryMode?: boolean;
  target?: string;
  updatedQuota?: string;
  allocationMessage?: string;
  pillLevel: PillLevel;
  pillIndex?: number;
  categoryName?: CategoryName;
  battleCardId: string;
  battleCardLocalCurrency?: string;
  enableContextMenu?: boolean;
  animate?: boolean;
  enableClick?: boolean;
  owner?: Owner | null;
}

const TerritoryPill: React.FC<TerritoryPillProps> = ({
  nodeData,
  quotaEntryMode,
  target,
  updatedQuota,
  allocationMessage,
  pillLevel,
  pillIndex,
  categoryName,
  battleCardId,
  animate,
  battleCardLocalCurrency,
  enableContextMenu = true,
  enableClick = true,
  owner
}: TerritoryPillProps) => {
  const {
    isActivityCountSelected,
    setIsActivityCountSelected,
    selectedPillIdTDR,
    setSelectedPillIdTDR,
    tgExpandedLookupMap: tgExpandedLookupMapTDA,
    setTgExpandedCollapsed: setTgExpandedCollapsedTDA,
    tdrBattlecardLookupMap,
    unassignedActivitiesLookupMap,
    tdrDialogState
  } = useTerritoryDefineAndRefine();
  const {
    selectedPillIdPlanTargets,
    setSelectedPillIdPlanTargets,
    tgExpandedLookupMap: tgExpandedLookupMapPlanTargets,
    setTgExpandedCollapsed: setTgExpandedCollapsedPlanTargets
  } = usePlanTargets();
  const { selectedDeploymentModelId } = useScope();
  const { sortModel } = useGrid();
  const { setSelectedBattleCardId, selectedBattleCardId, selectedQuotaComponentId, battleCardLookupMap } =
    useBattleCard();
  const { userRole } = useUser();
  const { coinsortProgressLookupMap } = useCoinsort();
  const deploymentModelPhase = usePhase();
  const { selectedDataTrayTab } = useDataTray();
  const { allDataSheets } = useData();
  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);

  const defaultSellerSheetId = allDataSheets?.find(
    (sheet) => sheet.sheetType === SheetType.SELLER_SHEET && sheet.sheetName === DefaultSheetName.SELLER_QUOTA_SHEET
  )?.sheetId;

  const isPlanPhase = deploymentModelPhase === DeploymentModelPhase.plan;

  const tg = nodeData as ExpandedTerritoryGroupDefineAndRefinePillData;
  // eslint-disable-next-line no-restricted-syntax
  const isContributor = userRole === UserRoleType.CONTRIBUTOR;
  const disablePill = !enableClick && pillLevel !== PillLevel.CATEGORY;
  const number = pillLevel === PillLevel.CATEGORY ? pillIndex + 1 : null;
  const precedence = (nodeData as ExpandedTerritoryGroupDefineAndRefinePillData)?.precedence;
  const territoryGroupId = (nodeData as ExpandedTerritoryGroupDefineAndRefinePillData)?.territoryGroupId;
  const icon = getIcon(categoryName, pillLevel, precedence);
  const battleCardType = battleCardLookupMap?.[battleCardId]?.battlecardType;
  const battleCardName = battleCardLookupMap?.[battleCardId]?.battlecardName;

  // the activityCount being selected takes precedence over the pill being selected for styling purposes
  const isSelectedActivityCountTDR = isActivityCountSelected && territoryGroupId === selectedPillIdTDR;
  const isSelectedTDR = !isSelectedActivityCountTDR && !quotaEntryMode && territoryGroupId === selectedPillIdTDR;
  const isSelectedPlanTargets = quotaEntryMode && territoryGroupId === selectedPillIdPlanTargets;
  const isSelected = isSelectedTDR || isSelectedPlanTargets;

  const { sheetDefinitions: sellerSheetDefinitions } = useGetSheetDefinitions({
    deploymentModelId: selectedDeploymentModelId,
    sheetId: +defaultSellerSheetId,
    isTQM: !isPlanPhase
  });

  const sellerSheetDefinitionLists = sellerSheetDefinitions?.[0]?.sheetDefinitions;

  const assignedSellerQuotaId = sellerSheetDefinitionLists?.find(
    (measure) => measure?.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA
  )?.measureId;
  const assignedTerritoryId = sellerSheetDefinitionLists?.find(
    (measure) => measure?.measureName === SellerSheetGridColumnName.ACTUAL_TERRITORY_QUOTA
  )?.measureId;

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const fieldIds = [];
  if (assignedSellerQuotaId) fieldIds.push(assignedSellerQuotaId);
  if (assignedTerritoryId) fieldIds.push(assignedTerritoryId);
  const fieldTotalVariables: GetFieldTotalsVariables = {
    quotaComponentId: selectedQuotaComponentId,
    battlecardId: +selectedBattleCardId,
    measureId: 0,
    sorting: sortModel,
    fieldIds,
    territoryGroupId: +territoryGroupId || null,
    startRow: 1,
    endRow: 1
  };

  const [getFieldTotals, { data: territoryRulesData }] = useGetFieldTotalsLazy({
    variables: fieldTotalVariables,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    getFieldTotals();
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedPillId]);

  const fieldTotals = territoryRulesData?.getTerritoryRules?.fieldTotals;
  const revisedTerritoryQuota =
    fieldTotals?.find((total) => total?.fieldId === assignedTerritoryId)?.fieldValue || null;
  const assignedSellerQuota =
    fieldTotals?.find((total) => total?.fieldId === assignedSellerQuotaId)?.fieldValue || null;

  const totalChildNodeCount = getTotalChildNodeCount(tg);

  if (isContributor) {
    enableContextMenu = false;
  }

  const handleClickPill = (event) => {
    // needed to prevent click from registering on canvas, which would deselect pill
    event.stopPropagation();

    if (!enableClick) {
      return;
    }

    if (!quotaEntryMode) {
      setSelectedPillIdTDR(territoryGroupId);
    } else {
      setSelectedPillIdPlanTargets(territoryGroupId);
    }

    setIsActivityCountSelected(false);
    setSelectedBattleCardId(battleCardId);
  };

  const handleClickActivityCountTag = (event) => {
    // needed to prevent click from registering on the pill, which would deselect the activityCount
    event.stopPropagation();

    setIsActivityCountSelected(true);
    setSelectedPillIdTDR(territoryGroupId);
    setSelectedBattleCardId(battleCardId);
  };

  const shouldDimPill = (groupId) => {
    return quotaEntryMode
      ? !!(selectedPillIdPlanTargets && selectedPillIdPlanTargets !== groupId)
      : !!(selectedPillIdTDR && selectedPillIdTDR !== groupId);
  };

  const pillHighlightClassValue = getHighlightCssPropertyValue(precedence);
  const vdsClassName = getHighlightCssPropertyName(precedence);

  const shouldShowOverlay = shouldDimPill(territoryGroupId);
  const shouldShowExpandCollapse = pillLevel === PillLevel.TERRITORY && !!tg.children.length;

  const renderTDRPill = () => {
    const isExpanded = tgExpandedLookupMapTDA?.[territoryGroupId];
    const isUnassigned = categoryName === CategoryName.UNASSIGNED;

    const bcActivityCounts = coinsortProgressLookupMap?.[battleCardId]?.assignments;

    // if TG is expanded, show its activity count, otherwise show total including its children
    let currActivityCount;
    if (isUnassigned) {
      const unassignedActivityCount = unassignedActivitiesLookupMap[battleCardId];
      currActivityCount = unassignedActivityCount;
    } else if (isExpanded) {
      const tgActivityCount = matchingEngineEnabled ? 0 : bcActivityCounts?.[tg.territoryGroupId];
      currActivityCount = tgActivityCount;
    } else {
      const totalChildActivityCount = matchingEngineEnabled
        ? tdrBattlecardLookupMap?.[tg.territoryGroupId]?.activityCount
        : getTotalChildActivityCount(tg, bcActivityCounts);
      currActivityCount = totalChildActivityCount;
    }

    return (
      <div className={b('container')}>
        <PillOverlay animate={animate} isShown={shouldShowOverlay} />
        <Tag
          className={b('pill', {
            selected: isSelected
          })}
          style={
            pillLevel === PillLevel.CATEGORY
              ? {
                  backgroundColor: pillHighlightClassValue,
                  color: 'var(--white)'
                }
              : {}
          }
        >
          <div className={b()}>
            <div className={b('topRowContainer')}>
              <div className={b('pillNameContainer')} data-testid={'territory-pill-name'}>
                {!!number && (
                  <span
                    className={b('numberBackground')}
                    style={{ color: pillHighlightClassValue }}
                    data-testid={'territory-pill-number'}
                  >
                    {number}
                  </span>
                )}
                {pillLevel !== PillLevel.CATEGORY && <Icon icon={icon} />}
                {isUnassigned && !!currActivityCount && (
                  <VaricentTag icon={<Icon icon={<WarningAltFilled />} />} className={b('unassignedTag')} />
                )}
                <span className={b('pillNameLabel')}>{tg?.name}</span>
              </div>
              <div className={b('actionContainer')}>
                {!!currActivityCount && (
                  <MessageTooltip
                    target={
                      <ActivityCountTag
                        activityCount={currActivityCount}
                        isSelected={isSelectedActivityCountTDR}
                        onClick={handleClickActivityCountTag}
                        className={vdsClassName}
                      />
                    }
                    content={formatMessage('VIEW_ALL_ACTIVITIES')}
                    placement={'bottom'}
                  />
                )}
                {shouldShowExpandCollapse && (
                  <IconButton
                    icon={isExpanded ? <Icon icon={<Minimize />} /> : <Icon icon={<Maximize />} />}
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setTgExpandedCollapsedTDA(territoryGroupId, !isExpanded);
                    }}
                    small
                    testId={`expand-pill-${tg?.name}`}
                  />
                )}
              </div>
            </div>
            {owner?.firstName && owner?.lastName && (
              <div className={b('ownerNameContainer')} data-testid={'owner-section'}>
                <Icon
                  icon={
                    <UserAvatar style={pillLevel === PillLevel.TERRITORY ? { fill: pillHighlightClassValue } : {}} />
                  }
                />
                <span className={b('ownerNameLabel')}> {`${owner?.firstName} ${owner?.lastName}`}</span>
              </div>
            )}
          </div>
        </Tag>
        {!!tg?.children?.length && pillLevel === PillLevel.TERRITORY && (
          <VaricentTag
            icon={<Icon icon={<ParentChild />} />}
            className={b('tgChildTag', { dim: shouldShowOverlay })}
            fill={false}
            data-testid={`child-count-${tg?.name}`}
          >
            {isExpanded ? `${tg?.children?.length} / ${totalChildNodeCount}` : totalChildNodeCount}
          </VaricentTag>
        )}
      </div>
    );
  };

  const TargetComparisonByType = () => {
    const isRootPill = target !== undefined;
    const currency = battleCardLocalCurrency || 'USD';

    // Check specifically for manage
    if (!isPlanPhase) {
      if (selectedDataTrayTab === TabIds.MANAGE_SELLER) {
        if (isRootPill) {
          return (
            <TargetComparison
              revisedTerritoryQuota={
                revisedTerritoryQuota
                  ? formatNumber(parseInt(revisedTerritoryQuota), {
                      style: 'currency',
                      currency
                    })
                  : revisedTerritoryQuota
              }
              assignedSellerQuota={
                assignedSellerQuota
                  ? formatNumber(parseInt(assignedSellerQuota), {
                      style: 'currency',
                      currency
                    })
                  : assignedSellerQuota
              }
            />
          );
        }

        return (
          <div className={b('quotaNameContainer')} data-testid="quota-section">
            <Money style={pillLevel === PillLevel.TERRITORY ? { fill: pillHighlightClassValue } : {}} />
            <span className={b('quotaNameLabel')} data-testid="territory-pill-assigned-seller-quota">
              <MessageTooltip
                content={formatMessage('ASSIGNED_SELLER_QUOTA')}
                target={
                  <span>
                    {assignedSellerQuota
                      ? formatNumber(parseInt(assignedSellerQuota), {
                          style: 'currency',
                          currency
                        })
                      : assignedSellerQuota}
                  </span>
                }
                placement="top"
              />
            </span>
          </div>
        );
      }

      if (selectedDataTrayTab === TabIds.MANAGE_TERRITORY) {
        if (isRootPill) {
          return (
            <TargetComparison
              target={target}
              revisedTerritoryQuota={
                revisedTerritoryQuota
                  ? formatNumber(parseInt(revisedTerritoryQuota), {
                      style: 'currency',
                      currency
                    })
                  : revisedTerritoryQuota
              }
            />
          );
        }

        return (
          <div className={b('quotaNameContainer')} data-testid="quota-section">
            <Money style={pillLevel === PillLevel.TERRITORY ? { fill: pillHighlightClassValue } : {}} />
            <span className={b('quotaNameLabel')} data-testid="territory-pill-revised-territory-quota">
              <MessageTooltip
                content={formatMessage('REVISED_TERRITORY_QUOTA')}
                target={
                  <span>
                    {revisedTerritoryQuota
                      ? formatNumber(parseInt(revisedTerritoryQuota), {
                          style: 'currency',
                          currency
                        })
                      : revisedTerritoryQuota}
                  </span>
                }
                placement="top"
              />
            </span>
          </div>
        );
      }
    }

    // The rest is plan
    if (isRootPill) {
      return <TargetComparison target={target} updatedQuota={updatedQuota} allocationMessage={allocationMessage} />;
    }

    return (
      <div className={b('quotaNameContainer')} data-testid="quota-section">
        <Money style={pillLevel === PillLevel.TERRITORY ? { fill: pillHighlightClassValue } : {}} />
        <span className={b('quotaNameLabel')} data-testid="territory-pill-updated-quota">
          {updatedQuota}
        </span>
      </div>
    );
  };

  const renderPlanTargetsPill = () => {
    const isRootPill = target !== undefined;
    const isExpanded = tgExpandedLookupMapPlanTargets?.[territoryGroupId];
    return (
      <div className={b('container')}>
        <PillOverlay animate={animate} isShown={shouldDimPill(territoryGroupId)} />
        <Tag
          className={b('pill', { selected: isSelected, rootPill: isRootPill })}
          style={
            pillLevel === PillLevel.CATEGORY
              ? {
                  backgroundColor: pillHighlightClassValue,
                  color: 'var(--white)'
                }
              : {}
          }
        >
          <div className={b('topRowContainer')}>
            <div className={b('pillNameContainer')} data-testid={'territory-pill-name'}>
              {isRootPill ? (
                <BattleCardIcon isRootCard={false} battleCardType={battleCardType} className={b('battleCardIcon')} />
              ) : (
                icon
              )}
              <span className={b('pillNameLabel')}>
                {isRootPill ? battleCardName : (nodeData as TerritoryGroupWithMeasuresNodeData)?.name}
              </span>
            </div>
            {shouldShowExpandCollapse && (
              <IconButton
                icon={isExpanded ? <Icon icon={<Minimize />} /> : <Icon icon={<Maximize />} />}
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  setTgExpandedCollapsedPlanTargets(territoryGroupId, !isExpanded);
                }}
                small
                testId={`expand-pill-${tg?.name}`}
              />
            )}
          </div>
          {owner?.firstName && owner?.lastName && (
            <div className={b('ownerNameContainer')} data-testid={'owner-section'}>
              <Icon
                icon={<UserAvatar style={pillLevel === PillLevel.TERRITORY ? { fill: pillHighlightClassValue } : {}} />}
              />
              <span className={b('ownerNameLabel')}> {`${owner?.firstName} ${owner?.lastName}`}</span>
            </div>
          )}
          <TargetComparisonByType />
        </Tag>
        {!!tg?.children?.length && pillLevel === PillLevel.TERRITORY && (
          <VaricentTag
            icon={<Icon icon={<ParentChild />} />}
            className={b('tgChildTag', { dim: shouldShowOverlay })}
            fill={false}
            data-testid={`child-count-${tg?.name}`}
          >
            {isExpanded ? `${tg?.children?.length} / ${totalChildNodeCount}` : totalChildNodeCount}
          </VaricentTag>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={handleClickPill}
      className={b({
        disableClick: !enableClick
      })}
      data-testid={'territory-pill'}
    >
      {(() => {
        if (quotaEntryMode) {
          return renderPlanTargetsPill();
        }
        if (enableContextMenu) {
          return (
            <div data-testid={'territory-pill-hover-toolbar'}>
              <Popover
                interactionKind={PopoverInteractionKind.HOVER}
                openOnTargetFocus={false}
                content={
                  <TerritoryHoverToolbar
                    pillLevel={pillLevel}
                    territoryGroupId={territoryGroupId}
                    shouldShowOptimize={totalChildNodeCount === 0}
                  />
                }
                placement={'top-end'}
                disabled={!!tdrDialogState}
              >
                {renderTDRPill()}
              </Popover>
            </div>
          );
        }
        if (isContributor && disablePill) {
          return (
            <MessageTooltip
              className={b()}
              target={renderTDRPill()}
              content={formatMessage('CANNOT_VIEW_TERRITORY_GROUP')}
              placement={'top'}
              data-testid="disabled-pill-tooltip"
            />
          );
        }
        return renderTDRPill();
      })()}
    </div>
  );
};

export default TerritoryPill;
