import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { DeleteSheetMeasures, DeleteSheetMeasuresVariables } from 'app/graphql/generated/apolloTypes';

export const DELETE_SHEET_MEASURES = gql`
  mutation DeleteSheetMeasures($sheetId: Int!, $measureIds: [Int]!) {
    deleteSheetMeasures(input: { sheetId: $sheetId, measureIds: $measureIds }) {
      success
    }
  }
`;

export const useDeleteSheetMeasures = createUseMutation<DeleteSheetMeasures, DeleteSheetMeasuresVariables>(
  DELETE_SHEET_MEASURES
);
