import React from 'react';

import { MutationTuple, useMutation } from '@apollo/client';
import { Position } from '@blueprintjs/core';
import { Intent } from '@varicent/components';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { publishFileFromSymonS3, publishFileFromSymonS3Variables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { PUBLISH_FILE_FROM_SYMON_S3 } from 'app/graphql/mutations/publishFileFromSymonS3';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UsePublishFileFromSymonS3Props {
  setShowReviewDialog?: (boolean) => void;
  onCloseConfigurationDetail?: () => void;
  pollForProcessingStatus: (value) => void;
}
export const usePublishFileFromSymonS3 = ({
  setShowReviewDialog,
  onCloseConfigurationDetail,
  pollForProcessingStatus
}: UsePublishFileFromSymonS3Props): MutationTuple<publishFileFromSymonS3, publishFileFromSymonS3Variables> =>
  useMutation<publishFileFromSymonS3, publishFileFromSymonS3Variables>(PUBLISH_FILE_FROM_SYMON_S3, {
    onCompleted(data) {
      // eslint-disable-next-line deprecation/deprecation
      showToast(
        <ToastMessage
          title={formatMessage('SYMON_FILE_IMPORT_IN_PROGRESS')}
          message={formatMessage('SYMON_FILE_IMPORT_IN_PROGRESS_MESSAGE', {
            tableName: data.publishFileFromSymonS3.tableNameLabel
          })}
        />,
        Intent.PRIMARY,
        Position.TOP_RIGHT
      );
      setShowReviewDialog?.(false);
      pollForProcessingStatus({
        variables: { symonPipeConfigurationId: data.publishFileFromSymonS3.symonPipeConfigurationId }
      });
      if (onCloseConfigurationDetail) {
        onCloseConfigurationDetail();
      }
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(
        <ToastMessage message={graphQLErrors?.[0]?.message || formatMessage('SYMON_FILE_IMPORT_ERROR')} />,
        Intent.DANGER,
        Position.TOP_RIGHT
      );
    }
  });
