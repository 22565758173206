import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Notebook, OverflowMenuVertical } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { GraphQLError } from 'graphql';
import { useHistory } from 'react-router-dom';

import BattleCardIcon from 'components/BattleCardIcon/BattleCardIcon';
import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import WarningButton from 'components/Buttons/WarningButton/WarningButton';
import ConfirmDeleteModal from 'components/Dialog/ConfirmDeleteModal/ConfirmDeleteModal';
import FlagIcon from 'components/FlagIcon/FlagIcon';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Popover from 'components/Popover/Popover';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useComments } from 'app/contexts/commentProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';
import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { BCInfoLevelEnum } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_BATTLECARD } from 'app/graphql/mutations/deleteBattleCard';

import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';

import {
  BattleCardData,
  CardCopySection,
  CommandCenterDrawerState,
  CommandCenterMenuItem,
  DeploymentModelPhase,
  PlanningPath,
  SectionName
} from 'app/models';

import block from 'utils/bem-css-modules';
import { deepEqual } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import BattleCardCollapsedView from './BattleCardCollapsedView/BattleCardCollapsedView';
import style from './BattleCardContent.module.pcss';
import { getCardCopy, getOpenPanel } from './battleCardContentUtils';
import BattleCardExpandedView from './BattleCardExpandedView/BattleCardExpandedView';
import QuotaComponentDropdown from './QuotaComponentDropdown/QuotaComponentDropdown';

const b = block(style);

interface BattleCardContentProps {
  cardData: BattleCardData;
  cachedPanelCollapseStateByCard: Record<string, Record<string, boolean>>;
  setCachedPanelCollapseStateByCard: Dispatch<SetStateAction<Record<string, Record<string, boolean>>>>;
  setShowDialog: Dispatch<SetStateAction<boolean | null>>;
  setEditingBattleCardId: Dispatch<SetStateAction<string | null>>;
  deploymentModelId: number;
  battleCardRef: MutableRefObject<HTMLDivElement>;
  battleCardNodeRef: MutableRefObject<HTMLDivElement>;
}

const BattleCardContent: React.FC<BattleCardContentProps> = ({
  cardData,
  cachedPanelCollapseStateByCard,
  setCachedPanelCollapseStateByCard,
  setShowDialog,
  setEditingBattleCardId,
  deploymentModelId,
  battleCardRef,
  battleCardNodeRef
}: BattleCardContentProps) => {
  const { battlecardId, battlecardName, isRootCard, battlecardType, isLocalCurrencyMode } = cardData;
  const [showDeleteBattleCardDialog, setShowDeleteBattleCardDialog] = useState<boolean>(false);
  const { selectedPillIdTDR, setSelectedPillIdTDR, setIsActivityCountSelected } = useTerritoryDefineAndRefine();
  const { selectedPillIdPlanTargets, setSelectedPillIdPlanTargets } = usePlanTargets();
  const { setTerritoryOptimizationDrawerState } = useTerritoryOptimization();
  const {
    selectedBattleCardId,
    setSelectedBattleCardId,
    expandedBattleCardId,
    setExpandedBattleCardId,
    sectionName,
    setSectionName,
    setCardIsExpanded,
    setExitingBattleCardId,
    setCardBox,
    setShouldRefetchBattleCardDataImmediately,
    selectedQuotaComponentId,
    setPendingSelectedBattleCardId,
    resetExpandedView,
    setResetExpandedView
  } = useBattleCard();
  const { isMoved } = useRebalancing();
  const { commandCenterDrawerState, setCommandCenterDrawerState, setActiveMenu } = useCommandCenter();
  const deploymentModelPhase = usePhase();
  const { isCommentPanelOpen, setIsCommentPanelOpen } = useComments();
  const { selectedDeploymentModelId } = useScope();
  const showToast = useShowToast();

  const [deleteBattleCard, { loading: queryLoading }] = useMutation(DELETE_BATTLECARD, {
    onCompleted() {
      setShouldRefetchBattleCardDataImmediately(true);
      setShowDeleteBattleCardDialog(false);
      showToast(formatMessage('DELETE_BATTLECARD_SUCCESS'), 'success');
      setEditingBattleCardId(null);
      setExpandedBattleCardId(null);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(parseForErrorMsg(graphQLErrors), 'danger');
      setShowDeleteBattleCardDialog(false);
    }
  });
  const makePlanningPath = useMakePlanningPath();
  const shouldShowCommentsPanel =
    expandedBattleCardId && (sectionName === SectionName.TERRITORY_DEFINE || sectionName === SectionName.PLAN_TARGETS);

  const parseForErrorMsg = (graphQLErrors: readonly GraphQLError[]): string => {
    return graphQLErrors.length > 0 &&
      graphQLErrors[0].message === 'This battlecard has territory rules therefore you cannot delete it.'
      ? formatMessage('DELETE_BATTLECARD_TERRITORY_RULE_ERROR')
      : formatMessage('DELETE_BATTLECARD_GENERIC_ERROR');
  };

  const menuContent = (
    <Menu>
      <MenuItem
        text={formatMessage('EDIT')}
        onClick={() => setShowDialog(true)}
        data-testid="battle-card-content-edit-button"
      />
      {!isRootCard && (
        <MenuItem
          text={formatMessage('DELETE')}
          onClick={() => setShowDeleteBattleCardDialog(true)}
          data-testid="battle-card-content-delete-button"
        />
      )}
    </Menu>
  );

  const history = useHistory();

  const handleOverflowMenuOpen = (event) => {
    event.stopPropagation();
    setEditingBattleCardId(battlecardId);
  };

  const cardCopy = getCardCopy(battlecardType, isRootCard);
  const cardCopySections: CardCopySection[] = cardCopy.sections;
  const defaultPanelOpenStateObject = cardCopySections.reduce((acc, currentPanel, index) => {
    acc[currentPanel.name] = index === 0;
    return acc;
  }, {});

  const [panelOpenState, setPanelOpenState] = useState<Record<string, boolean>>(defaultPanelOpenStateObject);
  const openPanel = getOpenPanel(panelOpenState);

  useEffect(() => {
    if (cachedPanelCollapseStateByCard[battlecardId]) {
      setPanelOpenState(cachedPanelCollapseStateByCard[battlecardId]);
    }
  }, []);

  useEffect(() => {
    setCachedPanelCollapseStateByCard((prevState) => {
      return { ...prevState, [battlecardId]: panelOpenState };
    });
  }, [panelOpenState]);

  useEffect(() => {
    // Checking whether the newly selected QC is contained in the currently selected BattleCard and
    // deselecting the selected battleCard if it isn't
    if (cardData && cardData.battlecardId === selectedBattleCardId) {
      const quotaChecker = (component) => component.quotaComponentId === selectedQuotaComponentId;
      if (!cardData.quotaComponents.some(quotaChecker)) {
        setSelectedBattleCardId(null);
      }
    }
  }, [selectedQuotaComponentId]);

  useEffect(() => {
    // Resetting expanded view when the back button is clicked on the PlanningCyclePageHeader
    if (!expandedBattleCardId && selectedBattleCardId === battlecardId && sectionName) {
      setTerritoryOptimizationDrawerState(null);
      resetExpandedViewStates(selectedBattleCardId);
      setIsCommentPanelOpen(false);
    }
  }, [expandedBattleCardId]);

  // When the current deployment model in scope changes, reset expand view
  useEffect(() => {
    if (expandedBattleCardId) {
      resetExpandedViewStates(expandedBattleCardId);
    }
  }, [selectedDeploymentModelId]);

  const toggleCommentPanelThread = () => {
    setIsCommentPanelOpen(!isCommentPanelOpen);
  };

  const selectBattleCard = (battleCardId) => {
    const shouldSetBattleCardId = selectedBattleCardId !== battleCardId && !isMoved;
    const shouldSetPendingSelectedBattleCardId = selectedBattleCardId !== battleCardId && isMoved;
    if (shouldSetBattleCardId) {
      setSelectedBattleCardId(battleCardId);
    } else if (shouldSetPendingSelectedBattleCardId) {
      setPendingSelectedBattleCardId(battleCardId);
    }
  };

  const openCurrencyPage = () => {
    setCommandCenterDrawerState(CommandCenterDrawerState.OPEN);
    setActiveMenu(CommandCenterMenuItem.CURRENCIES);
  };

  // reset expanded view states when switching between tabs
  useEffect(() => {
    if (resetExpandedView && expandedBattleCardId === battlecardId) {
      resetExpandedViewStates(expandedBattleCardId);
      setResetExpandedView(false);
    }
  }, [resetExpandedView]);

  const resetExpandedViewStates = (cardId) => {
    setSelectedPillIdPlanTargets(null);
    setExpandedBattleCardId(null);
    setSelectedPillIdTDR(null);
    setIsActivityCountSelected(false);
    setCardIsExpanded(false);
    setExitingBattleCardId(cardId);
    setSectionName(null);
    setCardBox(battleCardNodeRef?.current.getBoundingClientRect());
  };

  const toggleViewExpandedCard = (event, cardId, panelSectionName) => {
    if (event) event.stopPropagation();

    if (!expandedBattleCardId) {
      setSelectedBattleCardId(cardId);
      setExpandedBattleCardId(cardId);
      setCardIsExpanded(true);
      setSectionName(panelSectionName);
      setCardBox(battleCardRef.current.getBoundingClientRect());
    } else {
      resetExpandedViewStates(cardId);
    }
    navigateToTerritoryOrQuota(panelSectionName);
  };

  //TODO: Set up temporary path until this ticket got addressed: https://varicent.atlassian.net/browse/TQP-4580
  const navigateToTerritoryOrQuota = (panelSectionName) => {
    if (panelSectionName === SectionName.WORKFLOW || panelSectionName === SectionName.PLAN_TARGETS) {
      history.push(
        makePlanningPath({
          viewParam: PlanningPath.quota
        })
      );
    } else {
      history.push(
        makePlanningPath({
          viewParam: PlanningPath.territory
        })
      );
    }
  };

  const isPlanTargetsViewExpanded = openPanel === SectionName.PLAN_TARGETS || sectionName === SectionName.PLAN_TARGETS;
  const shouldShowLocalFlag = isLocalCurrencyMode || isPlanTargetsViewExpanded;

  const localCurrencyCode = cardData.localCurrencyCode;
  const reportingCurrencyCode = cardData.reportingCurrencyCode;
  const invalidConversionReason = cardData.invalidConversionReason;

  const shouldHideBCContent =
    !cardData.battlecardInfoLevel ||
    cardData.battlecardInfoLevel === BCInfoLevelEnum.hidden ||
    cardData.battlecardInfoLevel === BCInfoLevelEnum.territoryOwner;

  const FlagTooltip = () => {
    let Tooltip;

    if (isRootCard) {
      Tooltip = (
        <span data-testid="root-card-tooltip">
          {formatMessage('VALUES_SHOWN_IN_REPORTING_CURRENCY', { value: reportingCurrencyCode })}
        </span>
      );
    } else if (shouldShowLocalFlag) {
      Tooltip = (
        <>
          <span data-testid="local-currency-tooltip">
            {formatMessage('VALUES_SHOWN_IN_LOCAL_CURRENCY', { value: localCurrencyCode })}
          </span>
          <span data-testid="local-reporting-currency-tooltip">
            {formatMessage('REPORTING_CURRENCY_IS', { value: reportingCurrencyCode })}
          </span>
        </>
      );
    } else {
      Tooltip = (
        <>
          <span data-testid="reporting-currency-tooltip">
            {formatMessage('VALUES_SHOWN_IN_REPORTING_CURRENCY', { value: reportingCurrencyCode })}
          </span>
          <span data-testid="reporting-local-currency-tooltip">
            {formatMessage('LOCAL_CURRENCY_IS', { value: localCurrencyCode })}
          </span>
        </>
      );
    }
    return Tooltip;
  };

  const isBcActive =
    cardData?.quotaComponents?.length &&
    !!cardData.quotaComponents.find((quotaComponent) => quotaComponent?.quotaComponentId === selectedQuotaComponentId);

  const isBcExpanded = expandedBattleCardId && expandedBattleCardId !== battlecardId;

  const QuotaComponentOverlay = () => {
    return (
      <>
        <div className={b('overlayText')} data-testid="qc-overlay-text">
          {cardData?.quotaComponents?.length
            ? cardData.quotaComponents.map((quotaComponent) => quotaComponent?.quotaComponentName).join(', ')
            : ''}
        </div>
        <div className={b('overlay')} data-testid="qc-overlay" />
      </>
    );
  };

  const BattleCardOverlay = () => {
    return <div className={b('overlay')} data-testid="bc-overlay" />;
  };

  const ViewOnlyBody = () => {
    return (
      <div className={b('viewOnlyBody')} data-testid="view-only-battlecard-body">
        <TextButton
          text={formatMessage('VIEW_TERRITORIES')}
          testId={'view-territories-button'}
          type="button"
          onClick={(event) => toggleViewExpandedCard(event, battlecardId, SectionName.TERRITORY_DEFINE)}
        />
      </div>
    );
  };

  const isTDAExpanded =
    expandedBattleCardId &&
    expandedBattleCardId === cardData.battlecardId &&
    sectionName === SectionName.TERRITORY_DEFINE;

  const isBCViewable =
    cardData.battlecardInfoLevel === BCInfoLevelEnum.territoryGroupOwner ||
    cardData.battlecardInfoLevel === BCInfoLevelEnum.territoryGroupTypeOwner;

  const renderBattleCardBody = () => {
    if (shouldHideBCContent) {
      return (
        <div className={b('hiddenBody')} data-testid="hidden-battlecard-body">
          {formatMessage('HIDDEN')}
        </div>
      );
    } else if (isBCViewable) {
      if (isTDAExpanded) {
        return (
          <BattleCardExpandedView
            section={{ name: SectionName.TERRITORY_DEFINE, header: formatMessage('TERRITORY_DEFINE_REFINE') }}
            toggleViewExpandedCard={toggleViewExpandedCard}
            sectionName={sectionName}
            battlecardId={battlecardId}
          />
        );
      }

      return <ViewOnlyBody />;
    }
    return renderBattleCardBodyContent();
  };

  const renderBattleCardBodyContent = () => {
    return (
      <div data-testid="battlecard-body" className={b('cardBody')}>
        {cardCopySections &&
          cardCopySections.map((section, index) => {
            const isExpanded =
              expandedBattleCardId && expandedBattleCardId === cardData.battlecardId && sectionName === section.name;
            if (isExpanded) {
              return (
                <BattleCardExpandedView
                  section={section}
                  toggleViewExpandedCard={toggleViewExpandedCard}
                  sectionName={sectionName}
                  battlecardId={battlecardId}
                  key={`${section}-${index}`}
                  data-testid={`expanded-view-${section.name}`}
                />
              );
            } else if (!expandedBattleCardId || expandedBattleCardId !== cardData.battlecardId) {
              return (
                <BattleCardCollapsedView
                  cardData={cardData}
                  section={section}
                  index={index}
                  key={`${section}-${index}`}
                  panelOpenState={panelOpenState}
                  setPanelOpenState={setPanelOpenState}
                  isBcActive={isBcActive}
                  toggleViewExpandedCard={toggleViewExpandedCard}
                  data-testid={`collapsed-view-${section.name}`}
                />
              );
            } else return null;
          })}
      </div>
    );
  };

  return (
    <div
      className={b('cardContent', {
        hidden: shouldHideBCContent,
        selected:
          battlecardId === selectedBattleCardId &&
          !expandedBattleCardId &&
          !selectedPillIdTDR &&
          !selectedPillIdPlanTargets
      })}
      {...(!expandedBattleCardId &&
        isBcActive && {
          onClick: (event) => {
            // needed to prevent click from registering on canvas, which would deselect battle card
            event.stopPropagation();

            if (!shouldHideBCContent) {
              // select battle card on click
              selectBattleCard(battlecardId);

              // deselect TD&R and plan targets pills on battle card click
              setSelectedPillIdTDR(null);
              setSelectedPillIdPlanTargets(null);
            }
          }
        })} // use spread to add onclick handler only when cardIsExpanded
      data-testid={`battle-card-content-${battlecardName}`}
    >
      {isBcActive ? null : <QuotaComponentOverlay />}
      {isBcExpanded ? <BattleCardOverlay /> : null}
      <div
        className={b('cardHeader', { root: cardData.isRootCard, team: !cardData.isRootCard })}
        data-testid="card-header"
      >
        <div className={b('titleArea')}>
          {<BattleCardIcon isRootCard={isRootCard} battleCardType={battlecardType} className={b('titleAreaIcon')} />}
          <div className={b('titleAreaText')}>
            <div className={b('titleAreaHeading')} data-testid="battle-card-title">
              <HTMLHeading tagLevel="h6" text={battlecardName} />
            </div>
          </div>
        </div>
        <div className={b('optionsArea')}>
          {expandedBattleCardId && (
            <div className={b('quotaComponentDropdown')} data-testid="quota-component-dropdown">
              <QuotaComponentDropdown />
            </div>
          )}
          {invalidConversionReason ? (
            <WarningButton
              onClick={(e) => {
                // don't select/deselect the battlecard if the icon is clicked
                e.stopPropagation();
                openCurrencyPage();
              }}
              tooltipText={
                // due to how the CommandCenterDrawer works, ensure the tooltip is only shown when the command center is closed,
                // to prevent the tooltip from being "stuck" open when the user clicks on the icon and then closes the command center
                commandCenterDrawerState === CommandCenterDrawerState.CLOSE ? invalidConversionReason : null
              }
              testId={'currency-conversion-warning-button'}
            />
          ) : null}
          {reportingCurrencyCode && localCurrencyCode ? (
            <MessageTooltip
              popoverClassName="tooltipContent"
              content={<FlagTooltip />}
              placement={'top'}
              target={
                <FlagIcon
                  countryId={shouldShowLocalFlag ? localCurrencyCode : reportingCurrencyCode}
                  data-testid={
                    shouldShowLocalFlag
                      ? `${localCurrencyCode}-local-flag-icon`
                      : `${reportingCurrencyCode}-reporting-flag-icon`
                  }
                />
              }
            />
          ) : null}
          {shouldShowCommentsPanel && (
            <div className={b('commentPanelButton')}>
              <IconButton
                title={formatMessage('COMMENT')}
                testId="comment-panel-button"
                type="button"
                icon={<Notebook size={20} />}
                onClick={() => toggleCommentPanelThread()}
              />
            </div>
          )}
          {deploymentModelPhase === DeploymentModelPhase.plan && (
            <CanUser
              perform={UserAction.BATTLE_CARD_CANVAS_MODIFY}
              yes={
                <div className={b('menuArea')} onClick={(event) => handleOverflowMenuOpen(event)}>
                  <Popover content={menuContent} placement={'bottom-end'}>
                    <OverflowMenuVertical
                      size={24}
                      aria-label={formatMessage('OPTIONS')}
                      data-testid="battle-card-content-header-menu"
                    />
                  </Popover>
                </div>
              }
            />
          )}
        </div>
      </div>
      <div className={b('cardBody')}>{renderBattleCardBody()}</div>
      <ConfirmDeleteModal
        isOpen={showDeleteBattleCardDialog}
        onConfirmDelete={(e) => {
          e.stopPropagation();
          deleteBattleCard({
            variables: {
              battlecardId: +battlecardId,
              deploymentModelId
            }
          });
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setShowDeleteBattleCardDialog(false);
        }}
        bodyText={formatMessage('DELETE_VALUE', { value: battlecardName })}
        isSubmitting={queryLoading}
      />
    </div>
  );
};

export default React.memo(BattleCardContent, (prevProps, nextProps) => {
  if (deepEqual(prevProps.cardData, nextProps.cardData)) {
    return true;
  }

  return false;
});
