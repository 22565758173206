import React from 'react';

import { useHistory } from 'react-router-dom';

import HelpMenu from 'app/components/HelpMenu/HelpMenu';
import TenantMenu from 'app/components/TenantMenu/TenantMenu';

import { useUser } from 'app/core/userManagement/userProvider';

import block from 'utils/bem-css-modules';

import style from './CyclesPageHeader.module.pcss';

const b = block(style);

interface CyclesPageHeaderProps {
  logoButton?: React.ReactNode;
  logoPath?: string;
  leftActionItems?: React.ReactNode[];
  rightActionItems?: React.ReactNode[];
  hideActions?: boolean;
}

const CyclesPageHeader: React.FC<CyclesPageHeaderProps> = ({
  logoPath,
  logoButton,
  leftActionItems,
  rightActionItems,
  hideActions
}: CyclesPageHeaderProps) => {
  const history = useHistory();

  const { userMembershipSpecs } = useUser();

  return (
    <header className={b()}>
      <div className={b('logoArea')}>
        <div
          className={!logoPath ? b('backBtn') : b('logo')}
          data-testid="user-profile-logo-btn"
          onClick={() => (!logoPath ? history.goBack() : history.push(logoPath))}
        >
          {logoButton}
        </div>
      </div>
      <div className={b('actionArea')}>
        <div className={b('leftActions')}>
          {!hideActions && leftActionItems?.length ? (
            leftActionItems.map((item, index) => (
              <div className={b('leftActionsItems')} key={index}>
                {item}
              </div>
            ))
          ) : (
            <></>
          )}
          <div className={b('tenantMenu')}>
            <TenantMenu tenants={userMembershipSpecs} />
          </div>
        </div>
        <div className={b('rightActions')}>
          {rightActionItems?.length ? rightActionItems.map((item, index) => <div key={index}>{item}</div>) : <></>}
        </div>
        <HelpMenu />
      </div>
    </header>
  );
};

export default CyclesPageHeader;
