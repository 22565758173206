import React, { useMemo } from 'react';

import TerritoryMapV3 from 'app/components/TerritoryMap/TerritoryMapV3';
import { findPipeUuidForScenario } from 'app/components/TerritoryOptimization/utils/territoryOptimizationUtils';

import { useTerritoryOptimization } from 'app/contexts/territoryOptimizationProvider';

import { SourceTypeEnum, SourceInput } from 'app/graphql/generated/apolloTypes';

import { OptimizationScenario } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './TerritoryOptimizationMap.module.pcss';

const b = block(style);

const TerritoryOptimizationMap: React.FC = () => {
  const { selectedOptimizationScenario, optimizedTerritoryInProgress } = useTerritoryOptimization();

  const source: SourceInput = useMemo(() => {
    if (
      selectedOptimizationScenario === OptimizationScenario.MOST_BALANCED ||
      selectedOptimizationScenario === OptimizationScenario.MOST_CONTIGUOUS
    ) {
      return {
        sourceType: SourceTypeEnum.territoryOptimization,
        optimizationPipeUuid: findPipeUuidForScenario(optimizedTerritoryInProgress, selectedOptimizationScenario)
      };
    }
    return null;
  }, [selectedOptimizationScenario, optimizedTerritoryInProgress]);

  return (
    <div className={b()} data-testid="territory-optimization-map">
      <TerritoryMapV3 source={source} />;
    </div>
  );
};

export default TerritoryOptimizationMap;
