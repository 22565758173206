import React, { useMemo, useState } from 'react';

import { Add } from '@carbon/icons-react';

import Dialog from 'components/Dialog/Dialog';

import CreateScenarioDialog from 'app/components/PlanningCyclePageHeader/CreateScenarioDialog';
import { sortScenarios } from 'app/components/PlanningCyclePageHeader/scenarioHelpers';
import { ActionClickEvent, ScenarioActions } from 'app/components/PlanningCyclePageHeader/ScenarioMenu';

import { useScope } from 'app/contexts/scopeProvider';

import { DeploymentModelTypeEnum, SortDirection } from 'app/graphql/generated/apolloTypes';
import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';

import { useChangeScenario } from 'app/hooks/useChangeScenario';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ManageScenariosDialog.module.pcss';
import ScenarioTile from './ScenarioTile';
import SortScenariosButton from './SortScenariosButton';

const b = block(style);

type SortBy = keyof ScenarioSummary;

interface ManageScenariosDialogProps {
  onClose: () => void;
  onActionClick: (actionClick: ActionClickEvent) => void;
  deploymentModelSummaries: ScenarioSummary[];
  isPlanningCycleActivated: boolean;
}

const ManageScenariosDialog: React.FC<ManageScenariosDialogProps> = ({
  onClose,
  onActionClick,
  deploymentModelSummaries,
  isPlanningCycleActivated
}) => {
  const changeScenario = useChangeScenario();
  const { selectedDeploymentModelId } = useScope();
  const [isCreateScenarioDialogOpen, setIsCreateScenarioDialogOpen] = useState(false);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(true);
  const [sortBy, setSortBy] = useState<SortBy>('deploymentModelLastOpenedAt');
  const [sortOrder, setSortOrder] = useState(SortDirection.desc);

  const unPublishedScenarios = useMemo(() => {
    return deploymentModelSummaries?.filter(
      (dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Plan && !dm.isPublished
    );
  }, [deploymentModelSummaries]);

  const sortedUnPublishedScenarios = useMemo(() => {
    return sortScenarios(unPublishedScenarios, sortBy, sortOrder);
  }, [unPublishedScenarios, sortBy, sortOrder]);

  const publishedScenario = deploymentModelSummaries?.find(
    (dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Plan && dm.isPublished
  );

  const selectedScenario = deploymentModelSummaries?.find((dm) => dm.deploymentModelId === selectedDeploymentModelId);
  const selectedScenarioId = selectedScenario?.deploymentModelId;

  const handleLaunch = (scenarioName: string) => {
    changeScenario(scenarioName);
    onClose();
  };

  const toggleCreateScenarioDialog = () => {
    setIsCreateScenarioDialogOpen((prev) => !prev);
    toggleManageDialog();
  };

  const toggleManageDialog = () => {
    setIsManageDialogOpen((prev) => !prev);
  };

  return (
    <>
      {isManageDialogOpen && (
        <Dialog
          onClose={onClose}
          title={formatMessage('MANAGE_SCENARIOS')}
          isOpen
          bodyMinHeight={600}
          size="small"
          onSubmit={toggleCreateScenarioDialog}
          confirmButtonText={formatMessage('CREATE')}
          cancelButtonText={formatMessage('CLOSE')}
          confirmButtonIcon={<Add />}
        >
          <div className={b('dialogBody')}>
            <div>
              <h4 className={b('publishedHeader')}>{formatMessage('PUBLISHED')}</h4>
              {publishedScenario && (
                <ScenarioTile
                  key={publishedScenario.deploymentModelId}
                  isCurrentScenario={selectedScenarioId === publishedScenario.deploymentModelId}
                  isPublished={publishedScenario.isPublished}
                  lastOpened={publishedScenario.deploymentModelLastOpenedAt}
                  scenarioName={publishedScenario.deploymentModelName}
                  dateCreated={publishedScenario.deploymentModelCreatedAt}
                  onLaunch={() => handleLaunch(publishedScenario.deploymentModelName)}
                  onActionClick={(action: ScenarioActions) =>
                    onActionClick({ action, deploymentModelId: publishedScenario.deploymentModelId })
                  }
                  isPlanningCycleActivated={isPlanningCycleActivated}
                />
              )}
            </div>
            <div className={b('unPublishedScenarios')}>
              <h4 className={b('publishedHeader')}>
                {formatMessage('UNPUBLISHED')} ({unPublishedScenarios.length})
              </h4>
              <SortScenariosButton
                sortBy={sortBy}
                onChangeSortBy={setSortBy}
                sortOrder={sortOrder}
                onChangeSortOrder={setSortOrder}
              />
              {sortedUnPublishedScenarios?.map((scenario) => (
                <ScenarioTile
                  key={scenario.deploymentModelId}
                  isCurrentScenario={selectedScenarioId === scenario.deploymentModelId}
                  isPublished={scenario.isPublished}
                  lastOpened={scenario.deploymentModelLastOpenedAt}
                  onLaunch={() => handleLaunch(scenario.deploymentModelName)}
                  onActionClick={(action: ScenarioActions) =>
                    onActionClick({ action, deploymentModelId: scenario.deploymentModelId })
                  }
                  scenarioName={scenario.deploymentModelName}
                  dateCreated={scenario.deploymentModelCreatedAt}
                  isPlanningCycleActivated={isPlanningCycleActivated}
                />
              ))}
            </div>
          </div>
        </Dialog>
      )}
      {isCreateScenarioDialogOpen && (
        <CreateScenarioDialog planDms={deploymentModelSummaries} onClose={toggleCreateScenarioDialog} />
      )}
    </>
  );
};

export default ManageScenariosDialog;
