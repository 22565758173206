import React, { useMemo, useState } from 'react';

import { CheckmarkFilled, ChevronDown, Network_3Reference as NetworkReference } from '@carbon/icons-react';
import { Classes, IconOnlyButton } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import Icon from 'components/Icon/Icon';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import CreateScenarioDialog from 'app/components/PlanningCyclePageHeader/CreateScenarioDialog';
import ManageScenariosDialog from 'app/components/PlanningCyclePageHeader/ManageScenariosDialog/ManageScenariosDialog';
import RenameScenarioDialog from 'app/components/PlanningCyclePageHeader/RenameScenarioDialog/RenameScenarioDialog';
import ScenarioLimitDialog from 'app/components/PlanningCyclePageHeader/ScenarioLimitDialog';
import ScenarioMenuActionItems from 'app/components/PlanningCyclePageHeader/ScenarioMenuActionItems';
import CompareModeDialog from 'app/components/TerritoryMap/CompareModeDialog';

import { useScope } from 'app/contexts/scopeProvider';

import { ScenarioSummary, useScenarioSummaries } from 'app/graphql/hooks/useScenarioSummaries';

import { useChangeScenario } from 'app/hooks/useChangeScenario';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import CannotDeletePublishedScenarioDialog from './CannotDeletePublishedScenarioDialog';
import CopyScenarioDialog from './CopyScenarioDialog/CopyScenarioDialog';
import DeleteScenarioDialog from './DeleteScenarioDialog';
import { getRecentScenarios } from './scenarioHelpers';
import style from './ScenarioMenu.module.pcss';
import { usePublishAndApplyScenario } from './usePublishAndApplyScenario';

const b = block(style);

const MAXIMUM_SCENARIO_COUNT = 100;

export enum ScenarioActions {
  Create = 'Create',
  Manage = 'Manage',
  Compare = 'Compare',
  Rename = 'Rename',
  Copy = 'Copy',
  Delete = 'Delete',
  Publish = 'Publish',
  Launch = 'Launch',
  Activate = 'Activate'
}

interface ActionModalState {
  action:
    | ScenarioActions.Create
    | ScenarioActions.Copy
    | ScenarioActions.Compare
    | ScenarioActions.Delete
    | ScenarioActions.Rename;
  relevantScenario: ScenarioSummary | null;
}

export interface ActionClickEvent {
  action: ScenarioActions;
  deploymentModelId: number | null;
}

interface ScenarioMenuProps {
  onClickActivate: () => void;
  isPlanningCycleActivated: boolean;
}

const ScenarioMenu: React.FC<ScenarioMenuProps> = ({ onClickActivate, isPlanningCycleActivated }) => {
  const { scenarioSummaries, scenarioSummariesLoading } = useScenarioSummaries();
  const { selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const changeScenario = useChangeScenario();

  const [actionModalState, setActionModalState] = useState<ActionModalState | null>(null);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectedScenario = useMemo(() => {
    return scenarioSummaries?.find((scenario) => scenario.deploymentModelId === selectedDeploymentModelId);
  }, [selectedDeploymentModelId, scenarioSummaries]);

  const publishedScenario = useMemo(() => {
    return scenarioSummaries?.find((scenario) => scenario.isPublished);
  }, [selectedDeploymentModelId, scenarioSummaries]);

  const recentScenarios = useMemo(
    () => getRecentScenarios(selectedDeploymentModelId, scenarioSummaries),
    [selectedDeploymentModelId, scenarioSummaries]
  );

  const handlePublishScenario = usePublishAndApplyScenario();

  const handleLaunchScenario = (scenarioName: string) => {
    changeScenario(scenarioName);
    setIsManageDialogOpen(false);
  };

  const handleActionClick = ({ action, deploymentModelId }: ActionClickEvent) => {
    const relevantScenario = scenarioSummaries.find((dm) => dm.deploymentModelId === deploymentModelId);
    switch (action) {
      case ScenarioActions.Manage:
        setIsManageDialogOpen(true);
        break;
      case ScenarioActions.Publish:
        handlePublishScenario(deploymentModelId);
        break;
      case ScenarioActions.Launch:
        handleLaunchScenario(relevantScenario.deploymentModelName);
        break;
      case ScenarioActions.Activate:
        onClickActivate();
        break;
      default:
        setActionModalState({
          action,
          relevantScenario
        });
    }
  };

  const handleCloseManageDialog = () => {
    setIsManageDialogOpen(false);
  };

  const handleCloseActionDialog = () => {
    setActionModalState(null);
  };

  const scenarioCount = scenarioSummaries?.length;
  const isScenarioLimitReached = scenarioCount >= MAXIMUM_SCENARIO_COUNT;
  const isPublishedScenario = selectedDeploymentModelId === publishedScenario?.deploymentModelId;
  const showScenarioLimitDialog =
    (actionModalState?.action === ScenarioActions.Create || actionModalState?.action === ScenarioActions.Copy) &&
    isScenarioLimitReached;
  const relevantScenario = actionModalState?.relevantScenario;

  const scenarioTitleTooltipText = formatMessage(isPublishedScenario ? 'PUBLISHED_TOOLTIP' : 'UNPUBLISHED_TOOLTIP', {
    name: selectedScenario?.deploymentModelName
  });

  return (
    <>
      {scenarioSummariesLoading ? (
        <div className={b('skeletonWrapper')} data-testid="scenario-menu-loading-skeleton">
          <div className={Classes.SKELETON} />
          <div className={Classes.SKELETON} />
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <>
          <div className={b('scenarioNameHeaderIcon', { isPublishedScenario })}>
            <Icon icon={isPublishedScenario ? <CheckmarkFilled /> : <NetworkReference />} />
          </div>
          <MessageTooltip
            content={scenarioTitleTooltipText}
            target={
              <div className={b('scenarioTitleText')} data-testid="scenario-title-text">
                {selectedScenario?.deploymentModelName}
              </div>
            }
            placement={'bottom'}
          />
          <DropdownMenu
            content={
              <ScenarioMenuActionItems
                hideLaunchAction={true}
                hidePublishAction={isPlanningCycleActivated || isPublishedScenario}
                hideActivateAction={isPlanningCycleActivated || !isPublishedScenario}
                onActionClick={(action: ScenarioActions) =>
                  handleActionClick({ action, deploymentModelId: selectedDeploymentModelId })
                }
                recentScenarios={recentScenarios}
              />
            }
            placement={'bottom-start'}
            onOpenChange={setIsDropdownOpen}
            target={
              <IconOnlyButton
                icon={ChevronDown}
                text={null}
                minimal
                data-testid="scenario-menu-button"
                active={isDropdownOpen}
              />
            }
          />
        </>
      )}

      {actionModalState?.action === ScenarioActions.Create && !isScenarioLimitReached && (
        <CreateScenarioDialog onClose={handleCloseActionDialog} planDms={scenarioSummaries} />
      )}
      {actionModalState?.action === ScenarioActions.Copy && !isScenarioLimitReached && (
        <CopyScenarioDialog
          onClose={handleCloseActionDialog}
          planDms={scenarioSummaries}
          relevantScenario={relevantScenario}
        />
      )}
      {isManageDialogOpen && !actionModalState?.action && (
        <ManageScenariosDialog
          isPlanningCycleActivated={isPlanningCycleActivated}
          deploymentModelSummaries={scenarioSummaries}
          onActionClick={handleActionClick}
          onClose={handleCloseManageDialog}
        />
      )}
      {showScenarioLimitDialog && (
        <ScenarioLimitDialog
          onClose={handleCloseActionDialog}
          scenarioCount={scenarioCount}
          data-testid="scenario-limit-dialog"
        />
      )}
      {actionModalState?.action === ScenarioActions.Compare && (
        <CompareModeDialog
          relevantScenarioId={actionModalState.relevantScenario?.deploymentModelId}
          onClose={handleCloseActionDialog}
        />
      )}
      {actionModalState?.action === ScenarioActions.Rename && (
        <RenameScenarioDialog
          onClose={handleCloseActionDialog}
          planDms={scenarioSummaries}
          relevantScenario={relevantScenario}
        />
      )}
      {actionModalState?.action === ScenarioActions.Delete &&
        (relevantScenario?.isPublished ? (
          <CannotDeletePublishedScenarioDialog
            scenarioName={relevantScenario?.deploymentModelName}
            onClose={handleCloseActionDialog}
          />
        ) : (
          <DeleteScenarioDialog
            scenarioName={relevantScenario?.deploymentModelName}
            scenarioId={relevantScenario?.deploymentModelId}
            planningCycleId={selectedPlanningCycle?.id}
            onClose={handleCloseActionDialog}
          />
        ))}
    </>
  );
};

export default ScenarioMenu;
