import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetS3File, GetS3FileVariables } from 'app/graphql/generated/apolloTypes';

export const GET_S3_FILE = gql`
  query GetS3File($fileId: String!, $startRow: Int, $endRow: Int) {
    getS3File(input: { fileId: $fileId, startRow: $startRow, endRow: $endRow }) {
      fileRows
      fileType
      headers
      totalNumOfRows
    }
  }
`;

export const useGetS3File = createUseQuery<GetS3File, GetS3FileVariables>(GET_S3_FILE);
