import React, { useState } from 'react';

import { Add } from '@carbon/icons-react';
import { Formik, Form } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import FormRadioGroup from 'app/components/FormFields/FormRadioGroup/FormRadioGroup';
import ScenarioNameField from 'app/components/PlanningCyclePageHeader/ScenarioNameField';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  CreateBlankScenario_createDeploymentModel,
  DeploymentModelTypeEnum,
  GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries
} from 'app/graphql/generated/apolloTypes';
import { useCreateBlankScenario } from 'app/graphql/mutations/createBlankScenario';
import { useRequestDeploymentModelClone } from 'app/graphql/mutations/requestDeploymentModelClone';
import { GET_DEPLOYMENT_MODEL_SUMMARIES } from 'app/graphql/queries/getDeploymentModelSummaries';

import { useChangeScenario } from 'app/hooks/useChangeScenario';
import useShowToast from 'app/hooks/useShowToast';

import { DeploymentModelSpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import CloneScenarioToast from './CopyScenarioDialog/CloneScenarioToast';
import style from './CreateScenarioDialog.module.pcss';
import validationSchema from './validationSchema';

const b = block(style);

interface CreateScenarioDialogProps {
  onClose: () => void;
  planDms: Pick<
    GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
    'deploymentModelId' | 'deploymentModelName'
  >[];
}

export enum CreateScenarioSourceType {
  Blank = 'Blank',
  Copy = 'Copy'
}

interface CreateScenarioValues {
  createScenarioSourceType: CreateScenarioSourceType;
  createScenarioName: string;
}

const CreateScenarioDialog: React.FC<CreateScenarioDialogProps> = ({ onClose, planDms }) => {
  const [isCloneInProgress, setIsCloneInProgress] = useState(false);

  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const changeScenario = useChangeScenario();
  const { userPlanningCycles, setUserPlanningCycles } = useUser();
  const showToast = useShowToast();

  const [requestDeploymentModelClone] = useRequestDeploymentModelClone({
    onCompleted() {
      setIsCloneInProgress(true);
    },
    onError() {
      showToast(formatMessage('SCENARIO_COPY_FAILED'), 'danger');
    }
  });

  const [createBlankScenario] = useCreateBlankScenario({
    onCompleted(data) {
      showToast(
        <ToastMessage
          title={formatMessage('SCENARIO_CREATED')}
          message={formatMessage('SCENARIO_CREATED_MESSAGE', { name: data.createDeploymentModel.deploymentModelName })}
        />,
        'success'
      );
      applyCreateToUserPlanningCycles(data.createDeploymentModel);
      changeScenario(data.createDeploymentModel.deploymentModelName);
      onClose();
    },
    onError() {
      showToast(formatMessage('SCENARIO_CREATE_FAILED'), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_DEPLOYMENT_MODEL_SUMMARIES,
        variables: {
          planningCycleId: selectedPlanningCycle?.id
        }
      }
    ]
  });

  const applyCreateToUserPlanningCycles = ({
    deploymentModelId,
    deploymentModelName
  }: CreateBlankScenario_createDeploymentModel) => {
    const newDm: DeploymentModelSpec = {
      deploymentModelId,
      deploymentModelType: DeploymentModelTypeEnum.Plan,
      deploymentModelName,
      isPublished: false
    };
    const newPcs = userPlanningCycles.map((oldPc) => {
      if (oldPc.planningCycleId !== selectedPlanningCycle.id) return oldPc;
      return {
        ...oldPc,
        deploymentModels: [...oldPc.deploymentModels, newDm]
      };
    });
    setUserPlanningCycles(newPcs);
  };

  const initialValues: CreateScenarioValues = {
    createScenarioSourceType: CreateScenarioSourceType.Blank,
    createScenarioName: ''
  };

  const handleSubmit = async ({ createScenarioName, createScenarioSourceType }) => {
    switch (createScenarioSourceType) {
      case CreateScenarioSourceType.Copy:
        await requestDeploymentModelClone({
          variables: {
            deploymentModelId: selectedDeploymentModelId,
            clonedDeploymentModelType: DeploymentModelTypeEnum.Plan,
            clonedDeploymentModelName: createScenarioName
          }
        });
        break;
      case CreateScenarioSourceType.Blank:
        await createBlankScenario({
          variables: {
            deploymentModelName: createScenarioName,
            planningCycleId: selectedPlanningCycle.id
          }
        });
        break;
    }
  };

  const radioOptions = [
    { value: CreateScenarioSourceType.Blank, label: formatMessage('BLANK_SCENARIO') },
    { value: CreateScenarioSourceType.Copy, label: formatMessage('MAKE_A_COPY') }
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <>
          <Dialog
            title={formatMessage('CREATE_SCENARIO')}
            isOpen
            onSubmit={handleSubmit}
            onClose={onClose}
            confirmButtonIcon={<Add />}
            confirmButtonText={formatMessage('CREATE')}
            confirmButtonLoading={isSubmitting || isCloneInProgress}
            size="small"
          >
            <Form>
              <div className={b('radioOptions')}>
                <FormRadioGroup
                  name="createScenarioSourceType"
                  selectedRadio={values.createScenarioSourceType}
                  radioOptions={radioOptions}
                  inline
                  setSelectedRadio={(option: string) => setFieldValue('createScenarioSourceType', option)}
                />
              </div>
              <ScenarioNameField
                currentLength={values.createScenarioName.length}
                planDms={planDms}
                fieldName="createScenarioName"
              />
            </Form>
          </Dialog>
          {isCloneInProgress && (
            <CloneScenarioToast
              planningCycleName={selectedPlanningCycle.planningCycleName}
              scenarioName={values.createScenarioName}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default CreateScenarioDialog;
