import React from 'react';

import { Menu, MenuItem } from '@blueprintjs/core';
import { ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';

import useCanUser from 'app/hooks/useCanUser';

import { GEOGRAPHIES, HierarchyQuerySpec, HierarchyTypeName } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import style from './TerritoryBalancingGridActionButtons.module.pcss';

const b = block(style);

interface TerritoryBalancingGridActionButtonsProps {
  isSaveEnabled: boolean;
  onSave: () => void;
  onMove: () => void;
  hierarchies: HierarchyQuerySpec;
}

const TerritoryBalancingGridActionButtons: React.FC<TerritoryBalancingGridActionButtonsProps> = ({
  isSaveEnabled,
  onSave,
  onMove,
  hierarchies
}: TerritoryBalancingGridActionButtonsProps) => {
  const { selectedBattleCardId } = useBattleCard();
  const { selectedRebalancingHierarchy, setSelectedRebalancingHierarchy, isMoved, isSaved, sourcePanel } =
    useRebalancing();

  const canEditTerritoryBalancing = useCanUser(UserAction.TERRITORY_REBALANCING_EDIT);

  return (
    selectedBattleCardId && (
      <>
        <div className={b('hierarchySelect')} data-testid="rebalancing-hierarchy-dropdown">
          <Popover
            content={
              <div data-testid="select-menu-content">
                <Menu>
                  {hierarchies?.getRootHierarchies
                    ?.filter(
                      (hierarchy) =>
                        hierarchy.rootName === HierarchyTypeName.CUSTOMER_ACCOUNTS || hierarchy.rootName === GEOGRAPHIES
                    )
                    ?.map((hierarchy) => {
                      return (
                        <MenuItem
                          key={hierarchy?.rootName}
                          data-testid="select-menu-item"
                          onClick={() =>
                            setSelectedRebalancingHierarchy({
                              key: hierarchy?.rootName,
                              value: hierarchy?.rootKey
                            })
                          }
                          text={hierarchy?.rootName}
                        />
                      );
                    })}
                </Menu>
              </div>
            }
            placement={'bottom'}
            minimal
          >
            <TextButton
              testId={'select-menu-text-button'}
              type="button"
              text={selectedRebalancingHierarchy?.key}
              rightIcon={<ChevronDown />}
            />
          </Popover>
        </div>
        <div className={b('submitButtonContainer')}>
          {isMoved ? (
            <TextButton
              testId={'rebalancing-save-button'}
              type="button"
              text={formatMessage('SAVE')}
              intent="primary"
              large={false}
              onClick={onSave}
              disabled={!canEditTerritoryBalancing || isSaved}
              tooltipPlacement="left"
              tooltipText={canEditTerritoryBalancing ? '' : formatMessage('NO_EDIT_PERMISSION')}
            />
          ) : (
            <TextButton
              testId={'rebalancing-move-button'}
              type="button"
              text={formatMessage('MOVE')}
              intent="primary"
              large={false}
              onClick={onMove}
              disabled={!sourcePanel || !isSaveEnabled}
            />
          )}
        </div>
      </>
    )
  );
};

export default TerritoryBalancingGridActionButtons;
