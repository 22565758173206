import React, { useEffect, useState } from 'react';

import MultiStepDialog from 'components/Dialog/MultiStepDialog';

import ImportFileMappingContainer from 'app/components/ImportFileDialog/ImportFileMappingContainer/ImportFileMappingContainer';
import ImportSellerAssignmentFileUpload from 'app/components/ImportSellerAssignmentsDialog/ImportSellerAssignmentFileUpload/ImportSellerAssignmentFileUpload';

import { useData } from 'app/contexts/dataProvider';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';

import { formatMessage } from 'utils/messages/utils';

interface ImportSellerAssignmentsDialogProps {
  onClose: () => void;
}

const ImportSellerAssignmentsDialog: React.FC<ImportSellerAssignmentsDialogProps> = ({
  onClose
}: ImportSellerAssignmentsDialogProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataMatchingRequired, setDataMatchingRequired] = useState<boolean>(false);

  const { resetValues } = useData();

  useEffect(() => {
    resetValues();
  }, []);

  const onNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const renderPages = () => {
    let pages = [
      <ImportSellerAssignmentFileUpload
        onNext={onNext}
        dataMatchingRequired={dataMatchingRequired}
        setDataMatchingRequired={setDataMatchingRequired}
        onClose={onClose}
      />
    ];
    if (dataMatchingRequired) {
      pages = [...pages, <ImportFileMappingContainer fileType={FileTypeEnum.SellerAssignment} onClose={onClose} />];
    }
    return pages;
  };

  return (
    <MultiStepDialog
      data-testid="import-seller-assignments-dialog"
      isOpen
      onClose={onClose}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      pages={renderPages()}
      showDialogFooter={false}
      title={currentIndex === 0 ? formatMessage('IMPORT_SELLER_ASSIGNMENTS') : formatMessage('MATCH_FIELDS_TITLE')}
    />
  );
};

export default ImportSellerAssignmentsDialog;
