import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { BulkInviteUsers, BulkInviteUsersVariables } from 'app/graphql/generated/apolloTypes';

export const BULK_INVITE_USERS = gql`
  mutation BulkInviteUsers($users: [InviteUserInput!]!) {
    bulkInviteUsers(input: $users) {
      emailAddress
      invitationId
    }
  }
`;

export const useBulkInviteUsers = createUseMutation<BulkInviteUsers, BulkInviteUsersVariables>(BULK_INVITE_USERS);
