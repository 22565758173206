import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertMeasureValue, UpsertMeasureValueVariables } from 'app/graphql/generated/apolloTypes';

export const UPSERT_MEASURE_VALUE = gql`
  mutation UpsertMeasureValue($input: TerritoryMeasureValueInput!) {
    upsertMeasureValue(input: $input) {
      measureValue
    }
  }
`;

export const useUpsertMeasureValueLazy = createUseMutation<UpsertMeasureValue, UpsertMeasureValueVariables>(
  UPSERT_MEASURE_VALUE
);
