import { ColDef, ColGroupDef } from '@ag-grid-community/core';

import { PermissionCheckboxCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/PermissionCheckboxCellRenderer/PermissionCheckboxCellRenderer';
import PermissionHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/PermissionHeaderCellRenderer/PermissionHeaderCellRenderer';
import UserRoleCellRenderer from 'app/components/AdvancedGrid/CellRenderers/UserRoleCellRenderer/UserRoleCellRenderer';

import { ISheetDefinitionDef } from 'app/graphql/generated/apolloTypes';

import { formatMessage } from 'utils/messages/utils';

const buildPermissionsColumnDef = (sheetDefinitions: ISheetDefinitionDef[]): Array<ColDef | ColGroupDef> => {
  const columnDefs: Array<ColDef | ColGroupDef> = [];

  columnDefs.push({
    headerName: formatMessage('USER'),
    field: 'roleName',
    minWidth: 362,
    flex: 4,
    cellRendererFramework: UserRoleCellRenderer
  });

  sheetDefinitions.forEach((sheetDefinition, index) => {
    const cellStyle = {
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
      'background-color': index % 2 === 0 ? 'rgb(var(--light-blue-translucent))' : 'none'
    };

    columnDefs.push({
      headerComponentFramework: PermissionHeaderCellRenderer,
      flex: 2,
      field: `${sheetDefinition.measureName}_permissions`,
      minWidth: 200,
      cellStyle,
      cellRendererFramework: PermissionCheckboxCellRenderer,
      cellRendererSelector: (params) => {
        return {
          frameworkComponent: PermissionCheckboxCellRenderer,
          params: {
            params,
            measureFieldType: sheetDefinition.measureFieldType
          }
        };
      }
    });
  });

  return columnDefs;
};

export default buildPermissionsColumnDef;
