import gql from 'graphql-tag';

export const GET_FILE_LIST = gql`
  query GetFileList($planningCycleId: Int!, $lookupInput: LookupInput, $tableInput: TableInput) {
    getPlanningCycleSpec(
      input: { planningCycleId: $planningCycleId, lookupInput: $lookupInput, tableInput: $tableInput }
    ) {
      planningCycleId
      tables {
        tableList {
          published
          status
          tableDataType
          tableId
          tableName
          tableType
        }
        totalNumOfTables
      }
    }
  }
`;
