import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Callout } from '@blueprintjs/core';
import { PlayOutline, Save } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import AutoMapButton from 'app/components/DataMappingDrillIn/AutoMapButton';
import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';
import { useUpsertSymonPipeConfiguration } from 'app/components/DataPanel/ConfigurationsPanel/hooks/useUpsertSymonPipeConfiguration';
import ReviewConfigurationDialog from 'app/components/DataPanel/ConfigurationsPanel/ReviewConfigurationDialog';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  FileTypeEnum,
  publishFileToDB as publishFileToDBMutation,
  publishFileToDBVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { PUBLISH_FILE_TO_DB } from 'app/graphql/mutations/publishFileToDB';

import { DataMappingType, EXCLUDE_COLUMN, ProcessingStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

import style from './DataMappingController.module.pcss';

const b = block(style);

interface DataMappingControllerProps {
  controllerType: DataMappingType;
  rowData: React.ReactNode[];
  onCloseConfigurationDetail?: () => void;
  withSpacing?: boolean;
  isDialogMapping?: boolean;
  isSubmitted?: boolean;
  setIsSubmitted?: (boolean) => void;
  fileType: FileTypeEnum;
  rootHierarchyId?: number | null;
}

const DataMappingController: React.FC<DataMappingControllerProps> = ({
  rowData,
  onCloseConfigurationDetail,
  controllerType,
  withSpacing = true,
  isDialogMapping = false,
  isSubmitted,
  setIsSubmitted,
  fileType,
  rootHierarchyId = null
}: DataMappingControllerProps) => {
  const {
    selectedTable,
    setSelectedTable,
    pollForProcessingStatus,
    setFileUploadInProgress,
    mappedFields,
    selectedConfiguration,
    setMissingRequiredFields,
    shouldShowMappingError,
    setShouldShowMappingError,
    setIsAllColumnsMapped,
    setMappingProperties
  } = useData();
  const { selectedPlanningCycle } = useScope();
  const { selectedBattleCardId } = useBattleCard();
  const { mappingFields } = useMappingFields(
    selectedPlanningCycle.id,
    fileType,
    rootHierarchyId,
    +selectedBattleCardId
  );

  const [showReviewDialog, setShowReviewDialog] = useState<boolean>(false);

  const [upsertSymonPipeConfiguration, { loading: upsertSymonPipeConfigurationLoading }] =
    useUpsertSymonPipeConfiguration(true);

  useEffect(() => {
    if (isSubmitted && (missingRequiredFields?.length > 0 || !checkColumnsMapped())) {
      setShouldShowMappingError(true);
      if (setIsSubmitted) {
        setIsSubmitted(false);
      }
    }
  }, [isSubmitted]);

  let missingRequiredFields = mappingFields?.filter((header) => header.properties.colRequired);
  mappedFields?.forEach((mappedHeader) => {
    missingRequiredFields = missingRequiredFields?.filter((field) => field.key !== mappedHeader.key);
  });

  useEffect(() => {
    setMissingRequiredFields(missingRequiredFields);
    setShouldShowMappingError(shouldShowMappingError && missingRequiredFields?.length > 0);
    setIsAllColumnsMapped(checkColumnsMapped());
    if (rowData.length > 0) {
      if (controllerType === DataMappingType.CONFIGURATION) {
        setMappingProperties(buildProperties());
      }
      if (controllerType === DataMappingType.DATA_TABLE) {
        setMappingProperties(buildFieldNames());
      }
    }
  }, [mappedFields, mappingFields]);

  const [publishFileToDB, { loading: publishingFileToDB }] = useMutation<
    publishFileToDBMutation,
    publishFileToDBVariables
  >(PUBLISH_FILE_TO_DB, {
    onCompleted() {
      if (onCloseConfigurationDetail) {
        onCloseConfigurationDetail();
      }
      setFileUploadInProgress((prevState) => {
        // when there are no file upload in progress, set fileUploadInProgress as the selected table
        // in order to start the polling of file upload progress
        if (prevState.length === 0) return [selectedTable];

        // in a case where a 2nd request of the same file id, this will prevent adding a duplicate input
        const findIndexOfSelectedFileId = prevState.findIndex((file) => file.tableId === selectedTable?.tableId);
        // if index is less than 0 that means it doesn't exist and will add the file id to the array
        return findIndexOfSelectedFileId < 0 ? [...prevState, selectedTable] : prevState;
      });
      pollForProcessingStatus();
      setSelectedTable(null);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage('FILE_HAS_BEEN_PUBLISH_ERROR', { name: selectedTable.tableName }), 'danger');
    }
  });

  const buildFieldNames = () => {
    const fieldNameList = [];
    Object.entries(rowData?.[0]).forEach(([columnName, columnProperties]) => {
      if (columnProperties.key === EXCLUDE_COLUMN) return;
      fieldNameList.push({
        fieldName: columnName,
        value: {
          id: columnProperties.key,
          type: columnProperties.properties?.fieldType
        }
      });
    });
    return fieldNameList;
  };

  const buildProperties = () => {
    const fieldNameList = [];
    Object.entries(rowData?.[0]).forEach(([columnName, columnProperties], index) => {
      if (columnProperties.key === EXCLUDE_COLUMN) return;
      fieldNameList.push({
        fieldName: columnName,
        value: {
          id: columnProperties.key,
          type: columnProperties.properties?.fieldType,
          order: index + 1
        }
      });
    });
    return fieldNameList;
  };

  const checkColumnsMapped = () => {
    let isAllColumnsMapped = true;
    if (rowData.length <= 0) return false;
    Object.entries(rowData[0])?.forEach(([_columnName, columnProperties]) => {
      if (columnProperties.key === '') {
        isAllColumnsMapped = false;
      }
    });
    return isAllColumnsMapped;
  };

  const handlePublish = () => {
    const allColumnsMapped = checkColumnsMapped();
    if (missingRequiredFields?.length > 0 || publishingFileToDB || !allColumnsMapped) {
      return setShouldShowMappingError(true);
    } else {
      setShouldShowMappingError(false);
    }

    publishFileToDB({
      variables: {
        input: {
          fileId: selectedTable.tableId,
          fieldNames: buildFieldNames(),
          planningCycleId: selectedPlanningCycle.id,
          fileType
        }
      }
    });
  };

  const handleSave = () => {
    const allColumnsMapped = checkColumnsMapped();
    if (missingRequiredFields?.length > 0 || !allColumnsMapped) {
      setShouldShowMappingError(true);
      return null;
    } else {
      setShouldShowMappingError(false);
    }
    const {
      exportId,
      exportName,
      fileType: fileTypeFromConfiguration,
      pipeId,
      pipeName,
      tableNameLabel,
      symonPipeConfigurationId
    } = selectedConfiguration;

    return upsertSymonPipeConfiguration({
      variables: {
        input: {
          exportId,
          exportName,
          fileType: fileTypeFromConfiguration,
          pipeId,
          pipeName,
          planningCycleId: selectedPlanningCycle?.id,
          tableNameLabel,
          properties: buildProperties(),
          symonPipeConfigurationId,
          hierarchyId: rootHierarchyId
        }
      }
    });
  };

  const handleRun = () => {
    handleSave()?.then(() => {
      setShowReviewDialog(true);
    });
  };

  useEffect(() => {
    setShouldShowMappingError(false);
  }, [selectedConfiguration, selectedTable]);

  const ActionButton = (
    <div className={b('button')}>
      {controllerType === DataMappingType.CONFIGURATION ? (
        <div className={b('saveButton')}>
          <TextButton
            type="button"
            icon={<Save />}
            text={formatMessage('SAVE')}
            onClick={handleSave}
            testId={'data-mapping-controller-save-button'}
            loading={upsertSymonPipeConfigurationLoading}
            disabled={!rowData.length}
          />
        </div>
      ) : (
        <TextButton
          type="button"
          text={formatMessage('PUBLISH')}
          onClick={handlePublish}
          loading={publishingFileToDB}
          testId={'data-mapping-controller-action-button'}
          disabled={!rowData.length}
        />
      )}
    </div>
  );

  const shouldShowActionButtonAndText =
    mappingFields &&
    (controllerType === DataMappingType.CONFIGURATION ||
      (controllerType === DataMappingType.DATA_TABLE && !selectedTable?.published));

  const matchFieldText =
    controllerType === DataMappingType.CONFIGURATION
      ? formatMessage('MATCH_FIELDS_TEXT_RUN_THIS_CONFIGURATION')
      : formatMessage('MATCH_FIELDS_TEXT_PUBLISH_THIS_FILE');

  return (
    <div className={b('', { withSpacing })} data-testid="data-mapping-controller">
      <div className={b('dataMappingControllerToolbar')}>
        <div data-testid="data-mapping-controller-match-fields">
          {!isDialogMapping && (
            <div className={b('fileType')}>
              <span data-testid="data-mapping-type-label">{formatMessage('DATA_TABLE_TYPE_WITH_COLON')}</span>
              <span className={b('fileTypeText')} data-testid="data-mapping-type">
                {selectedTable?.tableDataType || selectedConfiguration?.fileType || formatMessage('ACTIVITY')}
              </span>
            </div>
          )}
          {controllerType === DataMappingType.DATA_TABLE && selectedTable?.status === ProcessingStatus.FAILED ? (
            <div className={b('flexRow')} data-testid="data-mapping-controller-error-callout">
              <Callout intent="danger" className={b('calloutDanger')}>
                <p>{formatMessage('TABLE_CONTROLLER_PUBLISHED_STATUS_FAILED_DEFAULT_ERROR')}</p>
              </Callout>
            </div>
          ) : (
            <div>
              {shouldShowActionButtonAndText && (
                <p className={b('matchFieldsText')} data-testid="match-field-text">
                  {matchFieldText}
                </p>
              )}
            </div>
          )}
          {controllerType === DataMappingType.CONFIGURATION && !isDialogMapping && (
            <p className={b('dataMappingText')} data-testid="configuration-mapping-text">
              {formatMessage('DATA_MAPPING_PANEL_TEXT')}
            </p>
          )}
          {(missingRequiredFields?.length > 0 || !checkColumnsMapped()) && shouldShowMappingError && (
            <div className={b('flexRow')} data-testid="data-mapping-controller-missing-required-fields-callout">
              <Callout intent="danger">
                <p>{formatMessage('DATA_MAPPING_FIELDS_ERROR')}</p>
              </Callout>
            </div>
          )}
        </div>
        {shouldShowActionButtonAndText && (
          <div
            className={b('dataMappingControllerRightActionItems')}
            data-testid="data-mapping-controller-right-action-items"
          >
            <div className={b('autoMapButtonContainer')}>
              <AutoMapButton rowData={rowData} fileType={fileType} rootHierarchyId={rootHierarchyId} />
            </div>
            {!isDialogMapping && (
              <>
                {ActionButton}
                {controllerType === DataMappingType.CONFIGURATION && (
                  <TextButton
                    type="button"
                    intent="primary"
                    icon={<PlayOutline />}
                    text={formatMessage('RUN')}
                    onClick={handleRun}
                    testId={'data-mapping-controller-run-button'}
                    loading={upsertSymonPipeConfigurationLoading}
                    disabled={!rowData.length}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <ReviewConfigurationDialog
        showReviewDialog={showReviewDialog}
        setShowReviewDialog={setShowReviewDialog}
        onCloseConfigurationDetail={onCloseConfigurationDetail}
      />
    </div>
  );
};

export default DataMappingController;
