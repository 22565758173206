import React, { useEffect, useState } from 'react';

import {
  Stepper,
  StepperStatus,
  CollapsibleSidebar,
  ConditionBuilderConditionsType,
  ConditionBuilderRuleType
} from '@varicent/components';
import { Form, Formik } from 'formik';

import Constraints from 'app/components/TerritoryOptimization/Constraints/Constraints';
import OptimizeResults from 'app/components/TerritoryOptimization/OptimizeResults/OptimizeResults';
import OptimizeTerritories from 'app/components/TerritoryOptimization/OptimizeTerritories/OptimizeTerritories';
import ParametersAndTerritoryRules from 'app/components/TerritoryOptimization/ParametersAndTerritoryRules/ParametersAndTerritoryRules';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';
import {
  MetricDropdownItem,
  SourceColumns,
  useTerritoryOptimization
} from 'app/contexts/territoryOptimizationProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  ConstraintOperator,
  FileProcessConstraint,
  FileProcessType,
  TerritoryRuleBase
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useCleanFileProcess } from 'app/graphql/mutations/cleanFileProcess';
import { useStartFileProcess } from 'app/graphql/mutations/startFileProcess';

import useShowToast from 'app/hooks/useShowToast';

import { TerritoryOptimizationStatus, TerritoryOptimizationStep } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryOptimizationStepper.module.pcss';
import validationSchema from './validationSchema';

const b = block(style);

export const formatConstraints = (
  constraints: ConditionBuilderConditionsType,
  sourceColumns: SourceColumns[]
): FileProcessConstraint[] => {
  const territoryConditionIds = constraints?.conditions?.['0']?.['children'];

  if (sourceColumns.length === 0) {
    return [];
  }
  return Object.values(constraints.conditions)
    .filter((item) => territoryConditionIds?.includes(item.id))
    .filter((item) => item['value'])
    .map((item) => {
      const { selectedSourceColumnId, selectedOperator, value } = item as ConditionBuilderRuleType;
      if (!value) {
        return null;
      }
      return {
        metric: sourceColumns.find((source) => source.id === selectedSourceColumnId).name,
        operator: selectedOperator as ConstraintOperator,
        value: +value
      };
    });
};
export interface TerritoryOptimizationFormValues {
  constraints: {
    accounts: ConditionBuilderConditionsType;
    territories: ConditionBuilderConditionsType;
  };
  metric: MetricDropdownItem | null;
  territoryDefinition: TerritoryRuleBase;
}

const TerritoryOptimizationStepper: React.FC = () => {
  const { optimizationTargetTgId } = useTerritoryOptimization();
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();
  const [startFileProcess] = useStartFileProcess({
    onError(error) {
      if (!error) return null;
      const { knownError } = error;

      const startFileProcessErrorMessage =
        knownError?.errorCode === 'API_KEY_NOT_FOUND'
          ? formatMessage('START_FILE_PROCESS_API_KEY_NOT_FOUND', { emailAddress: userProfile.emailAddress })
          : formatMessage('FAIL_OPTIMIZE_TERRITORIES');
      showToast(startFileProcessErrorMessage, 'danger');
    }
  });
  const { selectedQuotaComponentId } = useBattleCard();
  const { userProfile } = useUser();

  const {
    constraintsSourceColumns,
    territoryOptimizationDrawerState,
    setTerritoryOptimizationDrawerState,
    setOptimizationStatus,
    selectedOptimizationMetric,
    setSelectedOptimizationMetric,
    setOptimizedTerritoryInProgress
  } = useTerritoryOptimization();

  const [activeStepId, setActiveStepId] = useState(territoryOptimizationDrawerState);
  const [isStepperOpened, setIsStepperOpened] = useState(true);
  const [cleanOptimizationStatus] = useCleanFileProcess();

  const handleClick = (stepId: TerritoryOptimizationStep) => {
    setActiveStepId(stepId);
  };

  const [stepperItems, setStepperItems] = useState([
    {
      id: TerritoryOptimizationStep.PARAMETERS,
      label: formatMessage('PARAMETERS_AND_TERRITORY_RULES'),
      status: StepperStatus.Started,
      order: 1
    },
    {
      id: 'constraints',
      label: formatMessage('CONSTRAINTS'),
      status: StepperStatus.Unstarted,
      order: 2
    },
    {
      id: 'optimize-territories',
      label: formatMessage('OPTIMIZE_TERRITORIES'),
      status: StepperStatus.Unstarted,
      order: 3
    },
    {
      id: 'optimize-results',
      label: formatMessage('OPTIMIZE_RESULTS'),
      status: StepperStatus.Unstarted,
      order: 4
    }
  ]);

  useEffect(() => {
    if (territoryOptimizationDrawerState) {
      setActiveStepId(territoryOptimizationDrawerState);
      const activeItem = stepperItems.find((item) => item.id === territoryOptimizationDrawerState);
      setStepperItems((prevStepItem) =>
        prevStepItem.map((stepItem) => {
          let newStatus;
          if (stepItem.order < activeItem.order) {
            newStatus = StepperStatus.Complete;
          } else if (stepItem.order === activeItem.order) {
            newStatus = StepperStatus.Started;
          }
          return {
            ...stepItem,
            status: newStatus
          };
        })
      );
    }
  }, [territoryOptimizationDrawerState]);

  const handleNext = (completedStep: TerritoryOptimizationStep, nextStep: TerritoryOptimizationStep) => {
    handleClick(nextStep);
    setStepperItems((prevStepItem) =>
      prevStepItem.map((stepItem) => {
        let newStatus;
        if (stepItem.id === completedStep) {
          newStatus = StepperStatus.Complete;
        } else if (stepItem.id === nextStep) {
          newStatus = StepperStatus.Started;
        } else {
          newStatus = stepItem.status;
        }
        return {
          ...stepItem,
          status: newStatus
        };
      })
    );
    setTerritoryOptimizationDrawerState(nextStep);
  };

  const handleBack = (currentStep: TerritoryOptimizationStep, prevStep: TerritoryOptimizationStep) => {
    handleClick(prevStep);
    setStepperItems((prevStepItem) =>
      prevStepItem.map((stepItem) => {
        let newStatus;
        if (stepItem.id === currentStep) {
          newStatus = StepperStatus.Unstarted;
        } else if (stepItem.id === prevStep) {
          newStatus = StepperStatus.Started;
        } else {
          newStatus = stepItem.status;
        }
        return {
          ...stepItem,
          status: newStatus
        };
      })
    );
    setTerritoryOptimizationDrawerState(prevStep);
  };

  const handleSubmit = async (values: TerritoryOptimizationFormValues) => {
    setSelectedOptimizationMetric(values.metric);

    const { data } = await cleanOptimizationStatus({
      variables: {
        input: {
          territoryGroupId: +optimizationTargetTgId
        }
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    });

    if (data) {
      const constraintsSourceColumnNumberOfAccount = [
        {
          id: '0',
          name: formatMessage('NUMBER_OF_ACCOUNTS'),
          dataType: 'number' as const,
          valueType: 'number' as const
        }
      ];
      const territoriesConstraints = formatConstraints(
        values.constraints.territories,
        constraintsSourceColumnNumberOfAccount
      );
      const accountsConstraints = formatConstraints(values.constraints.accounts, constraintsSourceColumns);
      const { data: startFileProcessData } = await startFileProcess({
        variables: {
          input: {
            planningCycleId: selectedPlanningCycle.id,
            territoryGroupId: +optimizationTargetTgId,
            processType: FileProcessType.territoryOptimization,
            quotaComponentId: selectedQuotaComponentId,
            config: {
              constraints: {
                ...(territoriesConstraints.length > 0 && { territories: territoriesConstraints }),
                ...(accountsConstraints.length > 0 && { accounts: accountsConstraints })
              },
              measureId: values.metric.value,
              territoryRuleBase: TerritoryRuleBase.geographic
            }
          }
        }
      });
      if (startFileProcessData) {
        setOptimizedTerritoryInProgress({ fileId: startFileProcessData.startFileProcess.fileId });
        setOptimizationStatus(TerritoryOptimizationStatus.OPTIMIZING);
        setTerritoryOptimizationDrawerState(TerritoryOptimizationStep.OPTIMIZE_TERRITORIES);
        handleNext(TerritoryOptimizationStep.CONSTRAINTS, TerritoryOptimizationStep.OPTIMIZE_TERRITORIES);
      }
    }
  };

  const initialConstraintsValue = {
    rootId: '0',
    conditions: {
      '0': {
        id: '0',
        parent: null,
        children: ['1'],
        groupCombinator: 'all'
      },
      '1': {
        error: null,
        id: '1',
        parent: '0',
        selectedSourceColumnId: null,
        selectedOperator: null,
        value: null,
        type: 'number'
      }
    }
  } as ConditionBuilderConditionsType;

  const initialValues: TerritoryOptimizationFormValues = {
    constraints: {
      accounts: initialConstraintsValue,
      territories: initialConstraintsValue
    },
    metric: selectedOptimizationMetric,
    territoryDefinition: TerritoryRuleBase.geographic
  };

  return (
    <div className={b()} data-testid="territory-optimization-stepper">
      <CollapsibleSidebar
        heading={formatMessage('OPTIMIZATION_SETUP')}
        headingTagLevel="h4"
        width={300}
        open={isStepperOpened}
        onOpenChange={() => setIsStepperOpened((prevState) => !prevState)}
        bodyContent={
          <Stepper activeStepId={activeStepId} lastItemHasStar={true} ordered={true} stepperItems={stepperItems} />
        }
      />
      <Formik<TerritoryOptimizationFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => {
          return (
            <Form className={b('optimizationFormContent')}>
              {territoryOptimizationDrawerState === TerritoryOptimizationStep.PARAMETERS && (
                <ParametersAndTerritoryRules handleNext={handleNext} data-testid="optimization-parameters" />
              )}
              {territoryOptimizationDrawerState === TerritoryOptimizationStep.CONSTRAINTS && (
                <Constraints handleBack={handleBack} data-testid="optimization-constraints" />
              )}
              {territoryOptimizationDrawerState === TerritoryOptimizationStep.OPTIMIZE_TERRITORIES && (
                <OptimizeTerritories
                  handleBack={handleBack}
                  handleNext={handleNext}
                  data-testid="optimize-territories"
                />
              )}
              {territoryOptimizationDrawerState === TerritoryOptimizationStep.OPTIMIZE_RESULTS && (
                <OptimizeResults data-testid="optimize-results" />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TerritoryOptimizationStepper;
