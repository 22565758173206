import React, { useMemo } from 'react';

import { useMutation } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { Dialog, Label } from '@blueprintjs/core';
import { Field, Form, Formik } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';

import DirtyFormPrompt from 'app/components/DirtyFormPrompt/DirtyFormPrompt';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import SellerSelect from 'app/components/SellerSelect/SellerSelect';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  CreateTerritoryGroup,
  CreateTerritoryGroupVariables,
  UpdateTerritoryGroup,
  UpdateTerritoryGroupVariables,
  UpsertTerritoryGroupOwner,
  UpsertTerritoryGroupOwnerVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { CREATE_TERRITORY_GROUP } from 'app/graphql/mutations/createTerritoryGroup';
import { UPDATE_TERRITORY_GROUP } from 'app/graphql/mutations/updateTerritoryGroup';
import { UPSERT_TERRITORY_GROUP_OWNER } from 'app/graphql/mutations/upsertTerritoryGroupOwner';

import useShowToast from 'app/hooks/useShowToast';

import { TerritoryGroupDialogModes } from 'app/models';

import block from 'utils/bem-css-modules';
import { memberToString } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import style from './TerritoryGroupDialog.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

interface TerritoryGroupDialogFormValues {
  name: string;
  territoryGroupOwner: { key: string; value: number };
}

const TerritoryGroupDialog: React.FC<{ battleCardId: string }> = ({ battleCardId }) => {
  const { userProfile } = useUser();
  const { selectedQuotaComponentId } = useBattleCard();

  const { tdrLookupMap, getTDR, tdrDialogState, closeTdrDialog } = useTerritoryDefineAndRefine();
  const showToast = useShowToast();

  const isCreateMode = tdrDialogState.mode === TerritoryGroupDialogModes.CREATE_GROUP;

  const selectedTerritoryGroup = tdrLookupMap[tdrDialogState.territoryGroupId];

  const initialFormValues = useMemo(
    (): TerritoryGroupDialogFormValues =>
      isCreateMode
        ? { name: '', territoryGroupOwner: { key: '', value: 0 } }
        : {
            name: selectedTerritoryGroup.name,
            territoryGroupOwner: {
              key: memberToString(selectedTerritoryGroup.owner),
              value: selectedTerritoryGroup.owner.memberId
            }
          },
    [isCreateMode, selectedTerritoryGroup]
  );

  const [createTerritoryGroup] = useMutation<CreateTerritoryGroup, CreateTerritoryGroupVariables>(
    CREATE_TERRITORY_GROUP,
    {
      onCompleted() {
        showToast(formatMessage('CREATE_NEW_TERRITORY_GROUP_SUCCESS'), 'success');
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('CREATE_NEW_TERRITORY_GROUP_ERROR'), 'danger');
      }
    }
  );

  const [updateTerritoryGroup] = useMutation<UpdateTerritoryGroup, UpdateTerritoryGroupVariables>(
    UPDATE_TERRITORY_GROUP,
    {
      onCompleted() {
        showToast(formatMessage('UPDATE_TERRITORY_GROUP_SUCCESS'), 'success');
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('UPDATE_TERRITORY_GROUP_ERROR'), 'danger');
      }
    }
  );

  const [upsertTerritoryGroupOwner] = useMutation<UpsertTerritoryGroupOwner, UpsertTerritoryGroupOwnerVariables>(
    UPSERT_TERRITORY_GROUP_OWNER,
    {
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('UPSERT_TERRITORY_GROUP_OWNER_ERROR'), 'danger');
      }
    }
  );

  const handleSubmit = async (values: { name: string; territoryGroupOwner: { value: number } }) => {
    let selectedTerritoryGroupId: number;
    if (isCreateMode) {
      const result = await createTerritoryGroup({
        variables: {
          battlecardId: +battleCardId,
          territoryGroupName: values.name,
          // Use the selection as the parent
          territoryGroupParentId: +selectedTerritoryGroup.territoryGroupId,
          precedence: selectedTerritoryGroup.precedence
        }
      });
      selectedTerritoryGroupId = result?.data?.createTerritoryGroup?.territoryGroupId;
    } else {
      selectedTerritoryGroupId = +selectedTerritoryGroup.territoryGroupId;
      await updateTerritoryGroup({
        variables: {
          territoryGroupName: values.name,
          territoryGroupId: selectedTerritoryGroupId
        }
      });
    }

    // if in create mode and user provides territory group owner,
    // or if in edit mode and user chooses new territory group owner,
    // add the owner to the new territory group
    const newOwnerId = values.territoryGroupOwner.value;
    const isOwnerChanged = newOwnerId !== initialFormValues.territoryGroupOwner.value;
    const shouldUpsertTerritoryGroupOwner = newOwnerId && (isCreateMode || isOwnerChanged);

    if (selectedTerritoryGroupId && shouldUpsertTerritoryGroupOwner) {
      await upsertTerritoryGroupOwner({
        variables: {
          input: {
            tenantId: userProfile.tenant?.id,
            territoryGroupId: +selectedTerritoryGroupId,
            memberId: +newOwnerId
          }
        }
      });
    }
    closeTdrDialog();
    await getTDR(battleCardId, selectedQuotaComponentId);
  };

  return (
    <Dialog
      isOpen
      onClose={closeTdrDialog}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      portalClassName={b()}
      data-testid="territory-group-dialog"
    >
      <div className={b('dialogHeader')}>
        <div className={b('cardTitle')} data-testid="card-title">
          <span>{formatMessage('TERRITORY_GROUP')}</span>
        </div>
      </div>
      <div className={b('dialogBody')}>
        <Formik initialValues={initialFormValues} validationSchema={validationsSchema} onSubmit={handleSubmit}>
          {({ errors, touched, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <DirtyFormPrompt data-testid="dirty-form-prompt" />
              <div className={b('fieldContainer')}>
                <div className={b('fieldLabel')} data-testid="name-label">
                  <Label>{formatMessage('NAME_WITH_COLON')}</Label>
                </div>
                <div className={b('field')} data-testid="name-field">
                  <Field
                    name="name"
                    type="text"
                    placeholder={formatMessage('ADD')}
                    component={FormTextInputGroup}
                    onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                      handleBlur(event); // Needed to update touched object
                      handleChange(event);
                    }}
                  />
                </div>
                <div className={b('fieldLabel')} data-testid="owner-select-label">
                  <Label>{formatMessage('TERRITORY_OWNER')}</Label>
                </div>
                <div className={b('field')}>
                  <Field name="territoryGroupOwner" component={SellerSelect} data-testid="owner-select-field" />
                </div>
              </div>
              <div className={b('dialogFooter')}>
                <div className={b('footerSpacer')} />
                <div className={b('buttons')}>
                  <div className={b('button')} onClick={closeTdrDialog}>
                    <TextButton text="Cancel" type="button" testId={'cancel-button'} />
                  </div>
                  <div className={b('button')}>
                    <TextButton
                      text={isCreateMode ? formatMessage('ADD') : formatMessage('UPDATE')}
                      type="submit"
                      intent="primary"
                      disabled={!!errors.name || (!touched.name && !touched.territoryGroupOwner)}
                      loading={isSubmitting}
                      testId={'submit-button'}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default TerritoryGroupDialog;
