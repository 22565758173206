import React from 'react';

import { Launch } from '@carbon/icons-react';
import { Link } from 'react-router-dom';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { config } from 'utils/config';

const DocsLink = ({
  relativeUrl,
  label,
  icon = <Launch />
}: {
  relativeUrl: string;
  label: string;
  icon?: JSX.Element | null;
}): JSX.Element => (
  <Link
    to={{ pathname: `${config.DOCS_SITE_URL}${relativeUrl}` }}
    target="_blank"
    rel="noopener noreferrer"
    data-testid="documentation-link"
  >
    <TextButton type={'button'} icon={icon} minimal intent="primary" text={label} testId={'open-documentation-icon'} />
  </Link>
);

export default DocsLink;
