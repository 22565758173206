import { useMemo } from 'react';

import { findPublishedDeploymentModel } from 'app/containers/PlanningCycles/PlanningCyclePage/planningCyclePageUtils';

import { usePermissions } from 'app/contexts/permissionsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { DeploymentModelTypeEnum } from 'app/graphql/generated/apolloTypes';

import { Permission } from 'app/models';

export const useScenarioPermissions = (): Permission[] => {
  const { permissions } = usePermissions();
  const { selectedDm, secondaryDm } = useSelectedAndSecondaryDm();
  return useMemo(
    () =>
      permissions.filter(
        (permission) =>
          permission.deploymentModelId === selectedDm?.deploymentModelId ||
          permission.deploymentModelId === secondaryDm?.deploymentModelId
      ),
    [permissions, selectedDm, secondaryDm]
  );
};

const useSelectedAndSecondaryDm = () => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const { userPlanningCycles } = useUser();

  return useMemo(() => {
    const dmsInPc =
      userPlanningCycles?.find((pc) => pc.planningCycleId === selectedPlanningCycle?.id)?.deploymentModels ?? [];
    const selectedDm = dmsInPc.find((dm) => dm.deploymentModelId === selectedDeploymentModelId);
    const manageDm = dmsInPc.find((dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Manage);
    const publishedDm = findPublishedDeploymentModel(dmsInPc);

    const secondaryDm = selectedDm?.deploymentModelType === DeploymentModelTypeEnum.Manage ? publishedDm : manageDm;

    return { selectedDm, secondaryDm };
  }, [selectedPlanningCycle, selectedDeploymentModelId, userPlanningCycles]);
};
