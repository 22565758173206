import React from 'react';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { KnownErrorInfo } from 'app/models/ApiErrors';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const showRuleDeleteErrorToast = (knownError: KnownErrorInfo, includedRuleCount?: number): void => {
  let messageBody = formatMessage('FAIL_BULK_DELETE_TERRITORY_GENERIC');

  if (knownError?.errorCode === 'REMOVE_RULE_HAS_OVERLAY_DEPENDENCY') {
    messageBody = formatMessage('REMOVE_RULE_HAS_OVERLAY_DEPENDENCY');
  } else if (knownError?.errorCode === 'REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION') {
    messageBody = formatMessage('REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION');
  } else if (includedRuleCount === 1) {
    messageBody = formatMessage('FAIL_BULK_DELETE_TERRITORY', { count: includedRuleCount });
  } else if (includedRuleCount > 1) {
    messageBody = formatMessage('FAIL_BULK_DELETE_TERRITORIES', { count: includedRuleCount });
  }

  // eslint-disable-next-line deprecation/deprecation
  showToast(
    <ToastMessage title={formatMessage('FAIL_BULK_DELETE_TERRITORY_TITLE')} message={messageBody} />,
    'danger',
    'top-right'
  );
};
