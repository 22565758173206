import React from 'react';

import { Search } from '@carbon/icons-react';
import { Field, Form, Formik } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';

import GridFilter, { GridFilterProps } from 'app/components/AdvancedGrid/GridHelpers/Filters/GridFilter';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import {
  AccountRuleHeaderEnum,
  GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders
} from 'app/graphql/generated/apolloTypes';

import { FilterChangeInput, FilterInput } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import { STATIC_FILTER_COLUMNS } from './AccountRuleConstants';
import style from './AccountRuleHeader.module.pcss';

const b = block(style);

const filterType: Record<AccountRuleHeaderEnum, GridFilterProps['type']> = {
  NUMBER: 'number',
  STRING: 'text'
};

export interface AccountRuleFilterProps {
  dynamicColumns: GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders[];
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  accountFilters: FilterInput;
  onFilterApply: () => void;
}

const AccountRuleFilter: React.FC<AccountRuleFilterProps> = ({
  dynamicColumns,
  onFilterChange,
  accountFilters,
  onFilterApply
}) => {
  const staticInitialValues = {};
  const dynamicColumnIds = dynamicColumns.map((column) => column.colId);
  for (const key in accountFilters) {
    if (!dynamicColumnIds.includes(key)) {
      staticInitialValues[key] = accountFilters[key].filter;
    }
  }

  const handleFilterChange = ({ filterType, type, filter, colId }) =>
    onFilterChange({ filterType, type, filter, colId });

  return (
    <div className={b('inputWrapper')} data-testid="account-rule-filter-container">
      <Formik initialValues={staticInitialValues} onSubmit={null} enableReinitialize>
        {() => {
          return (
            <Form>
              {STATIC_FILTER_COLUMNS.map((column) => (
                <div className={b('fieldWrapper')} key={column.colId}>
                  <Field
                    component={FormTextInputGroup}
                    name={column.colId}
                    label={column.label}
                    value={accountFilters[column.colId]?.filter ?? ''}
                    showErrors={false}
                    leftIcon={<Search />}
                    onChange={(e) =>
                      handleFilterChange({
                        filterType: 'text',
                        type: 'contains',
                        filter: e.target.value,
                        colId: column.colId
                      })
                    }
                  />
                </div>
              ))}
            </Form>
          );
        }}
      </Formik>
      {dynamicColumns.map((column) => (
        <div key={column.colId}>
          {column.isFilterable && (
            <GridFilter
              type={filterType[column.dataType]}
              onFilterChange={(filter) =>
                handleFilterChange({
                  filterType: filterType[column.dataType],
                  type: filter.type,
                  filter: filter.filter,
                  colId: column.colId
                })
              }
              label={column.name}
              usePortal={false}
              isFullWidth
              showReset={false}
              typeInitialValue={accountFilters[column.colId]?.type}
              inputInitialValue={accountFilters[column.colId]?.filter}
            />
          )}
        </div>
      ))}
      <div className={b('applyFilterButton')}>
        <TextButton
          text={formatMessage('APPLY')}
          type="button"
          testId="filter-apply-button"
          large={false}
          onClick={onFilterApply}
        />
      </div>
    </div>
  );
};

export default AccountRuleFilter;
