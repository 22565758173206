import { ColDef } from '@ag-grid-community/core';

import { ISheetDefinitionDef } from 'app/graphql/generated/apolloTypes';

import { GridHeaders } from 'app/models';

import { sheetDefinitionColumns } from 'utils/helpers/sheetsPreviewUtils';

const buildQuotaSheetColumnDef = (sheetDefinitions: ISheetDefinitionDef[]): Array<ColDef> => [
  {
    headerName: GridHeaders.TERRITORY_ID,
    field: 'territoryId',
    minWidth: 181,
    flex: 1,
    lockPosition: true,
    suppressMovable: true
  },

  {
    headerName: GridHeaders.TERRITORY_NAME,
    field: 'territoryName',
    minWidth: 181,
    flex: 1,
    lockPosition: true,
    suppressMovable: true
  },
  ...sheetDefinitionColumns(sheetDefinitions)
];

export default buildQuotaSheetColumnDef;
