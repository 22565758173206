import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { AddComment, ChevronDown, Close } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import CommentPanelContent from 'app/components/CommentPanel/CommentPanelContent/CommentPanelContent';
import CommentThreadLoading from 'app/components/CommentPanel/CommentThreadLoading/CommentThreadLoading';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useComments } from 'app/contexts/commentProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  GetTerritoryWorkflowCommentThreads,
  GetTerritoryWorkflowCommentThreadsVariables,
  GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_TERRITORY_WORKFLOW_COMMENT_THREADS } from 'app/graphql/queries/getTerritoryWorkflowCommentThreads';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommentPanel.module.pcss';

const b = block(style);

const CommentPanel: React.FC = () => {
  const { setIsCommentPanelOpen, setShouldRenderCommentForm, setShouldRefetchCommentPanel, shouldRefetchCommentPanel } =
    useComments();
  const { selectedDeploymentModelId } = useScope();
  const { selectedBattleCardId } = useBattleCard();
  const showToast = useShowToast();

  const [commentThreads, setCommentThreads] =
    useState<GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items[]>(null);

  const {
    data: workflowCommentThreads,
    loading: isLoading,
    refetch: refetchCommentPanel
  } = useQuery<GetTerritoryWorkflowCommentThreads, GetTerritoryWorkflowCommentThreadsVariables>(
    GET_TERRITORY_WORKFLOW_COMMENT_THREADS,
    {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        battlecardId: +selectedBattleCardId,
        deploymentModelId: selectedDeploymentModelId,
        startRow: 1,
        endRow: 200
      },
      onError({ graphQLErrors, networkError }) {
        showToast(formatMessage('COMMENT_PANEL_LOADING_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const threadLists = workflowCommentThreads?.getDeploymentModelSpec?.territoryWorkflowCommentThreads?.items || [];

  useEffect(() => {
    if (threadLists?.length > 0 && !isLoading) {
      setCommentThreads(threadLists);
    }
  }, [commentThreads, isLoading]);

  useEffect(() => {
    if (shouldRefetchCommentPanel) {
      refetchCommentPanel();
      setShouldRefetchCommentPanel(false);
    }
  }, [shouldRefetchCommentPanel]);

  return (
    <div className={b('commentPanelWrapper')} data-testid="comment-panel">
      <>
        <div className={b('commentHeaderContainer')} data-testid="comment-panel-header">
          <span className={b('commentHeader')}>{formatMessage('COMMENTS')}</span>
          <IconButton
            type="button"
            icon={<Close size={24} />}
            onClick={() => {
              setIsCommentPanelOpen(false);
            }}
            testId={'close-btn'}
            title={formatMessage('CLOSE')}
          />
        </div>
        <div className={b('filterContainer')} data-testid="comment-filter-container">
          <div className={b('filterGroup')}>
            <div className={b('filterTitle')}>
              <span>{formatMessage('ALL_GROUPS')}</span>
              <ChevronDown size={24} />
            </div>
            <div className={b('filterTitle')}>
              <span>{formatMessage('ALL_TERRITORIES')}</span>
              <ChevronDown size={24} />
            </div>
          </div>
          <div className={b('commentIcon')}>
            <IconButton
              type="button"
              icon={<AddComment size={20} />}
              testId={'add-comment-icon'}
              title={formatMessage('ADD_COMMENTS')}
              onClick={() => setShouldRenderCommentForm(true)}
            />
          </div>
        </div>
      </>
      {isLoading ? (
        <CommentThreadLoading />
      ) : (
        <>
          <CommentPanelContent isLoading={isLoading} threads={commentThreads} />
        </>
      )}
    </div>
  );
};

export default CommentPanel;
