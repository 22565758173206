import React from 'react';

import { Save } from '@carbon/icons-react';
import { Formik, Form } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import ScenarioNameField from 'app/components/PlanningCyclePageHeader/ScenarioNameField';

import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import {
  GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
  RenameDeploymentModel
} from 'app/graphql/generated/apolloTypes';
import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';
import { useRenameDeploymentModel } from 'app/graphql/mutations/renameDeploymentModel';
import { GET_DEPLOYMENT_MODEL_SUMMARIES } from 'app/graphql/queries/getDeploymentModelSummaries';

import { useChangeScenario } from 'app/hooks/useChangeScenario';
import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

import validationSchema from './validationSchema';

interface RenameScenarioDialogProps {
  onClose: () => void;
  planDms: Pick<
    GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
    'deploymentModelId' | 'deploymentModelName'
  >[];
  relevantScenario: Pick<ScenarioSummary, 'deploymentModelId' | 'deploymentModelName'>;
}

interface RenameScenarioFormValues {
  newScenarioName: string;
}

const RenameScenarioDialog: React.FC<RenameScenarioDialogProps> = ({ onClose, planDms, relevantScenario }) => {
  const { selectedPlanningCycle, selectedDeploymentModelId: currentlyOpenScenarioId } = useScope();
  const { userPlanningCycles, setUserPlanningCycles } = useUser();
  const changeScenario = useChangeScenario();
  const showToast = useShowToast();

  const applyRenameToUserPlanningCycles = ({ updateDeploymentModel: patch }: RenameDeploymentModel) => {
    const newPcs = userPlanningCycles.map((oldPc) => ({
      ...oldPc,
      deploymentModels: oldPc.deploymentModels?.map((oldDm) => {
        if (oldDm.deploymentModelId !== patch.deploymentModelId) return oldDm;
        return {
          ...oldDm,
          deploymentModelName: patch.deploymentModelName
        };
      })
    }));
    setUserPlanningCycles(newPcs);
  };

  const [renameScenario] = useRenameDeploymentModel({
    onCompleted(data) {
      showToast(
        <ToastMessage
          title={formatMessage('SCENARIO_RENAMED')}
          message={formatMessage('SCENARIO_RENAMED_MESSAGE', { name: data.updateDeploymentModel.deploymentModelName })}
        />,
        'success'
      );
      onClose();
      applyRenameToUserPlanningCycles(data);
      if (currentlyOpenScenarioId === data.updateDeploymentModel.deploymentModelId)
        changeScenario(data.updateDeploymentModel.deploymentModelName);
    },
    onError() {
      showToast(formatMessage('SCENARIO_RENAMED_FAILURE'), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_DEPLOYMENT_MODEL_SUMMARIES,
        variables: {
          planningCycleId: selectedPlanningCycle?.id
        }
      }
    ]
  });

  const handleRenameScenario = ({ newScenarioName }: RenameScenarioFormValues) =>
    renameScenario({
      variables: {
        deploymentModelId: relevantScenario.deploymentModelId,
        deploymentModelName: newScenarioName
      }
    });

  const initialValues: RenameScenarioFormValues = {
    newScenarioName: relevantScenario.deploymentModelName
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleRenameScenario} validationSchema={validationSchema}>
      {({ handleSubmit, values, isSubmitting }) => (
        <Dialog
          title={formatMessage('RENAME_SCENARIO')}
          isOpen
          onSubmit={handleSubmit}
          onClose={onClose}
          confirmButtonIcon={<Save />}
          confirmButtonText={formatMessage('SAVE')}
          confirmButtonLoading={isSubmitting}
          bodyMinHeight={0}
          size="small"
        >
          <Form>
            <ScenarioNameField
              currentLength={values.newScenarioName.length}
              planDms={planDms}
              fieldName="newScenarioName"
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default RenameScenarioDialog;
