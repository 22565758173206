import React from 'react';

import {
  CheckmarkFilled,
  Network_3Reference as NetworkReference,
  OverflowMenuHorizontal,
  View
} from '@carbon/icons-react';
import { IconOnlyButton, Tag } from '@varicent/components';
import dayjs from 'dayjs';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import Icon from 'components/Icon/Icon';

import { ScenarioActions } from 'app/components/PlanningCyclePageHeader/ScenarioMenu';
import ScenarioMenuActionItems from 'app/components/PlanningCyclePageHeader/ScenarioMenuActionItems';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ScenarioTile.module.pcss';

const b = block(style);

export interface ScenarioTileProps {
  scenarioName: string;
  lastOpened: string;
  dateCreated: string;
  isPublished: boolean;
  isPlanningCycleActivated: boolean;
  onLaunch: () => void;
  onActionClick: (action: ScenarioActions) => void;
  isCurrentScenario: boolean;
}

const ScenarioTile: React.FC<ScenarioTileProps> = ({
  scenarioName,
  dateCreated,
  isPublished,
  isPlanningCycleActivated,
  lastOpened,
  onLaunch,
  onActionClick,
  isCurrentScenario
}) => {
  return (
    <div className={b()} data-testid="scenario-tile">
      <div className={b('scenarioName')}>
        <div className={b('scenarioNameHeader')}>
          <div className={b('scenarioNameHeaderIcon', { isPublished })}>
            <Icon icon={isPublished ? <CheckmarkFilled /> : <NetworkReference />} />
          </div>
          <h4 className={b('scenarioNameHeaderText')}>{scenarioName}</h4>
        </div>
        <div className={b('rightContainer')}>
          {isCurrentScenario && (
            <div className={b('currentScenario')}>
              <Tag data-testid="current-scenario-tag">{formatMessage('CURRENT_SCENARIO')}</Tag>
            </div>
          )}
          {!isCurrentScenario && (
            <IconOnlyButton
              className={b('launchScenarioButton')}
              icon={View}
              text={formatMessage('LAUNCH_SCENARIO')}
              minimal
              onClick={onLaunch}
              data-testid="launch-scenario-button"
            />
          )}
          <DropdownMenu
            content={
              <ScenarioMenuActionItems
                hideCreateAction={true}
                hideRecentsAction={true}
                hideManageAction={true}
                hideLaunchAction={isCurrentScenario}
                hidePublishAction={isPlanningCycleActivated || isPublished}
                hideActivateAction={isPlanningCycleActivated || !isPublished}
                onActionClick={onActionClick}
              />
            }
            placement={'bottom-start'}
            target={<IconOnlyButton icon={OverflowMenuHorizontal} text={null} minimal />}
          />
        </div>
      </div>
      <dl className={b('scenarioDates')}>
        <div className={b('lastOpened')}>
          <dt className={b('dateLabel')}>{formatMessage('LAST_OPENED')}</dt>
          <dd className={b('date')}>{dayjs(lastOpened).format('MMMM D, YYYY')}</dd>
        </div>
        <div className={b('dateCreated')}>
          <dt className={b('dateLabel')}>{formatMessage('DATE_CREATED')}</dt>
          <dd className={b('date')}>{dayjs(dateCreated).format('MMMM D, YYYY')}</dd>
        </div>
      </dl>
    </div>
  );
};

export default ScenarioTile;
