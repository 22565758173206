// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MAAKd ::-webkit-scrollbar {\n      height: 8px;\n    }\n    .MAAKd ::-webkit-scrollbar-track {\n      height: 100px;\n      width: 20px;\n    }\n    .MAAKd ::-webkit-scrollbar-thumb {\n      background: #ccc;\n      border-radius: 5px;\n    }\n    .MAAKd ::-webkit-scrollbar-thumb:hover {\n      background: #888;\n    }\n    .MAAKd .bp3-tag-input .bp3-tag-input-values {\n      max-height: 200px;\n      min-height: 30px;\n      overflow-x: auto;\n      margin-bottom: 5px;\n    }\n  ._5UVyd .bp3-tag.bp3-intent-primary, ._5UVyd .bp3-tag.bp3-intent-primary:hover {\n      background: rgb(var(--color-cobalt-5));\n      color: rgb(var(--color-cobalt-3));\n    }\n  ._0QNIl {\n    width: 500px;\n    height: 200px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchableTagInput_searchTagInput": "MAAKd",
	"SearchableTagInput_searchTagInputDefault": "_5UVyd",
	"SearchableTagInput_hierarchySearchContainer": "_0QNIl"
};
export default ___CSS_LOADER_EXPORT___;
