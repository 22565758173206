import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { RecolorTerritory, RecolorTerritoryVariables } from 'app/graphql/generated/apolloTypes';

export const RECOLOR_TERRITORY = gql`
  mutation RecolorTerritory($input: RecolorTerritoryInput!) {
    recolorTerritory(input: $input) {
      __typename
    }
  }
`;

export const useRecolorTerritory = createUseMutation<RecolorTerritory, RecolorTerritoryVariables>(RECOLOR_TERRITORY);
