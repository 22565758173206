import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpsertSheet, UpsertMemberVariables } from 'app/graphql/generated/apolloTypes';

export const UPSERT_MEMBER = gql`
  mutation UpsertMember($input: UpsertMemberInput!) {
    upsertMember(input: $input) {
      memberId
    }
  }
`;

export const useUpsertMember = createUseMutation<UpsertSheet, UpsertMemberVariables>(UPSERT_MEMBER);
