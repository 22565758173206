// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6Ulrm {\n    display: flex;\n    width: 100%;\n    background: rgb(var(--color-light-gray-5));\n  }\n  .Yjrq7 {\n    flex: 2;\n    max-width: 50%;\n  }\n  .Yjrq7 .ag-right-aligned-header .ag-react-container {\n      justify-content: end;\n    }\n  .Yjrq7 .ag-floating-bottom .ag-cell:nth-last-child(2) .ag-react-container {\n      justify-content: flex-end;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TerritoryBalancingGrid_gridsWrapper": "_6Ulrm",
	"TerritoryBalancingGrid_gridContainer": "Yjrq7"
};
export default ___CSS_LOADER_EXPORT___;
