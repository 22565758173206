import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTerritoryRulesForMap, GetTerritoryRulesForMapVariables } from 'app/graphql/generated/apolloTypes';

export const GET_TERRITORY_RULES_FOR_MAP = gql`
  query GetTerritoryRulesForMap(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $territoryGroupId: Int
    $startRow: Int
    $endRow: Int
    $source: SourceInput
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        territoryGroupId: $territoryGroupId
        startRow: $startRow
        endRow: $endRow
        source: $source
      }
    ) {
      territoryRules {
        territoryId
        territoryName
        effectiveDate
        endDate
        measureValue
        numberOfAccounts
        ruleId
        territoryGroupId
        ruleDefinitionSlimCompressed {
          compressed
          compressedType
        }
        color
      }
    }
  }
`;

export const useGetTerritoryRulesForMap = createUseQuery<GetTerritoryRulesForMap, GetTerritoryRulesForMapVariables>(
  GET_TERRITORY_RULES_FOR_MAP
);
