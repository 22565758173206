import React, { useEffect, useState } from 'react';

// TODO TQP-9477 change implementation to use showToast
// eslint-disable-next-line no-restricted-imports
import { ProgressBar, Toast } from '@blueprintjs/core';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import { ApolloErrorContainer } from 'app/graphql/apolloTypeOverrides';
import { GetJobProgress, PCJobType } from 'app/graphql/generated/apolloTypes';
import { useGetJobProgress } from 'app/graphql/hooks/useGetJobProgress';
import { useGetTDAJobProgress } from 'app/graphql/hooks/useGetTDAJobProgress';

import { LongRunningJob } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './JobProgressStatusToast.module.pcss';

const b = block(style);

interface JobProgressStatusToastProps {
  /* ***************************************************************************************/
  /* NB: jobKey and jobDetail are mutually exclusive.                                      */
  /* From this point forward, the getJobProgress query should only take a jobKey as input. */
  /* jobDetail is a remnant from when the input type was different for every type of job.  */
  /* Do not use jobDetail.                                                                 */
  jobKey?: string;
  jobDetail?: LongRunningJob;
  /* ***************************************************************************************/

  title: string;
  progressDescription: string;
  progress?: number;
  planningCycleId?: number;
  onPollSuccess: (data: GetJobProgress, stopPolling: () => void) => void;
  onPollError: (error: ApolloErrorContainer) => void;
}

const JobProgressStatusToast: React.FC<JobProgressStatusToastProps> = ({
  jobKey,
  jobDetail,
  title,
  progressDescription,
  progress = null,
  planningCycleId,
  onPollSuccess,
  onPollError
}) => {
  const { getJobProgress, data: jobProgress } = useGetJobProgress({
    jobDetail,
    onPollSuccess,
    onPollError
  });
  const { getJobProgressForTopdownAllocation } = useGetTDAJobProgress(jobDetail);
  const currentNumberOfTables = jobProgress?.getJobProgress?.pcCloneJobOutput?.numberOfTables || 0;
  const currentNumberOfTablesProcessed = jobProgress?.getJobProgress?.pcCloneJobOutput?.numberOfTablesProcessed || 0;
  const [isOpen, setIsOpen] = useState(true);
  const getStatusValueForCoinSortProgress = () => {
    return currentNumberOfTables ? currentNumberOfTablesProcessed / currentNumberOfTables : 0;
  };

  const progressValue = progress ? progress : getStatusValueForCoinSortProgress();

  const message = (
    <ToastMessage
      title={title}
      message={
        <>
          <div className={b('descriptionContainer')}>
            <span>{progressDescription}</span>
          </div>
          <div className={b('progressContainer')}>
            <ProgressBar className={b('progressBar')} stripes={false} value={progressValue} />
            <span className={b('progressPercentage')} data-testid="progress-percentage">{`${Math.floor(
              progressValue * 100
            )}%`}</span>
          </div>
        </>
      }
    />
  );

  useEffect(() => {
    let getJobProgressInput;
    if (jobKey) {
      getJobProgressInput = {
        variables: {
          input: {
            jobKey,
            pcCloneJobInput: {
              jobType: PCJobType.DEPLOYMENT_MODEL_DELETE,
              planningCycleId
            }
          }
        }
      };
      getJobProgress(getJobProgressInput);
    } else if (jobDetail) {
      /* ******************************************************************************************** */
      /* NB: THE CODE BELOW CONTAINS VERY SPECIFIC BUSINESS LOGIC THAT SHOULD NOT BE IN THIS FILE.    */
      /* FUTURE ATTEMPTS TO USE THIS TOAST SHOULD USE THE JOBKEY PROP.                                */
      /* WE SHOULD NOT BE ADDING HARD CODED LOGIC TO A COMPONENT THAT IS MEANT TO BE GENERIC.         */
      /* PLEASE ASK @lauraalkhoury-varicent FOR HELP IF NEEDED.                                       */
      /* ******************************************************************************************** */

      if ('planningCycleId' in jobDetail) {
        getJobProgressInput = {
          variables: {
            input: {
              pcCloneJobInput: {
                jobType: jobDetail.jobType,
                planningCycleId: jobDetail.planningCycleId
              }
            }
          }
        };
        getJobProgress(getJobProgressInput);
      }
      if ('jobKey' in jobDetail) {
        getJobProgressInput = {
          variables: {
            input: {
              territoryRuleDeleteJobInput: {
                jobKey: jobDetail.jobKey
              }
            }
          }
        };
        getJobProgress(getJobProgressInput);
      }

      if ('battlecardId' in jobDetail && 'quotaComponentId' in jobDetail) {
        getJobProgressInput = {
          variables: {
            input: {
              allocateTopDownJobInput: {
                battlecardId: jobDetail.battlecardId,
                quotaComponentId: jobDetail.quotaComponentId
              }
            }
          }
        };
        getJobProgressForTopdownAllocation(getJobProgressInput);
      }
    }
  }, []);

  return (
    isOpen && (
      <Toast
        message={message}
        timeout={0}
        intent="primary"
        icon="info-sign"
        onDismiss={(arg) => setIsOpen(arg)}
        data-testid="job-progress-status-toast"
      />
    )
  );
};

export default JobProgressStatusToast;
