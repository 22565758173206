import React from 'react';

import { Menu, MenuItem } from '@blueprintjs/core';
import { Calculation } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';
import { KeyValue } from 'components/models';
import Popover from 'components/Popover/Popover';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './OperatorSelectMenu.module.pcss';

const b = block(style);

interface OperatorSelectMenuProps {
  onSelect: (value: KeyValue<string>) => void;
  items?: KeyValue<string>[];
  disabled?: boolean;
}

const defaultOperatorItems = [
  {
    key: 'Addition',
    value: '+'
  },
  {
    key: 'Subtraction',
    value: '-'
  },
  {
    key: 'Multiplication',
    value: '*'
  },
  {
    key: 'Division',
    value: '/'
  },
  {
    key: 'Parenthesis',
    value: '()'
  }
];

const OperatorSelectMenu: React.FC<OperatorSelectMenuProps> = ({
  onSelect,
  items = defaultOperatorItems,
  disabled = false
}: OperatorSelectMenuProps) => {
  const MenuItems = (item) => {
    return (
      <MenuItem
        className={b('menuItem')}
        key={item.key}
        data-testid={`operator-select-menu-item-${item.key}`}
        onClick={() => onSelect(item)}
        text={<div className={b('menuItemText')}>{item.key}</div>}
        label={item.value}
      />
    );
  };

  const MenuContent = () => {
    return (
      <div className={b('menuContent')} data-testid="operator-select-menu-content">
        <Menu>
          <div className={b('menuHeader')}>{formatMessage('OPERATORS')}</div>
          {items.map((item) => {
            return <div key={item.key}>{MenuItems(item)}</div>;
          })}
        </Menu>
      </div>
    );
  };

  return (
    <div data-testid="operator-select-menu">
      <Popover content={<MenuContent />} placement={'bottom-start'} minimal disabled={disabled}>
        <IconButton
          type={'button'}
          icon={<Calculation />}
          testId={'select-menu-icon-button'}
          title={''}
          minimal
          disabled={disabled}
        />
      </Popover>
    </div>
  );
};

export default OperatorSelectMenu;
