import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Divider, Spinner } from '@blueprintjs/core';
import { View, ViewOff } from '@carbon/icons-react';
import { Field, Form, Formik } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Card from 'components/Card/Card';
import { SPINNER_DEFAULT_SIZE } from 'components/global/variables';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { UserProfile } from 'app/core/userManagement/userProvider';

import {
  ChangeCurrentUserPasswordMutation,
  ChangeCurrentUserPasswordMutationVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { CHANGE_CURRENT_USER_PASSWORD } from 'app/graphql/mutations/changeCurrentUserPassword';
import { GET_USER_SPEC } from 'app/graphql/queries/getUserSpec';

import useShowToast from 'app/hooks/useShowToast';

import { UserQuerySpec } from 'app/models';

import block from 'utils/bem-css-modules';
import { validatePassword } from 'utils/helpers/userProfileHelper';
import { formatMessage } from 'utils/messages/utils';

import style from './PasswordManagementCard.module.pcss';

const b = block(style);

interface PasswordManagementCardProps {
  userProfile: UserProfile;
}

const PasswordManagementCard: React.FC<PasswordManagementCardProps> = ({
  userProfile
}: PasswordManagementCardProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const showToast = useShowToast();

  const {
    data: userSpec,
    loading: userSpecLoading,
    refetch: userSpecRefetch
  } = useQuery<UserQuerySpec>(GET_USER_SPEC, {
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const [changeCurrentUserPassword] = useMutation<
    ChangeCurrentUserPasswordMutation,
    ChangeCurrentUserPasswordMutationVariables
  >(CHANGE_CURRENT_USER_PASSWORD, {
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (result.changeCurrentUserPassword.wasPasswordChanged) {
        showToast(formatMessage('PASSWORD_CHANGE_SUCCESS'), 'success');
      } else {
        showToast(formatMessage('PASSWORD_CHANGE_ERROR'), 'danger');
      }
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('PASSWORD_CHANGE_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const placeholderPasswordText = '**********';

  const updatePassword = async (proposedPassword: string, previousPassword: string) => {
    try {
      const updateResult = await changeCurrentUserPassword({
        variables: {
          proposedPassword,
          previousPassword
        }
      });
      return updateResult.data.changeCurrentUserPassword.wasPasswordChanged;
    } catch (error) {
      showToast(formatMessage('PASSWORD_CHANGE_ERROR'), 'danger');
      return false;
    }
  };

  const getPasswordLastChanged = () => {
    if (!userSpecLoading) {
      const event = new Date(userSpec.getUserSpec.passwordUpdatedAt);
      return event.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    return '';
  };

  const getInitialFormValues = () => {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    };
  };

  const submitForm = async (values) => {
    if (validatePassword(values.oldPassword, values.newPassword)) {
      const success = await updatePassword(values?.newPassword, values?.oldPassword);
      if (success) {
        setEditMode(false);
        userSpecRefetch();
      }
    }
  };

  const ChangePasswordButton = () => {
    return (
      <div>
        <TextButton
          testId={'change-password-button'}
          text={formatMessage('CHANGE_PASSWORD')}
          type="button"
          intent="primary"
          onClick={() => setEditMode(true)}
        />
      </div>
    );
  };

  const InformationSection = ({ title, value }) => {
    return (
      <div className={b('informationSection')} data-testid={'password-management-section'}>
        <div className={b('informationTitle')}>{title}</div>
        <div className={b('informationValue')} data-testid={'password-management-section-value'}>
          {value}
          {!userSpecLoading && !!userSpec.getUserSpec.passwordUpdatedAt && (
            <div className={b('passwordLastChangedText')} data-testid="password-last-changed">
              {formatMessage('PASSWORD_LAST_CHANGED', { date: getPasswordLastChanged() })}
            </div>
          )}
        </div>
      </div>
    );
  };

  const PasswordEntryRow = ({ label, testId, name }) => {
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    return (
      <div className={b('passwordRow')}>
        <label className={b('informationTitle')} data-testid={`${testId}-label`} htmlFor={name}>
          <div>{label}</div>
        </label>
        <div className={b('passwordInput')}>
          <Field
            name={name}
            type={passwordVisible ? 'text' : 'password'}
            component={FormTextInputGroup}
            data-testid={`${testId}-input`}
            icon={
              passwordVisible ? (
                <View onClick={() => setPasswordVisible(false)} />
              ) : (
                <ViewOff onClick={() => setPasswordVisible(true)} />
              )
            }
          />
        </div>
      </div>
    );
  };

  const PasswordEditForm = () => {
    return (
      <Formik initialValues={getInitialFormValues()} onSubmit={submitForm} enableReinitialization>
        {({ values }) => {
          return (
            <Form data-testid="password-change-form">
              <PasswordEntryRow
                label={formatMessage('CONFIRM_EXISTING_PASSWORD_LABEL')}
                testId={'old-password'}
                name={'oldPassword'}
              />
              <Divider />
              <div className={b('newPasswordContainer')}>
                <div className={b('passwordInstructions')}>{formatMessage('PASSWORD_INSTRUCTION')}</div>
                <PasswordEntryRow
                  label={formatMessage('NEW_PASSWORD_LABEL')}
                  testId={'new-password'}
                  name={'newPassword'}
                />
                <div className={b('passwordInstructions')}>
                  <div>{formatMessage('PASSWORD_STRENGTH')}</div>
                  {formatMessage('PASSWORD_STRENGTH_INSTRUCTION')}
                </div>
                <PasswordEntryRow
                  label={formatMessage('CONFIRM_NEW_PASSWORD_LABEL')}
                  testId={'confirm-new-password'}
                  name={'confirmNewPassword'}
                />
              </div>
              <div className={b('footer')}>
                <div className={b('cancelButtonContainer')}>
                  <TextButton
                    text={formatMessage('CANCEL')}
                    type="submit"
                    onClick={() => setEditMode(false)}
                    testId={'cancel-btn'}
                  />
                </div>
                <div>
                  <TextButton
                    testId={'change-password-submit-btn'}
                    text={formatMessage('CHANGE_PASSWORD')}
                    type="submit"
                    intent="primary"
                    disabled={
                      !validatePassword(values.oldPassword, values.newPassword) ||
                      values.confirmNewPassword !== values.newPassword
                    }
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Card className={b('')} data-testid="password-management-card">
      {!editMode && (
        <div data-testid="password-management-card-data">
          <InformationSection title={formatMessage('PASSWORD')} value={placeholderPasswordText} />
          <div className={b('changePasswordFooter')}>
            <ChangePasswordButton />
          </div>
        </div>
      )}
      {editMode && (
        <>
          <PasswordEditForm />
        </>
      )}
      {!userProfile && (
        <>
          <div data-testid="spinner">
            <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
          </div>
        </>
      )}
    </Card>
  );
};

export default PasswordManagementCard;
