import React, { useEffect, useMemo } from 'react';

import { Divider } from '@blueprintjs/core';
import { CalloutV2, Intent } from '@varicent/components';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import SelectMenu from 'components/SelectMenu/SelectMenu';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { AccountRedirectReallocationField } from 'app/graphql/generated/apolloTypes';
import { useGetAccountRuleBindingsForQuotaMoveLazy } from 'app/graphql/queries/getAccountRuleBindingsForQuotaMove';

import useShowToast from 'app/hooks/useShowToast';

import {
  AccountMoveOverlapRanges,
  AccountMoveVariables,
  QuotaReallocationPreviewItems,
  QuotaReallocationOptions
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountMoveWithQuotaDialog.module.pcss';
import {
  calculateRedirectDates,
  formatDateMonthDayYear,
  formatDateYearMonthDay,
  handleCalculatingPreviewQuotaReallocation,
  getRedirectDateAndTargetRuleOverlapPeriods,
  getPlanningCycleEndDate,
  calculateNewRedirectEndDate
} from './AccountQuotaUtils';
import { redirectAccountUnit } from './AccountSheetUtils';
import QuotaReallocationPreviewTable from './QuotaReallocationPreviewTable';
import TerritorySearchableSelect from './TerritorySearchableSelect';

const b = block(style);

interface AccountMoveWithQuotaDialogProps {
  accountMoveVariables: AccountMoveVariables;
  onClose: () => void;
  onMoveSuccess: () => void;
  currency: string;
}

const quotaReallocationItems = [
  { key: formatMessage('DONT_MOVE_QUOTA'), value: QuotaReallocationOptions.DONT_MOVE_QUOTA },
  { key: formatMessage('MOVE_PRORATED_QUOTA'), value: QuotaReallocationOptions.MOVE_PRORATED_QUOTA }
];

const AccountMoveWithQuotaDialog: React.FC<AccountMoveWithQuotaDialogProps> = ({
  accountMoveVariables,
  onClose,
  onMoveSuccess,
  currency
}) => {
  const { selectedQuotaComponentId } = useBattleCard();
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;

  const {
    accountId,
    sourceRuleId,
    redirects,
    accountName,
    sourceTerritoryId,
    targetTerritoryId,
    targetRuleId,
    sourceRule,
    territoryGroupTypeId,
    accountQuotaMeasureId,
    territoryQuotaAdjustmentMeasureId,
    effectiveDate,
    endDate
  } = accountMoveVariables;

  const [getRuleBindings, { data: accountRuleBindings, loading: accountRuleBidingsLoading }] =
    useGetAccountRuleBindingsForQuotaMoveLazy({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          territoryGroupTypeId,
          filters: JSON.stringify({
            ruleId: { filterType: 'number', type: 'equals', filter: sourceRuleId },
            accountId: { filterType: 'number', type: 'equals', filter: accountId }
          }),
          startRow: 1,
          endRow: 1
        }
      }
    });

  useEffect(() => {
    if (territoryGroupTypeId) {
      getRuleBindings();
    }
  }, [territoryGroupTypeId]);

  const measures = accountRuleBindings?.getAccountRuleBindings?.bindings[0]?.measures || [];

  const currentAccountValue = measures.find(
    (measure) => measure.quotaComponentId === selectedQuotaComponentId
  )?.measureValue;

  const minDate: Date = useMemo(() => {
    if (sourceRule.effectiveDate && dayjs(sourceRule.effectiveDate).isAfter(planningCycleStartDate)) {
      return dayjs(sourceRule.effectiveDate).toDate();
    } else {
      return dayjs(planningCycleStartDate).toDate();
    }
  }, [planningCycleStartDate]);

  const maxDate: Date = useMemo(() => {
    const endDate = dayjs(sourceRule.endDate);
    if (
      sourceRule.endDate &&
      endDate.isBefore(getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration))
    ) {
      return endDate.toDate();
    } else {
      return dayjs(getPlanningCycleEndDate(planningCycleStartDate, planningCycleDuration)).toDate();
    }
  }, [planningCycleStartDate]);

  const handleSubmit = async (value) => {
    try {
      const { territory, startDate, quotaReallocation } = value;

      const isRedirectWithQuotaMove = quotaReallocation.value === QuotaReallocationOptions.MOVE_PRORATED_QUOTA;
      // Account Quota for primaryFieldId and Territory Quota Adjustment for fieldId
      const reallocationField: AccountRedirectReallocationField = {
        primaryFieldId: accountQuotaMeasureId,
        fieldId: territoryQuotaAdjustmentMeasureId
      };

      const { newRedirects, redirectIdsToRemove } = calculateRedirectDates(
        formatDateYearMonthDay(startDate),
        redirects,
        dayjs(maxDate).format('YYYY-MM-DD'),
        territory.value,
        isRedirectWithQuotaMove ? reallocationField : undefined
      );

      await redirectAccountUnit({
        accountId,
        sourceRuleId,
        targets: newRedirects,
        redirectIdsToRemove
      });
      const targetText =
        targetTerritoryId !== formatMessage('UNASSIGNED_TERRITORY')
          ? targetTerritoryId
          : formatMessage('UNASSIGNED_LOWERCASE');
      const toastText = formatMessage('ACCOUNT_MOVE_DESCRIPTION_2', {
        accountName,
        sourceTerritoryId,
        targetText,
        startDate: formatDateMonthDayYear(value.startDate)
      });

      showToast(<ToastMessage title={formatMessage('ACCOUNT_MOVE_SCHEDULED')} message={toastText} />, 'success');
      onMoveSuccess();
    } catch (error) {
      showToast(formatMessage('CREATE_REDIRECT_ERROR'), 'danger');
    } finally {
      onClose();
    }
  };

  const existingRedirectStartDates = useMemo(() => {
    return redirects.map((redirect) => dayjs(redirect.startDate).toDate());
  }, [redirects]);

  const getToolTipMessage = (
    noOverlapRedirectDate: boolean,
    selectedTargetRuleId: number,
    selectedTargetTerritoryId: string
  ): string => {
    switch (true) {
      case sourceRuleId === selectedTargetRuleId || selectedTargetTerritoryId === formatMessage('UNASSIGNED_TERRITORY'):
        return formatMessage('MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_UNASSISGNED');

      case currentAccountValue === 0 || !currentAccountValue:
        return formatMessage('MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_ACCOUNT_VALUE');

      case noOverlapRedirectDate:
        return formatMessage('MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_OVERLAP_REDIRECT_DATE');

      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={{
        territory: {
          key: targetTerritoryId,
          value: targetRuleId,
          effectiveDate,
          endDate
        },
        startDate: '',
        quotaReallocation: quotaReallocationItems[0]
      }}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnChange={true}
    >
      {({ handleSubmit, values, isSubmitting, setFieldValue }) => {
        const selectedTargetRuleId = +values.territory.value;
        const selectedTargetTerritoryId = values.territory.key;
        const redirectStartDate = values.startDate;
        const redirectEndDate = calculateNewRedirectEndDate(redirectStartDate, sourceRule.endDate, redirects);
        const overlapRange: AccountMoveOverlapRanges[] = getRedirectDateAndTargetRuleOverlapPeriods(
          redirectStartDate,
          redirectEndDate,
          values.territory.effectiveDate,
          values.territory.endDate
        );
        const noOverlapRedirectDate = overlapRange.length === 0;
        const isDisabled =
          sourceRuleId === selectedTargetRuleId ||
          selectedTargetTerritoryId === formatMessage('UNASSIGNED_TERRITORY') ||
          currentAccountValue === 0 ||
          !currentAccountValue ||
          noOverlapRedirectDate;

        const isOriginalTerritory = sourceRuleId === selectedTargetRuleId;

        const previewQuotaReallocationMeasureValues: QuotaReallocationPreviewItems[] =
          handleCalculatingPreviewQuotaReallocation(measures, overlapRange);

        const getSelectedItems = () => {
          if (isDisabled) {
            // this is to ensure that when the field is disabled
            // the value is still set to the default move no quota dropdown item
            values.quotaReallocation = quotaReallocationItems[0];
            return quotaReallocationItems.filter((item) => item.value === QuotaReallocationOptions.DONT_MOVE_QUOTA);
          }
          return quotaReallocationItems.filter((item) => item.value === values.quotaReallocation.value);
        };

        return (
          <Dialog
            isOpen
            onClose={onClose}
            onSubmit={handleSubmit}
            disableConfirm={!values.startDate || !values.territory}
            confirmButtonText={formatMessage('ACCOUNT_MOVE_DIALOG_CONFIRM')}
            confirmButtonLoading={isSubmitting}
            bodyMinHeight={0}
            size="small"
            showOverflow={true}
            data-testid="account-move-dialog"
            title={formatMessage('SCHEDULE_ACCOUNT_MOVE')}
          >
            <Form>
              <div>
                <div className={b('dialogBody')}>
                  {formatMessage('ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_1')}
                  <span className={b('accountInfo')}>{`${accountName} (${sourceTerritoryId})`}</span>.
                  {formatMessage('ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_2')}
                </div>
                <div className={b('dropdownWrapper')}>
                  <div className={b('dropdownTitle')}>{formatMessage('TERRITORY_REQUIRED_MARK_V2')}</div>
                  <Field
                    name="territory"
                    component={TerritorySearchableSelect}
                    territoryGroupId={territoryGroupTypeId}
                    shouldIncludeUnassignItem
                    shouldReturnDates
                  />
                </div>
                <div className={b('dropdownWrapper')}>
                  <div className={b('dropdownTitle')}>{formatMessage('START_DATE_REQUIRED_MARK_QUOTA_MOVE')}</div>
                  <Field
                    name="startDate"
                    component={FormDatePicker}
                    showCalendarIcon
                    data-testid="form-date-picker"
                    showErrors={false}
                    fullWidth
                    placeholder={formatMessage('DATE_FORMAT_PLACEHOLDER')}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={existingRedirectStartDates}
                    setFieldValue={setFieldValue}
                  />
                </div>
                {isOriginalTerritory ? (
                  <CalloutV2 intent={Intent.PRIMARY}>
                    <div data-testid="quota-reallocation-message">
                      {formatMessage('MOVE_ACCOUNT_DIALOG__TEXT_FOR_ORIGINAL')}
                    </div>
                  </CalloutV2>
                ) : (
                  <div className={b('dropdownWrapper')}>
                    <div className={b('dropdownTitle')}>{formatMessage('QUOTA_REALLOCATION_MARK')}</div>
                    <Field
                      name="quotaReallocation"
                      component={SelectMenu}
                      data-testid="quota-reallocation-menu"
                      showErrors={false}
                      fullWidth
                      items={quotaReallocationItems}
                      placeHolderText={quotaReallocationItems[0].key}
                      disabled={isDisabled}
                      selectedItem={getSelectedItems()}
                      dropdownTooltipText={getToolTipMessage(
                        noOverlapRedirectDate,
                        selectedTargetRuleId,
                        selectedTargetTerritoryId
                      )}
                      theme="default"
                    />
                  </div>
                )}
              </div>
            </Form>
            <Divider className={b('divider')} />
            {redirectStartDate &&
              values.quotaReallocation.value === QuotaReallocationOptions.MOVE_PRORATED_QUOTA &&
              !accountRuleBidingsLoading && (
                <QuotaReallocationPreviewTable
                  measures={previewQuotaReallocationMeasureValues}
                  currency={currency}
                  data-testid="quota-reallocation-preview-table"
                />
              )}
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AccountMoveWithQuotaDialog;
