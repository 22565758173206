import { JobStatus } from 'app/models';

export const sortByStartTime = (items) => {
  return items.sort((a, b) => b.startDate - a.startDate);
};

export const sortByEndTime = (items) => {
  return items.sort((a, b) => b.endDate - a.endDate);
};

export const sortJobItems = (jobItems) => {
  const activeJobs = sortByStartTime(jobItems.filter((job) => job.isActive || job.status === JobStatus.PENDING));
  const failedJobs = sortByEndTime(jobItems.filter((job) => job.status === JobStatus.FAILED));
  const completedJobs = sortByEndTime(jobItems.filter((job) => job.status === JobStatus.COMPLETED));
  return [...activeJobs, ...failedJobs, ...completedJobs];
};

export const getJobItemDetail = (item) => {
  const metaData = JSON.parse(item.metadata || null);

  return {
    startTime: new Date(item.startDate * 1000),
    endTime: item.endDate ? new Date(item.endDate * 1000) : null,
    isInProgress: item.status === JobStatus.IN_PROGRESS || item.status === JobStatus.PENDING,
    metaData,
    actorFullName: metaData ? metaData.actorFirstName.concat(' ', metaData.actorLastName) : ''
  };
};
