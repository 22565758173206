import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { PlayFilledAlt, StopFilledAlt } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';

import { useScope } from 'app/contexts/scopeProvider';

import { ToggleTerritoryWorkflowStatus } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { TOGGLE_TERRITORY_WORKFLOW_STATUS } from 'app/graphql/mutations/toggleTerritoryWorkflowStatus';
import { usetGetTerritoryWorkflowStatus } from 'app/graphql/queries/getTerritoryWorkflowStatus';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

import StartWorkflowDialog from './StartWorkflowDialog/StartWorkflowDialog';
import StopWorkflowDialog from './StopWorkflowDialog/StopWorkflowDialog';

const ToggleTerritoryWorkflowButton: React.FC = () => {
  const [isTerritoryWorkflowStarted, setIsTerritoryWorkflowStarted] = useState<boolean>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const showToast = useShowToast();
  const { selectedDeploymentModelId } = useScope();

  const { loading: getTerritoryWorkflowStatusLoading, error: getTerritoryWorkflowStatusError } =
    usetGetTerritoryWorkflowStatus({
      skip: !selectedDeploymentModelId,
      fetchPolicy: 'network-only',
      variables: {
        deploymentModelId: selectedDeploymentModelId
      },
      onCompleted({ getDeploymentModelSpec }) {
        setIsTerritoryWorkflowStarted(getDeploymentModelSpec?.isTerritoryWorkflowStarted === 'true');
      },
      onError({ graphQLErrors, networkError }) {
        showToast(formatMessage('RETRIEVE_TERRITORY_WORKFLOW_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    });

  const [toggleTerritoryWorkflowStatusMutation, { loading: toggleTerritoryWorkflowStatusLoading }] =
    useMutation<ToggleTerritoryWorkflowStatus>(TOGGLE_TERRITORY_WORKFLOW_STATUS, {
      variables: {
        deploymentModelId: selectedDeploymentModelId
      },
      onCompleted({ toggleTerritoryWorkflowStatus }) {
        setIsTerritoryWorkflowStarted(toggleTerritoryWorkflowStatus?.isTerritoryWorkflowStarted === 'Y');
      },
      onError({ graphQLErrors, networkError }) {
        if (isTerritoryWorkflowStarted) {
          showToast(formatMessage('STOP_TERRITORY_WORKFLOW_ERROR'), 'danger');
        } else {
          showToast(formatMessage('START_TERRITORY_WORKFLOW_ERROR'), 'danger');
        }
        handleError(graphQLErrors, networkError);
      }
    });

  const handleToggleTerritoryWorkflowStatusClick = () => {
    toggleTerritoryWorkflowStatusMutation();
    setShowConfirmationDialog(false);
  };

  const isLoading = getTerritoryWorkflowStatusLoading || toggleTerritoryWorkflowStatusLoading;
  const activeButtonText = !isTerritoryWorkflowStarted
    ? formatMessage('START_WORKFLOW')
    : formatMessage('STOP_WORKFLOW');
  const loadingWithStatusButtonText = !isTerritoryWorkflowStarted
    ? formatMessage('STARTING')
    : formatMessage('STOPPING');
  const loadingButtonText = getTerritoryWorkflowStatusLoading ? formatMessage('LOADING') : loadingWithStatusButtonText;
  const buttonText = isLoading ? loadingButtonText : activeButtonText;
  const buttonIntent = !isTerritoryWorkflowStarted ? Intent.PRIMARY : Intent.DANGER;
  const buttonIcon = !isTerritoryWorkflowStarted ? <PlayFilledAlt /> : <StopFilledAlt />;

  return (
    <>
      <TextButton
        text={buttonText}
        intent={isLoading ? undefined : buttonIntent}
        icon={isLoading ? <Spinner intent={Intent.PRIMARY} size={SpinnerSize.SMALL} /> : buttonIcon}
        disabled={isLoading || !!getTerritoryWorkflowStatusError}
        minimal
        onClick={() => setShowConfirmationDialog(true)}
        type="button"
        testId="toggle-territory-workflow-button"
      />
      {showConfirmationDialog &&
        (!isTerritoryWorkflowStarted ? (
          <StartWorkflowDialog
            onSubmit={() => handleToggleTerritoryWorkflowStatusClick()}
            onClose={() => setShowConfirmationDialog(false)}
          />
        ) : (
          <StopWorkflowDialog
            onSubmit={() => handleToggleTerritoryWorkflowStatusClick()}
            onClose={() => setShowConfirmationDialog(false)}
          />
        ))}
    </>
  );
};

export default ToggleTerritoryWorkflowButton;
