import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import {
  UpsertBattlecardHierarchyTops,
  UpsertBattlecardHierarchyTopsVariables
} from 'app/graphql/generated/apolloTypes';

export const UPSERT_BATTLECARD_HIERARCHY_TOPS = gql`
  mutation UpsertBattlecardHierarchyTops($battlecardId: Int!, $hierarchyUpsertInput: [BattlecardHierarchyUpsert]!) {
    upsertBattlecardHierarchyTops(input: { battlecardId: $battlecardId, hierarchyUpsertInput: $hierarchyUpsertInput }) {
      battlecardId
      hierarchyAlias
      hierarchyName
      hierarchyTopId
      hierarchyTopName
      hierarchyType
      precedence
    }
  }
`;

export const useUpsertBattleCardHierarchyTops = createUseMutation<
  UpsertBattlecardHierarchyTops,
  UpsertBattlecardHierarchyTopsVariables
>(UPSERT_BATTLECARD_HIERARCHY_TOPS);
