import React from 'react';

import { Field } from 'formik';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useScope } from 'app/contexts/scopeProvider';

import { GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ScenarioNameField.module.pcss';

const b = block(style);

interface ScenarioNameFieldProps {
  currentLength: number;
  planDms: Pick<
    GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
    'deploymentModelId' | 'deploymentModelName'
  >[];
  fieldName: string;
}

export const MAX_NAME_LENGTH = 64;

const ScenarioNameField: React.FC<ScenarioNameFieldProps> = ({ planDms, currentLength, fieldName }) => {
  const { selectedDeploymentModelId } = useScope();

  const validateName = (proposedName: string) => {
    if (
      planDms.some(
        (dm) => dm.deploymentModelName === proposedName && dm.deploymentModelId !== selectedDeploymentModelId
      )
    ) {
      return formatMessage('SCENARIO_NAME_EXISTS', { name: proposedName });
    }
    return undefined;
  };
  return (
    <Field
      label={
        <div className={b('fieldLabel')}>
          <div>{formatMessage('NAME_REQUIRED')}</div>
          <div data-testid="character-count-label">
            {formatMessage('CHARACTER_COUNT_READOUT', {
              currentLength,
              maxLength: MAX_NAME_LENGTH
            })}
          </div>
        </div>
      }
      name={fieldName}
      type="text"
      component={FormTextInputGroup}
      validate={validateName}
    />
  );
};

export default ScenarioNameField;
