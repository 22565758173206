import { useState, useEffect } from 'react';

import { SYMON_PIPE_POLL_INTERVAL } from 'app/global/variables';

import { GetSymonPipeConfiguration } from 'app/graphql/generated/apolloTypes';
import { useGetSymonPipeConfiguration } from 'app/graphql/queries/getSymonPipeConfiguration';

interface UseGetSymonPipeCofigurationResult {
  symonPipeConfig: GetSymonPipeConfiguration;
  symonPipeConfigLoading: boolean;
}

export const useGetSymonPipeConfigurationData = (
  symonPipeConfigId: number | null
): UseGetSymonPipeCofigurationResult => {
  const [symonPipeConfig, setSymonPipeConfig] = useState<GetSymonPipeConfiguration>();
  const { data, loading, stopPolling } = useGetSymonPipeConfiguration({
    variables: { symonPipeConfigurationId: symonPipeConfigId },
    skip: !symonPipeConfigId,
    fetchPolicy: 'network-only',
    pollInterval: SYMON_PIPE_POLL_INTERVAL,
    onError() {
      stopPolling();
    }
  });

  useEffect(() => {
    if (!loading && data) {
      if (data.getSymonPipeConfiguration?.mapping) {
        setSymonPipeConfig(data);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (data?.getSymonPipeConfiguration?.status === 'completed') {
      stopPolling();
      setSymonPipeConfig(data);
    }
    if (data?.getSymonPipeConfiguration?.status === 'failed') {
      stopPolling();
      setSymonPipeConfig(data);
    }
  }, [data]);

  return { symonPipeConfig, symonPipeConfigLoading: loading };
};
