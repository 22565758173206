import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';

import DataMappingSelectMenu from 'app/components/DataMappingSelectMenu/DataMappingSelectMenu';

import { useData } from 'app/contexts/dataProvider';

import {
  FileTypeEnum,
  GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping
} from 'app/graphql/generated/apolloTypes';

import { DataMappingSelectMenuItem } from 'app/models';

interface ColumnMappingCellRendererProps extends ICellRendererParams {
  params: ICellRendererParams;
  symonPipeConfigMapping: GetSymonPipeConfiguration_getSymonPipeConfiguration_mapping[];
  items: DataMappingSelectMenuItem[];
  fileType: FileTypeEnum;
}

export const ColumnMappingCellRenderer: React.FC<ColumnMappingCellRendererProps> = ({
  params,
  symonPipeConfigMapping,
  items,
  fileType
}: ColumnMappingCellRendererProps) => {
  const { value, setValue } = params;
  const { mappedFields, setMappedFields, shouldShowMappingError } = useData();

  if (value.key === null && symonPipeConfigMapping) {
    const fieldName = params.colDef?.field;
    const savedMapping = symonPipeConfigMapping?.find((field) => field.fieldName === fieldName);
    if (savedMapping) {
      const matchingMapping = items.find((item) => item.key === savedMapping?.value.id);
      const defaultValue = matchingMapping;
      defaultValue.autoMatched = false;

      setValue(defaultValue);
      mappedFields.push(defaultValue);
    }
  }

  const isMappedField = Boolean(
    mappedFields.find((mappedField) => mappedField.key === value.key && mappedField.autoMatched)
  );

  const newValue = isMappedField ? { ...value, autoMatched: true } : value;
  const shouldShowExcludeColumn =
    fileType !== FileTypeEnum.CustomerAccountHierarchy && fileType !== FileTypeEnum.CustomHierarchy;
  return (
    <DataMappingSelectMenu
      items={items}
      minimal
      disabledItems={mappedFields}
      supportReset
      defaultSelection={newValue}
      setValue={setValue}
      setDisabledItems={setMappedFields}
      showLeftIcon
      shouldShowMappingError={shouldShowMappingError}
      supportMultiSections
      shouldShowExcludeColumn={shouldShowExcludeColumn}
    />
  );
};

export default ColumnMappingCellRenderer;
