import { Dispatch, SetStateAction } from 'react';

import { MutationTuple, useMutation } from '@apollo/client';

import { DeleteHierarchyById, DeleteHierarchyByIdVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_HIERARCHY_BY_ID } from 'app/graphql/mutations/deleteHierarchyById';

import useShowToast from 'app/hooks/useShowToast';

import { CommandCenterDrawerState, HierarchySpec } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface UseDeleteHierarchyProps {
  selectedHierarchy: HierarchySpec;
  setCommandCenterDrawerState: Dispatch<SetStateAction<CommandCenterDrawerState>>;
  setShowDeleteHierarchyDialog: (value: boolean) => void;
  setSelectedHierarchy: (value) => void;
}

export const useDeleteHierarchy = ({
  selectedHierarchy,
  setCommandCenterDrawerState,
  setShowDeleteHierarchyDialog,
  setSelectedHierarchy
}: UseDeleteHierarchyProps): MutationTuple<DeleteHierarchyById, DeleteHierarchyByIdVariables> => {
  const showToast = useShowToast();
  return useMutation<DeleteHierarchyById, DeleteHierarchyByIdVariables>(DELETE_HIERARCHY_BY_ID, {
    onCompleted() {
      setShowDeleteHierarchyDialog(false);
      showToast(formatMessage('DELETE_HIERARCHY_SUCCESS', { name: selectedHierarchy?.rootName }), 'success');

      setCommandCenterDrawerState(CommandCenterDrawerState.OPEN);
      setSelectedHierarchy(null);
    },
    onError({ graphQLErrors, networkError }) {
      setShowDeleteHierarchyDialog(false);
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('DELETE_HIERARCHY_ERROR', { name: selectedHierarchy?.rootName }), 'danger');
    }
  });
};
