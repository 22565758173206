import React from 'react';

import { Intent } from '@blueprintjs/core';

import Dialog from 'components/Dialog/Dialog';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import { TargetRuleComponent } from 'app/graphql/generated/apolloTypes';
import { useUpsertTerritoryRule } from 'app/graphql/hooks/useUpsertTerritoryRule';
import { useUnassignRuleHierarchies } from 'app/graphql/mutations/unassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { FixConflictDetails, MapRuleUpsert } from 'app/models';

import { formatListSummary, formatMessage } from 'utils/messages/utils';

interface FixConflictDialogProps {
  generatedRule: MapRuleUpsert;
  hierarchyNamesBeingRemoved: string[];
  onTerritoryUpdated: () => void;
  onClose: () => void;
  fixConflictDetails: FixConflictDetails;
}

const NAME_PREVIEW_COUNT = 6;

const FixConflictDialog: React.FC<FixConflictDialogProps> = ({
  generatedRule,
  onClose,
  hierarchyNamesBeingRemoved,
  onTerritoryUpdated,
  fixConflictDetails
}: FixConflictDialogProps) => {
  const { selectedTerritoryGroupId } = useMapContextRedistributor();
  const [isCustomHierEditEnabled] = useTreatment(SplitFeatures.MAP_EDIT_CUSTOM_HIER_RULES);
  const [unassignRuleHierarchies, { loading: loadingUnassignRuleHierarchies }] = useUnassignRuleHierarchies();
  const showToast = useShowToast();

  const removedCount = isCustomHierEditEnabled
    ? fixConflictDetails?.hierarchyIdsBeingRemoved.length
    : hierarchyNamesBeingRemoved?.length;
  const territoryIdToFixConflict = isCustomHierEditEnabled
    ? fixConflictDetails?.territoryId
    : generatedRule?.territoryId;
  const ruleIdForFixConflict = isCustomHierEditEnabled ? fixConflictDetails?.ruleId : generatedRule?.ruleId;
  const hierarchyNamesBeingRemovedToFixConflict = isCustomHierEditEnabled
    ? fixConflictDetails?.hierarchyNamesBeingRemoved
    : hierarchyNamesBeingRemoved;

  const successToastMessage = formatMessage(removedCount > 1 ? 'REMOVE_CONFLICTS' : 'REMOVE_CONFLICT', {
    count: removedCount,
    ruleId: ruleIdForFixConflict
  });

  const [upsertTerritoryRule, { loading }] = useUpsertTerritoryRule(
    successToastMessage,
    formatMessage('UPDATE_TERRITORY_ERROR')
  );

  const removeConflictFromTerritory = async () => {
    await upsertTerritoryRule({ variables: generatedRule });
    onTerritoryUpdated();
    onClose();
  };

  const unassignRuleHierarchiesToFixConflict = async () => {
    unassignRuleHierarchies({
      variables: {
        input: {
          scopingTerritoryGroupId: selectedTerritoryGroupId,
          sourceHierarchyIds: fixConflictDetails?.hierarchyIdsBeingRemoved,
          ruleIdsFilter: [ruleIdForFixConflict],
          targetRuleComponent: TargetRuleComponent.Dimension
        }
      },
      onCompleted: () => {
        showToast(successToastMessage, 'success');
        onTerritoryUpdated();
        onClose();
      },
      onError: (error) => {
        showToast(formatMessage('UPDATE_TERRITORY_ERROR'), 'danger');
        throw error;
      }
    });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onSubmit={() =>
        isCustomHierEditEnabled ? unassignRuleHierarchiesToFixConflict() : removeConflictFromTerritory()
      }
      confirmButtonText={formatMessage('REMOVE_CONFLICT_BUTTON')}
      confirmButtonIntent={Intent.DANGER}
      title={formatMessage('REMOVE_CONFLICT_DIALOG_TITLE')}
      bodyMinHeight={0}
      confirmButtonLoading={loading || loadingUnassignRuleHierarchies}
      size="small"
      children={
        <div data-testid="fix-conflict-dialog">
          {formatMessage('REMOVE_CONFLICT_DIALOG_TEXT', { territory: territoryIdToFixConflict })}
          <div>{formatListSummary(hierarchyNamesBeingRemovedToFixConflict, NAME_PREVIEW_COUNT)}</div>
        </div>
      }
    />
  );
};

export default FixConflictDialog;
