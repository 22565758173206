import React, { useRef, useState, useMemo, FC } from 'react';

import { ColDef, GridApi } from '@ag-grid-community/core';
import { CollapseAll, ExpandAll, Save } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import PermissionCheckboxCellRenderer, {
  HIDDEN_EDIT_FUNCTIONALITY_KEYS
} from 'app/components/AdvancedGrid/CellRenderers/RolePermissionCheckboxCellRenderer/PermissionCheckboxCellRenderer';
import RolePermissionColumnHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/RolePermissionHeaderCellRenderer/RolePermissionColumnHeaderCellRenderer';
import AddUserRoleStatusBar from 'app/components/DataPanel/SheetsPanel/SheetDetail/AddUserRoleStatusBar/AddUserRoleStatusBar';

import { usePermissions } from 'app/contexts/permissionsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { useUser } from 'app/core/userManagement/userProvider';

import { DeploymentModelTypeEnum, UpsertRolePermissionInput } from 'app/graphql/generated/apolloTypes';
import { useUpsertRolePermissions } from 'app/graphql/mutations/upsertRolePermissions';
import { GET_PERMISSIONS } from 'app/graphql/queries/getPermissions';

import useShowToast from 'app/hooks/useShowToast';

import { UserRoleType, Permission, PermissionPerType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { PermissionActions, PermissionFunctionality, getPermissionsByRole } from 'utils/permissions/userActions';

import style from './RolePermissionPanel.module.pcss';

const b = block(style);

const PLAN_DEPLOYMENT_MODEL_FUNCTIONALITY_LIST: { key: PermissionFunctionality; message: string }[] = [
  {
    key: PermissionFunctionality.MAPS,
    message: formatMessage('MAPS')
  },
  {
    key: PermissionFunctionality.SELLER_ASSIGNMENT,
    message: formatMessage('SELLER_ASSIGNMENT')
  },
  {
    key: PermissionFunctionality.TERRITORY_DEFINITION_AND_ASSIGNMENT,
    message: formatMessage('TERRITORY_DEFINE_REFINE')
  },
  {
    key: PermissionFunctionality.TERRITORY_REBALANCING,
    message: formatMessage('TERRITORY_BALANCING')
  }
];

const MANAGE_DEPLOYMENT_MODEL_FUNCTIONALITY_LIST: { key: PermissionFunctionality; message: string }[] = [
  {
    key: PermissionFunctionality.ACCOUNT_QUOTA,
    message: formatMessage('ACCOUNT_QUOTA')
  },
  {
    key: PermissionFunctionality.TERRITORY_QUOTA,
    message: formatMessage('TERRITORY_QUOTA')
  },
  {
    key: PermissionFunctionality.SELLER_QUOTA,
    message: formatMessage('SELLER_QUOTA')
  }
];

const getDeploymentModelTypeForFunctionality = (functionalityKey: PermissionFunctionality): DeploymentModelTypeEnum => {
  if (MANAGE_DEPLOYMENT_MODEL_FUNCTIONALITY_LIST.some((item) => item.key === functionalityKey)) {
    return DeploymentModelTypeEnum.Manage;
  }
  return DeploymentModelTypeEnum.Plan;
};

const contributorRoles = {
  [UserRoleType.BATTLE_CARD_OWNER]: formatMessage('BATTLE_CARD_OWNER'),
  [UserRoleType.TERRITORY_GROUP_OWNER]: formatMessage('TERRITORY_GROUP_AND_GROUP_TYPE_OWNER')
};
const administratorRoles = { [UserRoleType.ADMIN]: formatMessage('ADMINISTRATOR') };

const buildColumnDefs = (checkboxRenderer: (params) => JSX.Element): ColDef[] => {
  const columnDefs: ColDef[] = [];

  const cellStyle = {
    display: 'flex',
    justifyContent: 'center',
    'background-color': 'rgb(var(--light-blue-translucent))',
    border: 'none'
  };

  columnDefs.push({
    headerName: formatMessage('PERMISSIONS'),
    field: 'permissions',
    flex: 2,
    minWidth: 200,
    cellStyle,

    headerComponentFramework: RolePermissionColumnHeaderCellRenderer,
    cellRendererFramework: checkboxRenderer,
    cellRendererSelector: (params) => {
      return {
        frameworkComponent: checkboxRenderer,
        params: {
          params
        }
      };
    }
  });

  return columnDefs;
};

const findPermissionInData = (permissions: Permission[], functionalityKey: string): PermissionPerType => {
  const permissionsForFunctionality = { view: false, edit: false };

  [PermissionActions.VIEW, PermissionActions.EDIT].forEach((permissionType) => {
    const actionName = `${permissionType}:${functionalityKey}`;

    const permission = permissions.find((i) => i.actionName === actionName);

    if (permission) permissionsForFunctionality[permissionType] = true;
  });

  return permissionsForFunctionality;
};

const buildRowTreeData = (selectedRole: UserRoleType, permissions: Permission[], includeManageSection: boolean) => {
  const treeData = new Array<{
    hierarchy: string[];
    functionality: string;
    functionalityKey: PermissionFunctionality;
    permissions: {
      view: boolean;
      edit: boolean;
    };
  }>();
  const rolePermissions = getPermissionsByRole(permissions, selectedRole);

  const planHierarchyRootMessage = formatMessage('PLAN');
  const planHierarchyRootKey = PermissionFunctionality.PLAN;
  const planPermissions = { view: true, edit: true };
  for (const functionality of PLAN_DEPLOYMENT_MODEL_FUNCTIONALITY_LIST) {
    const permissionValues = findPermissionInData(rolePermissions, functionality.key);

    planPermissions.view = planPermissions.view && permissionValues.view;
    if (!HIDDEN_EDIT_FUNCTIONALITY_KEYS.includes(functionality.key)) {
      planPermissions.edit = planPermissions.edit && permissionValues.edit;
    }

    treeData.push({
      hierarchy: [planHierarchyRootMessage, functionality.message],
      functionality: functionality.message,
      functionalityKey: functionality.key,
      permissions: {
        view: permissionValues.view,
        edit: permissionValues.edit
      }
    });
  }
  treeData.push({
    hierarchy: [planHierarchyRootMessage],
    functionality: planHierarchyRootMessage,
    functionalityKey: planHierarchyRootKey,
    permissions: {
      view: planPermissions.view,
      edit: planPermissions.edit
    }
  });

  if (includeManageSection) {
    const manageHierarchyRootMessage = formatMessage('MANAGE');
    const manageHierarchyRootKey = PermissionFunctionality.MANAGE;
    const managePermissions = { view: true, edit: true };
    for (const functionality of MANAGE_DEPLOYMENT_MODEL_FUNCTIONALITY_LIST) {
      const permissionValues = findPermissionInData(rolePermissions, functionality.key);

      managePermissions.edit = managePermissions.edit && permissionValues.edit;
      managePermissions.view = managePermissions.view && permissionValues.view;

      treeData.push({
        hierarchy: [manageHierarchyRootMessage, functionality.message],
        functionality: functionality.message,
        functionalityKey: functionality.key,
        permissions: {
          view: permissionValues.view,
          edit: permissionValues.edit
        }
      });
    }
    treeData.push({
      hierarchy: [manageHierarchyRootMessage],
      functionality: manageHierarchyRootMessage,
      functionalityKey: manageHierarchyRootKey,
      permissions: {
        view: managePermissions.view,
        edit: managePermissions.edit
      }
    });
  }

  return treeData;
};

const RolePermissionsPanel: FC = () => {
  const { selectedPlanningCycle } = useScope();
  const { userPlanningCycles } = useUser();
  const { permissions, loadingPermissions } = usePermissions();
  const showToast = useShowToast();

  const gridContainerRef = useRef(null);

  const [selectedRole, setSelectedRole] = useState<UserRoleType>(UserRoleType.BATTLE_CARD_OWNER);
  const [rolePermissionsGridApi, setRolePermissionsGridApi] = useState<GridApi>(null);

  const [upsertRolePermissions, { loading: permissionsUpserting }] = useUpsertRolePermissions({
    fetchPolicy: 'network-only',
    onCompleted() {
      showToast(formatMessage('ROLE_PERMISSIONS_UPSERT_SUCCESS', { role: contributorRoles[selectedRole] }), 'success');
    },
    onError() {
      showToast(formatMessage('ROLE_PERMISSIONS_UPSERT_ERROR'), 'danger');
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_PERMISSIONS]
  });

  const dmsByType = useMemo(() => {
    const output = {
      [DeploymentModelTypeEnum.Plan]: new Array<number>(),
      [DeploymentModelTypeEnum.Manage]: new Array<number>()
    };
    const currentPlanningCycle = userPlanningCycles.find(
      (planningCycle) => planningCycle.planningCycleId === selectedPlanningCycle.id
    );
    if (!currentPlanningCycle) return output;

    for (const dm of currentPlanningCycle.deploymentModels) {
      output[dm.deploymentModelType].push(dm.deploymentModelId);
    }
    return output;
  }, [userPlanningCycles, selectedPlanningCycle]);

  const hasManageDm = dmsByType[DeploymentModelTypeEnum.Manage].length > 0;
  const gridData = useMemo(
    () => JSON.stringify(buildRowTreeData(selectedRole, permissions, hasManageDm)),
    [selectedRole, permissions]
  );

  const handleCollapseButtonClicked = () => {
    rolePermissionsGridApi.forEachNode((node) => {
      rolePermissionsGridApi.setRowNodeExpanded(node, false);
    });
  };

  const transformGridDataIntoMutationInput = () => {
    const mutationInput: UpsertRolePermissionInput = {
      roleName: selectedRole,
      planningCycleId: selectedPlanningCycle.id,
      actions: []
    };

    rolePermissionsGridApi.forEachNode((node) => {
      const functionalityKey: PermissionFunctionality = node.data.functionalityKey;
      if (!functionalityKey) return;
      if (functionalityKey === PermissionFunctionality.PLAN) return;
      if (functionalityKey === PermissionFunctionality.MANAGE) return;

      [PermissionActions.VIEW, PermissionActions.EDIT].forEach((permissionType) => {
        if (!node.data.permissions?.[permissionType]) return;
        if (permissionType === PermissionActions.EDIT && HIDDEN_EDIT_FUNCTIONALITY_KEYS.includes(functionalityKey))
          return;
        const deploymentModelType = getDeploymentModelTypeForFunctionality(functionalityKey);
        for (const deploymentModelId of dmsByType[deploymentModelType]) {
          mutationInput.actions.push({
            actionName: `${permissionType}:${functionalityKey}`,
            deploymentModelId
          });
        }
      });
    });

    return mutationInput;
  };

  const handleSaveButtonClicked = () => {
    upsertRolePermissions({
      variables: {
        input: transformGridDataIntoMutationInput()
      }
    });
  };

  const handleExpandButtonClicked = () => {
    rolePermissionsGridApi.forEachNode((node) => {
      rolePermissionsGridApi.setRowNodeExpanded(node, true);
    });
  };

  const onRolePermissionsGridReady = (params) => {
    setRolePermissionsGridApi(params?.api);
  };

  const isLoading = loadingPermissions || permissionsUpserting;

  return (
    <div className={b('rolePermissionPanel')} data-testid="role-permission-panel">
      <div className={b('roleList')}>
        <h3 className={b('headingText')} data-testid="role-list-heading">
          {formatMessage('ROLES')}
        </h3>
        <div className={b('roleListBody')}>
          <div className={b('rolePillContainer')}>
            {Object.entries(administratorRoles).map(([roleKey, roleMessage]) => {
              return (
                <MessageTooltip
                  content={formatMessage('ADMINISTRATOR_ACCESS_DESCRIPTION')}
                  target={<div className={b('disabledPill')}>{roleMessage}</div>}
                  key={roleKey}
                  placement={'right'}
                />
              );
            })}
          </div>
          <div className={b('rolePillContainer')}>
            <h4 data-testid="contributor-role-heading" className={b('contributorRoleHeading')}>
              {formatMessage('CONTRIBUTOR_ROLES')}
            </h4>
            {Object.entries(contributorRoles).map(([roleKey, roleMessage]) => {
              return (
                <div
                  className={b('rolePill', { isSelected: selectedRole === roleKey })}
                  onClick={() => setSelectedRole(roleKey as UserRoleType)}
                  key={roleKey}
                  data-testid={`role-pill-${roleKey}`}
                >
                  {roleMessage}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={b('rolePermissionDetail')} data-testid="role-permission-detail">
        <div className={b('selectedRoleHeading')}>
          <h3 className={b('headingText')} data-testid="selected-role-heading-text">
            {contributorRoles[selectedRole]}
          </h3>
          <div className={b('buttons')}>
            <TextButton
              type="submit"
              text={formatMessage('SAVE')}
              testId="role-permissions-save-button"
              className={b('saveButton')}
              intent="primary"
              icon={<Save />}
              onClick={handleSaveButtonClicked}
              loading={isLoading}
            />
            <DividerV2 vertical className={b('buttonDivider')} />
            <MessageTooltip
              content={formatMessage('COLLAPSE_ALL')}
              target={
                <IconButton
                  type="button"
                  icon={<CollapseAll />}
                  testId="role-permissions-collapse-all-button"
                  onClick={handleCollapseButtonClicked}
                />
              }
              className={b('permissionOptions')}
              placement={'top'}
            />
            <MessageTooltip
              content={formatMessage('EXPAND_ALL')}
              target={
                <IconButton
                  type="button"
                  icon={<ExpandAll />}
                  testId="role-permissions-expand-all-button"
                  onClick={handleExpandButtonClicked}
                />
              }
              className={b('permissionOptions')}
              placement={'top'}
            />
          </div>
        </div>
        <DividerV2 />
        <div className={b('rolePermissionTableGroup')}>
          <div className={b('rolePermissionTable')} ref={gridContainerRef}>
            <AdvancedGrid
              data={gridData}
              columnDefs={buildColumnDefs(PermissionCheckboxCellRenderer)}
              frameworkComponents={{
                statusBarComponent: AddUserRoleStatusBar
              }}
              gridProps={{
                rowSelection: 'multiple',
                suppressRowClickSelection: true,
                treeData: true,
                groupDefaultExpanded: 1
              }}
              getDataPath={(data) => data.hierarchy}
              autoGroupColumnDef={{
                headerName: formatMessage('FUNCTIONALITY'),
                flex: 2,
                minWidth: 200,
                cellRendererParams: { suppressCount: true }
              }}
              data-testid="role-permissions-table"
              gridWidth={gridContainerRef?.current?.offsetWidth}
              gridHeight={gridContainerRef?.current?.offsetHeight}
              showGridLoading={isLoading}
              onGridReady={onRolePermissionsGridReady}
            />
          </div>
          <div className={b('rolePermissionTableFooter')}>
            {formatMessage('ROLE_PERMISSIONS_TABLE_QUOTA_SHEET_INFO')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolePermissionsPanel;
