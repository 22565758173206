import { useCallback } from 'react';

import { useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const useSelectionNotificationToasts = (): void => {
  const onSelectionNotification = useCallback((message) => {
    switch (message.notificationKind) {
      case 'empty-rule':
        // eslint-disable-next-line deprecation/deprecation
        showToast(
          formatMessage('NO_TERRITORY_RULES', { territories: message.details.territoryNames.join(', ') }),
          'primary',
          'top-right'
        );
        break;
    }
  }, []);
  useOnMapWorkerMessage(onSelectionNotification);
};
