import React, { useEffect, useState } from 'react';

import { GridApi, GridOptions } from '@ag-grid-community/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import ReferenceCheckboxBattleCardsCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ReferenceCheckboxBattleCardsCellRenderer/ReferenceCheckboxBattleCardsCellRenderer';
import ReferenceCheckboxQuotaComponentsCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ReferenceCheckboxQuotaComponentsCellRenderer/ReferenceCheckboxQuotaComponentsCellRenderer';
import QuotaSheetHeader from 'app/components/AdvancedGrid/Sheets/Quota/QuotaSheetHeader/QuotaSheetHeader';
import AddUserRoleStatusBar from 'app/components/DataPanel/SheetsPanel/SheetDetail/AddUserRoleStatusBar/AddUserRoleStatusBar';
import buildBattleCardReferencedColumnDef from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildBattleCardReferencedColumnDef/buildBattleCardReferencedColumnDef';
import { buildBattleCardReferencedTreeData } from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildBattleCardReferencedTreeData';
import buildPermissionsColumnDef from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildPermissionsColumnDef/buildPermissionsColumnDef';
import buildQuotaComponentReferencedColumnDef from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildQuotaComponentReferencedColumnDef';
import buildQuotaSheetColumnDef from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildQuotaSheetColumnDef/buildQuotaSheetColumnDef';
import {
  buildSheetsPreviewBottomRowData,
  buildSheetsPreviewColumnData
} from 'app/components/DataPanel/SheetsPanel/SheetDetail/buildSheetsPreviewColumnsData';
import SheetsAddFieldButton from 'app/components/DataPanel/SheetsPanel/SheetDetail/SheetsAddFieldButton/SheetsAddFieldButton';
import SheetGridHeader from 'app/components/DataPanel/SheetsPanel/SheetGridHeader/SheetGridHeader';
import SheetsAddFieldDrawer from 'app/components/SheetsAddFieldDrawer/SheetsAddFieldDrawer';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  GetAllBattleCards,
  GetAllBattleCardsVariables,
  UpsertSheet,
  UpsertSheetVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_SHEET } from 'app/graphql/mutations/upsertSheet';
import { GET_ALL_BATTLE_CARDS } from 'app/graphql/queries/getAllBattleCards';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './QuotaSheetDetail.module.pcss';

const b = block(style);

const DEFAULT_MEASURE_ITEM_TOTALS = 6;

const getRowData = (gridApi: GridApi): React.ReactNode[] => {
  const rowData = [];
  if (gridApi) {
    gridApi.forEachNode((node) => rowData.push(node.data));
  }
  return rowData;
};

const QuotaSheetDetail: React.FC = () => {
  const { selectedDeploymentModelId } = useScope();
  const {
    getSheetDefinitions,
    sheetDefinitions,
    sheetDefinitionsLoading,
    permissionGridData,
    dataSheetDrawerState,
    selectedSheet
  } = useData();
  const sheetReferenceGridContainerRef = React.useRef(null);
  const permissionGridContainerRef = React.useRef(null);
  const quotaSheetGridContainerRef = React.useRef(null);

  const [battleCardGridApi, setBattleCardGridApi] = useState<GridApi>(null);
  const [quotaComponentsGridApi, setQuotaComponentsGridApi] = useState<GridApi>(null);
  const [permissionGridApi, setPermissionGridApi] = useState<GridApi>(null);
  const [quotaSheetGridApi, setQuotaSheetGridApi] = useState<GridApi>(null);
  const [quotaSheetShowTotals, setQuotaSheetShowTotals] = useState<boolean>(true);

  const showToast = useShowToast();

  const [upsertSheet, { loading: upsertSheetLoading }] = useMutation<UpsertSheet, UpsertSheetVariables>(UPSERT_SHEET, {
    onCompleted() {
      showToast(formatMessage('COLUMN_REORDER_SUCCESS'), 'success');
      getSheetDefinitions(selectedDeploymentModelId, selectedSheet?.sheetId);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('COLUMN_REORDER_FAILED'), 'danger');
    }
  });

  const battleCardReferencedGridProps = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    treeData: true
  };

  const quotaComponentReferencedGridProps: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true
  };

  const getDataPath = (data) => {
    return data.hierarchy;
  };

  const sortSheetDefinitions = (sheetDefs, indices) =>
    sheetDefs.sort((a, b) => indices.indexOf(sheetDefs.indexOf(a)) - indices.indexOf(sheetDefs.indexOf(b)));

  const handleColumnChange = (orderArray) => {
    const indices = orderArray.map((measureName) =>
      sheetDefinitions.findIndex((sheetDef) => sheetDef.measureName === measureName)
    );

    const sheetDefCopy = sheetDefinitions.map((sheetDef) => {
      const newSheetDef = Object.assign({}, sheetDef);
      delete newSheetDef.__typename;
      delete newSheetDef.defaultMeasureId;
      delete newSheetDef.quotaComponents;
      return newSheetDef;
    });

    const sortedSheetDef = sortSheetDefinitions(sheetDefCopy, indices);

    // Remove the years field from all the measures as it will break the upsertSheet call
    sortedSheetDef.forEach((field) => {
      delete field.years;
    });

    upsertSheet({
      variables: {
        input: {
          dataSheets: [
            {
              sheetId: selectedSheet.sheetId,
              sheetDefinitions: sortedSheetDef
            }
          ]
        }
      }
    });
  };

  const permissionGridOptions: GridOptions = {
    alignedGrids: [],
    suppressDragLeaveHidesColumns: true,
    rowData: permissionGridData,
    suppressMovableColumns: true
  };
  const quotaSheetGridOptions: GridOptions = {
    alignedGrids: [],
    getRowClass: (params) => {
      let className = '';
      if (params.node.rowPinned === 'bottom') {
        className = b('bottomRowPinned');
      }
      return className;
    },
    pinnedBottomRowData: [buildSheetsPreviewBottomRowData(sheetDefinitions)],
    suppressDragLeaveHidesColumns: true,
    defaultColDef: {
      lockPinned: true
    },
    onDragStopped: (params) => {
      const previousOrderArray = sheetDefinitions.map((sheetDef) => sheetDef.measureName);
      const orderArray = params.columnApi
        .getColumnState()
        .slice(3)
        .reduce((acc, curr) => {
          acc.push(curr.colId);
          return acc;
        }, []);

      if (JSON.stringify(previousOrderArray) !== JSON.stringify(orderArray)) {
        handleColumnChange(orderArray);
      }
    }
  };

  permissionGridOptions.alignedGrids.push(quotaSheetGridOptions);
  quotaSheetGridOptions.alignedGrids.push(permissionGridOptions);

  const [getAllBattleCards, { data: battleCardsList, loading: loadingBattleCards }] = useLazyQuery<
    GetAllBattleCards,
    GetAllBattleCardsVariables
  >(GET_ALL_BATTLE_CARDS, {
    variables: {
      deploymentModelId: selectedDeploymentModelId
    },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const onBattleCardGridReady = (params) => {
    setBattleCardGridApi(params?.api);
  };

  const onQuotaComponentsGridReady = (params) => {
    setQuotaComponentsGridApi(params?.api);
  };

  const onPermissionGridReady = (params) => {
    setPermissionGridApi(params?.api);
  };

  const onQuotaSheetGridReady = (params) => {
    setQuotaSheetGridApi(params?.api);
  };

  useEffect(() => {
    if (!quotaSheetGridOptions || !permissionGridOptions) {
      return;
    }
    if (quotaSheetShowTotals && quotaSheetGridApi) {
      quotaSheetGridApi?.setPinnedBottomRowData([buildSheetsPreviewBottomRowData(sheetDefinitions)]);
    } else {
      quotaSheetGridApi?.setPinnedBottomRowData([]);
    }
  }, [quotaSheetShowTotals, quotaSheetGridApi]);

  useEffect(() => {
    if (!!selectedSheet) {
      getAllBattleCards();
      getSheetDefinitions(selectedDeploymentModelId, selectedSheet?.sheetId);
    }
  }, [selectedSheet]);

  const referencedQuotaComponentIds = sheetDefinitions?.[0]?.quotaComponents?.map((qc) => qc.quotaComponentId);

  const buildQuotaComponentGridData = (qcList, referencedQuotaComponentIds) => {
    const gridData = [];
    qcList?.forEach((quotaComponent) => {
      gridData.push({
        quotaComponent: quotaComponent.quotaComponentName,
        quotaComponentId: quotaComponent.quotaComponentId,
        referenceThisSheet: referencedQuotaComponentIds?.includes(quotaComponent?.quotaComponentId)
      });
    });
    return gridData;
  };

  const battleCards = battleCardsList?.getDeploymentModelSpec?.battlecards ?? [];
  const quotaComponents = battleCardsList?.getDeploymentModelSpec?.quotaComponents;
  const hasCustomMeasure = sheetDefinitions.length > DEFAULT_MEASURE_ITEM_TOTALS;

  const loadingQCReferenced = loadingBattleCards || sheetDefinitionsLoading;
  return (
    <div className={b()}>
      <QuotaSheetHeader
        permissionRowData={getRowData(permissionGridApi)}
        bcRowData={getRowData(battleCardGridApi)}
        qcRowData={getRowData(quotaComponentsGridApi)}
        getAllBattleCards={getAllBattleCards}
      />
      <div className={b('content')}>
        <div className={b('topSheetDetail')}>
          <div className={b('gridSection')}>
            <SheetGridHeader
              title={formatMessage('BATTLE_CARD_REFERENCED')}
              subtitle={formatMessage('SELECT_BATTLE_CARD_REFERENCE')}
            />
            <div className={b('battleCardReferencedGrid')}>
              <div className={b('fullWidthGrid')} ref={sheetReferenceGridContainerRef}>
                {loadingBattleCards || battleCards?.length > 0 ? (
                  <AdvancedGrid
                    data={
                      !loadingBattleCards &&
                      JSON.stringify(
                        buildBattleCardReferencedTreeData(
                          battleCards,
                          battleCards.find((battleCard) => battleCard.battlecardParentId === null)?.children ?? [],
                          [],
                          [],
                          selectedSheet.sheetId
                        )
                      )
                    }
                    columnDefs={
                      !loadingBattleCards &&
                      buildBattleCardReferencedColumnDef(selectedSheet, ReferenceCheckboxBattleCardsCellRenderer)
                    }
                    gridProps={battleCardReferencedGridProps}
                    getDataPath={getDataPath}
                    autoGroupColumnDef={{
                      headerName: formatMessage('BATTLE_CARD_NAME'),
                      flex: 2,
                      minWidth: 200,
                      cellRendererParams: { suppressCount: true }
                    }}
                    data-testid="battle-card-referenced-grid"
                    gridWidth={sheetReferenceGridContainerRef?.current?.offsetWidth}
                    gridHeight={sheetReferenceGridContainerRef?.current?.offsetHeight}
                    showGridLoading={loadingBattleCards}
                    onGridReady={onBattleCardGridReady}
                  />
                ) : (
                  <div className={b('noData')} data-testid="battle-card-referenced-grid-empty">
                    {formatMessage('EMPTY_GRID')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={b('gridSection')}>
            <SheetGridHeader title={formatMessage('QUOTA_COMPONENTS_REFERENCED')} />
            <div className={b('battleCardReferencedGrid')}>
              <div className={b('fullWidthGrid')} ref={sheetReferenceGridContainerRef}>
                {loadingQCReferenced || quotaComponents?.length > 0 ? (
                  <AdvancedGrid
                    rowData={buildQuotaComponentGridData(quotaComponents, referencedQuotaComponentIds)}
                    columnDefs={buildQuotaComponentReferencedColumnDef(ReferenceCheckboxQuotaComponentsCellRenderer)}
                    gridProps={quotaComponentReferencedGridProps}
                    data-testid="quota-components-referenced-grid"
                    gridWidth={sheetReferenceGridContainerRef?.current?.offsetWidth}
                    gridHeight={sheetReferenceGridContainerRef?.current?.offsetHeight}
                    showGridLoading={loadingQCReferenced}
                    onGridReady={onQuotaComponentsGridReady}
                  />
                ) : (
                  <div className={b('noData')} data-testid="quota-components-referenced-grid-empty">
                    {formatMessage('EMPTY_GRID')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={b('sheetControllerContainer')}>
          <div className={`${b('gridSection')} ${b('noBorderBottom')}`}>
            <SheetGridHeader title={formatMessage('PERMISSIONS')} subtitle={formatMessage('APPLY_PER_USER_ROLE')} />

            <div className={b('permissionsGrid')}>
              <div
                className={`ag-theme-alpine ${hasCustomMeasure ? b('fullWidthGrid') : b('condensedWidthGrid')}`}
                ref={permissionGridContainerRef}
              >
                {(sheetDefinitionsLoading ||
                  upsertSheetLoading ||
                  (sheetDefinitions?.length > 0 && permissionGridData)) && (
                  <AdvancedGrid
                    gridOptions={permissionGridOptions}
                    columnDefs={buildPermissionsColumnDef(sheetDefinitions)}
                    data={JSON.stringify(permissionGridData)}
                    frameworkComponents={{
                      statusBarComponent: AddUserRoleStatusBar
                    }}
                    statusBar={{
                      statusPanels: [{ statusPanel: 'statusBarComponent', align: 'left' }]
                    }}
                    onGridReady={onPermissionGridReady}
                    data-testid="permissions-grid"
                    gridWidth={permissionGridContainerRef?.current?.offsetWidth}
                    gridHeight={permissionGridContainerRef?.current?.offsetHeight}
                    showGridLoading={sheetDefinitionsLoading || upsertSheetLoading}
                  />
                )}
                {!sheetDefinitionsLoading && sheetDefinitions?.length === 0 && (
                  <div className={b('noData')} data-testid="permissions-grid-empty">
                    {formatMessage('EMPTY_GRID')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={b('gridSection')}>
            <SheetGridHeader
              title={formatMessage('QUOTA_SHEET_COLUMNS')}
              subtitle={formatMessage('COLUMNS_DESCRIPTION')}
              rightButton={<SheetsAddFieldButton />}
            />

            <div className={b('quotaSheetColumnsGrid')}>
              <div
                className={`ag-theme-alpine ${hasCustomMeasure ? b('fullWidthGrid') : b('condensedWidthGrid')}`}
                ref={quotaSheetGridContainerRef}
              >
                {sheetDefinitionsLoading || upsertSheetLoading || sheetDefinitions?.length > 0 ? (
                  <AdvancedGrid
                    gridOptions={quotaSheetGridOptions}
                    columnDefs={buildQuotaSheetColumnDef(sheetDefinitions)}
                    rowData={buildSheetsPreviewColumnData(sheetDefinitions)}
                    onGridReady={onQuotaSheetGridReady}
                    data-testid="quota-sheet-columns-grid"
                    gridWidth={quotaSheetGridContainerRef?.current?.offsetWidth}
                    gridHeight={quotaSheetGridContainerRef?.current?.offsetHeight}
                    showGridLoading={sheetDefinitionsLoading || upsertSheetLoading}
                  />
                ) : (
                  <div className={b('noData')} data-testid="quota-sheet-columns-grid-empty">
                    {formatMessage('EMPTY_GRID')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={b('showTotalsArea')}>
          <Checkbox
            data-testid="show-totals-checkbox"
            checked={quotaSheetShowTotals}
            onClick={() => setQuotaSheetShowTotals(!quotaSheetShowTotals)}
          />
          <span className={b('showTotalsText')} data-testid="show-totals-text">
            {formatMessage('SHOW_TOTALS')}
          </span>
        </div>
      </div>
      {dataSheetDrawerState && <SheetsAddFieldDrawer />}
    </div>
  );
};

export default QuotaSheetDetail;
