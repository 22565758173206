import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetMeasuresForMap, GetMeasuresForMapVariables } from 'app/graphql/generated/apolloTypes';

export const GET_MEASURES_FOR_MAP = gql`
  query GetMeasuresForMap($planningCycleId: Int!) {
    getMeasures(planningCycleId: $planningCycleId) {
      measureName
      measureId
    }
  }
`;

export const useGetMeasuresForMap = createUseQuery<GetMeasuresForMap, GetMeasuresForMapVariables>(GET_MEASURES_FOR_MAP);
