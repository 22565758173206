import gql from 'graphql-tag';

import { createUseLazyQuery, createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetRootHierarchies, GetRootHierarchiesVariables } from 'app/graphql/generated/apolloTypes';

export const GET_ROOT_HIERARCHIES = gql`
  query GetRootHierarchies($planningCycleId: Int!) {
    getRootHierarchies(planningCycleId: $planningCycleId, version: 1) {
      hierarchyType
      rootHierarchyId
      rootKey
      rootName
      numOfMembers
      attributes {
        name
        type
        editable
      }
    }
  }
`;

export const useGetRootHierarchies = createUseQuery<GetRootHierarchies, GetRootHierarchiesVariables>(
  GET_ROOT_HIERARCHIES
);
export const useGetRootHierarchiesLazy = createUseLazyQuery<GetRootHierarchies, GetRootHierarchiesVariables>(
  GET_ROOT_HIERARCHIES
);
