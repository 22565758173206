import { useMemo } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import {
  DeploymentModelTypeEnum,
  GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries
} from 'app/graphql/generated/apolloTypes';
import { useGetDeploymentModelSummaries } from 'app/graphql/queries/getDeploymentModelSummaries';

export type ScenarioSummary = Omit<
  GetDeploymentModelSummaries_getPlanningCycleSpec_deploymentModelSummaries,
  '__typename'
>;

export const useScenarioSummaries = (): { scenarioSummaries: ScenarioSummary[]; scenarioSummariesLoading: boolean } => {
  const { selectedPlanningCycle } = useScope();

  const { data, loading } = useGetDeploymentModelSummaries({
    fetchPolicy: 'cache-and-network',
    skip: !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    }
  });
  const allSummaries = data?.getPlanningCycleSpec?.deploymentModelSummaries;

  const scenarioSummaries = useMemo(
    () => allSummaries?.filter((dm) => dm.deploymentModelType === DeploymentModelTypeEnum.Plan) ?? [],
    [allSummaries]
  );

  return { scenarioSummaries, scenarioSummariesLoading: loading };
};
