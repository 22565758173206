import React, { FC } from 'react';

import ReactMapGl from 'react-map-gl';

import { config } from 'utils/config';

type ReactMapGlProps = Parameters<typeof ReactMapGl>[0];

type BasicMapboxMapProps = Partial<ReactMapGlProps>;

export const DefaultMapboxMap: FC<BasicMapboxMapProps> = (props) => (
  <ReactMapGl
    mapboxAccessToken={config.MAPBOX_ACCESS_TOKEN}
    mapStyle={config.MAPBOX_STYLE_URL}
    initialViewState={{ latitude: 40, longitude: -100, zoom: 3 }}
    attributionControl={false}
    boxZoom={false}
    {...props}
  />
);
