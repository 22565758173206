import times from 'lodash/times';

import { ISheetDefinitionDef } from 'app/graphql/generated/apolloTypes';

import { MeasureFormatType } from 'app/models';

import { shouldShowBalancingMetricDiagram } from 'utils/helpers/sheetsPreviewUtils';
import { formatMessage } from 'utils/messages/utils';

export const buildSheetsPreviewColumnData = (sheetDefs: ISheetDefinitionDef[]): Record<string, unknown>[] => {
  const buildQuotaSheetObject = (num, gap) =>
    sheetDefs.reduce((acc, curr) => {
      const { measureName, measureFormatType, sheetDefinitionType } = curr;
      const exampleString = () => {
        if (measureFormatType === MeasureFormatType.CURRENCY) {
          return shouldShowBalancingMetricDiagram(sheetDefinitionType) ? '###' : '###,###,###';
        } else if (measureFormatType === MeasureFormatType.PERCENTAGE) {
          return '##.#%';
        }
        return '###';
      };
      return {
        ...acc,
        territoryId: `Territory ID 0${num + 1}`,
        territoryName: `Territory Name 0${num + 1}`,
        measureGap: gap,
        territoryEffectiveDates: `Start Date 0${num + 1} - End Date 0${num + 1}`,
        sellerEffectiveDates: `Start Date 0${num + 1} - End Date 0${num + 1}`,
        Seasonality: `Seasonality ${num + 1}`,
        sellers: `Seller ${num + 1}`,
        assignmentPercentage: `##.#%`,
        Ramp: `Ramp ${num + 1}`,
        [measureName]: exampleString()
      };
    }, {});
  //this math takes the index and creates a mock value from -1 to 1 to be used by the balance metric cell graphic
  //remove when real data is provided and mocks are no longer needed
  return times(4, (index) =>
    buildQuotaSheetObject(index, index % 2 === 0 ? (index % 10) / 10 : (-1 * (index % 10)) / 10)
  );
};

export const buildSheetsPreviewBottomRowData = (sheetDefs: ISheetDefinitionDef[]): Record<string, unknown> => {
  return sheetDefs.reduce((acc, curr) => {
    const { measureName, sheetDefinitionType } = curr;
    return shouldShowBalancingMetricDiagram(sheetDefinitionType)
      ? { ...acc, territoryId: formatMessage('TOTAL'), [measureName]: formatMessage('AVERAGE') }
      : {
          ...acc,
          territoryId: formatMessage('TOTAL'),
          Seasonality: '',
          Ramp: '',
          [measureName]: formatMessage('TOTAL')
        };
  }, {});
};
