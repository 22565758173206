import React, { useState, useCallback, useEffect } from 'react';

import { ServerSideStoreType } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getHierarchyData } from 'app/components/CommandCenterHierarchyPanel/hooks/useGetSubtreeHierarchy';

import { DEFAULT_BLOCK_SIZE, MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies,
  GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
} from 'app/graphql/generated/apolloTypes';

import { HierarchyType, HierarchyItem, RulePartType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchyGrid.module.pcss';

const b = block(style);

interface HierarchyGridProps {
  rootHierarchyId: number;
  hierarchyType: HierarchyType;
  planningCycleId: number;
  onSelect: (rowSelectedEvent) => void;
  initialSelection?: HierarchyItem[];
  rulePartType?: RulePartType;
}

const HierarchyGrid: React.FC<HierarchyGridProps> = ({
  rootHierarchyId,
  hierarchyType,
  planningCycleId,
  onSelect,
  initialSelection = [],
  rulePartType = RulePartType.BASE
}) => {
  const hierarchyPanelGridContainerRef = React.useRef(null);
  const [initialBlock, setInitialBlock] = useState<
    | GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies
    | GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies
    | GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
  >(null);

  const [blockSize, setBlockSize] = useState(DEFAULT_BLOCK_SIZE);

  const loadHierarchyData = useCallback(
    async (hierarchyId: number, startRow: number, endRow: number, isFirstLevel = false) => {
      const result = await getHierarchyData(hierarchyType, {
        planningCycleId,
        hierarchyId,
        depth: 1,
        startRow,
        endRow
      });

      if (isFirstLevel) {
        setInitialBlock(result);
      }
      return result;
    },
    [hierarchyType]
  );

  useEffect(() => {
    if (!initialBlock && blockSize) {
      loadHierarchyData(rootHierarchyId, 1, blockSize, true);
    }
  }, [initialBlock, blockSize, loadHierarchyData]);

  useEffect(() => {
    if (hierarchyPanelGridContainerRef?.current?.offsetHeight) {
      setBlockSize(
        Math.round((hierarchyPanelGridContainerRef?.current?.offsetHeight / MENU_INFINITE_SCROLL_ITEM_HEIGHT) * 2)
      );
    }
  }, [hierarchyPanelGridContainerRef]);

  const handleOnGridReady = (gridEvent) => {
    const dataSource = {
      getRows: async (params) => {
        const startRow = params.request.startRow + 1;
        const endRow = params.request.startRow + blockSize;

        const parentNodeHierarchyId = params?.parentNode?.data?.hierarchyId;
        const isFirstLevelNode = !parentNodeHierarchyId;

        const setData = (newNodes) => {
          // Calculate the total items that have been loaded as the last block may not be full
          const totalCount = params.request.endRow - blockSize + newNodes.length;
          // If there is not enough nodes in the new block which means there is no more data, stop fetching
          if (newNodes.length < blockSize) {
            params.success({ rowData: newNodes, rowCount: totalCount });
          } else {
            // Else, set null as the total count so that ag grid will keep fetching
            params.success({ rowData: newNodes });
          }
        };

        if (!isFirstLevelNode) {
          // handle nodes from the second level
          const { items: newNodes } = await loadHierarchyData(parentNodeHierarchyId, startRow, endRow);
          setData(newNodes);
        } else {
          // handle nodes on the first level
          const { items: newNodes } = await loadHierarchyData(rootHierarchyId, startRow, endRow);
          setData(newNodes);
        }
      }
    };
    gridEvent.api.setServerSideDatasource(dataSource);
  };

  const hierarchyTreeGridProps = {
    rowSelection: 'multiple',
    rowMultiSelectWithClick: false,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    treeData: true,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    headerHeight: 0,
    rowHeight: MENU_INFINITE_SCROLL_ITEM_HEIGHT,
    cacheBlockSize: blockSize,
    suppressRowDrag: false,
    getRowStyle: () => {
      return { border: 'none', background: 'white' };
    },
    onGridReady: handleOnGridReady,
    onRowClicked: ({ node }) => {
      if (rulePartType === RulePartType.OVERRIDE && !node.group) {
        // Only allow selection for children
        node.setSelected(!node.selected);
      }
    }
  };

  const isNodePreselected = (node) => {
    return !!initialSelection.filter((selectedNode) => selectedNode.hierarchyId === node.data.hierarchyId).length;
  };

  return (
    <div>
      {initialBlock?.items?.length === 0 && (
        <div className={b('validationMessage')} data-testid={'empty-hierarchy-tree-message'}>
          {formatMessage('NO_HIERARCHIES')}
        </div>
      )}
      <div className={b('fullWidthGrid')} ref={hierarchyPanelGridContainerRef}>
        <AdvancedGrid
          className={b('defaultHierarchiesGrid')}
          autoGroupColumnDef={{
            field: 'name',
            width: 750,
            checkboxSelection: ({ node }) => {
              if (node.parent?.isSelected() || isNodePreselected(node)) {
                node.setSelected(true);
              }
              // Disable selection on parent nodes in overrides if exceptionsEnabled FF is on
              return !(rulePartType === RulePartType.OVERRIDE && !!node.group);
            }
          }}
          animateRows={true}
          gridProps={hierarchyTreeGridProps}
          isServerSideGroup={(dataItem) => dataItem?.hasChildren}
          getServerSideGroupKey={(dataItem) => dataItem?.name}
          showGridLoading={!initialBlock}
          data-testid="hierarchy-grid"
          onRowSelected={onSelect}
        />
      </div>
    </div>
  );
};

export default HierarchyGrid;
