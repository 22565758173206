import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetLocationGroups, GetLocationGroupsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_LOCATION_GROUPS = gql`
  query GetLocationGroups($planningCycleId: Int!) {
    getPlanningCycleSpec(input: { planningCycleId: $planningCycleId }) {
      planningCycleId
      locationGroups {
        locationGroupId
        locationGroupName
      }
    }
  }
`;

export const useGetLocationGroups = createUseQuery<GetLocationGroups, GetLocationGroupsVariables>(GET_LOCATION_GROUPS);
