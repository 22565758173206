import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetTerritoryGroupChildrenVariables,
  GetTerritoryGroupChildren_getTerritoryGroupChildren,
  GetTerritoryGroupChildren_getTerritoryGroupChildren_items
} from 'app/graphql/generated/apolloTypes';
import { GET_TERRITORY_GROUP_CHILDREN } from 'app/graphql/queries/getTerritoryGroupChildren';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface GetTerritoryGroupChildrenResults {
  items: GetTerritoryGroupChildren_getTerritoryGroupChildren_items[];
  totalCount: number;
}

export const getTerritoryGroupChildren = async (
  variables: GetTerritoryGroupChildrenVariables
): Promise<GetTerritoryGroupChildrenResults> => {
  try {
    const result: ApolloQueryResult<{
      getTerritoryGroupChildren: GetTerritoryGroupChildren_getTerritoryGroupChildren;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_TERRITORY_GROUP_CHILDREN,
      variables,
      fetchPolicy: 'no-cache'
    });

    const { items, totalCount } = result.data.getTerritoryGroupChildren;
    return {
      items,
      totalCount
    };
  } catch (error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('TERRITORY_GROUPS_ERROR'), 'danger');
    throw error;
  }
};
