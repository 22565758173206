import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import clonedeep from 'lodash.clonedeep';

import Comment from 'app/components/CommentPanel/Comment/Comment';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useComments } from 'app/contexts/commentProvider';
import { useScope } from 'app/contexts/scopeProvider';

import {
  GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies,
  GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadTags,
  GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_TERRITORY_WORKFLOW_COMMENT_THREAD_REPLIES } from 'app/graphql/queries/getTerritoryWorkflowCommentThreads';

import useShowToast from 'app/hooks/useShowToast';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommentThread.module.pcss';

const b = block(style);

interface CommentThreadProps {
  topLevelComment: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_topLevelComment;
  collapsedReplyList: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadReplies;
  threadId: number;
  threadTags: GetTerritoryWorkflowCommentThreads_getDeploymentModelSpec_territoryWorkflowCommentThreads_items_threadTags[];
  onClick?: () => void;
}

const sortReplies = (replies) => {
  const repliesCloned = clonedeep(replies);
  if (repliesCloned) {
    repliesCloned.items = replies?.items?.length > 0 ? [...replies?.items]?.reverse() : replies?.items;
  }
  return repliesCloned;
};

const CommentThread: React.FC<CommentThreadProps> = ({
  topLevelComment,
  threadId,
  collapsedReplyList
}: CommentThreadProps) => {
  const { setShouldRefetchCommentPanel } = useComments();
  const [shouldRenderExpandedRepliesSection, setShouldRenderExpandedRepliesSection] = useState(false);
  const { selectedDeploymentModelId } = useScope();
  const { selectedBattleCardId } = useBattleCard();
  const showToast = useShowToast();

  const [loadReplies, { data: replyListResponse, loading: isLoading }] = useLazyQuery(
    GET_TERRITORY_WORKFLOW_COMMENT_THREAD_REPLIES,
    {
      fetchPolicy: 'cache-first',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('COMMENT_PANEL_LOADING_ERROR'), 'danger');
        setShouldRenderExpandedRepliesSection(false);
      }
    }
  );
  useEffect(() => {
    if (shouldRenderExpandedRepliesSection && !isLoading) {
      loadReplies({
        variables: {
          battlecardId: +selectedBattleCardId,
          deploymentModelId: selectedDeploymentModelId,
          threadId: +threadId,
          startRow: 1,
          endRow: collapsedReplyList?.totalCount + 1
        }
      });
    }
  }, [shouldRenderExpandedRepliesSection, replyListResponse]);

  const showMoreRepliesCount = () => {
    const numRemainingReplies = replies?.totalCount - 2;
    const remainingReplyText = numRemainingReplies === 1 ? 'reply' : 'replies';
    return (
      <>
        <div
          className={b('threadReplyCollapseIcon')}
          onClick={() => {
            setShouldRenderExpandedRepliesSection(!shouldRenderExpandedRepliesSection);
            setShouldRefetchCommentPanel(false);
          }}
          data-testid="thread-reply-collapse-expand-message"
        >
          {!shouldRenderExpandedRepliesSection ? `Show ${numRemainingReplies} more ${remainingReplyText}` : `Collapse`}
        </div>
      </>
    );
  };

  const replies =
    shouldRenderExpandedRepliesSection && !isLoading
      ? sortReplies(replyListResponse?.getDeploymentModelSpec?.territoryWorkflowCommentThreads?.items[0]?.threadReplies)
      : sortReplies(collapsedReplyList);

  return (
    <div data-testid="comment-thread">
      <div data-testid="top-level-comment">
        <Comment comment={topLevelComment} skeleton={false} />
      </div>

      {replies?.totalCount > 2 && showMoreRepliesCount()}
      {replies?.items?.length > 0 && (
        <>
          {replies?.items?.map((reply) => {
            return (
              <div className={b('threadReplySection')} data-testid="thread-reply-section" key={`${reply?.commentId}`}>
                <Comment comment={reply} skeleton={isLoading} />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default CommentThread;
