import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { CreateBattlecard, CreateBattlecardVariables } from 'app/graphql/generated/apolloTypes';

export const CREATE_BATTLECARD = gql`
  mutation CreateBattlecard(
    $battlecardName: String!
    $battlecardParentId: Int!
    $battlecardType: String!
    $deploymentModelId: Int!
    $hierarchies: [BattlecardHierarchyInput]
    $quotaComponent: [Int]!
    $teams: [TeamInput]
    $fileIds: [String]
    $memberId: Int
    $currencyCode: String
    $mapLevel: BCMapLevelEnum
    $quotaDistributionHierarchyIds: [Int!]
  ) {
    createBattlecard(
      battlecard: {
        battlecardName: $battlecardName
        battlecardParentId: $battlecardParentId
        battlecardType: $battlecardType
        deploymentModelId: $deploymentModelId
        hierarchies: $hierarchies
        quotaComponent: $quotaComponent
        teams: $teams
        fileIds: $fileIds
        memberId: $memberId
        currencyCode: $currencyCode
        mapLevel: $mapLevel
        quotaDistributionHierarchyIds: $quotaDistributionHierarchyIds
      }
    ) {
      battlecardId
    }
  }
`;

export const useCreateBattleCard = createUseMutation<CreateBattlecard, CreateBattlecardVariables>(CREATE_BATTLECARD);
