import React from 'react';

import { Spinner, Text } from '@blueprintjs/core';

import EllipsisText from 'components/EllipsisText/EllipsisText';

import DataTypeTag from 'app/components/DataTypeTag/DataTypeTag';

import { DataType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './DataTypeTagList.module.pcss';

const b = block(style);

type MappingFields = {
  key: string;
  value: string;
  properties: { colRequired: boolean; dataType: DataType };
};

interface DataTypeTagListProps {
  fields: MappingFields[];
  fieldsLoading?: boolean;
  fileUploadType: string;
  showOptional?: boolean;
}

const DataTypeTagList: React.FC<DataTypeTagListProps> = ({
  fields,
  fileUploadType,
  fieldsLoading = false,
  showOptional = false
}: DataTypeTagListProps) => {
  return (
    <div data-testid="data-type-tag-list">
      <div className={b('requiredText')}>{formatMessage('FILE_REQUIREMENT_TEXT', { fileType: fileUploadType })}</div>
      <div className={b('requiredHeadersGrid')}>
        {fieldsLoading ? (
          <div className={b('spinnerContainer')} data-testid="data-type-tag-list-loading">
            <Spinner intent="primary" size={40} />
          </div>
        ) : (
          fields?.map((field) => {
            if (showOptional || field.properties.colRequired) {
              return (
                // temporarily use field value for key as some key are null
                <div key={field.key || field.value} className={b('requiredHeader')}>
                  <DataTypeTag dataType={field.properties?.['dataType']} data-testid="data-type-tag" />
                  <EllipsisText className={b('ellipsisText')} text={field.value} />
                  {showOptional && field.properties.colRequired && <Text>*</Text>}
                </div>
              );
            }
            return null;
          })
        )}
      </div>
    </div>
  );
};

export default DataTypeTagList;
