// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oAtXl {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n  .Cnm\\+f {\n    justify-content: flex-start;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  ._48oqx {\n    font-size: 16px;\n    font-weight: 600;\n  }\n\n  .fuF\\+8 {\n    padding: 8px 12px;\n  }\n\n  .fuF\\+8 .bp3-radio {\n      font-weight: 400;\n    }\n\n  .fuF\\+8:first-child {\n    padding-top: 12px;\n  }\n\n  .fuF\\+8:last-child {\n    padding-bottom: 12px;\n  }\n\n  .uVul4 {\n    font-weight: bold;\n    padding: 8px 12px;\n  }\n\n  .SN8hn {\n    font-weight: bold;\n    font-size: 14px;\n    margin-left: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapGridTitle": "oAtXl",
	"MapGridTitle__isEverythingOn": "Cnm+f",
	"MapGridTitle_menuTrigger": "_48oqx",
	"MapGridTitle_menuItem": "fuF+8",
	"MapGridTitle_tgLevelTitle": "uVul4",
	"MapGridTitle_title": "SN8hn"
};
export default ___CSS_LOADER_EXPORT___;
