import React, { useCallback } from 'react';

import { RowNode } from '@ag-grid-community/core';
import { useMutation } from '@apollo/client';
import { TrashCan } from '@carbon/icons-react';
import isequal from 'lodash.isequal';
import sortby from 'lodash.sortby';

import IconButton from 'components/Buttons/IconButton/IconButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { useGrid } from 'app/contexts/gridProvider';

import { UpsertTerritoryOwners, UpsertTerritoryOwnersVariables } from 'app/graphql/generated/apolloTypes';
import { UPSERT_TERRITORY_OWNERS } from 'app/graphql/mutations/upsertTerritoryOwners';

import useCanUser from 'app/hooks/useCanUser';
import useShowToast from 'app/hooks/useShowToast';

import { OwnersUpsert } from 'app/models';

import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

interface DeleteSellersActionButtonProps {
  tenantId: number;
  quotaComponentId: number;
}

const removeObject = (currentArray, itemToRemove) => {
  const updatedArray = currentArray.filter((i) => {
    return !isequal(i, itemToRemove);
  });
  return updatedArray;
};

// group selected seller rows data by territoryRuleId
const buildSellerGroups = (sellersToDelete: RowNode[]): OwnersUpsert[] => {
  const result: OwnersUpsert[] = [];
  const sortedSellers = sortby(sellersToDelete, [(node) => node.data.ruleId]);
  let currentRuleId;
  sortedSellers.forEach((seller) => {
    const ruleId = seller.data?.ruleId;
    if (currentRuleId !== ruleId) {
      currentRuleId = ruleId;
      const sellerGroup = {
        ownersToDelete: seller.data?.owners,
        allOwnersInTerritory: seller.level === 0 ? seller.data?.owners : seller.parent.data?.owners,
        territoryRuleId: ruleId
      };
      result.push(sellerGroup);
    } else {
      result[result.length - 1].ownersToDelete.push(seller.data?.owners?.[0]);
    }
  });
  return result;
};

const buildNewOwners = (sellerGroup: OwnersUpsert) => {
  const shouldDeleteAllSellers = sellerGroup.allOwnersInTerritory.length === sellerGroup.ownersToDelete.length;
  if (shouldDeleteAllSellers) {
    return [];
  }
  // remove the selected owners so they can be deleted
  let newOwnersList = sellerGroup.allOwnersInTerritory;
  sellerGroup.ownersToDelete.forEach((owner) => {
    newOwnersList = removeObject(newOwnersList, owner);
  });

  return newOwnersList.map((owner) => {
    return {
      memberId: owner.memberId,
      ownership: owner.ownership,
      ownerId: owner.ownerId,
      effectiveEndDate: owner.effectiveEndDate,
      effectiveStartDate: owner.effectiveStartDate
    };
  });
};

const createSuccessMsg = (sellerGroup: OwnersUpsert[]): string => {
  const sellerDeleted = sellerGroup.reduce((acc, cur) => cur.ownersToDelete.length + acc, 0);
  if (sellerDeleted === 1) {
    return formatMessage(
      sellerGroup.length === 1
        ? 'DELETE_SELLER_FROM_TERRITORY_SUCCESS_MESSAGE'
        : 'DELETE_SELLER_FROM_TERRITORIES_SUCCESS_MESSAGE',
      { sellerCount: sellerDeleted, terCount: sellerGroup.length }
    );
  }
  return formatMessage(
    sellerGroup.length === 1
      ? 'DELETE_SELLERS_FROM_TERRITORY_SUCCESS_MESSAGE'
      : 'DELETE_SELLERS_FROM_TERRITORIES_SUCCESS_MESSAGE',
    { sellerCount: sellerDeleted, terCount: sellerGroup.length }
  );
};

const DeleteSellersActionButton: React.FC<DeleteSellersActionButtonProps> = ({ tenantId, quotaComponentId }) => {
  const { selectedRowData, setSelectedRowData, setRefreshGrid } = useGrid();
  const sellersToDelete = selectedRowData as RowNode[];

  const showToast = useShowToast();

  // this mutation will overwrite the owners of a given territory, so when old owners are not included, we essentially delete them from the territory
  const [upsertTerritoryOwners] = useMutation<UpsertTerritoryOwners, UpsertTerritoryOwnersVariables>(
    UPSERT_TERRITORY_OWNERS
  );

  const canEditSellerAssignment = useCanUser(UserAction.SELLER_ASSIGNMENT_EDIT);

  const onDeleteIconClick = useCallback(async () => {
    const sellerGroups = buildSellerGroups(sellersToDelete);
    try {
      await Promise.all(
        sellerGroups.map((sellerGroup) =>
          upsertTerritoryOwners({
            variables: {
              tenantId,
              territoryId: sellerGroup.territoryRuleId,
              owners: buildNewOwners(sellerGroup),
              quotaComponentId
            }
          })
        )
      );
      showToast(
        <ToastMessage
          title={
            sellersToDelete?.length > 1 ? formatMessage('REMOVE_SELLERS_TITLE') : formatMessage('REMOVE_SELLER_TITLE')
          }
          message={createSuccessMsg(sellerGroups)}
        />,
        'success'
      );
      setRefreshGrid(true);
    } catch (error) {
      console.log(error);
      showToast(
        <ToastMessage
          title={formatMessage('REMOVE_SELLER_ERROR_TITLE')}
          message={formatMessage('DELETE_SELLER_FROM_MULTIPLE_TERRITORIES_ERROR')}
        />,
        'danger'
      );
    } finally {
      setSelectedRowData(null);
    }
  }, [sellersToDelete]);

  return (
    <div data-testid="delete-sellers-action-button">
      <MessageTooltip
        target={
          <IconButton
            onClick={onDeleteIconClick}
            type={'button'}
            icon={<TrashCan />}
            testId={'delete-sellers-icon'}
            disabled={!sellersToDelete?.length || !canEditSellerAssignment}
            tooltipText={canEditSellerAssignment ? '' : formatMessage('NO_EDIT_PERMISSION')}
          />
        }
        content={formatMessage('REMOVE_SELLER_ASSIGNMENT')}
        placement="top"
      />
    </div>
  );
};

export default DeleteSellersActionButton;
