import React from 'react';

import { ChevronRight } from '@carbon/icons-react';
import { HTMLHeading, MenuItem } from '@varicent/components';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { CommandCenterMenuSection, CommandCenterMenuState, Hierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterMenu.module.pcss';

const b = block(style);

const AdminMenuItem = ({ changeActiveMenuItem, menuItem, active }) => (
  <MenuItem
    className={b('menuItem', { active })}
    icon={menuItem.icon}
    key={`${menuItem.text}`}
    target={menuItem.action}
    onClick={() => {
      changeActiveMenuItem({ key: menuItem.key, hierarchy: null });
    }}
    text={
      <div className={b('menuItemTextContainer')}>
        <div>
          <div className={b('menuItemText')} data-testid={`menu-item-text-${menuItem.text}`}>
            <HTMLHeading tagLevel="h6" text={menuItem.text} />
          </div>
          {menuItem?.availability && (
            <div className={b('menuItemSubtext')} data-testid={`menu-item-subtext-${menuItem.text}`}>
              {menuItem.availability}
            </div>
          )}
        </div>
        <div>
          <ChevronRight />
        </div>
      </div>
    }
  />
);

interface DefaultMenuItem {
  key?: string;
  section: string;
  icon: JSX.Element;
  component: JSX.Element;
  title: string;
  text: string;
  availability?: string;
  drawerState: string;
  menuState: string;
}

export const CommandCenterMenuAdmin = ({
  defaultMenuItems,
  changeActiveMenuItem
}: {
  defaultMenuItems: DefaultMenuItem[];
  changeActiveMenuItem: ({ key, hierarchy }: { key: string; hierarchy: Hierarchy }) => void;
}): React.ReactElement => {
  const { commandCenterMenuState, activeMenu } = useCommandCenter();
  const isOnFullMenuView = commandCenterMenuState === CommandCenterMenuState.FULL;

  return (
    <React.Fragment>
      <li className={b('menuHeader')} data-testid={'menu-header-admin'}>
        {isOnFullMenuView && <HTMLHeading tagLevel="h5" text={formatMessage('ADMIN')} />}
      </li>
      {defaultMenuItems.map((menuItem) => {
        const isActive = activeMenu === menuItem.key;

        return menuItem.section === CommandCenterMenuSection.ADMIN ? (
          <AdminMenuItem
            key={menuItem.title}
            menuItem={menuItem}
            active={isActive}
            changeActiveMenuItem={changeActiveMenuItem}
          />
        ) : null;
      })}
    </React.Fragment>
  );
};
