import React from 'react';

import { Divider } from '@blueprintjs/core';
import { HTMLHeading } from '@varicent/components';
import { Field, useFormikContext } from 'formik';

import { ConfigurationDialogFormInput } from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationDialog';
import FormCheckbox from 'app/components/FormFields/FormCheckbox/FormCheckbox';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ReviewImportConfiguration.module.pcss';

const b = block(style);

export const ReviewImportConfiguration: React.FC = () => {
  const { values } = useFormikContext<ConfigurationDialogFormInput>();

  return (
    <div className={b()} data-testid="review-import-configuration">
      <HTMLHeading bold tagLevel="h5" text="Data file" className={b('heading')} />
      <div className={b('dataFile')}>
        <div className={b('textContainer')}>
          <span className={b('subText')}>{formatMessage('FILE_TYPE')}</span>
          <span className={b('description')}>{values?.dataTableType?.value}</span>
        </div>
      </div>
      <HTMLHeading bold tagLevel="h5" text="Configuration" className={b('heading')} />

      <span className={b('description')} data-testid="configuration-text">
        {values.configurationId
          ? formatMessage('SYMON_REVIEW_DIALOG_EXISTING_CONFIGURATION_TEXT', { name: values.destinationTable })
          : formatMessage('SYMON_REVIEW_DIALOG_CONFIGURATION_TEXT')}
      </span>

      <div className={b('configuration')}>
        <div className={b('textContainer')}>
          <span className={b('subText')}>{formatMessage('SYMON_AI_SOURCE')}</span>
          <span className={b('description')}>{values?.symonPipeName?.key}</span>
        </div>
        <div className={b('textContainer')}>
          <span className={b('subText')}>{formatMessage('SYMON_AI_EXPORT_NODE')}</span>
          <span className={b('description')}>{values?.symonExportNode?.key}</span>
        </div>
      </div>
      <div className={b('runOnCreateCheckbox')}>
        <Field
          name="runOnCreate"
          component={FormCheckbox}
          placeHolderText={formatMessage('SELECT')}
          type="checkbox"
          labelElement={
            <span className={b('checkboxLabel')}> {formatMessage('RUN_CONFIGURATION_AFTER_CREATING')}</span>
          }
        />
      </div>
      {!values?.configurationId && values?.dataTableType?.value === FileTypeEnum.Activity && (
        <>
          <Divider />
          <div className={b('destinationFieldContainer')} data-testid="import-configuration-message">
            <HTMLHeading bold tagLevel="h5" text={formatMessage('DESTINATION')} className={b('heading')} />
            <span>{formatMessage('IMPORT_CONFIG_MESSAGE')}</span>
            <div className={b('destinationInput')} data-testid="destination-table-input">
              <Field
                label={formatMessage('DESTINATION_TABLE')}
                name="destinationTable"
                type="text"
                component={FormTextInputGroup}
                placeHolder={formatMessage('ENTER_TABLE_NAME')}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
