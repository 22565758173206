import React from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Copy, DocumentView, OverflowMenuHorizontal } from '@carbon/icons-react';

import Popover from 'components/Popover/Popover';

import { useData } from 'app/contexts/dataProvider';

import useShowToast from 'app/hooks/useShowToast';

import { DataPanelViews } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportErrorsMenuCellRenderer.module.pcss';

const b = block(style);

type ImportErrorsMenuCellRendererProps = ICellRendererParams;

const ImportErrorsMenuCellRenderer: React.FC<ImportErrorsMenuCellRendererProps> = ({
  data
}: ImportErrorsMenuCellRendererProps) => {
  const { setSelectedTable, setSelectedDataView } = useData();
  const showToast = useShowToast();

  if (!data) {
    return null;
  }

  const onSelectionChanged = () => {
    setSelectedTable(data);
    setSelectedDataView(DataPanelViews.ERROR_DETAIL);
  };

  const arrayOfErrors: Record<string, string>[] = Object.values(JSON.parse(data.messages ?? '{}'));

  const fileName = `Failed to import ${data.tableName} due to unexpected error that occurred during the publishing process.\n${arrayOfErrors.length} errors.\n`;

  const copySummary = fileName + arrayOfErrors.map((error) => error.message).join('\n');

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(copySummary);
    showToast(formatMessage('ERROR_SUMMARY_COPIED_TO_CLIPBOARD'), 'success');
  };

  return (
    <div
      data-testid="import-errors-menu-cell-renderer"
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={b()}
    >
      <Popover
        content={
          <Menu data-testid="import-errors-grid-options-menu">
            <MenuItem
              text={formatMessage('VIEW_DETAILS')}
              onClick={(event) => {
                onSelectionChanged();
                event.stopPropagation();
              }}
              data-testid="import-errors-view-details-menu-item"
              icon={<DocumentView />}
            />
            <MenuItem
              text={formatMessage('COPY_SUMMARY')}
              onClick={(event) => {
                onCopyToClipboard();
                event.stopPropagation();
              }}
              data-testid="import-errors-copy-summary-menu-item"
              icon={<Copy />}
            />
          </Menu>
        }
        placement={'bottom-end'}
        className={b('menuCellPopover')}
        portalClassName={b()}
      >
        <OverflowMenuHorizontal size={20} data-testid="import-errors-menu-cell-renderer-icon" />
      </Popover>
    </div>
  );
};

export default ImportErrorsMenuCellRenderer;
