import React, { useMemo } from 'react';

import { useGetPermissions } from 'app/graphql/queries/getPermissions';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { Permission } from 'app/models';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

import { useScope } from './scopeProvider';

export interface PermissionsContextValues {
  permissions: Permission[];
  loadingPermissions: boolean;
}

export const PermissionsContext = React.createContext<PermissionsContextValues | null>(null);
PermissionsContext.displayName = 'PermissionsContext';

export const PermissionsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { selectedPlanningCycle } = useScope();
  const selectedPcId = selectedPlanningCycle?.id;
  const { data, loading: fetchingPermissions } = useGetPermissions({
    skip: !selectedPcId,
    variables: {
      planningCycleId: selectedPcId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: () => {
      // eslint-disable-next-line deprecation/deprecation
      showToast(formatMessage(`ROLE_PERMISSIONS_GET_ERROR`), 'danger');
    }
  });

  const hasStalePermissisons = selectedPcId !== data?.getPlanningCycleSpec?.planningCycleId;

  const loadingPermissions = !selectedPcId || fetchingPermissions || hasStalePermissisons;

  const permissions = useMemo(
    () => data?.getPlanningCycleSpec?.permissions || [],
    [data?.getPlanningCycleSpec?.permissions]
  );
  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      loadingPermissions,
      permissions
    }),
    [permissions, loadingPermissions]
  );

  // Return the interface that we want to expose to our other components
  return <PermissionsContext.Provider value={values}>{children}</PermissionsContext.Provider>;
};

// Custom hook to read these values from
export const usePermissions = (): PermissionsContextValues => useContextSafe(PermissionsContext);
