import React, { useState, useMemo, Dispatch, SetStateAction } from 'react';

import { KeyValue } from 'components/models';

import { DEFAULT_REBALANCING_HIERARCHY } from 'app/global/variables';

import { GetTerritoryRules_getTerritoryRules_territoryRules } from 'app/graphql/generated/apolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, SourcePanel, BalancingPanelRow, RebalancingMetric } from 'app/models/index';

interface RebalancingContextValues extends BaseContext {
  resetValues: () => void;
  resetPanelsConfig: () => void;
  selectedRebalancingHierarchy: KeyValue<string>;
  setSelectedRebalancingHierarchy: Dispatch<SetStateAction<KeyValue<string>>>;
  selectedRebalancingRows: GetTerritoryRules_getTerritoryRules_territoryRules[];
  setSelectedRebalancingRows: Dispatch<SetStateAction<GetTerritoryRules_getTerritoryRules_territoryRules[]>>;
  setMetrics: Dispatch<SetStateAction<RebalancingMetric>>;
  isMoved: boolean;
  setIsMoved: Dispatch<SetStateAction<boolean>>;
  metrics: RebalancingMetric;
  isSaved: boolean;
  setIsSaved: Dispatch<SetStateAction<boolean>>;
  sourcePanel: SourcePanel;
  setSourcePanel: Dispatch<SetStateAction<SourcePanel>>;
  selectedData: BalancingPanelRow[];
  setSelectedData: Dispatch<SetStateAction<BalancingPanelRow[]>>;
  rebalancingMetricDropdownItems: KeyValue<number>[];
  setRebalancingMetricDropdownItems: Dispatch<SetStateAction<KeyValue<number>[]>>;
  selectedRebalancingMetric: KeyValue<number>;
  setSelectedRebalancingMetric: Dispatch<SetStateAction<KeyValue<number>>>;
}

export const RebalancingContext = React.createContext<RebalancingContextValues | null>(null);
RebalancingContext.displayName = 'RebalancingContext';

export const RebalancingProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [selectedRebalancingHierarchy, setSelectedRebalancingHierarchy] = useState(DEFAULT_REBALANCING_HIERARCHY);
  const [selectedRebalancingRows, setSelectedRebalancingRows] = useState([null, null]);
  const [metrics, setMetrics] = useState<RebalancingMetric>({
    totalAccountsCount: 0,
    totalAccountsMean: 0,
    measureValueMean: 0,
    measureValueStandardDeviation: 0
  });
  const [isMoved, setIsMoved] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [sourcePanel, setSourcePanel] = useState<SourcePanel>(null);
  const [selectedData, setSelectedData] = useState<BalancingPanelRow[]>([]);
  const [rebalancingMetricDropdownItems, setRebalancingMetricDropdownItems] = useState<KeyValue<number>[]>([]);
  const [selectedRebalancingMetric, setSelectedRebalancingMetric] = useState<KeyValue<number>>(null);

  const resetValues = () => {
    setSelectedRebalancingHierarchy(DEFAULT_REBALANCING_HIERARCHY);
    setSelectedRebalancingRows([null, null]);
    setIsMoved(false);
    setIsSaved(false);
    setSourcePanel(null);
    setSelectedData([]);
    setRebalancingMetricDropdownItems([]);
    setSelectedRebalancingMetric(null);
  };

  const resetPanelsConfig = () => {
    setIsMoved(false);
    setIsSaved(false);
    setSourcePanel(null);
    setSelectedData([]);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      resetValues,
      resetPanelsConfig,
      selectedRebalancingHierarchy,
      setSelectedRebalancingHierarchy,
      selectedRebalancingRows,
      setSelectedRebalancingRows,
      metrics,
      setMetrics,
      isMoved,
      setIsMoved,
      isSaved,
      setIsSaved,
      sourcePanel,
      setSourcePanel,
      selectedData,
      setSelectedData,
      rebalancingMetricDropdownItems,
      setRebalancingMetricDropdownItems,
      selectedRebalancingMetric,
      setSelectedRebalancingMetric
    }),
    [
      selectedRebalancingHierarchy,
      selectedRebalancingRows,
      metrics,
      isMoved,
      isSaved,
      sourcePanel,
      selectedData,
      rebalancingMetricDropdownItems,
      selectedRebalancingMetric
    ]
  );

  // Return the interface that we want to expose to our other components
  return <RebalancingContext.Provider value={values}>{children}</RebalancingContext.Provider>;
};

// Custom hook to read these values from
export const useRebalancing = (): RebalancingContextValues => useContextSafe(RebalancingContext);
