import React, { useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { UseComboboxStateChange } from 'downshift';
import { Field, Form, Formik, FormikErrors } from 'formik';

import TextButton from 'components/Buttons/TextButton/TextButton';
import { SearchableSelectMenuItem } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import PinSelector from 'app/components/TerritoryMap/PinSetDialog/PinSelector';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { MAX_PIN_SET_NAME_LENGTH } from 'app/global/variables';

import { UpsertPinSet, UpsertPinSetVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_PIN_SET } from 'app/graphql/mutations/upsertPinSet';

import useShowToast from 'app/hooks/useShowToast';

import { LocationGroup, PinIcon, PinSet } from 'app/models';

import block from 'utils/bem-css-modules';
import { addUnique } from 'utils/helpers/collectionUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './PinSetCustomizer.module.pcss';

const b = block(style);

interface PinSetCustomizerProps {
  forcedLocationGroupName?: string;
  loadingLocationGroups: boolean;
  locationGroups: LocationGroup[];
  pinSets: PinSet[];
  onPinSetCreated: () => void;
  onCancel: () => void;
}

enum LocationGroupKey {
  MISSING_REQUIRED_LOCATION_GROUP = 'Please select a data source',
  DATA_SOURCE_DUPLICATE_USAGE = 'A pin set on the battle card is already using this data source. Please select a different data source',
  MISSING_REQUIRED_PIN_SET_NAME = 'Please enter a pin set name'
}

interface PinSetFormValues {
  locationGroupItem: { key: string; value: string } | null;
  pinSetName: string;
  color: string | null;
  icon: PinIcon | null;
}

const PinSetCustomizer: React.FC<PinSetCustomizerProps> = ({
  forcedLocationGroupName,
  loadingLocationGroups,
  locationGroups,
  pinSets,
  onPinSetCreated,
  onCancel
}: PinSetCustomizerProps) => {
  const { selectedBattleCardId } = useMapContextRedistributor();
  const { setSelectedPinSetIds } = useDedicatedMapProvider();
  const [locationGroupSearchText, setLocationGroupSearchText] = useState('');
  const showToast = useShowToast();
  const locationGroupItems = useMemo(
    () =>
      locationGroups
        .filter(
          (lg) =>
            !locationGroupSearchText ||
            lg.locationGroupName.toLowerCase().includes(locationGroupSearchText.toLowerCase())
        )
        .map(({ locationGroupName }) => ({ key: locationGroupName, value: locationGroupName })),
    [locationGroups, locationGroupSearchText]
  );
  const [upsertPinSet] = useMutation<UpsertPinSet, UpsertPinSetVariables>(UPSERT_PIN_SET, {
    onCompleted(data) {
      showToast(formatMessage('CREATE_PIN_SET_SUCCESS'), 'success');
      const newPinSetId = data.upsertPinSet.pinSetId;
      setSelectedPinSetIds((pinSetIds) => addUnique(pinSetIds, newPinSetId));
      onPinSetCreated();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('CREATE_PIN_SET_ERROR'), 'danger');
    },
    refetchQueries: ['GetPinSets']
  });

  const handleSubmit = async (values: PinSetFormValues) => {
    const selectedLocationGroup = locationGroups.find((lg) => lg.locationGroupName === values.locationGroupItem?.key);
    if (!selectedLocationGroup) {
      showToast(formatMessage('CREATE_PIN_SET_ERROR'), 'danger');
      return;
    }

    await upsertPinSet({
      variables: {
        input: {
          battlecardId: +selectedBattleCardId,
          pinSetName: values.pinSetName,
          icon: values.icon,
          color: values.color,
          locationGroupId: selectedLocationGroup.locationGroupId
        }
      }
    });
  };

  return (
    <div className={b()} data-testid="pin-set-customizer">
      <Formik<PinSetFormValues>
        initialValues={{
          locationGroupItem: forcedLocationGroupName
            ? { key: forcedLocationGroupName, value: forcedLocationGroupName }
            : null,
          pinSetName: '',
          color: null,
          icon: null
        }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors: FormikErrors<PinSetFormValues> = {};
          if (!values.locationGroupItem)
            errors.locationGroupItem = {
              key: LocationGroupKey.MISSING_REQUIRED_LOCATION_GROUP,
              value: formatMessage('MISSING_REQUIRED_LOCATION_GROUP')
            };
          const selectedlocationGroup =
            values.locationGroupItem &&
            locationGroups.find((locationGroup) => locationGroup.locationGroupName === values.locationGroupItem.key);
          const isDuplicatedLocationGroup =
            selectedlocationGroup &&
            pinSets.some((pinSet) => pinSet.locationGroupId === selectedlocationGroup.locationGroupId);
          if (isDuplicatedLocationGroup)
            errors.locationGroupItem = {
              key: LocationGroupKey.DATA_SOURCE_DUPLICATE_USAGE,
              value: formatMessage('DATA_SOURCE_DUPLICATE_USAGE')
            };
          const isDuplicatedPinName = pinSets.some((pinSet) => {
            return pinSet.pinSetName === values.pinSetName;
          });
          if (isDuplicatedPinName) errors.pinSetName = formatMessage('PIN_SET_NAME_UNIQUENESS_ERROR');
          if (!values.pinSetName) errors.pinSetName = LocationGroupKey.MISSING_REQUIRED_PIN_SET_NAME;
          if (values.pinSetName.length > MAX_PIN_SET_NAME_LENGTH)
            errors.pinSetName = formatMessage('PIN_SET_NAME_TOO_LONG', { maxCharacters: MAX_PIN_SET_NAME_LENGTH });
          if (!values.color) errors.color = formatMessage('MISSING_REQUIRED_COLOR');
          if (!values.icon) errors.icon = formatMessage('MISSING_REQUIRED_ICON');
          return errors;
        }}
      >
        {({ values, isValid, isSubmitting, setFieldValue }) => (
          <Form>
            <label className={b('locationGroupLabel')}>
              {formatMessage('DATA_SOURCE')}
              <Field
                name="locationGroupItem"
                component={SearchableSelectMenu}
                placeHolderText={formatMessage('PIN_SET_DATA_PLACEHOLDER')}
                label={formatMessage('DATA_SOURCE')}
                initialLoadingComplete={!loadingLocationGroups}
                items={locationGroupItems}
                disabled={!!forcedLocationGroupName}
                onSearch={setLocationGroupSearchText}
                onSearchReset={() => setLocationGroupSearchText('')}
                onSelectItem={(changes: UseComboboxStateChange<SearchableSelectMenuItem>) =>
                  setFieldValue('locationGroupName', changes.selectedItem)
                }
                showErrors={!forcedLocationGroupName}
                showIconInField={false}
                theme="default"
              />
            </label>

            <span>
              <Field
                name="pinSetName"
                type="text"
                label={formatMessage('PIN_SET_NAME')}
                placeHolder={formatMessage('PIN_NAME_PLACEHOLDER')}
                component={FormTextInputGroup}
                autoComplete="off"
              />
            </span>
            <PinSelector
              pinColor={values.color}
              setPinColor={(color) => setFieldValue('color', color)}
              selectedIcon={values.icon}
              setSelectedIcon={(icon) => setFieldValue('icon', icon)}
            />
            <div className={b('buttons')}>
              <TextButton text={formatMessage('CANCEL')} type="button" testId="cancel-button" onClick={onCancel} />
              <TextButton
                text={formatMessage('ADD_PIN_SET')}
                type="submit"
                testId="add-pins-button"
                intent="primary"
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default PinSetCustomizer;
