import React from 'react';

import { Menu, MenuItem } from '@blueprintjs/core';
import { AddAlt } from '@carbon/icons-react';

import Popover from 'components/Popover/Popover';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions } from 'app/graphql/generated/apolloTypes';
import { useDeleteSheetMeasures } from 'app/graphql/mutations/deleteSheetMeasures';

import useShowToast from 'app/hooks/useShowToast';

import { DataSheetDrawerFormType, DataSheetDrawerState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SheetsAddFieldCellRenderer.module.pcss';

const b = block(style);

interface SheetsAddFieldCellRendererProps {
  popoverTarget?: JSX.Element;
  sheetDefinition?: GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions;
}
export const SheetsAddFieldCellRenderer: React.FC<SheetsAddFieldCellRendererProps> = ({
  popoverTarget,
  sheetDefinition
}: SheetsAddFieldCellRendererProps) => {
  const { selectedDeploymentModelId } = useScope();
  const {
    selectedSheet,
    getSheetDefinitions,
    setDataSheetDrawerState,
    setSheetDefinition,
    dataSheetDrawerState,
    setDataSheetDrawerFormType
  } = useData();
  const showToast = useShowToast();

  const [deleteSheetMeasures, { loading: deletingSheetMeasures }] = useDeleteSheetMeasures({
    onError(error) {
      if (!error) return null;

      let errorMessage: string;
      switch (error.knownError?.errorCode) {
        case 'REMOVE_DEFAULT_BALANCING_METRIC':
          errorMessage = formatMessage('REMOVE_DEFAULT_BALANCING_METRIC');
          break;
        case 'REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY': {
          const measureName =
            error.knownError.errorContext?.['measureUsedInFormulaFor']?.[0]?.fieldName ||
            formatMessage('OTHER_MEASURES');
          errorMessage = formatMessage('REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY', {
            measureName
          });
          break;
        }
        default:
          errorMessage = formatMessage('DELETE_FIELD_FAILED');
          break;
      }

      showToast(errorMessage, 'danger');
    },
    onCompleted() {
      showToast(formatMessage('DELETE_FIELD_SUCCESS'), 'success');
      getSheetDefinitions(selectedDeploymentModelId, selectedSheet.sheetId);
    }
  });

  const onDeleteField = () => {
    deleteSheetMeasures({
      variables: { sheetId: selectedSheet?.sheetId, measureIds: [sheetDefinition.measureId] }
    });
  };

  const MenuContent = () => {
    return (
      <Menu data-testid="grid-options-menu">
        <MenuItem
          text={formatMessage('EDIT')}
          onClick={(event) => {
            setDataSheetDrawerFormType(DataSheetDrawerFormType.EDIT);
            setDataSheetDrawerState(DataSheetDrawerState.OPEN);
            setSheetDefinition(sheetDefinition);
            event.stopPropagation();
          }}
          data-testid="edit-measure-menu-item"
        />
        <MenuItem
          disabled={!!sheetDefinition?.defaultMeasureId || deletingSheetMeasures}
          text={formatMessage('DELETE')}
          onClick={(event) => {
            onDeleteField();
            event.stopPropagation();
          }}
          data-testid="delete-measure-menu-item"
        />
      </Menu>
    );
  };

  return (
    <div
      data-testid="quota-sheet-column-menu"
      onClick={(e) => {
        if (!sheetDefinition) {
          // when its not an existing sheet definition, open drawer
          setDataSheetDrawerFormType(DataSheetDrawerFormType.NEW);
          setDataSheetDrawerState(DataSheetDrawerState.OPEN);
          setSheetDefinition(sheetDefinition);
        }
        e.stopPropagation();
      }}
      className={b()}
    >
      <Popover
        content={<MenuContent />}
        placement={'right'}
        className={b('menuCellPopover')}
        disabled={dataSheetDrawerState === DataSheetDrawerState.OPEN}
      >
        {popoverTarget || <AddAlt size={24} data-testid="add-icon" tabIndex="0" />}
      </Popover>
    </div>
  );
};

export default SheetsAddFieldCellRenderer;
