import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetDeploymentModelForPcPage, GetDeploymentModelForPcPageVariables } from 'app/graphql/generated/apolloTypes';

export const GET_DEPLOYMENT_MODEL_FOR_PC_PAGE = gql`
  query GetDeploymentModelForPcPage($deploymentModelId: Int!, $planningCycleId: Int!, $isTQM: Boolean!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId, isTQM: $isTQM }) {
      deploymentModelId
      deploymentModelName
      quotaComponents {
        quotaComponentId
        quotaComponentName
        quotaComponentComment
        canDelete
      }
      isTerritoryWorkflowStarted
      dataSheets {
        isTQM
        sheetId
        sheetName
        sheetType
        sheetCreatedAt
        sheetUpdatedAt
      }
    }
    getWorkflowInitiationStatus(input: { planningCycleId: $planningCycleId, deploymentModelId: $deploymentModelId })
      @skip(if: $isTQM) {
      workflowInitiated
    }
  }
`;

export const useGetDeploymentModelForPcPage = createUseQuery<
  GetDeploymentModelForPcPage,
  GetDeploymentModelForPcPageVariables
>(GET_DEPLOYMENT_MODEL_FOR_PC_PAGE);
