import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetSubtreeCustomHierarchies,
  GetSubtreeCustomHierarchiesVariables,
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeCustomerAccountHierarchies,
  GetSubtreeCustomerAccountHierarchiesVariables,
  GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies,
  GetSubtreeGeographicTerritoryHierarchies,
  GetSubtreeGeographicTerritoryHierarchiesVariables,
  GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
} from 'app/graphql/generated/apolloTypes';

import { HierarchyType } from 'app/models';

import { getSubtreeQueryLookupMap } from 'utils/helpers/getHierarchyLookupMap';
import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const getHierarchyData = async (
  hierarchyType: HierarchyType,
  variables:
    | GetSubtreeCustomHierarchiesVariables
    | GetSubtreeCustomerAccountHierarchiesVariables
    | GetSubtreeGeographicTerritoryHierarchiesVariables
): Promise<
  | GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies
  | GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies
  | GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
> => {
  try {
    const result: ApolloQueryResult<{
      data:
        | GetSubtreeCustomHierarchies
        | GetSubtreeCustomerAccountHierarchies
        | GetSubtreeGeographicTerritoryHierarchies;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: getSubtreeQueryLookupMap[hierarchyType].queryConstant,
      variables,
      fetchPolicy: 'no-cache'
    });

    return result?.data?.[getSubtreeQueryLookupMap[hierarchyType].queryName];
  } catch (error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('HIERARCHY_ERROR'), 'danger');
    return null;
  }
};
