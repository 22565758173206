import { datadogRum } from '@datadog/browser-rum';

import { config } from 'utils/config';

const ALLOWED_RUM_ENVS = ['production-eu', 'production-ue2', 'demo3-ue2'];
const isAllowedRUMEnv = (targetEnv: string) => {
  return ALLOWED_RUM_ENVS.includes(targetEnv);
};

export const initializeAnalytics = (): void => {
  const shouldAllowRUM = isAllowedRUMEnv(config.TARGET_ENV);
  if (shouldAllowRUM) {
    // convert env var strings into numbers
    const sessionSampleRate = config.DATADOG_RUM_SESSION_SAMPLE_RATE ? +config.DATADOG_RUM_SESSION_SAMPLE_RATE : 0;
    const sessionReplaySampleRate = config.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE
      ? +config.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE
      : 0;
    const traceSampleRate = config.DATADOG_RUM_TRACE_SAMPLE_RATE ? +config.DATADOG_RUM_TRACE_SAMPLE_RATE : 0;

    datadogRum.init({
      applicationId: config.DATADOG_RUM_APPLICATION_ID,
      clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'sales-planning',
      env: config.TARGET_ENV,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      actionNameAttribute: 'data-testid',
      allowedTracingUrls: [config.APPSYNC_GRAPHQL_ENDPOINT],
      traceSampleRate
    });
  }
};
