import React from 'react';

import { Card, IllustrationFileBox } from '@varicent/components';

import CyclesPageHeader from 'app/components/CyclesPageHeader/CyclesPageHeader';
import UserMenu from 'app/components/UserMenu/UserMenu';

import AccessibleTenantsList from 'app/containers/App/AccessibleTenantsList/AccessibleTenantsList';

import { GetTenantWideInfo_getUserSpec_membershipSpecs } from 'app/graphql/generated/apolloTypes';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import varicentLogo from 'assets/svgs/varicentlogo.svg';

import style from './TenantAccessErrorPage.module.pcss';

const b = block(style);

interface TenantAccessErrorPageProps {
  membershipSpecs: GetTenantWideInfo_getUserSpec_membershipSpecs[];
}

const TenantAccessErrorPage: React.FC<TenantAccessErrorPageProps> = ({
  membershipSpecs
}: TenantAccessErrorPageProps) => {
  return (
    <div className={b()}>
      <CyclesPageHeader
        logoPath="/"
        logoButton={<img src={varicentLogo} alt={formatMessage('VARICENT_SP')} />}
        rightActionItems={[<UserMenu key="user-menu" />]}
        hideActions
      />

      <div className={b('errorWrapper')}>
        <div className={b('errorCardWrapper')}>
          <Card className={b('errorCard')}>
            <h2 className={b('title')}>{formatMessage('TENANT_ACCESS_ERROR')}</h2>
            <div className={b('illustrationWrapper')}>
              <IllustrationFileBox size={192} />
            </div>
            <div className={b('textWrapper')}>
              <p className={b('text')}>{formatMessage('EMPTY_TENANT_LIST_MESSAGE')}</p>
            </div>
            {membershipSpecs.length > 0 && <AccessibleTenantsList membershipSpecs={membershipSpecs} />}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TenantAccessErrorPage;
