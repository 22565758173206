import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
// TODO TQP-9477 change implementation to use showToast
// eslint-disable-next-line no-restricted-imports
import { Position, Spinner, Toaster } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { SPINNER_DEFAULT_SIZE } from 'components/global/variables';
import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';
import TopDownAllocationToaster from 'components/TopdownAllocationToaster/TopDownAllocationToaster';

import AccountQuotaGrid from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaGrid';
import AccountRuleGrid from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleGrid';
import DeleteSellersActionButton from 'app/components/AdvancedGrid/Sheets/Seller/DeleteSellersActionButton';
import SellerSheetGridV2 from 'app/components/AdvancedGrid/Sheets/Seller/SellerSheetGridV2/SellerSheetGridV2';
import TerritoryQuotaGridContainer from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaGridContainer/TerritoryQuotaGridContainer';
import Assistant from 'app/components/Assistant/Assistant';
import BattleCardCanvas from 'app/components/BattleCardDiagram/Canvas/BattleCardCanvas/BattleCardCanvas';
import BulkDeleteTerritoryDialog from 'app/components/BulkDeleteTerritoryDialog/BulkDeleteTerritoryDialog';
import { StandardGeoCatalogModal } from 'app/components/CommandCenterHierarchyPanel/StandardGeoCatalogModal';
import CommentThread from 'app/components/CommentPanel/CommentPanel';
import DataTray from 'app/components/DataTray/DataTray';
import DataTrayImportButton from 'app/components/DataTray/DataTrayImportButton/DataTrayImportButton';
import QuotaGridActionButtons from 'app/components/DataTray/QuotaGrid/QuotaGridActionButtons/QuotaGridActionButtons';
import QuotaGridContainer from 'app/components/DataTray/QuotaGrid/QuotaGridContainer/QuotaGridContainer';
import TerritoryBalancingGrid from 'app/components/DataTray/TerritoryBalancingGrid/TerritoryBalancingGrid';
import TerritoryBalancingGridActionButtons from 'app/components/DataTray/TerritoryBalancingGrid/TerritoryBalancingGridActionButtons/TerritoryBalancingGridActionButtons';
import TerritoryGridActionButtons from 'app/components/DataTray/TerritoryGrid/TerritoryGridActionButtons/TerritoryGridActionButtons';
import TerritoryGridContainer from 'app/components/DataTray/TerritoryGrid/TerritoryGridContainer/TerritoryGridContainer';
import ImportQuotasDialog from 'app/components/ImportQuotasDialog/ImportQuotasDialog';
import ImportSellerAssignmentsDialog from 'app/components/ImportSellerAssignmentsDialog/ImportSellerAssignmentsDialog';
import MoveTerritoryDialog from 'app/components/MoveTerritoryDialog/MoveTerritoryDialog';
import PlanningCyclePageHeader from 'app/components/PlanningCyclePageHeader/PlanningCyclePageHeader';
import { useSetupQuotaBreakdownHierarchies } from 'app/components/TerritoryMap/hooks/useSetupQuotaBreakdownHierarchies';
import MapVisualizationSettingsDialog from 'app/components/TerritoryMap/MapVisualizationSettingsDialog/MapVisualizationSettingsDialog';
import ScenarioPlanningMap from 'app/components/TerritoryMap/ScenarioPlanningMap/ScenarioPlanningMap';
import TerritoryOptimizationDrawer from 'app/components/TerritoryOptimization/TerritoryOptimizationDrawer/TerritoryOptimizationDrawer';

import ErrorPage, { ErrorType } from 'app/containers/App/ErrorPage/ErrorPage';
import ContributorPage from 'app/containers/Contributor/ContributorPage';
import { findQcIdToSelect } from 'app/containers/PlanningCycles/PlanningCyclePage/planningCyclePageUtils';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useComments } from 'app/contexts/commentProvider';
import { useContributor } from 'app/contexts/contributorProvider';
import { useData } from 'app/contexts/dataProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { usePermissions } from 'app/contexts/permissionsProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import FileUploadDialog from 'app/core/fileUpload/FileUploadDialog/FileUploadDialog';
import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import { useUser } from 'app/core/userManagement/userProvider';

import { SplitFeatures } from 'app/global/features';
import { DEFAULT_REBALANCING_HIERARCHY } from 'app/global/variables';

import {
  RebalanceHierarchyInputTypesEnum,
  RebalanceTerritoryRules,
  RebalanceTerritoryRulesVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useInUseCountryLevels } from 'app/graphql/hooks/useInUseCountryLevels';
import { REBALANCE_TERRITORY_RULES } from 'app/graphql/mutations/rebalanceTerritoryRules';
import { useGetBattlecardLazy } from 'app/graphql/queries/getBattlecard';
import { useGetDataSheetsLazy } from 'app/graphql/queries/getDataSheets';
import { useGetDeploymentModelForPcPage } from 'app/graphql/queries/getDeploymentModelForPcPage';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useCanUser from 'app/hooks/useCanUser';
import useMakePlanningPath from 'app/hooks/useMakePlanningPath';
import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  BattlecardType,
  CategoryName,
  DataTrayContent,
  PlanningCycleModal,
  DeploymentModelPhase,
  FileType,
  HierarchyQuerySpec,
  PeriodicityType,
  PlanningPath,
  RebalanceHierarchyInputKey,
  SectionName,
  SelectedPlanningType,
  SourcePanel,
  TabIds,
  UserRoleType,
  SheetType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './PlanningCyclePage.module.pcss';
import { useDeploymentModelRouting } from './useDeploymentModelRouting';

const b = block(style);
interface PlanningCyclePageProps {
  appContainerRef: MutableRefObject<HTMLDivElement>;
}

const PlanningCyclePage: React.FC<PlanningCyclePageProps> = ({ appContainerRef }) => {
  const { setDefaultReportingCurrency } = useLocalization();
  const [isCopilotEnabled] = useTreatment(SplitFeatures.COPILOT);

  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );
  const deploymentModelPhase = usePhase();
  const { setAllDataSheets, getSheetDefinitions } = useData();
  const { userPlanningCycles, userRole } = useUser();
  const {
    selectedPlanningType,
    selectedPlanningCycle,
    setSelectedPlanningCycle,
    selectedDeploymentModelId,
    selectedTenant,
    setSelectedDeploymentModelId
  } = useScope();
  const {
    contributorWorkflow,
    contributorWorkflowLoading,
    contributorWorkflowError,
    getContributorWorkflow,
    resetValues: resetContributorValues
  } = useContributor();
  const {
    sectionName,
    battleCardLookupMap,
    expandedBattleCardId,
    selectedBattleCardId,
    setQuotaComponentList,
    selectedQuotaComponentId,
    setSelectedQuotaComponentId,
    setShouldRefetchBattleCardDataImmediately,
    setPendingSelectedBattleCardId
  } = useBattleCard();
  const { isCommentPanelOpen } = useComments();
  const {
    setRefreshGrid,
    showActivities,
    setShowActivities,
    showAggregatedActivities,
    setShowAggregatedActivities,
    bulkDeleteTerritoryJobKey,
    showManageTerritoryQuotaDrillIn,
    selectedQuotaDrillInTerritory
  } = useGrid();

  const { showUploadSequenceByFileType } = useFileUpload();
  const {
    sourcePanel,
    setSourcePanel,
    selectedRebalancingHierarchy,
    setSelectedRebalancingHierarchy,
    selectedRebalancingRows,
    selectedData,
    setSelectedData,
    setIsSaved,
    setIsMoved
  } = useRebalancing();
  const { setIsDataTrayMapOpen, isMultiMapOpen } = useMapVariant();
  const { selectedDataTrayTab, setSelectedDataTrayTab } = useDataTray();
  const { countryLevels: inUseCountryLevels } = useInUseCountryLevels();
  const { selectedPillIdPlanTargets, topdownAllocationJobStatusLookupMap } = usePlanTargets();

  const { selectedPillIdTDR, setSelectedPillIdTDR, isActivityCountSelected, setIsActivityCountSelected } =
    useTerritoryDefineAndRefine();
  const { loadingPermissions } = usePermissions();
  const canViewPlan = useCanUser(UserAction.PLAN_VIEW);
  const canViewManage = useCanUser(UserAction.MANAGE_VIEW);
  const canEditSellerAssignment = useCanUser(UserAction.SELLER_ASSIGNMENT_EDIT);
  const canViewMapSettings = useCanUser(UserAction.MAPS_SETTINGS_VIEW);

  const { planningCycleSlug, deploymentModelComponent } = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const makePlanningPath = useMakePlanningPath();
  const showToast = useShowToast();

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const isManagePhase = deploymentModelPhase === DeploymentModelPhase.manage;

  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;
  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isUnassignedSelected = selectedPillId === CategoryName.UNASSIGNED;

  const { data: hierarchies } = useQuery<HierarchyQuerySpec>(GET_ROOT_HIERARCHIES, {
    skip: !selectedPlanningCycle?.id,
    variables: { planningCycleId: selectedPlanningCycle?.id },
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const [getBattlecard, { data: battleCardData }] = useGetBattlecardLazy({
    fetchPolicy: 'network-only',
    variables: {
      battlecardId: +selectedBattleCardId,
      deploymentModelId: selectedDeploymentModelId,
      isTQM: deploymentModelPhase === DeploymentModelPhase.manage
    }
  });

  const [getDataSheets, { data: sheetsData }] = useGetDataSheetsLazy({
    fetchPolicy: 'network-only'
  });

  // feature flags
  const [isTerritoryOptimizationOn] = useTreatment(SplitFeatures.TERRITORY_OPTIMIZATION);
  const [isPermissionEngineOn] = useTreatment(SplitFeatures.PERMISSIONS_ENGINE);
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);

  // data tray specific state

  const [selectedNodes, setSelectedNodes] = useState(null);
  const [selectedHierarchy, setSelectedHierarchy] = useState('');
  const [moveTerritoriesTGTypes, setMoveTerritoriesTGTypes] = useState([]);
  const [isRebalancingSaveEnabled, setIsRebalancingSaveEnabled] = useState(true);
  const [isFilterPresent, setIsFilterPresent] = useState(false);

  const [openedModal, setOpenedModal] = useState<PlanningCycleModal | null>(null);

  const handleIsFilterPresentChange = (isFilterActive: boolean) => {
    setIsFilterPresent(isFilterActive);
  };

  // TODO: TQP-7495 refactor map code out of PlanningCyclePage

  const handleInUseGeo = (): boolean => {
    if (!canViewMapSettings) {
      return false;
    } else if (inUseCountryLevels.length === 0) {
      const openedModal = isAccountFirstMapOn
        ? PlanningCycleModal.MAP_VIZ_SETTINGS
        : PlanningCycleModal.STD_GEO_HIERARCHY;
      setOpenedModal(openedModal);
      return false;
    } else {
      return true;
    }
  };

  const handleOpenStandardGeoHierarchyDialog = () => {
    if (inUseCountryLevels.length === 0) {
      setOpenedModal(PlanningCycleModal.STD_GEO_HIERARCHY);
    } else {
      setOpenedModal(null);
    }
  };

  const handleOpenMap = () => {
    const shouldOpenMap = handleInUseGeo();
    if (shouldOpenMap) {
      setIsDataTrayMapOpen(true);
    }
  };

  const handleCloseMap = () => {
    setIsDataTrayMapOpen(false);
  };

  useEffect(() => {
    if (expandedBattleCardId && sectionName === SectionName.PLAN_TARGETS) {
      if (isManagePhase) {
        setSelectedDataTrayTab(TabIds.MANAGE_TERRITORY);
      } else {
        setSelectedDataTrayTab(Object.keys(dataTrayContent)[0]);
      }
    } else if (expandedBattleCardId && sectionName === SectionName.TERRITORY_DEFINE) {
      setSelectedDataTrayTab(TabIds.PLAN_TERRITORY);
    }
  }, [expandedBattleCardId]);

  useEffect(() => {
    // if planning type or phase changes, set selected tab to first tab of data tray content
    setSelectedDataTrayTab(Object.keys(dataTrayContent)[0]);
  }, [selectedPlanningType, deploymentModelPhase, sheetsData]);

  const planningCycleFromUrl =
    planningCycleSlug &&
    userPlanningCycles?.length > 0 &&
    userPlanningCycles.find((cycle) => cycle && cycle.planningCycleSlug === planningCycleSlug);

  const relevantDms = useDeploymentModelRouting();
  const dmIdToSelect = relevantDms.selected?.deploymentModelId ?? null;
  useEffect(() => {
    setSelectedDeploymentModelId(dmIdToSelect);
  }, [dmIdToSelect]);

  const canvasRef = useRef();

  useSetupQuotaBreakdownHierarchies(hierarchies);

  // eslint-disable-next-line no-restricted-syntax
  const isUserContributor = userRole === UserRoleType.CONTRIBUTOR;
  // eslint-disable-next-line no-restricted-syntax
  const isUserNone = userRole === UserRoleType.NONE;

  useEffect(() => {
    if (
      isUserContributor &&
      selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING &&
      planningCycleFromUrl &&
      selectedDeploymentModelId
    ) {
      resetContributorValues();
      getContributorWorkflow(selectedDeploymentModelId, planningCycleFromUrl.planningCycleId);
      void getDataSheets({
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          isTQM: false,
          battlecardId: +selectedBattleCardId,
          territoryGroupId: +selectedPillIdPlanTargets
        }
      });
    }
  }, [selectedPlanningType, planningCycleFromUrl, selectedDeploymentModelId]);

  useEffect(() => {
    if (isUserContributor && selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING) {
      void getDataSheets({
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          isTQM: false,
          battlecardId: +selectedBattleCardId,
          territoryGroupId: +selectedPillIdPlanTargets
        }
      });
    }
  }, [selectedBattleCardId, selectedPillIdPlanTargets]);

  useEffect(() => {
    if (!isUserContributor && selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING) {
      void getDataSheets({
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          isTQM: false,
          battlecardId: +selectedBattleCardId,
          quotaComponentId: selectedQuotaComponentId
        }
      });
    }
  }, [selectedDeploymentModelId, selectedBattleCardId, selectedQuotaComponentId, selectedPlanningType]);

  useEffect(() => {
    if (!planningCycleFromUrl) return;
    setSelectedPlanningCycle({
      id: planningCycleFromUrl.planningCycleId,
      slug: planningCycleFromUrl.planningCycleSlug,
      periodicity: PeriodicityType[planningCycleFromUrl.planningCyclePeriodicity.toUpperCase()],
      planningCycleDuration: planningCycleFromUrl.planningCycleDuration,
      planningCycleStartDate: planningCycleFromUrl.planningCycleStartDate.toString(),
      planningCycleName: planningCycleFromUrl.planningCycleName
    });
    if (planningCycleFromUrl.currencyCode) {
      setDefaultReportingCurrency(planningCycleFromUrl.currencyCode);
    }
  }, [planningCycleFromUrl]);

  const { loading: isDmForPcLoading, data: dmForPcData } = useGetDeploymentModelForPcPage({
    skip: !dmIdToSelect || !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id,
      deploymentModelId: dmIdToSelect,
      isTQM: deploymentModelPhase === DeploymentModelPhase.manage
    },
    fetchPolicy: 'network-only',
    onError: () => {
      showToast(formatMessage('DEPLOYMENT_MODEL_ERROR'), 'danger');
    },
    onCompleted: (data) => {
      const deploymentModel = data.getDeploymentModelSpec;
      if (deploymentModel?.quotaComponents?.length) {
        setQuotaComponentList(deploymentModel.quotaComponents);
        setSelectedQuotaComponentId(findQcIdToSelect(deploymentModel.quotaComponents, selectedQuotaComponentId));
        setShouldRefetchBattleCardDataImmediately(true);
      }
      if (deploymentModel?.dataSheets?.length) {
        setAllDataSheets(deploymentModel.dataSheets);
      }
    }
  });

  const workflowState = useMemo(
    () => ({
      isLoading: isDmForPcLoading,
      isQuotaWorkflowStarted: dmForPcData?.getWorkflowInitiationStatus?.workflowInitiated ?? false,
      isTerritoryWorkflowStarted: dmForPcData?.getDeploymentModelSpec?.isTerritoryWorkflowStarted === 'true'
    }),
    [dmForPcData, isDmForPcLoading]
  );

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    if (userRole === UserRoleType.ADMIN) return;
    if (deploymentModelPhase === DeploymentModelPhase.manage) return;
    if (workflowState.isLoading) return;
    if (isPermissionEngineOn && loadingPermissions) return;

    const canViewQuotaPlanning = workflowState.isQuotaWorkflowStarted;
    const canViewTerritoryPlanning =
      workflowState.isTerritoryWorkflowStarted || (isPermissionEngineOn && (canViewPlan || canViewManage));

    if (!canViewQuotaPlanning && !canViewTerritoryPlanning) {
      history.push(`/${selectedTenant.slug}`);
      return;
    }
    if (
      selectedPlanningType === SelectedPlanningType.TERRITORY_PLANNING &&
      !canViewTerritoryPlanning &&
      canViewQuotaPlanning
    ) {
      history.push(
        makePlanningPath({
          viewParam: PlanningPath.quota
        })
      );
      return;
    }
    if (
      selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING &&
      !canViewQuotaPlanning &&
      canViewTerritoryPlanning
    ) {
      history.push(
        makePlanningPath({
          viewParam: PlanningPath.territory
        })
      );
    }
  }, [
    userRole,
    deploymentModelPhase,
    workflowState,
    isPermissionEngineOn,
    loadingPermissions,
    canViewPlan,
    canViewManage,
    selectedPlanningType
  ]);

  const [rebalanceTerritoryRules] = useMutation<RebalanceTerritoryRules, RebalanceTerritoryRulesVariables>(
    REBALANCE_TERRITORY_RULES
  );

  const handleRebalancingMove = () => {
    setIsMoved(true);
  };

  const handleRebalancingSave = async () => {
    showToast(formatMessage('REBALANCING_SUCCESS'), 'success');
    setSourcePanel(null);
    setSelectedData([]);
    setIsSaved(true);
    setIsMoved(false);
    setPendingSelectedBattleCardId(null);

    await rebalanceTerritoryRules({
      variables: {
        input: {
          hierarchyType:
            selectedRebalancingHierarchy.key === RebalanceHierarchyInputKey.GEOGRAPHIES
              ? RebalanceHierarchyInputTypesEnum.GeographicTerritoryHierarchy
              : RebalanceHierarchyInputTypesEnum.CustomerAccountHierarchy,
          sourceRuleId: selectedRebalancingRows[sourcePanel === SourcePanel.LEFT ? 0 : 1].ruleId,
          targetRuleId: selectedRebalancingRows[sourcePanel === SourcePanel.LEFT ? 1 : 0].ruleId,
          hierarchies: selectedData.map((row) => parseInt(row.id))
        }
      }
    });
  };

  const ContributorContent = () => {
    if (contributorWorkflowLoading && !contributorWorkflow) {
      return (
        <div className={b('spinner')} data-testid="spinner">
          <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
        </div>
      );
    } else {
      if (!contributorWorkflowError) {
        return <ContributorPage forwardedRef={canvasRef} data-testid="contributor-page" />;
      } else {
        return <ErrorPage errorType={ErrorType._500} />;
      }
    }
  };

  const MaybeBattleCardCanvas = () => {
    if (planningCycleFromUrl) {
      return selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING ? (
        <CanUser
          perform={UserAction.CONTRIBUTOR_PAGE_VIEW}
          yes={ContributorContent()}
          no={<BattleCardCanvas data-testid="battle-card-canvas" />}
        />
      ) : (
        <BattleCardCanvas data-testid="battle-card-canvas" />
      );
    }

    return <ErrorPage errorType={ErrorType._401} />;
  };

  useEffect(() => {
    // reset the activity view
    if (showActivities) {
      setSelectedDataTrayTab(TabIds.PLAN_TERRITORY);
    }
  }, [isActivityCountSelected, showActivities]);

  // when a new pill or an activityCount is selected,
  // check if the data tray should be showing activities
  useEffect(() => {
    if (isUnassignedSelected || isActivityCountSelected) {
      setShowActivities(true);
    } else if (!isUnassignedSelected && !isActivityCountSelected) {
      setShowActivities(false);
    }
  }, [isUnassignedSelected, isActivityCountSelected, selectedPillIdTDR]);

  // reset rebalancingHierarchy
  useEffect(() => {
    setSelectedRebalancingHierarchy(DEFAULT_REBALANCING_HIERARCHY);

    if (selectedDataTrayTab === TabIds.ACCOUNT_QUOTA) {
      getBattlecard();
    }
    if (selectedDataTrayTab?.includes(TabIds.QUOTA)) {
      const selectedSheetId = selectedDataTrayTab.substring(TabIds.QUOTA.length);
      void getSheetDefinitions(selectedDeploymentModelId, +selectedSheetId);
    }
  }, [selectedDataTrayTab, selectedBattleCardId]);

  const goBack = () => {
    if (showActivities) {
      setShowActivities(false);
      setIsActivityCountSelected(false);
    }

    if (showAggregatedActivities) {
      setShowAggregatedActivities(false);
      setSelectedHierarchy('');
    }

    if (isUnassignedSelected) {
      // clear selected pill
      setSelectedPillIdTDR(null);
    }
  };

  const getDataTrayContent = (): Record<string, DataTrayContent> => {
    const isPlanPhase = deploymentModelPhase === DeploymentModelPhase.plan;

    const dataTrayContent: Record<string, DataTrayContent> = {};

    // territory - plan
    if (isPlanPhase) {
      if (selectedPlanningType === SelectedPlanningType.TERRITORY_PLANNING) {
        dataTrayContent[TabIds.PLAN_TERRITORY] = {
          tabId: TabIds.PLAN_TERRITORY,
          tabName: formatMessage('TERRITORY_DEFINE_REFINE'),
          gridComponent: (
            <TerritoryGridContainer
              hierarchies={hierarchies}
              selectedHierarchy={selectedHierarchy}
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
              setOpenedModal={setOpenedModal}
              setMoveTerritoriesTGTypes={setMoveTerritoriesTGTypes}
              onIsFilterPresentChange={handleIsFilterPresentChange}
            />
          ),
          actionButtons: (
            <TerritoryGridActionButtons
              hierarchies={hierarchies}
              selectedHierarchy={selectedHierarchy}
              setSelectedHierarchy={setSelectedHierarchy}
              selectedNodes={selectedNodes}
              setOpenedModal={setOpenedModal}
              onOpenMap={handleOpenMap}
              onCloseMap={handleCloseMap}
              moveTerritoriesTGTypes={moveTerritoriesTGTypes}
              isFilterPresent={isFilterPresent}
            />
          )
        };
        dataTrayContent[TabIds.PLAN_SELLER] = {
          tabId: TabIds.PLAN_SELLER,
          tabName: formatMessage('SELLER_ASSIGNMENT'),
          gridComponent: <SellerSheetGridV2 data-testid="tqm-seller-gridV2" />,
          actionButtons: (
            <>
              <DeleteSellersActionButton
                tenantId={selectedTenant.id}
                quotaComponentId={null}
                data-testid="tqp-delete-seller-btn"
              />
              <DataTrayImportButton
                disabled={!selectedBattleCardId || isRootBattleCard || isRollUpBattleCard || !canEditSellerAssignment}
                tooltipText={formatMessage('IMPORT_SELLER_ASSIGNMENTS')}
                onClick={() => setOpenedModal(PlanningCycleModal.IMPORT_SELLER_ASSIGNMENTS)}
                data-testid="tqp-import-seller-btn"
              />
            </>
          )
        };
        dataTrayContent[TabIds.TERRITORY_BALANCING] = {
          tabId: TabIds.TERRITORY_BALANCING,
          tabName: formatMessage('TERRITORY_BALANCING'),
          gridComponent: (
            <TerritoryBalancingGrid setSaveStatus={setIsRebalancingSaveEnabled} onSave={handleRebalancingSave} />
          ),
          actionButtons: (
            <TerritoryBalancingGridActionButtons
              onSave={handleRebalancingSave}
              onMove={handleRebalancingMove}
              hierarchies={hierarchies}
              isSaveEnabled={isRebalancingSaveEnabled}
            />
          )
        };
        dataTrayContent[TabIds.ACCOUNT_LIST] = {
          tabId: TabIds.ACCOUNT_LIST,
          tabName: formatMessage('ACCOUNT_LIST'),
          gridComponent: <AccountRuleGrid data-testid="account-rule-grid" key={selectedBattleCardId} />
        };
      } // quota
      else if (selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING) {
        if (sheetsData) {
          const sheets = sheetsData.getDeploymentModelSpec?.dataSheets || [];
          const quotaSheets = sheets.filter((sheet) => sheet.sheetType === SheetType.QUOTA_SHEET);
          quotaSheets.forEach((sheet) => {
            dataTrayContent[TabIds.QUOTA + sheet.sheetId] = {
              tabId: TabIds.QUOTA,
              tabName: sheet.sheetName,
              quotaSheetId: TabIds.QUOTA + sheet.sheetId,
              gridComponent: <QuotaGridContainer sheetId={sheet.sheetId} />,
              actionButtons: <QuotaGridActionButtons setOpenedModal={setOpenedModal} />
            };
          });
        } else {
          dataTrayContent[TabIds.LOADING_SHEETS_DATA] = {
            tabId: TabIds.QUOTA,
            tabName: formatMessage('LOADING_SHEETS_DATA'),
            gridComponent: <></>,
            actionButtons: <></>
          };
        }
      }
    } else if (!isPlanPhase) {
      // territory - manage
      dataTrayContent[TabIds.MANAGE_TERRITORY] = {
        tabId: TabIds.MANAGE_TERRITORY,
        tabName: formatMessage('TERRITORY_QUOTA'),
        gridComponent: <TerritoryQuotaGridContainer data-testid="territory-grid-container" />,
        actionButtons: showManageTerritoryQuotaDrillIn && isAccountMoveInTerritoryQuotaGridEnabled && (
          <div data-testid="territory-quota-drill-in-header">
            {formatMessage('TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN', {
              effectiveDate: dayjs(selectedQuotaDrillInTerritory?.effectiveDate).format('MM/DD/YYYY'),
              endDate: dayjs(selectedQuotaDrillInTerritory?.endDate).format('MM/DD/YYYY')
            })}
          </div>
        )
      };

      dataTrayContent[TabIds.MANAGE_SELLER] = {
        tabId: TabIds.MANAGE_SELLER,
        tabName: formatMessage('SELLER_QUOTA'),
        gridComponent: <SellerSheetGridV2 data-testid="tqm-seller-gridV2" />,
        actionButtons: (
          <DeleteSellersActionButton
            tenantId={selectedTenant.id}
            quotaComponentId={selectedQuotaComponentId}
            data-testid="tqm-seller-grid-action-btn"
          />
        )
      };

      dataTrayContent[TabIds.ACCOUNT_QUOTA] = {
        tabId: TabIds.ACCOUNT_QUOTA,
        tabName: formatMessage('ACCOUNT_QUOTA'),
        gridComponent: (
          <AccountQuotaGrid
            territoryGroupTypes={battleCardData?.getDeploymentModelSpec?.battlecards?.[0]?.territoryGroupTypes}
            data-testid="tqm-account-quota-grid"
          />
        )
      };
    }

    return dataTrayContent;
  };

  const dataTrayContent = getDataTrayContent();

  const shouldHideDataTray =
    deploymentModelComponent === 'change-requests' ||
    isMultiMapOpen ||
    (isUserContributor && contributorWorkflowError && selectedPlanningType === SelectedPlanningType.QUOTA_PLANNING);
  return (
    <div className={b()} data-testid={'planning-cycle-page'} ref={setContainerElement}>
      {isCopilotEnabled && <Assistant data-testid="planning-cycle-page-assistant" />}
      <PlanningCyclePageHeader
        planningCycleName={planningCycleFromUrl?.planningCycleName}
        isTerritoryWorkflowStarted={workflowState.isTerritoryWorkflowStarted}
        isQuotaWorkflowStarted={workflowState.isQuotaWorkflowStarted}
        manageDeploymentModelId={relevantDms.manage?.deploymentModelId}
        planDeploymentModelId={relevantDms.plan?.deploymentModelId}
        publishedDeploymentModel={relevantDms.planPublished}
        data-testid="planning-cycle-page-header"
      />

      <Switch>
        <Route
          path={'/:tenantSlug/:planningCycleSlug/:deploymentModelSlug/compare'}
          exact
          render={() => <ScenarioPlanningMap />}
        />
        <Route
          path={['/:tenantSlug/:planningCycleSlug/:deploymentModelSlug']}
          exact
          render={() => (
            <CanUser
              perform={UserAction.BATTLE_CARD_CANVAS_VIEW}
              yes={<BattleCardCanvas data-testid="battle-card-canvas" />}
              no={MaybeBattleCardCanvas()}
            />
          )}
        />
        <Route path={`/:tenantSlug/:planningCycleSlug`} exact render={() => MaybeBattleCardCanvas()} />
      </Switch>
      {selectedPlanningType === SelectedPlanningType.NONE && deploymentModelPhase === DeploymentModelPhase.plan && (
        <Redirect
          to={makePlanningPath({
            viewParam: PlanningPath.territory
          })}
        />
      )}
      {planningCycleFromUrl && !isUserNone && !shouldHideDataTray && (
        <>
          <DataTray
            content={dataTrayContent}
            selectedTab={selectedDataTrayTab}
            onSelectedTabChange={setSelectedDataTrayTab}
            onBackClick={goBack}
            canvasRef={canvasRef}
            appContainerRef={appContainerRef}
            data-testid="data-tray"
          />
          {isCommentPanelOpen && <CommentThread />}
        </>
      )}
      {showUploadSequenceByFileType && showUploadSequenceByFileType[FileType.TERRITORY_RULE] && (
        <FileUploadDialog
          dialogTitle={formatMessage('ADD_TERRITORIES')}
          fileUploadType={FileType.TERRITORY_RULE}
          onProcessingCompleted={() => setRefreshGrid(true)}
          data-testid="upload-dialog"
        />
      )}
      {openedModal === PlanningCycleModal.MOVE_TERRITORY && (
        <MoveTerritoryDialog
          battleCardId={selectedBattleCardId}
          onClose={() => setOpenedModal(null)}
          setSelectedNodes={setSelectedNodes}
          selectedNodes={selectedNodes}
          moveTerritoriesTGTypes={moveTerritoriesTGTypes}
        />
      )}
      {openedModal === PlanningCycleModal.IMPORT_QUOTAS && <ImportQuotasDialog onClose={() => setOpenedModal(null)} />}
      {openedModal === PlanningCycleModal.IMPORT_SELLER_ASSIGNMENTS && (
        <ImportSellerAssignmentsDialog onClose={() => setOpenedModal(null)} />
      )}
      {openedModal === PlanningCycleModal.STD_GEO_HIERARCHY && (
        <StandardGeoCatalogModal
          cancelButtonText={formatMessage('BACK')}
          showMapMessage
          onClose={() => setOpenedModal(PlanningCycleModal.MAP_VIZ_SETTINGS)}
          onCompleted={() => setOpenedModal(null)}
        />
      )}
      {openedModal === PlanningCycleModal.MAP_VIZ_SETTINGS && (
        <MapVisualizationSettingsDialog
          isLastStep={inUseCountryLevels.length > 0}
          onClose={() => {
            setOpenedModal(null);
          }}
          onComplete={handleOpenStandardGeoHierarchyDialog}
        />
      )}
      {openedModal === PlanningCycleModal.BULK_DELETE_TERRITORY && (
        <BulkDeleteTerritoryDialog
          onClose={() => {
            setOpenedModal(null);
          }}
          selectedNodes={selectedNodes}
        />
      )}
      {isTerritoryOptimizationOn && (
        <TerritoryOptimizationDrawer containerElement={containerElement} data-testid="territory-optimization-drawer" />
      )}
      {bulkDeleteTerritoryJobKey && (
        <Toaster position={Position.TOP_RIGHT}>
          <JobProgressStatusToast
            jobDetail={{ jobKey: bulkDeleteTerritoryJobKey }}
            title={formatMessage('START_BULK_DELETE_TERRITORY_TITLE')}
            progressDescription={formatMessage('START_BULK_DELETE_TERRITORY_GENERIC')}
            data-testid="territory-rule-delete-progress-toast"
            onPollSuccess={() => {
              // TODO use success callback instead of hardcoding logic in component
              return;
            }}
            onPollError={() => {
              // TODO use success callback instead of hardcoding logic in component
              return;
            }}
          />
        </Toaster>
      )}
      <Toaster position={Position.TOP_RIGHT}>
        {topdownAllocationJobStatusLookupMap.map(({ jobStatus, battlecardId, quotaComponentId, selectedPillId }) => {
          if (jobStatus) {
            return (
              <TopDownAllocationToaster
                jobDetail={{ battlecardId, quotaComponentId, selectedPillId }}
                data-testid={`allocate-top-down-progress-toast-${battlecardId}-${quotaComponentId}`}
                key={`allocate-top-down-progress-toast-${battlecardId}-${quotaComponentId}`}
              />
            );
          }
          return null;
        })}
      </Toaster>
    </div>
  );
};

export default PlanningCyclePage;
