import React, { FC, useMemo } from 'react';

import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { useGetBattleCardsForEmbeddedMap } from 'app/graphql/queries/getBattlecardsForEmbeddedMap';

import { HierarchyType } from 'app/models';

import {
  MapContextRedistributorContextValues,
  MapContextRedistributorProvider
} from './mapContextRedistributorProvider';

type MapComparisonMcrLoaderProps = {
  isPrimaryMap?: boolean;
};

export const MapComparisonMcrLoader: FC<MapComparisonMcrLoaderProps> = ({ isPrimaryMap = true, children }) => {
  const { mapCompareState } = useMapVariant();
  const { coinsortProgressLookupMap } = useCoinsort();

  const selectedMapState = isPrimaryMap ? mapCompareState?.primary : mapCompareState?.secondary;

  const selectedDeploymentModelId = selectedMapState?.deploymentModelId;
  const selectedBattleCardId = selectedMapState?.battlecardId.toString();
  const selectedQuotaComponentId = selectedMapState?.quotaComponentId;
  const selectedTerritoryGroupTypeId = selectedMapState?.geoTgtId;

  const { data: battleCardData, loading: isBattlecardDataLoading } = useGetBattleCardsForEmbeddedMap({
    fetchPolicy: 'network-only',
    skip: !selectedBattleCardId,
    variables: {
      input: {
        deploymentModelId: selectedDeploymentModelId,
        battlecardId: +selectedBattleCardId
      }
    }
  });

  const selectedBattleCard = useMemo(() => battleCardData?.getDeploymentModelSpec?.battlecards[0], [battleCardData]);

  // Transformed values
  const isMapPointerDisabled = false;
  const coinsortStatus = coinsortProgressLookupMap[selectedBattleCardId]?.coinsortStatus ?? null;

  const selectedTerritoryGroupId = selectedTerritoryGroupTypeId;

  const contextValue = useMemo(
    (): MapContextRedistributorContextValues => ({
      // From useBattleCard
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      // Transformed
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      isEmbedded: false,
      selectedDeploymentModelId,
      isBattlecardDataLoading,
      externalControlSuffix: isPrimaryMap ? '' : 'Secondary',
      primaryHierarchy: HierarchyType.GeographicTerritoryHierarchy
    }),
    [
      selectedBattleCardId,
      selectedQuotaComponentId,
      selectedTerritoryGroupTypeId,
      selectedTerritoryGroupId,
      coinsortStatus,
      isMapPointerDisabled,
      selectedBattleCard,
      selectedDeploymentModelId,
      isBattlecardDataLoading,
      mapCompareState
    ]
  );

  return <MapContextRedistributorProvider value={contextValue}> {children}</MapContextRedistributorProvider>;
};
