import React, { useState } from 'react';

import Dialog from 'components/Dialog/Dialog';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import FileUploadSequence from 'app/core/fileUpload/FileUploadSequence/FileUploadSequence';

import { GetRootHierarchies_getRootHierarchies } from 'app/graphql/generated/apolloTypes';

import { FileType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const hierarchyTypeMap = {
  CustomHierarchy: 'CUSTOM_HIERARCHY',
  CustomerAccountHierarchy: 'CUSTOMER_ACCOUNT_HIERARCHY',
  GeographicTerritoryHierarchy: 'GEOGRAPHIC_TERRITORY_HIERARCHY'
};

interface HierarchyUploadDialogProps {
  hierarchyToUpload: GetRootHierarchies_getRootHierarchies | null;
}

const HierarchyUploadDialog: React.FC<HierarchyUploadDialogProps> = ({
  hierarchyToUpload
}: HierarchyUploadDialogProps) => {
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(true);

  const { rootHierarchyId, hierarchyType } = hierarchyToUpload;
  const mappedHierarchyType = hierarchyTypeMap[hierarchyType];

  const { setForceRefresh } = useCommandCenter();
  const { showUploadHierarchyDialog, setShowUploadHierarchyDialog } = useFileUpload();

  const resetStates = () => {
    setShowUploadHierarchyDialog(false);
  };

  const handleUploadCancel = () => {
    setDisableComplete(false);
    resetStates();
  };

  const handleComplete = () => {
    setDisableCancel(false);
    setShowUploadHierarchyDialog(false);
    setForceRefresh(true);
  };

  const dialogProps = {
    confirmButtonText: formatMessage('COMPLETE'),
    disableConfirm: disableComplete,
    disableCancel,
    confirmButtonLoading: false,
    onSubmit: handleComplete,
    onClose: handleUploadCancel
  };

  return (
    <Dialog
      isOpen={showUploadHierarchyDialog}
      title={formatMessage('UPLOAD_TO', { name: hierarchyToUpload?.rootName })}
      cancelButtonText={formatMessage('CANCEL')}
      data-testid="hierarchy-upload-dialog"
      {...dialogProps}
    >
      <FileUploadSequence
        fileUploadType={FileType[mappedHierarchyType]}
        setDisableDialogCancel={setDisableCancel}
        setDisableDialogComplete={setDisableComplete}
        hierarchyId={rootHierarchyId}
        data-testid="hierarchy-upload-sequence"
      />
    </Dialog>
  );
};

export default HierarchyUploadDialog;
