import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import {
  GetAccountQuotaRedirectDistribution,
  GetAccountQuotaRedirectDistributionVariables
} from 'app/graphql/generated/apolloTypes';

export const GET_ACCOUNT_QUOTA_REDIRECT_DISTRIBUTION_TOTALS = gql`
  query GetAccountQuotaRedirectDistributionTotals($input: GetTerritoryQuotaInput!) {
    getTerritoryQuota(input: $input) {
      accountRedirectQuotaDistributionTotals {
        editable
        measureId
        measureName
        measureValue
        measureValueBreakdown {
          measureValue
          periodEndDate
          periodStartDate
        }
      }
    }
  }
`;

export const useGetAccountQuotaRedirectDistributionTotalsLazy = createUseLazyQuery<
  GetAccountQuotaRedirectDistribution,
  GetAccountQuotaRedirectDistributionVariables
>(GET_ACCOUNT_QUOTA_REDIRECT_DISTRIBUTION_TOTALS);
