import React, { useCallback, useEffect, useState } from 'react';

import { Classes, Divider } from '@blueprintjs/core';
import { ChevronRight } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';
import { Field, Form, Formik } from 'formik';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import SelectMenu from 'components/SelectMenu/SelectMenu';

import { getHierarchyData } from 'app/components/CommandCenterHierarchyPanel/hooks/useGetSubtreeHierarchy';
import FormattedCurrencyInput from 'app/components/FormFields/FormattedCurrencyInput/FormattedCurrencyInput';
import FormSwitch from 'app/components/FormFields/FormSwitch/FormSwitch';
import SeasonalityDialog from 'app/components/SeasonalityDialog/SeasonalityDialog';
import AllocationSummary from 'app/components/TerritoriesAndPlanTargets/AllocationSummary/AllocationSummary';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import {
  GetFieldTotalsVariables,
  GetSheetMeasures_getDeploymentModelSpec_sheetMeasures,
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies_items
} from 'app/graphql/generated/apolloTypes';
import { useGetSheetDefinitions } from 'app/graphql/hooks/useGetSheetDefinitions';
import { useGetTDAJobProgress } from 'app/graphql/hooks/useGetTDAJobProgress';
import { useGetFieldTotalsLazy } from 'app/graphql/queries/getFieldTotals';
import { useGetTDAByHierarchyLazy } from 'app/graphql/queries/getTDAByHierarchy';

import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import {
  HierarchyType,
  DefaultSheetName,
  DeploymentModelPhase,
  JobStatus,
  MeasureFieldType,
  MeasureType,
  PlanTargetsMeasureAllowed,
  SellerSheetGridColumnName,
  SheetDefinitionType,
  SheetType,
  HierarchyBasedTargetItem,
  onAllocateParams,
  TabIds
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage, formatCurrency } from 'utils/messages/utils';

import { ReactComponent as DoubleChevronLeft } from 'assets/svgs/double-chevron-left.svg';

import style from './ExpandedPlanTargetsDrawer.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

const defaultMeasuresForTDA = [MeasureType.PRIOR_YEAR_ACTUAL];
const HIERARCHY_TARGET = 'hierarchyTarget';

const calculateNewAllocation = (topDown: number, preserveQuotaAdjustments: boolean, adjustments: number) => {
  const newAdjustment = preserveQuotaAdjustments ? adjustments : 0;

  return {
    newTopDown: topDown,
    newAdjustment,
    newQuota: topDown + newAdjustment
  };
};

const RunButton = ({
  disabled = false,
  loading = false,
  isHierarchyBasedTargetEnabled = false
}: {
  disabled?: boolean;
  loading?: boolean;
  isHierarchyBasedTargetEnabled?: boolean;
}) => {
  return (
    <TextButton
      text={formatMessage(isHierarchyBasedTargetEnabled ? 'ALLOCATE_QUOTAS' : 'RUN')}
      intent="primary"
      type="submit"
      disabled={disabled}
      loading={loading}
      testId={'run-button'}
    />
  );
};

const getTerritoryMessage = (territoryCount = 0) => {
  return formatMessage(territoryCount === 1 ? 'TERRITORY_WITH_COUNT' : 'TERRITORIES_WITH_COUNT', {
    totalCount: territoryCount.toLocaleString()
  });
};

interface ExpandedPlanTargetsDrawerProps {
  title: string;
  isLoading: boolean;
  allocatedTopDownValue: number;
  updatedQuotaValue: number;
  quotaAdjustmentValue: number;
  territoryCount: number;
  measures: GetSheetMeasures_getDeploymentModelSpec_sheetMeasures[];
  onAllocate: (params: onAllocateParams) => void;
  businessTarget: number;
}

const ExpandedPlanTargetsDrawer: React.FC<ExpandedPlanTargetsDrawerProps> = ({
  title,
  isLoading,
  allocatedTopDownValue,
  updatedQuotaValue,
  quotaAdjustmentValue,
  territoryCount,
  measures,
  onAllocate,
  businessTarget
}: ExpandedPlanTargetsDrawerProps) => {
  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const { selectedDeploymentModelId, selectedPlanningCycle } = useScope();
  const { sortModel } = useGrid();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const deploymentModelPhase = usePhase();
  const { selectedPillIdPlanTargets, onUpdatePlanTargets, getPlanTargets } = usePlanTargets();
  const { allDataSheets } = useData();
  const { selectedDataTrayTab } = useDataTray();
  const [isHierarchyBasedTargetEnabled] = useTreatment(SplitFeatures.HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING);
  const [preserveSeasonalityForTDAFeatureFlag] = useTreatment(SplitFeatures.PRESERVE_SEASONALITY_TDA);

  const [showSeasonalityDialog, setShowSeasonalityDialog] = useState(false);
  const [hierarchyData, setHierarchyData] = useState<GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies_items[]>(
    []
  );

  const jobDetail = { battlecardId: +selectedBattleCardId, quotaComponentId: selectedQuotaComponentId };
  const { getJobProgressForTopdownAllocation, data: jobProgress } = useGetTDAJobProgress(jobDetail);
  const allocateTopDownJobStatus = jobProgress?.getJobProgress?.allocateTopDownJobOutput?.status;

  const isBattleCardCurrencyConversionValid = !battleCardLookupMap?.[selectedBattleCardId]?.invalidConversionReason;
  const battleCardLocalCurrency = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;
  const isHierarchyBasedTargetSelected = battleCardLookupMap?.[
    selectedBattleCardId
  ]?.quotaDistributionHierarchies?.some((hierarchy) => hierarchy.isAllocationTarget);

  const isTerritorySelected = !!title;

  const defaultSellerSheetId = allDataSheets.filter(
    (sheet) => sheet.sheetType === SheetType.SELLER_SHEET && sheet.sheetName === DefaultSheetName.SELLER_QUOTA_SHEET
  )?.[0]?.sheetId;

  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;

  const { sheetDefinitions } = useGetSheetDefinitions({
    deploymentModelId: selectedDeploymentModelId,
    sheetId: +defaultSellerSheetId,
    isTQM
  });

  const bcQuotaDistributionHierarchies = battleCardLookupMap[selectedBattleCardId]?.quotaDistributionHierarchies;
  const targetSettingHierarchyRootId = bcQuotaDistributionHierarchies?.find(
    (hierarchy) => hierarchy.isAllocationTarget
  )?.hierarchyRootId;

  const loadSubTreeData = useCallback(async () => {
    const result = (await getHierarchyData(HierarchyType.CustomHierarchy, {
      planningCycleId: selectedPlanningCycle.id,
      hierarchyId: targetSettingHierarchyRootId,
      depth: 1,
      startRow: 0,
      endRow: 0 /* set startRow and endRow to 0 to retrieve the full list */
    })) as GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies;

    if (result?.items) {
      // filter out null catcher hierarchy
      const targetSettingHierarchies = result.items.filter((hierarchy) => {
        const customProperties = JSON.parse(hierarchy.customProperties);
        return customProperties?.isNullCatcher !== true;
      });
      setHierarchyData(targetSettingHierarchies);
    }
    return result;
  }, [targetSettingHierarchyRootId]);

  useEffect(() => {
    if (targetSettingHierarchyRootId) {
      loadSubTreeData();
    }
  }, [targetSettingHierarchyRootId]);

  useEffect(() => {
    if (allocateTopDownJobStatus === JobStatus.COMPLETED) {
      onUpdatePlanTargets();
      getPlanTargets(
        selectedBattleCardId,
        selectedQuotaComponentId,
        selectedPlanningCycle.id,
        selectedDeploymentModelId
      );
    }
  }, [allocateTopDownJobStatus]);

  const sellerSheetDefinitionLists = sheetDefinitions?.[0]?.sheetDefinitions;

  const assignedSellerQuotaId = sellerSheetDefinitionLists?.find(
    (measure) => measure?.measureName === SellerSheetGridColumnName.ACTUAL_SELLER_QUOTA
  )?.measureId;

  const assignedTerritoryId = sellerSheetDefinitionLists?.find(
    (measure) => measure?.measureName === SellerSheetGridColumnName.ACTUAL_TERRITORY_QUOTA
  )?.measureId;

  const measureId = 0;
  const fieldIds = [assignedSellerQuotaId, assignedTerritoryId];
  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const fieldTotalVariables: GetFieldTotalsVariables = {
    quotaComponentId: selectedQuotaComponentId,
    battlecardId: +selectedBattleCardId,
    measureId,
    sorting: sortModel,
    fieldIds,
    territoryGroupId: +selectedPillId || null,
    startRow: 1,
    endRow: 1
  };

  const [getFieldTotals, { data: territoryRulesData }] = useGetFieldTotalsLazy({
    variables: fieldTotalVariables,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    getFieldTotals();
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedPillId]);

  const fieldTotals = territoryRulesData?.getTerritoryRules?.fieldTotals;

  const revisedTerritoryQuotaTotal =
    fieldTotals?.find((total) => total?.fieldId === assignedTerritoryId)?.fieldValue || null;
  const assignedSellerQuotaTotal =
    fieldTotals?.find((total) => total?.fieldId === assignedSellerQuotaId)?.fieldValue || null;
  const isPlanPhase = deploymentModelPhase === DeploymentModelPhase.plan;

  const [showDrawer, setShowDrawer] = useState(isTerritorySelected);

  const toggleDrawer = () => {
    setShowDrawer((prevState) => {
      return !prevState;
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    // the backend expects a boolean representing whether the quota adjustment will be cleared,
    // instead of whether it will be preserved
    const allocationParams: onAllocateParams = {
      measureToProrateBy: values.basedOn,
      clearQuotaAdjustment: !values.preserveQuotaAdjustments,
      preserveSeasonality: values.preserveSetSeasonality
    };
    if (isHierarchyBasedTargetEnabled && targetSettingHierarchyRootId) {
      allocationParams.hierarchyTarget = Object.entries(values)
        .filter(([key]) => key.startsWith(HIERARCHY_TARGET))
        .map(([key, value]) => {
          const hierarchyId = +key.split('-')[1];
          return { hierarchyId, targetValue: +value };
        });
    } else {
      allocationParams.amount = parseFloat(values.allocation);
    }
    onAllocate(allocationParams);
    resetForm();
  };

  useEffect(() => {
    setShowDrawer(isTerritorySelected);
  }, [title]);

  useEffect(() => {
    const getJobProgressInput = {
      variables: {
        input: {
          allocateTopDownJobInput: {
            battlecardId: jobDetail.battlecardId,
            quotaComponentId: jobDetail.quotaComponentId
          }
        }
      }
    };
    if (isPlanPhase) {
      getJobProgressForTopdownAllocation(getJobProgressInput);
    }
  }, [selectedBattleCardId, selectedQuotaComponentId]);

  const [getTDAByHierarchy, { data: quotaByHierarchies }] = useGetTDAByHierarchyLazy({
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    // The format for selectedDataTrayTab is allocate-quotaXXXXX with XXXXX being the sheetId
    const selectedSheetId = +selectedDataTrayTab.replace(TabIds.QUOTA, '');
    const isHierarchyBasedTargetSelected = battleCardLookupMap?.[
      selectedBattleCardId
    ]?.quotaDistributionHierarchies?.some((hierarchy) => hierarchy.isAllocationTarget);
    if (isPlanPhase && selectedSheetId && isHierarchyBasedTargetSelected) {
      const tdaByHierarchyVariables = {
        battlecardId: +selectedBattleCardId,
        quotaComponentId: selectedQuotaComponentId,
        territoryGroupId: selectedPillId ? +selectedPillId : null,
        sheetId: selectedSheetId
      };
      getTDAByHierarchy({ variables: tdaByHierarchyVariables });
    }
  }, [selectedBattleCardId, selectedPillId, selectedQuotaComponentId, selectedDataTrayTab]);

  const tdaSummaryData = quotaByHierarchies?.getTerritoryQuota?.quotaByHierarchy || [];
  const getSummaryTargetItems = (measureName) => {
    const summary = tdaSummaryData.find((data) => data.measureName === measureName)?.hierarchyQuotaInfo || [];
    return summary.map(({ hierarchyId, hierarchyName, hierarchyQuota }) => {
      return {
        hierarchyId,
        hierarchyKey: hierarchyName,
        hierarchyName,
        value: hierarchyQuota
      };
    });
  };

  const hierarchyBasedTargets = getSummaryTargetItems(MeasureType.ALLOCATE_TD);
  const quotaAdjustmentTargets = getSummaryTargetItems(MeasureType.QUOTA_ADJUSTMENT);

  // prepend Even Split to proration options if hierarchy based target setting is not selected, convert measures to key-value pairs for dropdown
  let prorationOptions = [];
  if (!isHierarchyBasedTargetSelected || !isHierarchyBasedTargetEnabled) {
    prorationOptions.push({
      key: PlanTargetsMeasureAllowed.EVENSPLIT,
      value: 0
    });
  }
  prorationOptions = [
    ...prorationOptions,
    ...(measures || [])
      .filter((measure) => {
        // Historical balancing metric fields or any fields defined in defaultMeasuresForTDA
        return (
          defaultMeasuresForTDA.includes(measure.measureName as MeasureType) ||
          (measure.measureFieldType === MeasureFieldType.HISTORICAL &&
            measure.sheetDefinitionType !== SheetDefinitionType.NOT_BALANCING_METRIC)
        );
      })
      .map((measure) => {
        return {
          key: measure.measureName,
          value: measure.measureId
        };
      })
  ];

  const isAllocateTopDownLoading =
    allocateTopDownJobStatus === JobStatus.IN_PROGRESS || allocateTopDownJobStatus === JobStatus.PENDING;

  const renderPreserveSeasonality = () => {
    if (!preserveSeasonalityForTDAFeatureFlag) {
      return null;
    }

    return (
      <div className={b('quotaAdjustmentItem')}>
        <div className={b('allocationItemControl')}>
          <Field
            label={formatMessage('PRESERVE_SET_SEASONALITY')}
            name="preserveSetSeasonality"
            component={FormSwitch}
            data-testid="preserve-set-seasonality"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {!showDrawer && (
        <div
          className={b('toggleButton', { disabled: !isTerritorySelected })}
          onClick={isTerritorySelected ? toggleDrawer : undefined}
          data-testid="drawer-open-button"
        >
          <DoubleChevronLeft />
        </div>
      )}
      {showSeasonalityDialog && (
        <SeasonalityDialog
          isOpen={showSeasonalityDialog}
          setShowSeasonalityDialog={setShowSeasonalityDialog}
          data-testid="seasonality-dialog"
        />
      )}
      {isTerritorySelected && (
        <div className={b({ minimized: !showDrawer })}>
          <div className={b('header')}>
            <div className={b('headerText')}>
              <div className={b('territoryGroupName')} data-testid="drawer-title">
                <HTMLHeading bold tagLevel="h4" text={title} data-testid="drawer-label" />
              </div>
              {isLoading ? (
                <div
                  className={`${Classes.SKELETON} ${b('territoryCountSkeleton')}`}
                  data-testid="territory-count-loading"
                />
              ) : (
                <HTMLHeading
                  tagLevel="h5"
                  text={` (${getTerritoryMessage(territoryCount)})`}
                  data-testid="territory-count"
                />
              )}
            </div>
            <IconButton type="button" icon={<ChevronRight />} onClick={toggleDrawer} testId="drawer-close-button" />
          </div>
          <div className={b('existingAllocation')}>
            <HTMLHeading bold tagLevel="h5" text={formatMessage('SUMMARY')} data-testid="summary-header" />
            <HTMLHeading tagLevel="h6" text={formatMessage('EXISTING')} data-testid="existing-header" />
            {isPlanPhase ? (
              <AllocationSummary
                topDown={allocatedTopDownValue}
                adjustment={quotaAdjustmentValue}
                plannedQuota={updatedQuotaValue}
                loading={isLoading}
                businessTarget={businessTarget}
                data-testid="allocation-summary"
                hierarchyBasedTargets={hierarchyBasedTargets}
                quotaAdjustmentTargets={quotaAdjustmentTargets}
              />
            ) : (
              <>
                {selectedPillIdPlanTargets === 'battlecard' && (
                  <>
                    <AllocationSummary
                      businessTarget={businessTarget}
                      revisedQuota={+revisedTerritoryQuotaTotal}
                      loading={isLoading}
                      data-testid="manage-allocation-summary"
                    />
                    <Divider />
                    <br />
                  </>
                )}
                <AllocationSummary
                  revisedQuota={+revisedTerritoryQuotaTotal}
                  assignedQuota={+assignedSellerQuotaTotal}
                  loading={isLoading}
                  data-testid="manage-allocation-summary-secondary"
                />
              </>
            )}
          </div>
          {isPlanPhase && (
            <Formik
              initialValues={{
                allocation: null,
                basedOn: prorationOptions[0],
                preserveQuotaAdjustments: true,
                preserveSetSeasonality: preserveSeasonalityForTDAFeatureFlag
              }}
              onSubmit={handleSubmit}
              validationSchema={validationsSchema}
            >
              {({ values, isValid, isSubmitting, setFieldValue }) => {
                // hierarchy based target field has field name format: hierarchyTarget-<hierarchyId>-<key>-<name>
                const hierarchyBasedTargets: HierarchyBasedTargetItem[] = Object.entries(values)
                  .filter(([key]) => key.startsWith(HIERARCHY_TARGET))
                  .map(([key, value]) => {
                    const [, hierarchyId, hierarchyKey, hierarchyName] = key.split('-');
                    return { hierarchyId: +hierarchyId, hierarchyKey, hierarchyName, value };
                  });
                const hierarchyBasedTargetSum = Object.keys(values)
                  .filter((key) => key.startsWith(HIERARCHY_TARGET))
                  .reduce((total, key) => total + values[key], 0);
                if (hierarchyBasedTargetSum && hierarchyBasedTargetSum !== values.allocation) {
                  setFieldValue('allocation', hierarchyBasedTargetSum);
                }
                const canSubmit = values.allocation && isValid;

                const { newTopDown, newAdjustment, newQuota } = calculateNewAllocation(
                  parseInt(values.allocation, 10),
                  values.preserveQuotaAdjustments,
                  quotaAdjustmentValue || 0
                );

                return !isHierarchyBasedTargetEnabled ? (
                  <Form className={b('newAllocation')}>
                    <div className={b('newAllocationForm')}>
                      <Divider />
                      <HTMLHeading tagLevel="h6" text={formatMessage('NEW')} data-testid="new-header" />
                      <div className={b('newAllocationFormItem')}>
                        <span>{formatMessage('ALLOCATE_TOP_DOWN')}</span>
                        <div className={b('allocationItemControl')}>
                          <Field
                            name="allocation"
                            placeholder={formatMessage('ADD_NUMBER')}
                            component={FormattedCurrencyInput}
                            localCurrencyCode={battleCardLocalCurrency}
                            showErrors={false}
                            disabled={!isBattleCardCurrencyConversionValid}
                            data-testid="top-down-new-value"
                          />
                        </div>
                      </div>
                      <div className={b('newAllocationFormItem')}>
                        <span>{formatMessage('BASED_ON')}</span>
                        <div className={b('allocationItemControl')} data-testid="allocation-item-control">
                          <Field
                            label={formatMessage('BASED_ON')}
                            name="basedOn"
                            items={prorationOptions}
                            component={SelectMenu}
                            allowFlip={false}
                            disabled={!isBattleCardCurrencyConversionValid}
                            theme="default"
                            data-testid="prorate-new-value"
                            showErrors={false}
                          />
                        </div>
                      </div>
                      <div className={b('quotaAdjustmentItem')}>
                        <div className={b('allocationItemControl')}>
                          <Field
                            label={formatMessage('PRESERVE_QUOTA_ADJUSTMENTS')}
                            name="preserveQuotaAdjustments"
                            component={FormSwitch}
                            disabled={!isBattleCardCurrencyConversionValid}
                            data-testid="preserve-quota-adjustments-new-value"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={b('newAllocationPreview', { hidden: !canSubmit })}>
                      <AllocationSummary
                        topDown={newTopDown}
                        adjustment={newAdjustment}
                        plannedQuota={newQuota}
                        loading={false}
                        businessTarget={businessTarget}
                        data-testid="allocation-summary"
                      />
                    </div>
                    <div className={b('runButton')}>
                      {!territoryCount ? (
                        <MessageTooltip
                          content={formatMessage('ADD_TERRITORIES_TOOLTIP')}
                          target={<RunButton disabled loading={isAllocateTopDownLoading} />}
                          usePortal={false}
                          placement={'right'}
                          data-testid="add-territories-tooltip"
                        />
                      ) : (
                        <RunButton
                          loading={isSubmitting || isAllocateTopDownLoading}
                          disabled={!canSubmit || !isBattleCardCurrencyConversionValid}
                        />
                      )}
                    </div>
                  </Form>
                ) : (
                  <Form className={b('newAllocation')}>
                    <div className={b('newAllocationForm')}>
                      <Divider />
                      <HTMLHeading tagLevel="h6" text={formatMessage('NEW')} data-testid="new-header" />
                      <div className={b('newAllocationFormItem')}>
                        <span>{formatMessage('ALLOCATE_QUOTAS_BASED_ON')}</span>
                        <div className={b('allocationItemControl')} data-testid="allocation-item-control">
                          <Field
                            label={formatMessage('ALLOCATE_QUOTAS_BASED_ON')}
                            name="basedOn"
                            items={prorationOptions}
                            component={SelectMenu}
                            allowFlip={false}
                            disabled={!isBattleCardCurrencyConversionValid}
                            theme="default"
                            data-testid="prorate-new-value"
                            showErrors={false}
                          />
                        </div>
                      </div>

                      <div className={b('newAllocationFormItem')}>
                        <span>{formatMessage('QUOTA_TARGET_TOP_DOWN')}</span>
                        {targetSettingHierarchyRootId ? (
                          <div className={b('allocationItemControl', { bold: true })}>
                            <span data-testid="hierarchy-target-sum">
                              {formatCurrency(hierarchyBasedTargetSum, battleCardLocalCurrency, 2)}
                            </span>
                          </div>
                        ) : (
                          <div className={b('allocationItemControl')}>
                            <Field
                              name="allocation"
                              placeholder={formatMessage('ADD_NUMBER')}
                              component={FormattedCurrencyInput}
                              localCurrencyCode={battleCardLocalCurrency}
                              showErrors={false}
                              disabled={!isBattleCardCurrencyConversionValid}
                              data-testid="top-down-new-value"
                            />
                          </div>
                        )}
                      </div>
                      {hierarchyData.length > 0 && (
                        <>
                          <HTMLHeading
                            tagLevel="h6"
                            text={hierarchyData[0].parentKey}
                            data-testid="top-down-hierarchy-header"
                          />
                          <div className={b('newAllocationFormItem', { scrollable: true })}>
                            {hierarchyData.map((data) => {
                              return (
                                <React.Fragment key={data.key}>
                                  <span>{data.name}</span>
                                  <div className={b('allocationItemControl')}>
                                    <Field
                                      name={`${HIERARCHY_TARGET}-${data.hierarchyId}-${data.key}-${data.name}`}
                                      placeholder={formatMessage('ADD_NUMBER')}
                                      component={FormattedCurrencyInput}
                                      localCurrencyCode={battleCardLocalCurrency}
                                      showErrors={false}
                                      disabled={!isBattleCardCurrencyConversionValid}
                                      data-testid="hierarchy-target-new-value"
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      )}

                      <div className={b('quotaAdjustmentItem')}>
                        <div className={b('allocationItemControl')}>
                          <Field
                            label={formatMessage('PRESERVE_QUOTA_ADJUSTMENTS')}
                            name="preserveQuotaAdjustments"
                            component={FormSwitch}
                            disabled={!isBattleCardCurrencyConversionValid}
                            data-testid="preserve-quota-adjustments-new-value"
                          />
                        </div>
                      </div>

                      {renderPreserveSeasonality()}
                    </div>

                    <div className={b('newAllocationPreview', { hidden: !canSubmit })}>
                      <AllocationSummary
                        topDown={newTopDown}
                        adjustment={newAdjustment}
                        plannedQuota={newQuota}
                        loading={false}
                        businessTarget={businessTarget}
                        hierarchyBasedTargets={targetSettingHierarchyRootId && hierarchyBasedTargets}
                        data-testid="allocation-summary"
                      />
                    </div>
                    <div className={b('runButton')}>
                      {!territoryCount ? (
                        <MessageTooltip
                          content={formatMessage('ADD_TERRITORIES_TOOLTIP')}
                          target={
                            <RunButton
                              disabled
                              loading={isAllocateTopDownLoading}
                              isHierarchyBasedTargetEnabled={isHierarchyBasedTargetEnabled}
                            />
                          }
                          usePortal={false}
                          placement={'right'}
                          data-testid="add-territories-tooltip"
                        />
                      ) : (
                        <RunButton
                          loading={isSubmitting || isAllocateTopDownLoading}
                          disabled={!canSubmit || !isBattleCardCurrencyConversionValid}
                          isHierarchyBasedTargetEnabled={isHierarchyBasedTargetEnabled}
                        />
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      )}
    </>
  );
};

export default ExpandedPlanTargetsDrawer;
