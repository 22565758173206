import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTerritoryRulesForFields, GetTerritoryRulesForFieldsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_TERRITORY_RULES_FOR_FIELDS = gql`
  query GetTerritoryRulesForFields(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $searchInput: GetTerritoryRulesSearchInput
    $territoryGroupId: Int
    $battlecardId: Int
    $startRow: Int
    $endRow: Int
    $fieldIds: [Int]!
    $rangeFieldInput: GetRangeFieldInput
    $includeRedirectExists: Boolean!
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        territoryGroupId: $territoryGroupId
        battlecardId: $battlecardId
        startRow: $startRow
        endRow: $endRow
        fieldIds: $fieldIds
        rangeFieldInput: $rangeFieldInput
        searchInput: $searchInput
      }
    ) {
      territoryRules {
        fields {
          fieldId
          fieldValue
          owners {
            fieldValue
            memberId
            ownerId
          }
          rangeId
          effectiveStartDate
          effectiveEndDate
        }
        owners {
          firstName
          lastName
          memberId
          ownerId
          ownership
          employeeId
          effectiveEndDate
          effectiveStartDate
        }
        territoryRangeFields {
          fieldId
          fieldName
          fieldValue
          effectiveStartDate
          effectiveEndDate
          rangeId
          territoryId
          breakdownByPeriod {
            period
            value
          }
        }
        territoryGroupId
        territoryGroupName
        ruleId
        territoryId
        territoryName
        effectiveDate
        endDate
        redirectExists @include(if: $includeRedirectExists)
      }
      totalCount
      fieldTotals {
        fieldId
        fieldValue
      }
    }
  }
`;

export const useGetTerritoryRulesForFieldsLazy = createUseLazyQuery<
  GetTerritoryRulesForFields,
  GetTerritoryRulesForFieldsVariables
>(GET_TERRITORY_RULES_FOR_FIELDS);
