import React, { useState, useEffect } from 'react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { formatMessage } from 'utils/messages/utils';

interface TimerProps {
  time: Date;
}

const Timer: React.FC<TimerProps> = ({ time }: TimerProps) => {
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  const getTime = (time) => {
    const timeDuration = Math.abs(Date.now() - Date.parse(time));
    return {
      hours: Math.floor((timeDuration / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDuration / 1000 / 60) % 60),
      seconds: Math.floor((timeDuration / 1000) % 60)
    };
  };

  useEffect(() => {
    if (seconds === null) {
      const { hours, minutes, seconds } = getTime(time);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }
    const interval = setInterval(() => {
      const { hours, minutes, seconds } = getTime(time);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getMessage = (hours, minutes) => {
    let message;
    if (hours === 1) {
      message = formatMessage('ONE_HOUR_AGO');
    } else if (hours > 1) {
      message = formatMessage('HOURS_AGO', { numHours: hours });
    } else if (minutes > 1) {
      message = formatMessage('MINUTES_AGO', { numMinutes: minutes });
    } else if (minutes === 1) {
      message = formatMessage('A_MINUTE_AGO');
    } else {
      message = formatMessage('JUST_NOW');
    }
    return message;
  };

  useEffect(() => {
    getMessage(hours, minutes);
  }, [hours, minutes]);

  return (
    <div data-testid="timer">
      {seconds !== null && (
        <MessageTooltip
          data-testid="territory-tooltip"
          content={time?.toLocaleTimeString()}
          target={<div data-testid="timer-message">{getMessage(hours, minutes)}</div>}
          placement={'top'}
          usePortal={false}
        />
      )}
    </div>
  );
};

export default Timer;
