import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useScope } from 'app/contexts/scopeProvider';

import {
  GetInUseCountryLevels,
  GetInUseCountryLevelsVariables,
  GetInUseCountryLevels_getPlanningCycleSpec_countryLevels
} from 'app/graphql/generated/apolloTypes';
import { GET_IN_USE_COUNTRY_LEVELS } from 'app/graphql/queries/getInUseCountryLevels';

export const useInUseCountryLevels = (): {
  countryLevels: GetInUseCountryLevels_getPlanningCycleSpec_countryLevels[];
  loading: boolean;
} => {
  const { selectedPlanningCycle } = useScope();

  const { loading, data } = useQuery<GetInUseCountryLevels, GetInUseCountryLevelsVariables>(GET_IN_USE_COUNTRY_LEVELS, {
    skip: !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    }
  });

  const countryLevels = useMemo(() => data?.getPlanningCycleSpec?.countryLevels ?? [], [data]);
  return {
    countryLevels,
    loading
  };
};
