// This file is not being used. Keep it here in case we need it later.
import React, { useState, useMemo } from 'react';

import { ApolloError, ApolloQueryResult, isApolloError } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { SortDirection } from 'app/graphql/generated/apolloTypes';
import { GET_MEMBER_LIST } from 'app/graphql/queries/getMemberList';

import { useContextSafe } from 'app/hooks/useContextSafe';

import { BaseContext, MemberList } from 'app/models/index';

export interface MemberContextValues extends BaseContext {
  members: { key: string; value: number }[];
  getMembers: (number) => void;
  memberLoading: boolean;
  memberError: ApolloError;
  resetValues: () => void;
}

export const MemberContext = React.createContext<MemberContextValues | null>(null);
MemberContext.displayName = 'MemberContext';

export const MemberProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [members, setMembers] = useState(null);
  const [memberLoading, setMembersLoading] = useState<boolean>(false);
  const [memberError, setMembersError] = useState<ApolloError | null>(null);

  const getMembers = async (tenantId: number) => {
    setMembersLoading(true);
    setMembersError(null);
    try {
      const memberData: ApolloQueryResult<{
        getMemberList: MemberList;
        // eslint-disable-next-line no-restricted-syntax
      }> = await apolloClient.query({
        query: GET_MEMBER_LIST,
        fetchPolicy: 'network-only',
        variables: {
          tenantId,
          endRow: 20,
          startRow: 1,
          sorting: {
            sortModel: [
              {
                colId: 'lastName',
                sort: SortDirection.asc
              }
            ]
          }
        }
      });
      const owners = [];
      memberData?.data?.getMemberList?.memberList?.forEach((member) => {
        owners.push({ key: `(${member.employeeId}) ${member.lastName}, ${member.firstName}`, value: member.memberId });
      });
      setMembers(owners);
    } catch (error) {
      console.log(error);

      if (error instanceof Error && isApolloError(error)) {
        setMembersError(error);
      }
    } finally {
      setMembersLoading(false);
    }
  };

  const resetValues = () => {
    setMembers(null);
    setMembersLoading(false);
    setMembersError(null);
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(() => {
    return {
      members,
      getMembers,
      memberLoading,
      memberError,
      resetValues
    };
  }, [members, memberLoading, memberError]);

  // Return the interface that we want to expose to our other components
  return <MemberContext.Provider value={values}>{children}</MemberContext.Provider>;
};

// Custom hook to read these values from
export const useMember = (): MemberContextValues => useContextSafe(MemberContext);
