import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { PlanningCycleExistsByName, PlanningCycleExistsByNameVariables } from 'app/graphql/generated/apolloTypes';

export const PLANNING_CYCLE_EXISTS_BY_NAME = gql`
  query PlanningCycleExistsByName($tenantId: Int!, $planningCycleName: String!) {
    planningCycleExistsByName(input: { tenantId: $tenantId, planningCycleName: $planningCycleName }) {
      exists
      slugName
    }
  }
`;

export const usePlanningCycleExistsByNameLazy = createUseLazyQuery<
  PlanningCycleExistsByName,
  PlanningCycleExistsByNameVariables
>(PLANNING_CYCLE_EXISTS_BY_NAME);
