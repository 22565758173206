import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetDataSheets, GetDataSheetsVariables } from 'app/graphql/generated/apolloTypes';

export const GET_DATA_SHEETS = gql`
  query GetDataSheets(
    $deploymentModelId: Int!
    $isTQM: Boolean
    $battlecardId: Int
    $quotaComponentId: Int
    $territoryGroupId: Int
  ) {
    getDeploymentModelSpec(
      input: {
        deploymentModelId: $deploymentModelId
        isTQM: $isTQM
        battlecardId: $battlecardId
        quotaComponentId: $quotaComponentId
        territoryGroupId: $territoryGroupId
      }
    ) {
      dataSheets {
        isTQM
        sheetId
        sheetName
        sheetType
        sheetCreatedAt
        sheetUpdatedAt
      }
    }
  }
`;

export const useGetDataSheetsLazy = createUseLazyQuery<GetDataSheets, GetDataSheetsVariables>(GET_DATA_SHEETS);
