import { MutableRefObject } from 'react';

import { ColDef } from '@ag-grid-community/core';

import { getActivityFilesPanelCellRenderer } from 'app/components/AdvancedGrid/CellRenderers/ActivityFilesPanelCellRenderer/ActivityFilesPanelCellRenderer';

import { DataTableFileStatusType } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const buildActivityFilesPanelColumnDef = (totalCount: number, rowRef: MutableRefObject<HTMLDivElement>): ColDef[] => {
  const columnDefs: ColDef[] = [];

  columnDefs.push({
    headerName: formatMessage('NAME'),
    field: 'tableName',
    flex: 1,
    minWidth: 500,
    cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.DEFAULT, totalCount, rowRef)
  });

  columnDefs.push({
    headerName: formatMessage('TYPE'),
    field: 'tableType',
    flex: 1,
    minWidth: 250,
    valueFormatter: (params) => {
      if (params?.data?.tableType === 'Upload') {
        return params.data.tableDataType;
      }
    }
  });

  // columnDefs.push({
  //   headerName: FILE_LAST_UPDATED,
  //   field: 'metaDataUpdateAt',
  //   flex: 1,
  //   minWidth: 300,
  //   cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.DATE, totalCount, rowRef)
  // });

  columnDefs.push({
    headerName: formatMessage('STATUS'),
    field: 'status',
    flex: 1,
    minWidth: 250,
    cellRendererFramework: getActivityFilesPanelCellRenderer(DataTableFileStatusType.STATUS, totalCount, rowRef)
  });

  return columnDefs;
};

export default buildActivityFilesPanelColumnDef;
