import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Position, Toaster } from '@blueprintjs/core';

import JobProgressStatusToast from 'components/JobProgressStatusToast/JobProgressStatusToast';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import { GetJobProgress } from 'app/graphql/generated/apolloTypes';

import useShowToast from 'app/hooks/useShowToast';

import { JobStatus } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface DeleteScenarioToastProps {
  scenarioName: string;
  planningCycleId: number;
  jobKey: string;
}

const DeleteScenarioToast: React.FC<DeleteScenarioToastProps> = ({
  scenarioName,
  planningCycleId,
  jobKey
}: DeleteScenarioToastProps) => {
  const showToast = useShowToast();

  const onDeletePollSuccess = (data: GetJobProgress, stopPolling: () => void) => {
    if (data?.getJobProgress?.pcCloneJobOutput?.pcCloneStatus === JobStatus.COMPLETED) {
      const messageCompleted = formatMessage('SCENARIO_DELETED_MESSAGE', { name: scenarioName });
      showToast(<ToastMessage title={formatMessage('SCENARIO_DELETED')} message={messageCompleted} />, 'success');
      stopPolling();
    }
  };

  const onDeletePollError = () => {
    const title = formatMessage('SCENARIO_DELETED_FAILURE');
    const message = formatMessage('SCENARIO_DELETED_FAILURE_MESSAGE', { name: scenarioName });
    showToast(<ToastMessage title={title} message={message} />, 'danger');
  };

  return (
    <Toaster position={Position.TOP_RIGHT}>
      <JobProgressStatusToast
        data-testid="delete-scenario-status-toast"
        key={planningCycleId}
        jobKey={jobKey}
        planningCycleId={planningCycleId}
        title={formatMessage('DELETING_SCENARIO')}
        progressDescription={formatMessage('DELETION_STARTED_MESSAGE', { name: scenarioName })}
        onPollSuccess={onDeletePollSuccess}
        onPollError={onDeletePollError}
      />
    </Toaster>
  );
};

export default DeleteScenarioToast;
