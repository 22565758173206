import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import DialogFooter from 'components/Dialog/DialogFooter/DialogFooter';

import FormTextArea from 'app/components/FormFields/FormTextArea/FormTextArea';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import HierarchyAttributesFormFields from 'app/components/HierarchyAttributesFormFields/HierarchyAttributesFormFields';
import { formatAttributesToJSON } from 'app/components/HierarchyEditDialog/HierarchyEditDialog';

import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import FileUploadSequence from 'app/core/fileUpload/FileUploadSequence/FileUploadSequence';

import { SplitFeatures } from 'app/global/features';

import { UpsertCustomHierarchy, UpsertCustomHierarchyVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_CUSTOM_HIERARCHY } from 'app/graphql/mutations/upsertHierarchy';
import { GET_PINNED_HIERARCHIES } from 'app/graphql/queries/getPinnedHierarchies';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { FileType, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchyCreationDialog.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

enum ColumnTypeDropDownKeys {
  TEXT = 'Text',
  COMMENT = 'Comment',
  DATE = 'Date',
  EMAIL = 'Email',
  NUMERIC = 'Numeric',
  PICK_LIST = 'Pick List',
  URL = 'URL'
}

const columnTypeDropDownData = [
  { key: ColumnTypeDropDownKeys.TEXT, value: 'text' },
  { key: ColumnTypeDropDownKeys.COMMENT, value: 'comment' },
  { key: ColumnTypeDropDownKeys.DATE, value: 'date' },
  { key: ColumnTypeDropDownKeys.EMAIL, value: 'email' },
  { key: ColumnTypeDropDownKeys.NUMERIC, value: 'number' },
  { key: ColumnTypeDropDownKeys.PICK_LIST, value: 'pickList' },
  { key: ColumnTypeDropDownKeys.URL, value: 'url' }
];

enum DefaultAttributeNames {
  KEY = 'Key',
  NAME = 'Name',
  PARENT_KEY = 'ParentKey',
  EFFECTIVE_DATE = 'EffectiveDate',
  END_DATE = 'EndDate'
}

const columnTypeDropDown = {
  theme: 'default',
  items: columnTypeDropDownData,
  defaultItem: columnTypeDropDownData[0],
  menuName: 'columnType'
};

const hierarchyCreationDialogDefaultAttributes = [
  {
    editable: false,
    name: DefaultAttributeNames.KEY,
    type: columnTypeDropDown.defaultItem
  },
  {
    editable: false,
    name: DefaultAttributeNames.NAME,
    type: columnTypeDropDown.defaultItem
  },
  {
    editable: false,
    name: DefaultAttributeNames.PARENT_KEY,
    type: columnTypeDropDown.defaultItem
  }
];

const effectiveDatingAttributes = [
  {
    editable: false,
    name: DefaultAttributeNames.EFFECTIVE_DATE,
    type: { key: ColumnTypeDropDownKeys.DATE, value: 'date' }
  },
  {
    editable: false,
    name: DefaultAttributeNames.END_DATE,
    type: { key: ColumnTypeDropDownKeys.DATE, value: 'date' }
  }
];

enum DialogPage {
  INITIAL_SETUP_PAGE = 'Initial setup page',
  FILE_UPLOAD_SEQUENCE = 'File upload sequence'
}

const HierarchyCreationDialog: React.FC = () => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const showToast = useShowToast();
  const [isHierarchyEffectiveDatingOn] = useTreatment(SplitFeatures.HIERARCHY_EFFECTIVE_DATING);

  const [currentDialogPage, setCurrentDialogPage] = useState<DialogPage>(DialogPage.INITIAL_SETUP_PAGE);
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [hierarchyId, setHierarchyId] = useState<number | null>(null);

  const { showAddHierarchyDialog, setShowAddHierarchyDialog } = useFileUpload();

  const [upsertCustomHierarchy, { loading, error: upsertCustomHierarchyError }] = useMutation<
    UpsertCustomHierarchy,
    UpsertCustomHierarchyVariables
  >(UPSERT_CUSTOM_HIERARCHY, {
    onCompleted(data) {
      const id = data?.upsertCustomHierarchy?.hierarchyId;
      setHierarchyId(id);
      showToast(formatMessage('CREATE_NEW_HIERARCHY_SUCCESS'), 'success');
      setCurrentDialogPage(DialogPage.FILE_UPLOAD_SEQUENCE);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      setShowError(true);
    }
  });

  const resetStates = () => {
    setCurrentDialogPage(DialogPage.INITIAL_SETUP_PAGE);
    setShowError(false);
    setShowAddHierarchyDialog(false);
  };

  const handleUploadCancel = async () => {
    setDisableComplete(false);
    resetStates();
  };

  const handleComplete = async () => {
    setDisableCancel(false);
    resetStates();
  };

  const submitForm = (values) => {
    const { name, attributes } = values;

    upsertCustomHierarchy({
      variables: {
        planningCycleId: selectedPlanningCycle?.id,
        name,
        version: 1,
        key: name,
        customProperties: formatAttributesToJSON(attributes, HierarchyType.CustomHierarchy)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ROOT_HIERARCHIES,
          variables: { planningCycleId: selectedPlanningCycle?.id }
        },
        {
          query: GET_PINNED_HIERARCHIES,
          variables: { deploymentModelId: selectedDeploymentModelId, includeFirstLevelMemberCount: false }
        }
      ]
    });
  };

  return (
    <Dialog
      isOpen={showAddHierarchyDialog}
      title={formatMessage('ADD_HIERARCHY')}
      data-testid="hierarchy-creation-dialog"
      showDialogFooter={false}
    >
      <Formik
        initialValues={{
          name: '',
          description: '',
          attributes: isHierarchyEffectiveDatingOn
            ? [...hierarchyCreationDialogDefaultAttributes, ...effectiveDatingAttributes]
            : hierarchyCreationDialogDefaultAttributes
        }}
        validationSchema={validationsSchema}
        onSubmit={submitForm}
      >
        {({ handleSubmit }) => {
          return (
            <>
              {currentDialogPage === DialogPage.INITIAL_SETUP_PAGE ? (
                <>
                  <Form>
                    <div className={b('name')}>
                      <Field label={formatMessage('NAME')} name="name" type="text" component={FormTextInputGroup} />
                    </div>
                    <div className={b('description')}>
                      <Field
                        label={formatMessage('DESCRIPTION')}
                        name="description"
                        component={FormTextArea}
                        disabled
                      />
                    </div>
                    <HierarchyAttributesFormFields data-testid="attribute-form-fields" />
                  </Form>
                  {(showError || !!upsertCustomHierarchyError) && (
                    <div className={b('errorMessage')} data-testid="dialog-error-message">
                      {upsertCustomHierarchyError?.message || formatMessage('CREATE_NEW_HIERARCHY_ERROR')}
                    </div>
                  )}
                  <DialogFooter
                    confirmButtonText={formatMessage('NEXT')}
                    disableConfirm={loading}
                    disableCancel={false}
                    confirmButtonLoading={loading}
                    onSubmit={() => handleSubmit()}
                    onClose={() => setShowAddHierarchyDialog(false)}
                    cancelButtonText={formatMessage('CANCEL')}
                  />
                </>
              ) : (
                <>
                  <FileUploadSequence
                    fileUploadType={FileType.CUSTOM_HIERARCHY}
                    setDisableDialogCancel={setDisableCancel}
                    setDisableDialogComplete={setDisableComplete}
                    hierarchyId={hierarchyId}
                    data-testid="hierarchy-creation-upload-sequence"
                  />
                  <DialogFooter
                    confirmButtonText={formatMessage('COMPLETE')}
                    disableConfirm={disableComplete}
                    disableCancel={disableCancel}
                    confirmButtonLoading={false}
                    onSubmit={handleComplete}
                    onClose={handleUploadCancel}
                    cancelButtonText={formatMessage('CANCEL')}
                  />
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default HierarchyCreationDialog;
