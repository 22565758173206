import React, { FC, useCallback, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import Tab from 'components/Tab/Tab';
import Tabs from 'components/Tabs/Tabs';

import ConfigurationsPanel from 'app/components/DataPanel/ConfigurationsPanel/ConfigurationsPanel';
import TablesPanel from 'app/components/DataPanel/TablesPanel/TablesPanel';
import IntegrationPanelFallback from 'app/components/IntegrationPanel/IntegrationPanelFallback';
import SymonIntegrationForm from 'app/components/IntegrationPanel/SymonIntegrationForm/SymonIntegrationForm';
import Split from 'app/components/Split/Split';

import SymonImport from 'app/containers/Symon/SymonImport';
import { IntegrationTabIds, IntegrationTabs, IntegrationTab } from 'app/containers/Symon/SymonTypes';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { GetSymonAccount } from 'app/graphql/generated/apolloTypes';

import useSymonToken from 'app/hooks/symon/useSymonToken';
import useShouldShowSymonTab from 'app/hooks/useShouldShowSymonTab';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './IntegrationPanel.module.pcss';
import { useGetSymonAccount } from './SymonIntegrationForm/hooks/useGetSymonAccount';
import SymonPortalWrapper from './SymonPortalWrapper';
import useIntegrationTabQueryParamState from './useIntegrationTabQueryParamState';

const b = block(style);

interface InnerIntegrationPanelProps {
  symonAccountLoading: boolean;
  symonAccount: GetSymonAccount;
}

const NewIntegrationPanel: React.FC<InnerIntegrationPanelProps> = () => {
  const shouldShowSymonTab = useShouldShowSymonTab();
  const { selectedTenant } = useScope();
  const { data: symonAccount, loading: symonAccountLoading } = useGetSymonAccount(selectedTenant?.id);

  const [selectedTab, setSelectedTab] = useIntegrationTabQueryParamState();
  const { setSelectedDataView } = useData();

  const [isActivityExpanded, setIsActivityExpanded] = useState(false);
  const tokenState = useSymonToken();

  const handleStartImport = useCallback(() => setSelectedTab(IntegrationTabIds.SYMON_DATA), []);

  const getNonIframeTabComponent = (tab: { tabId: string; tabName: string }): JSX.Element => {
    switch (tab.tabId) {
      case IntegrationTabIds.CONFIGURATIONS:
        return <ConfigurationsPanel />;
      case IntegrationTabIds.MANAGE_DATA_TABLES:
        return (
          <TablesPanel
            isActivityExpanded={isActivityExpanded}
            setIsActivityExpanded={setIsActivityExpanded}
            setSelectedDataView={setSelectedDataView}
          />
        );
      case IntegrationTabIds.SYMON:
        if (!shouldShowSymonTab) return null;
        return (
          <SymonIntegrationForm
            symonAccountLoading={symonAccountLoading}
            symonDomain={symonAccount?.getSymonAccount?.symonDomain}
            symonUiClientKey={symonAccount?.getSymonAccount?.symonUiClientKey}
          />
        );
      case IntegrationTabIds.SYMON_IMPORT:
        return (
          <SymonImport
            isSymonAPIOriginLoading={symonAccountLoading}
            symonAPIOrigin={symonAccount?.getSymonAccount?.symonDomain}
            tokenState={tokenState}
            onStartImport={handleStartImport}
          />
        );
      default:
        console.warn('Unhandled tab ID', tab.tabId);
        return null;
    }
  };

  const renderTabContent = (tab: IntegrationTab) => {
    if (tab.iframe) {
      return (
        <SymonPortalWrapper
          symonDomainLoading={symonAccountLoading}
          pathname={tab.pathname}
          tokenState={tokenState}
          symonDomain={symonAccount?.getSymonAccount?.symonDomain}
        />
      );
    }
    return getNonIframeTabComponent(tab);
  };

  return (
    <>
      <Tabs
        id="new-integration-panel"
        className={b('tabList')}
        selectedTabId={selectedTab}
        onChange={(navbarTabId: IntegrationTabIds) => setSelectedTab(navbarTabId)}
      >
        {IntegrationTabs.map((tab) => {
          if (tab.tabId === IntegrationTabIds.SYMON && !shouldShowSymonTab) {
            return null;
          }
          return <Tab key={tab.tabId} id={tab.tabId} className={b('tab')} title={tab.tabName} />;
        })}
      </Tabs>
      <div className={b('tabPanel')}>
        {IntegrationTabs.map((tab) =>
          selectedTab === tab.tabId ? (
            <ErrorBoundary key={tab.tabId} FallbackComponent={IntegrationPanelFallback}>
              {renderTabContent(tab)}
            </ErrorBoundary>
          ) : null
        )}
      </div>
    </>
  );
};

const LegacyIntegrationPanel: FC<InnerIntegrationPanelProps> = ({ symonAccountLoading, symonAccount }) => (
  <div className={b('legacyPanel')}>
    <Tabs id="legacy-intergration-panel">
      <Tab id={IntegrationTabIds.SYMON} className={b('tab')} title={formatMessage('VARICENT_ELT')} />
    </Tabs>
    <SymonIntegrationForm
      symonAccountLoading={symonAccountLoading}
      symonDomain={symonAccount?.getSymonAccount?.symonDomain}
      symonUiClientKey={symonAccount?.getSymonAccount?.symonUiClientKey}
    />
  </div>
);

const IntegrationPanel: FC = () => {
  const { selectedTenant } = useScope();
  const { data: symonAccount, loading: symonAccountLoading } = useGetSymonAccount(selectedTenant?.id);

  return (
    <div className={b('container')}>
      <Split
        name={SplitFeatures.SYMON_EMBED}
        splitTreatment={{
          on: <NewIntegrationPanel symonAccount={symonAccount} symonAccountLoading={symonAccountLoading} />
        }}
        fallback={<LegacyIntegrationPanel symonAccount={symonAccount} symonAccountLoading={symonAccountLoading} />}
      />
    </div>
  );
};

export default IntegrationPanel;
