import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetAccountRuleGrid, GetAccountRuleGridVariables } from 'app/graphql/generated/apolloTypes';

export const GET_ACCOUNT_RULE_GRID = gql`
  query GetAccountRuleGrid($input: GetAccountRuleGridInput!) {
    getAccountRuleGrid(input: $input) {
      accountRuleHeaders {
        colId
        dataType
        isFilterable
        isSortable
        name
      }
      accountRules {
        accountId
        accountKey
        accountName
        dynamicColumns {
          colId
          value
        }
        rule {
          ruleId
          territoryName
          territoryId
          territoryGroupName
          territoryGroupId
        }
      }
      totalCount
    }
  }
`;

export const useGetAccountRuleGridLazy = createUseLazyQuery<GetAccountRuleGrid, GetAccountRuleGridVariables>(
  GET_ACCOUNT_RULE_GRID
);
