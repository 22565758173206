import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetFileProcessStatus, GetFileProcessStatusVariables } from 'app/graphql/generated/apolloTypes';

export const GET_FILE_PROCESS_STATUS = gql`
  query GetFileProcessStatus($input: GetFileProcessInput) {
    getFileProcessStatus(input: $input) {
      pipes {
        fileId
        pipeUuid
        status
        configuration {
          optimizationType
          territoryRuleBase
        }
      }
      fileMetadata {
        fileId
        status
      }
    }
  }
`;

export const useGetFileProcessStatusLazy = createUseLazyQuery<GetFileProcessStatus, GetFileProcessStatusVariables>(
  GET_FILE_PROCESS_STATUS
);
