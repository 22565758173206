import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetPostCoinsortProgress, GetPostCoinsortProgressVariables } from 'app/graphql/generated/apolloTypes';

export const GET_POST_COINSORT_PROGRESS = gql`
  query GetPostCoinsortProgress($planningCycleId: Int!) {
    getPostCoinsortProgress(input: { planningCycleId: $planningCycleId }) {
      id
      actorEmail
      jobAction
      status
      aggregationType
      planningCycleId
      aggregatedRowsInserted
      totalActivities
      jobNumber
      totalJobs
    }
  }
`;

export const useGetPostCoinsortProgressLazy = createUseLazyQuery<
  GetPostCoinsortProgress,
  GetPostCoinsortProgressVariables
>(GET_POST_COINSORT_PROGRESS);
