import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { UpdateBattlecard, UpdateBattlecardVariables } from 'app/graphql/generated/apolloTypes';

export const UPDATE_BATTLECARD = gql`
  mutation UpdateBattlecard(
    $battlecardId: Int!
    $deploymentModelId: Int!
    $battlecardName: String!
    $battlecardType: String!
    $currencyCode: String
    $battlecardParentId: Int
    $mapLevel: BCMapLevelEnum
    $quotaDistributionHierarchyIds: [Int!]
  ) {
    updateBattlecard(
      battlecard: {
        battlecardId: $battlecardId
        deploymentModelId: $deploymentModelId
        battlecardName: $battlecardName
        battlecardType: $battlecardType
        currencyCode: $currencyCode
        battlecardParentId: $battlecardParentId
        mapLevel: $mapLevel
        quotaDistributionHierarchyIds: $quotaDistributionHierarchyIds
      }
    ) {
      battlecardId
    }
  }
`;

export const useUpdateBattleCard = createUseMutation<UpdateBattlecard, UpdateBattlecardVariables>(UPDATE_BATTLECARD);
