import React, { useEffect, useRef } from 'react';

import { ArrowLeft, Copy } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';

import { useData } from 'app/contexts/dataProvider';

import useQueryParamState from 'app/hooks/useQueryParamState';
import useShowToast from 'app/hooks/useShowToast';

import { DataPanelTabIds, DataPanelViews } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportErrorsDetail.module.pcss';
const b = block(style);

const ImportErrorsDetail: React.FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const dataTabQueryParamKey = 'data-tab';
  const { selectedTable, setSelectedDataView } = useData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_selectedTab, setSelectedTab] = useQueryParamState<DataPanelTabIds>(dataTabQueryParamKey);
  const arrayOfErrors: Record<string, string>[] = Object.values(JSON.parse(selectedTable?.messages ?? '{}'));
  const showToast = useShowToast();

  const fileName = `Failed to import ${selectedTable?.tableName} due to unexpected error that occurred during the publishing process.\n${arrayOfErrors.length} errors.\n`;

  const copySummary = fileName + arrayOfErrors.map((error) => error.message).join('\n');

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(copySummary);
    showToast(formatMessage('ERROR_SUMMARY_COPIED_TO_CLIPBOARD'), 'success');
  };

  const onBackClick = () => {
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
    setSelectedTab(DataPanelTabIds.IMPORT_ERRORS);
  };

  useEffect(() => {
    if (textAreaRef && !!selectedTable) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, selectedTable]);

  return (
    <div className={b()} data-testid="import-errors-detail">
      <div className={b('header')}>
        <div className={b('leftActionItems')}>
          <IconButton type="button" icon={<ArrowLeft />} onClick={onBackClick} testId={'back-button'} />
          <span className={b('tableTitle')}>
            <HTMLHeading tagLevel={'h5'} text={selectedTable?.tableName} data-testid="table-title" />
          </span>
        </div>
      </div>
      <div className={b('detailContainer')}>
        <HTMLHeading bold tagLevel="h5" text={formatMessage('DETAILS')} className={b('heading')} />
        <div className={b('configuration')}>
          <div className={b('textContainer')}>
            <HTMLHeading bold tagLevel="h6" text={formatMessage('FILE_NAME')} />
            <span className={b('description')}>{selectedTable?.tableName}</span>
          </div>
          <div className={b('textContainer')}>
            <HTMLHeading bold tagLevel="h6" text={formatMessage('DATE')} />
            <span className={b('description')}>{selectedTable?.createdAt}</span>
          </div>
          <div className={b('textContainer')}>
            <HTMLHeading bold tagLevel="h6" text={formatMessage('USER')} />
            <span className={b('description')}>{selectedTable?.user?.emailAddress}</span>
          </div>
        </div>
        <HTMLHeading bold tagLevel="h5" text={formatMessage('SUMMARY')} className={b('heading')} />
        <code className={b('copySummary')}>
          <textarea
            className={b('clipboardInput')}
            value={copySummary}
            data-testid="clipboard-text-input"
            readOnly
            ref={textAreaRef}
          />
          <TextButton
            text={formatMessage('COPY_TO_CLIPBOARD')}
            type="button"
            icon={<Copy />}
            onClick={onCopyToClipboard}
            testId="copy-to-clipboard-button"
            minimal={false}
          />
        </code>
      </div>
    </div>
  );
};

export default ImportErrorsDetail;
