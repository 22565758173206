interface TgWithParent {
  territoryGroupId: number;
  territoryGroupParentId: number;
}

export class TerritoryGroupTree<T extends TgWithParent> {
  public readonly territoryGroups: ReadonlyArray<T>;
  public readonly groupById: ReadonlyMap<number, T>;
  public readonly idsByParentId: ReadonlyMap<number, number[]>;
  public readonly leafs: ReadonlySet<number>;
  public readonly rootId: number | null;

  constructor(territoryGroups: T[]) {
    this.territoryGroups = territoryGroups;
    this.groupById = TerritoryGroupTree.createGroupById(territoryGroups);
    this.idsByParentId = TerritoryGroupTree.createIdsByParentId(territoryGroups);
    this.leafs = TerritoryGroupTree.createLeafs(territoryGroups, (id) => !this.idsByParentId.has(id));
    this.rootId = TerritoryGroupTree.getRoot(this.idsByParentId.get(null) ?? []);
  }

  static getRoot(possibleRoots: number[]): number | null {
    if (possibleRoots.length > 1) throw new Error(`TGTree has multiple roots ${possibleRoots}`);
    if (possibleRoots.length === 0) return null;
    return possibleRoots[0];
  }

  private static createGroupById<T extends TgWithParent>(territoryGroups: T[]) {
    return new Map(territoryGroups.map((group) => [group.territoryGroupId, group]));
  }

  private static createIdsByParentId<T extends TgWithParent>(territoryGroups: T[]) {
    const groupIdsByParentId = new Map<number, number[]>();
    for (const group of territoryGroups) {
      const { territoryGroupId, territoryGroupParentId } = group;
      const siblings = groupIdsByParentId.get(territoryGroupParentId) ?? [];
      siblings.push(territoryGroupId);
      groupIdsByParentId.set(territoryGroupParentId, siblings);
    }
    return groupIdsByParentId;
  }

  private static createLeafs<T extends TgWithParent>(territoryGroups: T[], isLeaf: (id: number) => boolean) {
    const leafIds = new Set<number>();
    territoryGroups.forEach(({ territoryGroupId }) => {
      if (isLeaf(territoryGroupId)) leafIds.add(territoryGroupId);
    });
    return leafIds;
  }
}
