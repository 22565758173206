import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { MarkScenariosOpen, MarkScenariosOpenVariables } from 'app/graphql/generated/apolloTypes';

export const MARK_SCENARIOS_OPEN = gql`
  mutation MarkScenariosOpen($deploymentModelIds: [Int!]!) {
    markDeploymentModelsAsOpened(input: { deploymentModelIds: $deploymentModelIds }) {
      deploymentModelIds
    }
  }
`;

export const useMarkScenariosOpen = createUseMutation<MarkScenariosOpen, MarkScenariosOpenVariables>(
  MARK_SCENARIOS_OPEN
);
