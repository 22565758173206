// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xEkGB {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: center;\n  }\n  ._7W8WW {\n    width: 100%;\n    margin-top: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountTerritoryCellRenderer_selectionTargetContainer": "xEkGB",
	"AccountTerritoryCellRenderer_tooltip": "_7W8WW"
};
export default ___CSS_LOADER_EXPORT___;
