import { LazyQueryExecFunction } from '@apollo/client';

import {
  GetFileProcessStatus,
  GetFileProcessStatusVariables,
  GetFileProcessStatus_getFileProcessStatus_pipes
} from 'app/graphql/generated/apolloTypes';
import { useGetFileProcessStatusLazy } from 'app/graphql/queries/getFileProcessStatus';

import { FileStatus, TerritoryOptimizationJob } from 'app/models';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

interface UseGetFileProcessStatusProps {
  onFail?: () => void;
  onComplete?: (territoryInProgressStatus: TerritoryOptimizationJob) => void;
}
interface UseGetFileProcessStatusReturn {
  pollFileProcessStatus: LazyQueryExecFunction<GetFileProcessStatus, GetFileProcessStatusVariables>;
  stopPolling: () => void;
  data: GetFileProcessStatus;
  loading: boolean;
  called: boolean;
}

export const useGetFileProcessStatus = ({
  onFail,
  onComplete
}: UseGetFileProcessStatusProps): UseGetFileProcessStatusReturn => {
  const [pollFileProcessStatus, { data, startPolling, stopPolling, loading, called, ...rest }] =
    useGetFileProcessStatusLazy({
      fetchPolicy: 'network-only',
      pollInterval: 2000,
      notifyOnNetworkStatusChange: true,
      onError({ networkError }) {
        if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
          return stopPolling();
        }
        stopPolling();
        onFail?.();
        // eslint-disable-next-line deprecation/deprecation
        showToast(formatMessage('RETRIEVE_PROCESSING_STATUS_ERROR'), 'danger');
      },
      onCompleted(data) {
        const { getFileProcessStatus } = data;
        if (getFileProcessStatus) {
          getFileProcessStatus.forEach((fileStatus) => {
            const pipes: GetFileProcessStatus_getFileProcessStatus_pipes[] = [];
            fileStatus.pipes.forEach((pipe) => {
              pipes.push(pipe);
            });
            const territoryInProgressStatus: TerritoryOptimizationJob = {
              fileId: fileStatus.fileMetadata.fileId,
              status: fileStatus.fileMetadata.status,
              pipes: pipes.map((pipe) => ({
                pipeUuid: pipe.pipeUuid,
                optimizationType: pipe.configuration.optimizationType,
                territoryRuleBase: pipe.configuration.territoryRuleBase
              }))
            };
            if (fileStatus.fileMetadata.status === FileStatus.FAILED) {
              onFail();
              stopPolling();
            }
            // status pending means that it is ready to be published
            if (fileStatus.fileMetadata.status === FileStatus.PENDING) {
              onComplete?.(territoryInProgressStatus);
              stopPolling();
            }
          });
        }
      }
    });

  return { pollFileProcessStatus, data, stopPolling, loading, called, ...rest };
};
