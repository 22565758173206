import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { PublishDeploymentModel, PublishDeploymentModelVariables } from 'app/graphql/generated/apolloTypes';

export const PUBLISH_DEPLOYMENT_MODEL = gql`
  mutation PublishDeploymentModel($deploymentModelId: Int!) {
    updateDeploymentModel(input: { deploymentModelId: $deploymentModelId, isPrimaryScenario: true }) {
      deploymentModelId
      isPublished: isPrimaryScenario
      deploymentModelName
    }
  }
`;

export const usePublishDeploymentModel = createUseMutation<PublishDeploymentModel, PublishDeploymentModelVariables>(
  PUBLISH_DEPLOYMENT_MODEL
);
