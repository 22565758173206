import { useMemo } from 'react';

import { useScope } from 'app/contexts/scopeProvider';

import { GetLocationGroups_getPlanningCycleSpec_locationGroups } from 'app/graphql/generated/apolloTypes';
import { useGetLocationGroups } from 'app/graphql/queries/getLocationGroup';

export const useLocationGroups = (): {
  refetch: () => void;
  locationGroups: GetLocationGroups_getPlanningCycleSpec_locationGroups[];
  loading: boolean;
} => {
  const { selectedPlanningCycle } = useScope();
  const { refetch, loading, data } = useGetLocationGroups({
    skip: !selectedPlanningCycle?.id,
    variables: {
      planningCycleId: selectedPlanningCycle?.id
    }
  });
  const locationGroups = useMemo(() => data?.getPlanningCycleSpec?.locationGroups ?? [], [data]);
  return {
    locationGroups,
    refetch,
    loading
  };
};
