import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals,
  GetTerritoryQuotaTotalsVariables
} from 'app/graphql/generated/apolloTypes';
import { QUERY_NUM_TERRITORIES_IN_TERRITORY_GROUP } from 'app/graphql/queries/getNumberOfTerritoriesInTerritoryGroup';
import { GET_TERRITORY_QUOTA_TOTALS } from 'app/graphql/queries/getTerritoryMeasuresTotals';

import { ExpandedTerritoryGroupDefineAndRefinePillData } from 'app/models';

interface GetNumTerritoriesQueryVariables {
  battlecardId: string;
  territoryGroupId?: string;
}

export const getNumTerritories = async (battleCardId: string, territoryGroupId?: string): Promise<number> => {
  const getNumTerritoriesVariables: GetNumTerritoriesQueryVariables = {
    battlecardId: battleCardId
  };

  if (territoryGroupId) {
    getNumTerritoriesVariables.territoryGroupId = territoryGroupId;
  }

  // get number of territories in territory group or battle card
  const result: ApolloQueryResult<{
    getNumberOfTerritoriesInTerritoryGroup: {
      numOfTerritories: number;
    };
    // eslint-disable-next-line no-restricted-syntax
  }> = await apolloClient.query({
    query: QUERY_NUM_TERRITORIES_IN_TERRITORY_GROUP,
    variables: getNumTerritoriesVariables,
    fetchPolicy: 'network-only'
  });

  return result?.data?.getNumberOfTerritoriesInTerritoryGroup?.numOfTerritories;
};

// get territory measure totals in the quota grid using the new getTerritoryQuota api
export const getTerritoryMeasureTotalsV2 = async (
  battleCardId: number,
  quotaComponentId: number,
  sheetId: number,
  territoryGroupId?: number
) => {
  const queryVariables: GetTerritoryQuotaTotalsVariables = {
    battlecardId: battleCardId,
    quotaComponentId,
    sheetId
  };
  if (territoryGroupId) {
    queryVariables.territoryGroupId = territoryGroupId;
  }
  const result: ApolloQueryResult<{
    getTerritoryQuota: {
      periodicTotals: GetTerritoryQuotaTotals_getTerritoryQuota_periodicTotals[];
    };
    // eslint-disable-next-line no-restricted-syntax
  }> = await apolloClient.query({
    query: GET_TERRITORY_QUOTA_TOTALS,
    variables: queryVariables,
    fetchPolicy: 'network-only'
  });

  return result?.data?.getTerritoryQuota?.periodicTotals;
};

export const getTotalChildNodeCount = (tg: ExpandedTerritoryGroupDefineAndRefinePillData): number => {
  let sum = tg?.children?.length ?? 0;

  tg?.children?.forEach((tgChild) => {
    sum += getTotalChildNodeCount(tgChild);
  });

  return sum;
};

export const getTotalChildActivityCount = (
  tg: ExpandedTerritoryGroupDefineAndRefinePillData,
  activityCounts: Record<string, number>
): number => {
  let sum = activityCounts?.[tg?.territoryGroupId] ?? 0;

  tg?.children?.forEach((tgChild) => {
    sum += getTotalChildActivityCount(tgChild, activityCounts);
  });

  return sum;
};
