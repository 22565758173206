import React, { memo, useMemo } from 'react';

import { CirclePaint, Expression, SymbolLayout, SymbolPaint } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

import { CombinedRuleId, CustomerFeatureCollection } from 'app/models';

import { white, black, SourceIds, LayerIds } from './MapStyleTokens';

export interface ClusterAccountSourceLayersProps {
  features: CustomerFeatureCollection;
}
const isSelectedExpression = ['to-boolean', ['feature-state', 'isSelected']];
const isGeoOverassignedExpression = ['to-boolean', ['get', 'isGeoOverassigned']];
const isAccountOverassignedExpression = ['to-boolean', ['get', 'accountOverassignmentCount']];
const isOverassignedExpression = ['any', isGeoOverassignedExpression, isAccountOverassignedExpression];

const clusterProperties = {
  metaColor: [['accumulated'], ['get', 'ruleColor']],
  territoryName: [['accumulated'], ['get', 'territoryName']],
  territoryId: [['accumulated'], ['get', 'territoryId']],
  isClusterContainsOverassigned: [['any', ['accumulated'], isOverassignedExpression], isOverassignedExpression],
  combinedRuleId: [
    [
      'case',
      ['==', ['get', 'combinedRuleId'], CombinedRuleId.MIXED],
      'mixed',
      ['!=', ['get', 'combinedRuleId'], ['accumulated']],
      'mixed',
      ['accumulated']
    ],
    [
      'case',
      isOverassignedExpression,
      'mixed',
      ['to-string', ['coalesce', ['get', 'ruleId'], CombinedRuleId.UNASSIGNED]]
    ]
  ],
  isClusterContainsUnassigned: [
    ['any', ['accumulated'], ['get', 'isClusterContainsUnassigned']],
    ['==', ['get', 'ruleId'], null]
  ]
};

const baseColorExpression = ['coalesce', ['get', 'metaColor'], ['get', 'ruleColor'], '#aaaaaa'];

const circleColor: string | Expression = [
  'case',
  ['==', ['get', 'combinedRuleId'], 'mixed'],
  black,
  isSelectedExpression,
  white,
  isOverassignedExpression,
  black,
  baseColorExpression
];

const circleStroke: string | Expression = [
  'case',
  isSelectedExpression,
  ['case', isOverassignedExpression, black, baseColorExpression],
  white
];

const clusterLayout: SymbolLayout = {
  'text-field': '{point_count_abbreviated}',
  'text-font': ['Open Sans Semibold'],
  'text-size': 18,
  'text-allow-overlap': true
};

const clusterPaint: SymbolPaint = {
  'text-color': white
};
const textFilter = ['has', 'point_count'];

const ClusterAccountSourceLayers: React.FC<ClusterAccountSourceLayersProps> = memo(
  ({ features }: ClusterAccountSourceLayersProps) => {
    const featureCount = features.features.length;
    const circlePaint = useMemo(
      (): CirclePaint => ({
        'circle-color': circleColor,
        'circle-radius': [
          'case',
          ['all', ['<', featureCount, 200], ['has', 'point_count']],
          20,
          ['has', 'point_count'],
          [
            'interpolate',
            ['linear'],
            ['get', 'point_count'],
            1,
            15,
            Math.max(2, featureCount * 0.1),
            40,
            Math.max(3, featureCount * 0.8),
            70,
            Math.max(4, featureCount),
            75
          ],
          7.5
        ],
        'circle-stroke-color': circleStroke,
        'circle-stroke-width': 2
      }),
      [featureCount]
    );

    return (
      <Source
        type="geojson"
        promoteId="accountId"
        data={features}
        id={SourceIds.account.cluster}
        key={SourceIds.account.cluster}
        cluster={true}
        clusterRadius={50}
        clusterProperties={clusterProperties}
      >
        <Layer
          id={LayerIds.account.cluster.symbol}
          type="symbol"
          filter={textFilter}
          layout={clusterLayout}
          paint={clusterPaint}
        />
        <Layer
          id={LayerIds.account.cluster.interactiveCircle}
          beforeId={LayerIds.account.cluster.symbol}
          type="circle"
          paint={circlePaint}
        />
      </Source>
    );
  }
);

export default ClusterAccountSourceLayers;
