import bufferFrom from 'buffer-from';
import pako from 'pako';

import {
  CompressedRuleDefinitionResponseEnum,
  NewRuleDefinitionResponse,
  TGTAccountPinsCompressedEnum
} from 'app/graphql/generated/apolloTypes';

import { AggregatePin, HierarchyPolygons } from 'app/models';

export type CompressableTypeMap = {
  GetHierarchyPolygonIdOutput: HierarchyPolygons;
  [CompressedRuleDefinitionResponseEnum.NewRuleDefinitionResponse]: NewRuleDefinitionResponse;
  [TGTAccountPinsCompressedEnum.TGTAccountPins]: AggregatePin[];
};

export type Compressed<T extends keyof CompressableTypeMap> = {
  compressedType: T;
  compressed: string;
};

export class CompressionUtil {
  private static readonly ENCODING = 'base64';

  static compress<T extends keyof CompressableTypeMap>(type: T, data: CompressableTypeMap[T]): Compressed<T> {
    try {
      const bytes = pako.deflate(JSON.stringify(data));
      const compressed = bufferFrom(bytes).toString(CompressionUtil.ENCODING);
      return {
        compressed,
        compressedType: type
      };
    } catch (error) {
      CompressionUtil.processError(error);
    }
  }

  static decompress<T extends keyof CompressableTypeMap>({ compressed }: Compressed<T>): CompressableTypeMap[T] {
    try {
      const bytes = bufferFrom(compressed, CompressionUtil.ENCODING);
      const decompressed = pako.inflate(bytes, { to: 'string' });
      return JSON.parse(decompressed) as CompressableTypeMap[T];
    } catch (error) {
      CompressionUtil.processError(error);
    }
  }

  private static processError(error: unknown): never {
    if (!error) throw new Error(`Unknown error in CompressionUtil`);
    if (error instanceof Error) throw error;
    // Handles case of pako throwing errors as strings
    throw new Error(error.toString());
  }
}
