import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Intent, TagInput } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import HierarchySearch from 'app/components/HierarchySearchDialog/HierarchySearch/HierarchySearch';
import TerritorySearch from 'app/components/TerritorySearchDialog/TerritorySearch/TerritorySearch';

import * as battleCardProvider from 'app/contexts/battleCardProvider';

import useOutsideClick from 'app/hooks/useOutsideClick';

import { HierarchyItem, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './SearchableTagInput.module.pcss';

const b = block(style);

interface SearchableTagInputProps {
  items: HierarchyItem[];
  valueFormatter: (items: HierarchyItem[]) => string[];
  planningCycleId: number;
  hierarchyType: HierarchyType;
  rootHierarchyId: number;
  onChange: (values: HierarchyItem[]) => void;
  isRuleTypeTerritory?: boolean;
  isOverrideRule: boolean;
}

const SearchableTagInput: React.FC<SearchableTagInputProps> = ({
  items,
  valueFormatter,
  planningCycleId,
  hierarchyType,
  rootHierarchyId,
  onChange,
  isRuleTypeTerritory,
  isOverrideRule
}: SearchableTagInputProps) => {
  const [searchString, setSearchString] = useState('');

  const selectRef = useRef<HTMLDivElement | null>(null);
  const clickedOutside = useOutsideClick(selectRef);
  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId } = battleCardProvider.useBattleCard();

  useEffect(() => {
    if (clickedOutside) {
      setSearchString('');
    }
  }, [clickedOutside]);

  const onHierarchyItemSelect = (params) => {
    const isNodeSelectable = !(!!params.node.allChildrenCount && isOverrideRule);
    if (isNodeSelectable) {
      onChange([
        ...items,
        {
          hierarchyId: params.data?.hierarchyId,
          key: params.data?.key,
          name: params.data?.name
        }
      ]);
      setSearchString('');
    }
  };

  const onTerritoryItemSelect = (params) => {
    onChange([
      ...items,
      {
        territoryId: params.data?.territoryId,
        ruleId: params.data?.ruleId,
        name: params.data?.territoryName
      }
    ]);
    setSearchString('');
  };

  const onHierarchyItemRemove = (_value, index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  return (
    <div ref={selectRef} className={b('searchTagInput')}>
      <TagInput
        inputValue={searchString}
        values={valueFormatter(items)}
        onRemove={onHierarchyItemRemove}
        onInputChange={(event) => {
          setSearchString((event as ChangeEvent<HTMLInputElement>)?.target?.value);
        }}
        tagProps={{
          intent: Intent.PRIMARY,
          large: true,
          minimal: false
        }}
        className={b('searchTagInputDefault')}
        data-testid="searchable-tag-input"
      />

      <Popover2
        content={
          <div className={b('hierarchySearchContainer')}>
            {isRuleTypeTerritory ? (
              <TerritorySearch
                searchString={searchString}
                selectedNodes={items}
                onSelect={onTerritoryItemSelect}
                battlecardId={battleCardLookupMap[selectedBattleCardId]?.battlecardParentId ?? +selectedBattleCardId}
                quotaComponentId={selectedQuotaComponentId}
              />
            ) : (
              <HierarchySearch
                searchString={searchString}
                planningCycleId={planningCycleId}
                hierarchyType={hierarchyType}
                rootHierarchyId={rootHierarchyId}
                onSelect={onHierarchyItemSelect}
                data-testid="searchable-tag-input-search"
              />
            )}
          </div>
        }
        interactionKind="click"
        isOpen={!!searchString?.length}
        placement="bottom-start"
        autoFocus={false}
        usePortal={false}
        minimal
        data-testid="searchable-tag-input-popover"
      >
        <div tabIndex={-1} />
      </Popover2>
    </div>
  );
};

export default SearchableTagInput;
