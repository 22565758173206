export const RoutePaths = {
  LOGIN: '/login',
  CREATE_PLAN: '/create-plan',
  EDIT_PLAN: '/edit-plan',
  NEW_ORGANIZATION: '/organization/new',
  USER_PROFILE: '/profile',
  EMBEDDED_AUTH: '/embedded-auth',
  EMBEDDED_MAP: '/embedded-map',
  WELCOME: '/welcome',
  CHANGE_REQUESTS: '/change-requests'
};

export const RootPaths = [RoutePaths.EMBEDDED_MAP];

export const ExternalRoutePaths = {
  PRIVACY_STATEMENT: 'https://www.varicent.com/privacy-policy',
  TERMS_OF_SERVICE: 'https://www.varicent.com/terms-and-conditions',
  MAPS_GEOGRAPHY_LEVEL_EXTENSION: 'en/command-center/hierarchies/standard-geographies-hierarchy-data.html'
};
