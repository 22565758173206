import React, { useCallback, useEffect, useState } from 'react';

import { RowNode, ServerSideStoreType } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';

import { BLOCK_SIZE, MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetTerritoryGroupChildrenVariables,
  GetTerritoryGroupChildren_getTerritoryGroupChildren_items,
  GetTerritoryRulesWithPrimarySellerVariables,
  GetTerritoryRulesWithPrimarySeller_getTerritoryRules_territoryRules,
  SortDirection,
  SortableGetTerritoryRulesGridCols
} from 'app/graphql/generated/apolloTypes';
import { getTerritoryGroupChildren } from 'app/graphql/hooks/useGetTerritoryGroupChildren';
import { getTerritoryRulesWithPrimarySeller } from 'app/graphql/hooks/useGetTerritoryRules';

import { HierarchyItem } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AllTerritories.module.pcss';

const b = block(style);

interface AllTerritoriesProps {
  battlecardId: number;
  quotaComponentId: number;
  selectedNodes: HierarchyItem[];
  onSelect: (params) => void;
}

const AllTerritories: React.FC<AllTerritoriesProps> = ({
  quotaComponentId,
  battlecardId,
  selectedNodes,
  onSelect
}: AllTerritoriesProps) => {
  const allTerritoriesGridContainerRef = React.useRef(null);

  const [blockSize, setBlockSize] = useState(BLOCK_SIZE);
  const [initialBlockLoaded, setInitialBlockLoaded] = useState<boolean>(false);

  const loadTerritoryRulesData = useCallback(async (startRow, endRow, territoryGroupId) => {
    const variables: GetTerritoryRulesWithPrimarySellerVariables = {
      startRow,
      endRow,
      quotaComponentId,
      territoryGroupId,
      measureId: 0,
      battlecardId,
      sorting: {
        colId: SortableGetTerritoryRulesGridCols.territoryId,
        sort: SortDirection.asc
      },
      completeDefinition: false
    };
    const territoryRulesData: {
      items: GetTerritoryRulesWithPrimarySeller_getTerritoryRules_territoryRules[];
      totalCount: number;
    } = await getTerritoryRulesWithPrimarySeller(variables);
    return territoryRulesData;
  }, []);

  const loadTerritoryGroupChildren = useCallback(
    async (startRow: number, endRow: number, territoryGroupParentId: number) => {
      const variables: GetTerritoryGroupChildrenVariables = {
        battlecardId: +battlecardId,
        territoryGroupParentId,
        startRow,
        endRow
      };
      const territoryGroupChildrenData: {
        items: GetTerritoryGroupChildren_getTerritoryGroupChildren_items[];
        totalCount: number;
      } = await getTerritoryGroupChildren(variables);

      if (!initialBlockLoaded) {
        setInitialBlockLoaded(true);
      }
      return territoryGroupChildrenData;
    },
    []
  );

  useEffect(() => {
    if (allTerritoriesGridContainerRef?.current?.offsetHeight) {
      setBlockSize(
        Math.round((allTerritoriesGridContainerRef?.current?.offsetHeight / MENU_INFINITE_SCROLL_ITEM_HEIGHT) * 2)
      );
    }
  }, [allTerritoriesGridContainerRef]);

  const handleOnGridReady = (gridEvent) => {
    const dataSource = {
      getRows: async (params) => {
        const startRow = params.request.startRow + 1;
        const endRow = params.request.startRow + blockSize;

        if (params?.parentNode?.level === -1 || params?.parentNode.data?.children?.length !== 0) {
          const { items: newNodes, totalCount } = await loadTerritoryGroupChildren(
            startRow,
            endRow,
            params?.parentNode?.data?.territoryGroupId ?? 0
          );
          if (totalCount === 0) {
            const { items: newNodes, totalCount } = await loadTerritoryRulesData(
              startRow,
              endRow,
              params?.parentNode?.data?.territoryGroupId
            );

            params.success({ rowData: newNodes, rowCount: totalCount });
          } else {
            params.success({ rowData: newNodes, rowCount: totalCount });
          }
        } else {
          const { items: newNodes, totalCount } = await loadTerritoryRulesData(
            startRow,
            endRow,
            params?.parentNode?.data?.territoryGroupId
          );
          params.success({ rowData: newNodes, rowCount: totalCount });
        }
      }
    };
    gridEvent.api.setServerSideDatasource(dataSource);
  };

  const isNodePreselected = (node) => {
    return !!selectedNodes.filter((selectedNode) => selectedNode.hierarchyId === node.data.territoryId).length;
  };

  const territoryGridProps = {
    onGridReady: handleOnGridReady,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    suppressCellSelection: true,
    suppressRowClickSelection: false,
    groupSelectsChildren: false,
    treeData: true,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    headerHeight: 0,
    rowHeight: MENU_INFINITE_SCROLL_ITEM_HEIGHT,
    cacheBlockSize: blockSize,
    suppressRowDrag: false,
    getRowStyle: () => {
      return { border: 'none', background: 'white' };
    },
    onRowClicked: ({ api, node }) => {
      node.setSelected(!node.selected);
      // territoryGroupName sometimes null when loading
      api.refreshServerSideStore({ route: [node?.data?.territoryGroupName] });
    },
    isRowSelectable: (node: RowNode) => {
      return !!node.data.ruleId;
    },
    getRowNodeId: (nodeData) => {
      // Parent nodes have unique path, leaf nodes have unique ruleId
      return nodeData.path || (nodeData.ruleId as string);
    }
  };

  return (
    <div className={b()} data-testid={'all-territories'}>
      {!initialBlockLoaded && (
        <div className={b('validationMessage')} data-testid={'empty-territory-tree-message'}>
          {formatMessage('NO_TERRITORY')}
        </div>
      )}
      <div className={b('fullWidthGrid')} ref={allTerritoriesGridContainerRef} data-testid={'territories-grid'}>
        <AdvancedGrid
          className={b('allTerritoriesGrid')}
          colWidth={750}
          autoGroupColumnDef={{
            field: 'territoryGroupName',
            width: 750,
            cellRendererParams: {
              innerRenderer(params) {
                const { territoryId, territoryName, primarySeller, territoryGroupName } = params?.data;
                const firstName = primarySeller?.firstName ?? '';
                const lastName = primarySeller?.lastName ?? '';
                if (!params?.data?.ruleId) {
                  return territoryGroupName;
                }
                return firstName !== ''
                  ? `${territoryId} / ${territoryName} / ${firstName} ${lastName}`
                  : `${territoryId} / ${territoryName}`;
              }
            },
            checkboxSelection: ({ node }) => {
              if (isNodePreselected(node)) {
                node.setSelected(true);
              }
              return true;
            }
          }}
          isServerSideGroup={(dataItem) => {
            return !dataItem?.ruleId;
          }}
          getServerSideGroupKey={(dataItem) => dataItem?.territoryGroupParentId}
          showGridLoading={!initialBlockLoaded}
          gridProps={territoryGridProps}
          onRowSelected={onSelect}
        />
      </div>
    </div>
  );
};

export default AllTerritories;
