import React, { useState } from 'react';

import { Divider, Spinner } from '@blueprintjs/core';
import { Field, Form, Formik } from 'formik';
import Avatar from 'react-avatar';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Card from 'components/Card/Card';
import { SPINNER_DEFAULT_SIZE } from 'components/global/variables';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { UserProfile } from 'app/core/userManagement/userProvider';

import { UPDATE_CURRENT_USER } from 'app/graphql/mutations/updateCurrentUser';

import useShowToast from 'app/hooks/useShowToast';

import { UserInformationCardFields } from 'app/models';

import block from 'utils/bem-css-modules';
import { getFullName } from 'utils/helpers/userProfileHelper';
import { formatMessage } from 'utils/messages/utils';

import style from './UserInformationCard.module.pcss';

const b = block(style);

interface UserInformationCardProps {
  userProfile: UserProfile;
}

export const UserInformationCard: React.FC<UserInformationCardProps> = ({ userProfile }: UserInformationCardProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentFirstName, setCurrentFirstName] = useState(userProfile?.firstName);
  const [currentLastName, setCurrentLastName] = useState(userProfile?.lastName);
  const showToast = useShowToast();

  const updateUser = async (firstName: string, lastName: string) => {
    try {
      // eslint-disable-next-line no-restricted-syntax
      const result = await apolloClient.mutate({
        mutation: UPDATE_CURRENT_USER,
        variables: {
          firstName,
          lastName
        }
      });

      const userData = result.data.updateCurrentUser;

      setCurrentFirstName(userData.firstName);
      setCurrentLastName(userData.lastName);

      showToast(formatMessage('UPDATE_USER_SUCCESS'), 'success');
      setIsEditMode(false);
    } catch (error) {
      showToast(formatMessage('UPDATE_USER_ERROR'), 'danger');
    }
  };

  const EditButton = () => {
    return (
      <div className={b('profileEdit')}>
        <TextButton
          text={formatMessage('EDIT')}
          type="button"
          intent="primary"
          onClick={toggleEditMode}
          testId={'user-info-edit-btn'}
        />
      </div>
    );
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const ProfilePictureSection = ({ name }) => {
    return (
      <div className={b('informationSection')}>
        <div className={b('profilePictureInformationSection')}>
          <span className={b('profilePictureTitle')}>{formatMessage('PROFILE_PICTURE')}</span>
          <span className={b('profilePictureSubtitle')}>{formatMessage('PROFILE_PICTURE_SUBTITLE')}</span>
        </div>
        <div className={b('profilePictureContainer')}>
          <Avatar className={b('profilePicture')} name={name} round={true} size={'60'} />
        </div>
      </div>
    );
  };

  const InformationSection = ({ title, value, testId, editable, name }) => {
    return (
      <div className={b('informationSection')} data-testid={testId}>
        <div className={b('informationTitle')} data-testid={`${testId}-title`}>
          <label htmlFor={name}>{title}</label>
        </div>
        {isEditMode && editable ? (
          <div className={b('informationValue')} data-testid={`${testId}-field`}>
            <Field name={name} type="text" showErrors={false} component={FormTextInputGroup} />
          </div>
        ) : (
          <div className={b('informationValue')} data-testid={`${testId}-value`}>
            {value}
          </div>
        )}
      </div>
    );
  };

  const onSubmitUserInfo = (submittedUserInfo) => {
    updateUser(
      submittedUserInfo[UserInformationCardFields.FIRST_NAME],
      submittedUserInfo[UserInformationCardFields.LAST_NAME]
    );
  };

  const getInitialFormValues = () => {
    return {
      [UserInformationCardFields.FIRST_NAME]: userProfile.firstName,
      [UserInformationCardFields.LAST_NAME]: userProfile.lastName
    };
  };

  return (
    <Card className={b()} data-testid="user-information-card">
      {userProfile && (
        <Formik initialValues={getInitialFormValues()} onSubmit={onSubmitUserInfo} enableReinitialization>
          {() => {
            return (
              <Form data-testid="user-info-form">
                <div data-testid="user-information-card-data">
                  <ProfilePictureSection name={getFullName(userProfile)} />
                  <Divider />
                  <InformationSection
                    editable={true}
                    title={formatMessage('FIRST_NAME')}
                    value={currentFirstName}
                    testId="user-first-name-section"
                    name={UserInformationCardFields.FIRST_NAME}
                  />
                  <Divider />
                  <InformationSection
                    editable={true}
                    title={formatMessage('LAST_NAME')}
                    value={currentLastName}
                    testId="user-last-name-section"
                    name={UserInformationCardFields.LAST_NAME}
                  />
                  <Divider />
                  <InformationSection
                    editable={false}
                    title={formatMessage('EMAIL')}
                    value={userProfile.emailAddress}
                    testId="user-email-section"
                    name={UserInformationCardFields.EMAIL}
                  />
                </div>
                {isEditMode ? (
                  <div>
                    <div data-testid="contact-admin-email" className={b('contactAdmin')}>
                      {formatMessage('CONTACT_ADMIN_EMAIL')}
                    </div>
                    <div className={b('formSubmitContainer')}>
                      <div>
                        <TextButton
                          testId={'user-info-cancel-btn'}
                          text={formatMessage('CANCEL')}
                          type="button"
                          onClick={toggleEditMode}
                        />
                      </div>
                      <div className={b('submitButton')}>
                        <TextButton
                          text={formatMessage('SAVE_CHANGES')}
                          type="submit"
                          intent="primary"
                          testId={'user-info-save-btn'}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={b('formSubmitContainer')}>
                    <EditButton />
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
      {!userProfile && (
        <>
          <div data-testid="spinner">
            <Spinner intent="primary" size={SPINNER_DEFAULT_SIZE} />
          </div>
        </>
      )}
    </Card>
  );
};

export default UserInformationCard;
