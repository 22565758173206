import { useMemo } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useGetTerritoryGroupsForMap } from 'app/graphql/queries/getTerritoryGroupsForMap';

import { HierarchyType, TerritoryGroupForMap } from 'app/models';

import { filterTerritoryGroupsByHierarchyType } from 'utils/helpers/territoryGroupHelpers';
import { LeastUsedColorPicker } from 'utils/LeastUsedColorPicker';
import { TerritoryGroupTree } from 'utils/TerritoryGroupTree';

export const useGeoTerritoryGroupTree = (
  skip: boolean
): { loading: boolean; territoryGroupTree: TerritoryGroupTree<TerritoryGroupForMap> } => {
  const { selectedBattleCardId, selectedQuotaComponentId } = useMapContextRedistributor();
  const hasAllIds = selectedBattleCardId && selectedQuotaComponentId;
  const { data, loading } = useGetTerritoryGroupsForMap({
    skip: skip || !hasAllIds,
    variables: { battlecardId: +selectedBattleCardId, quotaComponentId: +selectedQuotaComponentId }
  });

  const territoryGroupTree = useMemo(() => {
    if (!data?.getAllTerritoryGroups.allTerritoryGroups) return new TerritoryGroupTree([]);
    const allGroups = data.getAllTerritoryGroups.allTerritoryGroups.filter(Boolean);
    const geoGroups = filterTerritoryGroupsByHierarchyType(allGroups, HierarchyType.GeographicTerritoryHierarchy);
    const colorPicker = new LeastUsedColorPicker([]);
    const groupsForMap = geoGroups.map(
      ({ territoryGroupId, territoryGroupParentId, territoryGroupName, owner }): TerritoryGroupForMap => ({
        territoryGroupId,
        territoryGroupParentId,
        owner,
        name: territoryGroupName,
        color: colorPicker.useLeastUsedColor()
      })
    );
    return new TerritoryGroupTree(groupsForMap);
  }, [data]);

  return { loading, territoryGroupTree };
};
