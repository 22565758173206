import React, { Dispatch, SetStateAction, useState } from 'react';

import MultiStepDialog from 'components/Dialog/MultiStepDialog';

import ImportActivityFileUpload from 'app/components/ImportActivityDialog/ImportActivityFileUpload/ImportActivityFileUpload';
import ImportFileMappingContainer from 'app/components/ImportFileDialog/ImportFileMappingContainer/ImportFileMappingContainer';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';

import { formatMessage } from 'utils/messages/utils';

interface ImportActivityDialogProps {
  setShowImportActivityDialog: Dispatch<SetStateAction<boolean>>;
  showImportActivityDialog: boolean;
  handleShouldRefetch: Dispatch<SetStateAction<boolean>>;
}

const ImportActivityDialog: React.FC<ImportActivityDialogProps> = ({
  setShowImportActivityDialog,
  showImportActivityDialog,
  handleShouldRefetch
}: ImportActivityDialogProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataMatchingRequired, setDataMatchingRequired] = useState<boolean>(false);

  const onNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const renderPages = () => {
    let pages = [
      <ImportActivityFileUpload
        onNext={onNext}
        dataMatchingRequired={dataMatchingRequired}
        setDataMatchingRequired={setDataMatchingRequired}
        onClose={() => setShowImportActivityDialog(false)}
        handleShouldRefetch={handleShouldRefetch}
      />
    ];
    if (dataMatchingRequired) {
      pages = [
        ...pages,
        <ImportFileMappingContainer
          fileType={FileTypeEnum.Activity}
          onClose={() => setShowImportActivityDialog(false)}
          handleShouldRefetch={handleShouldRefetch}
        />
      ];
    }
    return pages;
  };

  const renderProps = () => {
    return { title: currentIndex === 0 ? formatMessage('IMPORT_FROM_CSV') : formatMessage('MATCH_FIELDS_TITLE') };
  };
  return (
    <MultiStepDialog
      data-testid="import-activity-dialog"
      isOpen={showImportActivityDialog}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      pages={renderPages()}
      showDialogFooter={false}
      {...renderProps()}
    />
  );
};

export default ImportActivityDialog;
