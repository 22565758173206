import React, { useRef } from 'react';

import { Add } from '@carbon/icons-react';
import { CalloutV2, Intent } from '@varicent/components';
import { Field, Form, Formik, FormikProps } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import SelectMenu from 'components/SelectMenu/SelectMenu';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useScope } from 'app/contexts/scopeProvider';

import { SystemRoleName } from 'app/graphql/generated/apolloTypes';
import { useInviteUser } from 'app/graphql/mutations/inviteUser';
import { useUpsertMember } from 'app/graphql/mutations/upsertMember';
import { GET_MEMBER_COUNT } from 'app/graphql/queries/getMemberCount';
import { GET_MEMBER_LIST } from 'app/graphql/queries/getMemberList';

import useShowToast from 'app/hooks/useShowToast';

import { UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CreateUserDialog.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

interface CreateUserDialogProps {
  onClose: () => void;
  onComplete: () => void;
}

export interface CreateUserDialogFormValues {
  employeeId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  systemRoleName: {
    key: string;
    value: UserRoleType;
  };
}

const initialFormValues: CreateUserDialogFormValues = {
  employeeId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  systemRoleName: { value: UserRoleType.NONE, key: formatMessage('NONE') }
};

const userRoleList = [
  { value: UserRoleType.NONE, key: formatMessage('NONE') },
  { value: UserRoleType.ADMIN, key: formatMessage('ADMINISTRATOR') },
  { value: UserRoleType.CONTRIBUTOR, key: formatMessage('CONTRIBUTOR') }
];

const CreateUserDialog: React.FC<CreateUserDialogProps> = ({
  onClose,
  onComplete
}: CreateUserDialogProps): JSX.Element => {
  const portalRef = useRef(null);

  const formRef = useRef<FormikProps<CreateUserDialogFormValues>>();

  const { selectedTenant } = useScope();
  const showToast = useShowToast();

  const textInputSharedProps = {
    type: 'text',
    isRequired: true,
    component: FormTextInputGroup
  };

  const [upsertMember, { loading }] = useUpsertMember({
    refetchQueries: [GET_MEMBER_LIST, GET_MEMBER_COUNT],
    onError: (error) => {
      if (error.knownError?.errorCode === 'MEMBER_ALREADY_EXISTS') {
        const { setFieldError } = formRef.current;
        setFieldError('emailAddress', formatMessage('ENTER_UNIQUE_EMAIL'));
      } else {
        showToast(
          <ToastMessage
            title={formatMessage('USER_CREATION_FAILED')}
            message={formatMessage('USER_CREATION_FAILED_MESSAGE')}
          />,
          'danger'
        );
        onClose();
      }
    }
  });

  const handleSubmit = async (values: CreateUserDialogFormValues) => {
    const { employeeId, emailAddress, firstName, lastName, jobTitle, systemRoleName } = values;
    const response = await upsertMember({
      variables: {
        input: {
          emailAddress,
          firstName,
          lastName,
          employeeId,
          jobTitle
        }
      }
    });

    if (!response.data) return;

    showToast(
      <ToastMessage
        title={formatMessage('USER_PROFILE_ADDED')}
        message={formatMessage('USER_PROFILE_CREATION_SUCCESS', {
          name: `${firstName} ${lastName}`
        })}
      />,
      'success'
    );

    switch (systemRoleName.value) {
      case UserRoleType.ADMIN:
        handleInviteUser(values, SystemRoleName.Administrator);
        break;
      case UserRoleType.CONTRIBUTOR:
        handleInviteUser(values, SystemRoleName.Contributor);
        break;
      case UserRoleType.NONE:
        onComplete();
    }
    onClose();
  };

  const [inviteUser] = useInviteUser({
    refetchQueries: [GET_MEMBER_LIST]
  });

  const handleInviteUser = async (values: CreateUserDialogFormValues, systemRoleName: SystemRoleName) => {
    const { emailAddress, firstName, lastName } = values;
    try {
      await inviteUser({
        variables: {
          emailAddress,
          firstName,
          lastName,
          systemRoleName,
          tenantId: selectedTenant.id
        }
      });

      showToast(
        <ToastMessage
          title={formatMessage('ACCESS_GRANTED_TITLE', { systemRoleName })}
          message={formatMessage('INVITATION_SENT')}
        />,
        'success'
      );
      onComplete();
    } catch (error) {
      console.error('Error during user invitation', error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationsSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ handleSubmit, values }) => {
        return (
          <Dialog
            isOpen
            title={formatMessage('CREATE_USER_PROFILE')}
            confirmButtonIcon={<Add />}
            confirmButtonText={formatMessage('CREATE')}
            onClose={onClose}
            onSubmit={handleSubmit}
            portalClassName={b('dialog')}
            confirmButtonLoading={loading}
          >
            <Form>
              <div className={b('formWrapper')} ref={portalRef} data-testid="create-user-dialog">
                <Field
                  {...textInputSharedProps}
                  label={formatMessage('USER_MANAGEMENT_TABLE_EMPLOYEE_ID')}
                  name="employeeId"
                />
                <Field {...textInputSharedProps} label={formatMessage('EMAIL')} name="emailAddress" />
                <Field {...textInputSharedProps} label={formatMessage('FIRST_NAME')} name="firstName" />
                <Field {...textInputSharedProps} label={formatMessage('LAST_NAME')} name="lastName" />
                <Field
                  label={formatMessage('USER_MANAGEMENT_TABLE_JOB_TITLE')}
                  name="jobTitle"
                  type="text"
                  component={FormTextInputGroup}
                />
                <div className={b('dropdownWrapper')}>
                  <label className={b('roleLabel')}>{formatMessage('ROLE_REQUIRED')}</label>
                  <Field
                    name="systemRoleName"
                    type="text"
                    isRequired
                    component={SelectMenu}
                    items={userRoleList}
                    usePortal
                    portalRef={portalRef}
                  />
                </div>
              </div>
              {/* eslint-disable-next-line no-restricted-syntax */}
              {values.systemRoleName.value === UserRoleType.CONTRIBUTOR && (
                <CalloutV2 intent={Intent.PRIMARY}>{formatMessage('CONTRIBUTOR_INVITE_CALLOUT')}</CalloutV2>
              )}
              {/* eslint-disable-next-line no-restricted-syntax */}
              {values.systemRoleName.value === UserRoleType.ADMIN && (
                <CalloutV2 intent={Intent.PRIMARY}>{formatMessage('ADMIN_INVITE_CALLOUT')}</CalloutV2>
              )}
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreateUserDialog;
