import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GridApi, ServerSideStoreType } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { ApolloQueryResult } from '@apollo/client';
import dayjs from 'dayjs';

import ToastMessage from 'components/ToastMessage/ToastMessage';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import GridFilter from 'app/components/AdvancedGrid/GridHelpers/Filters/GridFilter';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';
import { ACCOUNT_QUOTA_BLOCK_SIZE } from 'app/global/variables';

import {
  AccountRedirectInput,
  GetAccountRuleBindings,
  GetAccountRuleBindings_getAccountRuleBindings_bindings,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects,
  GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule,
  GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes
} from 'app/graphql/generated/apolloTypes';
import { useGetSheetDefinitions } from 'app/graphql/hooks/useGetSheetDefinitions';

import useCanUser from 'app/hooks/useCanUser';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  AccountMoveVariables,
  AqgTerritoryKind,
  DeleteRedirectVariables,
  GridFields,
  GridHeaders,
  HierarchyType,
  Toast,
  MeasureType,
  SheetType,
  DefaultSheetName,
  TerritorySheetGridColumnName
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import AccountMoveDialog from './AccountMoveDialog';
import AccountMoveWithQuotaDialog from './AccountMoveWithQuotaDialog';
import style from './AccountQuotaGrid.module.pcss';
import buildAccountQuotaGridColumnDef from './AccountQuotaGridColumnDef';
import AccountQuotaTGTFilter from './AccountQuotaTGTFilter';
import {
  getDayAfterDate,
  getFormattedBindings,
  getPlanningCycleEndDate,
  handleRedirectRowRefresh
} from './AccountQuotaUtils';
import { redirectAccountUnit } from './AccountSheetUtils';
import DeleteRedirectDialog from './DeleteRedirectDialog';
import { useGetAccountRuleBindings } from './useGetAccountRuleBindings';

const b = block(style);

interface AccountQuotaGridProps {
  territoryGroupTypes: GetBattleCard_getDeploymentModelSpec_battlecards_territoryGroupTypes[];
}

export interface AqgRow {
  territoryName: string;
  territoryId: string;
  sourceRuleId: number;
  kind: AqgTerritoryKind;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
  redirectStartDate?: string;
  redirectEndDate?: string;
  redirectId?: number;
  firstRedirectStartDate?: string;
}

export interface AqgBindingRow extends AqgRow {
  accountId: number;
  accountKey: string;
  accountName: string;
  redirects: GetAccountRuleBindings_getAccountRuleBindings_bindings_redirects[];
  sourceRule: GetAccountRuleBindings_getAccountRuleBindings_bindings_sourceRule;
  accountQuotaMeasureValue: number;
  accountQuotaMeasureId: number;
}

const AccountQuotaGrid: React.FC<AccountQuotaGridProps> = ({ territoryGroupTypes }) => {
  const { selectedQuotaComponentId, battleCardLookupMap, selectedBattleCardId, quotaBreakdownHierarchies } =
    useBattleCard();
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const canEditAccountQuota = useCanUser(UserAction.ACCOUNT_QUOTA_EDIT);
  const { allDataSheets } = useData();

  const [currentTerritoryGroupTypeId, setCurrentTerritoryGroupTypeId] = useState<number>(null);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = useState<boolean>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(null);
  const [accountMoveVariables, setAccountMoveVariables] = useState<AccountMoveVariables>(null);
  const [deleteRedirectVariables, setDeleteRedirectVariables] = useState<DeleteRedirectVariables>(null);
  const [isAccountMoveWithQuotaEnabled] = useTreatment(SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_GRID);
  const { defaultReportingCurrency } = useLocalization();
  const showToast = useShowToast();
  const gridApi = useRef<GridApi>(null);

  const containerRef = React.useRef(null);

  const territoryGroupTypeId = useMemo(() => {
    return currentTerritoryGroupTypeId ?? territoryGroupTypes?.[0]?.territoryGroupId;
  }, [territoryGroupTypes, currentTerritoryGroupTypeId]);

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const [getRuleBindings, { data: initialRuleBindings, loading, fetchMore }] = useGetAccountRuleBindings({
    input: {
      territoryGroupTypeId,
      quotaComponentId: selectedQuotaComponentId,
      startRow: 1,
      endRow: ACCOUNT_QUOTA_BLOCK_SIZE
    }
  });

  const isOnlyCustomerHierarchySelected =
    quotaBreakdownHierarchies?.length === 1 &&
    quotaBreakdownHierarchies[0]?.hierarchyType === HierarchyType.CustomerAccountHierarchy;

  // get territory quota adjustment measureId from the territory sheet
  const defaultTerritorySheetId = allDataSheets.filter(
    (sheet) =>
      sheet.sheetType === SheetType.TERRITORY_SHEET && sheet.sheetName === DefaultSheetName.TERRITORY_QUOTA_SHEET
  )?.[0]?.sheetId;
  const { sheetDefinitions: territoryQuotaSheetDefinitions } = useGetSheetDefinitions({
    deploymentModelId: selectedDeploymentModelId,
    sheetId: defaultTerritorySheetId,
    isTQM: true
  });

  const territoryQuotaSheetDefinitionLists = territoryQuotaSheetDefinitions?.[0]?.sheetDefinitions;
  const territoryQuotaAdjustmentMeasureId = territoryQuotaSheetDefinitionLists?.find(
    (measure) => measure?.measureName === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
  )?.measureId;
  const accountQuotaMeasureId = initialRuleBindings?.getAccountRuleBindings?.bindings?.[0]?.measures?.find(
    (measure) => measure.measureName === MeasureType.ACCOUNT_QUOTA
  )?.measureId;

  const getIsServerSideGroup = useCallback((dataItem: AqgBindingRow) => dataItem?.redirects?.length > 0, []);

  const getServerSideGroupKey = useCallback(
    (binding: AqgBindingRow) => `${binding.sourceRuleId}::${binding.accountId}`,
    []
  );

  useEffect(() => {
    if (territoryGroupTypeId) {
      getRuleBindings();
    }
  }, [territoryGroupTypeId]);

  const formatBindings = (bindings: GetAccountRuleBindings_getAccountRuleBindings_bindings[]): AqgBindingRow[] => {
    return getFormattedBindings(
      bindings,
      getPlanningCycleEndDate(selectedPlanningCycle.planningCycleStartDate, selectedPlanningCycle.planningCycleDuration)
    );
  };

  const updateCurrentTerritoryGroupType = useCallback((territoryGroupTypeId: number): void => {
    setCurrentTerritoryGroupTypeId(territoryGroupTypeId);
  }, []);

  const getExpandedTerritoryRows = (expandedRow: AqgBindingRow): AqgRow[] => {
    const sortedTerritories = expandedRow?.redirects
      ? [...expandedRow.redirects].sort((a, b) => b.startDate.localeCompare(a.startDate))
      : [];

    const redirectedTerritories = sortedTerritories.map((redirect, index) => {
      const fallBackMinDate =
        expandedRow.sourceRule?.effectiveDate &&
        dayjs(expandedRow.sourceRule?.effectiveDate).isAfter(selectedPlanningCycle.planningCycleStartDate)
          ? expandedRow.sourceRule?.effectiveDate
          : selectedPlanningCycle.planningCycleStartDate;

      const minDateForUpsert =
        index < sortedTerritories.length - 1 ? sortedTerritories[index + 1].startDate : fallBackMinDate;
      const accountQuotaMeasureValue = redirect.fields.find(
        (field) => field.primaryFieldId === expandedRow.accountQuotaMeasureId
      )?.fieldValue;
      const territoryName = redirect.targetRule?.territoryName ?? formatMessage('UNASSIGNED_TERRITORY');
      const territoryId = redirect.targetRule?.territoryId ?? null;
      let kind;
      if (!redirect.targetRule?.ruleId) {
        kind = AqgTerritoryKind.UNASSIGNED;
      } else if (redirect.targetRule.ruleId === expandedRow.sourceRule.ruleId) {
        kind = AqgTerritoryKind.MOVE_BACK;
      } else {
        kind = AqgTerritoryKind.MOVE_AWAY;
      }
      return {
        territoryName,
        territoryId,
        kind,
        redirectStartDate: redirect.startDate,
        redirectEndDate: redirect.endDate,
        redirectId: redirect.redirectId,
        sourceRuleId: expandedRow.sourceRule.ruleId,
        minDateForUpsert: getDayAfterDate(minDateForUpsert),
        accountQuotaMeasureValue
      };
    });

    const originalTerritory = {
      territoryName: expandedRow.sourceRule.territoryName,
      territoryId: expandedRow.sourceRule.territoryId,
      sourceRuleId: expandedRow.sourceRule.ruleId,
      kind: AqgTerritoryKind.EXPANDED_SOURCE,
      effectiveStartDate: expandedRow.sourceRule.effectiveDate,
      effectiveEndDate: expandedRow.sourceRule.endDate,
      firstRedirectStartDate: redirectedTerritories[redirectedTerritories.length - 1].redirectStartDate
    };
    return [...redirectedTerritories, originalTerritory];
  };

  const accountQuotaGridProps: AgGridReactProps = {
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    cacheBlockSize: ACCOUNT_QUOTA_BLOCK_SIZE,
    suppressMenuHide: true,
    onRowGroupOpened(event) {
      const nodeData = event?.node?.data;
      event?.node?.setData({ ...nodeData, isRowExpanded: !!event?.expanded });
    },
    rowHeight: CELL_HEIGHT,
    serverSideDatasource: {
      getRows: async (params) => {
        const { startRow, endRow } = params?.request;
        const filterModel = params.api.getFilterModel();
        const newFilterModel = {};

        if ('ag-Grid-AutoColumn' in filterModel) {
          newFilterModel['accountName'] = filterModel['ag-Grid-AutoColumn'];
        }

        if ('accountKey' in filterModel) {
          newFilterModel['key'] = filterModel['accountKey'];
        }

        // ParentNode key exists when a row is expanded
        if (params.parentNode?.key) {
          const expandedTerritoryRows = getExpandedTerritoryRows(params.parentNode.data);
          params.success({ rowData: expandedTerritoryRows, rowCount: expandedTerritoryRows.length });
          return;
        }
        const response: ApolloQueryResult<GetAccountRuleBindings> = await fetchMore({
          variables: {
            input: {
              territoryGroupTypeId,
              quotaComponentId: selectedQuotaComponentId,
              startRow: startRow + 1,
              endRow,
              filters: JSON.stringify(newFilterModel)
            }
          }
        });
        const { totalCount, bindings } = response?.data?.getAccountRuleBindings ?? {};
        if (totalCount === null || !bindings) {
          params.fail();
          return;
        }

        if (totalCount === 0) {
          params.api.showNoRowsOverlay();
        } else {
          params.api.hideOverlay();
        }

        params.success({
          rowData: formatBindings(bindings),
          rowCount: totalCount
        });
      }
    }
  };

  const handleAccountMoveDialogOpen = (moveVariables: AccountMoveVariables) => {
    setIsMoveDialogOpen(true);
    setAccountMoveVariables({
      ...moveVariables,
      accountQuotaMeasureId,
      territoryQuotaAdjustmentMeasureId
    });
  };

  const handleAccountMoveDialogClose = () => {
    setIsMoveDialogOpen(false);
    setAccountMoveVariables(null);
  };

  const handleDeleteDialogOpen = (deleteVariables: DeleteRedirectVariables) => {
    setIsDeleteDialogOpen(true);
    setDeleteRedirectVariables(deleteVariables);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setDeleteRedirectVariables(null);
  };

  const handleRedirectAccountUnit = async (accountRedirectInput: AccountRedirectInput, toast: Toast) => {
    try {
      await redirectAccountUnit(accountRedirectInput);
      showToast(<ToastMessage title={toast.title} message={toast.message} />, 'success');
      const refetchPromises: Promise<void>[] = [];
      gridApi?.current.forEachNode((rowNode) => {
        refetchPromises.push(
          handleRedirectRowRefresh({
            rowNode,
            accountRedirectInput,
            fetchMore,
            territoryGroupTypeId,
            selectedQuotaComponentId,
            selectedPlanningCycle,
            getExpandedTerritoryRows
          })
        );
      });
      await Promise.all(refetchPromises);
      gridApi?.current.refreshCells({ force: true });
    } catch (error) {
      showToast(formatMessage('UPDATE_REDIRECT_ERROR'), 'danger');
    }
  };
  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;
  const selectedPlanningCycleDuration = {
    planningCycleStartDate: selectedPlanningCycle.planningCycleStartDate,
    planningCycleDuration: selectedPlanningCycle.planningCycleDuration
  };

  const columnDefs = useMemo(() => {
    if (!loading) {
      return buildAccountQuotaGridColumnDef(
        handleAccountMoveDialogOpen,
        handleDeleteDialogOpen,
        selectedPlanningCycleDuration,
        handleRedirectAccountUnit,
        canEditAccountQuota,
        currency,
        currentTerritoryGroupTypeId,
        isAccountMoveWithQuotaEnabled
      );
    }
    return null;
  }, [loading]);

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const namedTgts = useMemo(
    () => territoryGroupTypes?.map((tgt) => ({ name: tgt.hierarchyAlias, id: tgt.territoryGroupId })) ?? [],
    [territoryGroupTypes]
  );

  return (
    <div className={b('gridWrapper')} ref={containerRef}>
      <AccountQuotaTGTFilter
        territoryGroupTypes={namedTgts}
        onTerritoryGroupTypeChange={updateCurrentTerritoryGroupType}
        currentTerritoryGroupType={currentTerritoryGroupTypeId}
      />
      <div className={b('advancedGridWrapper')}>
        {!loading && initialRuleBindings?.getAccountRuleBindings?.bindings?.length ? (
          <AdvancedGrid
            data-testid="account-quota-grid"
            gridProps={accountQuotaGridProps}
            columnDefs={columnDefs}
            treeData
            autoGroupColumnDef={{
              headerName: GridHeaders.ACCOUNT_NAME,
              field: GridFields.ACCOUNT_NAME,
              flex: 2,
              filter: 'accountQuotaFilter',
              filterParams: {
                type: 'text'
              },
              icons: {
                menu: '<i class="ag-icon ag-icon-filter"/>',
                filter: ' '
              }
            }}
            frameworkComponents={{
              accountQuotaFilter: GridFilter
            }}
            suppressRowHoverHighlight
            isServerSideGroup={getIsServerSideGroup}
            getServerSideGroupKey={getServerSideGroupKey}
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            suppressRowTransform
            onGridReady={onGridReady}
          />
        ) : (
          <>
            {loading ? (
              <div data-testid="account-quota-grid-loading">
                <GridLoading
                  gridHeight={containerRef?.current?.offsetHeight}
                  gridWidth={containerRef?.current?.offsetWidth}
                />
              </div>
            ) : (
              <div className={b('gridOverlayContainer')} data-testid="no-data-overlay">
                <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
              </div>
            )}
          </>
        )}
      </div>
      {isMoveDialogOpen &&
        accountMoveVariables &&
        (isAccountMoveWithQuotaEnabled && isOnlyCustomerHierarchySelected ? (
          <AccountMoveWithQuotaDialog
            accountMoveVariables={accountMoveVariables}
            onClose={handleAccountMoveDialogClose}
            onMoveSuccess={getRuleBindings}
            currency={currency}
            data-testid="account-move-with-quota-dialog"
          />
        ) : (
          <AccountMoveDialog
            accountMoveVariables={accountMoveVariables}
            onClose={handleAccountMoveDialogClose}
            territoryGroupTypeId={territoryGroupTypeId}
            onMoveSuccess={getRuleBindings}
          />
        ))}
      {isDeleteDialogOpen && deleteRedirectVariables && (
        <DeleteRedirectDialog
          deleteRedirectVariables={deleteRedirectVariables}
          onClose={handleDeleteDialogClose}
          onDeleteSuccess={getRuleBindings}
        />
      )}
    </div>
  );
};

export default AccountQuotaGrid;
