import React from 'react';

import DataTrayImportButton from 'app/components/DataTray/DataTrayImportButton/DataTrayImportButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';

import { BattlecardType, PlanningCycleModal } from 'app/models';

import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

interface QuotaGridActionButtonsProps {
  setOpenedModal: (dataTrayModal: PlanningCycleModal) => void;
}

const QuotaGridActionButtons: React.FC<QuotaGridActionButtonsProps> = ({
  setOpenedModal
}: QuotaGridActionButtonsProps) => {
  const { selectedBattleCardId } = useBattleCard();
  const { battleCardLookupMap } = useBattleCard();

  const { showAccountQuotaDrillIn } = useGrid();

  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;

  const isDisabled = !selectedBattleCardId || isRootBattleCard || isRollUpBattleCard || showAccountQuotaDrillIn;

  return (
    <CanUser
      perform={UserAction.TERRITORY_DEFINITION_MODIFY}
      yes={
        <>
          <DataTrayImportButton
            disabled={isDisabled}
            tooltipText={formatMessage('IMPORT_QUOTAS')}
            onClick={() => setOpenedModal(PlanningCycleModal.IMPORT_QUOTAS)}
            data-testid="import-quota-button"
          />
        </>
      }
    />
  );
};

export default QuotaGridActionButtons;
