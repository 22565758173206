import { useRef, useState } from 'react';

import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetUserTenant } from 'app/graphql/generated/apolloTypes';

export const GET_USER_TENANT = gql`
  query GetUserTenant {
    getUserSpec {
      subjectId
      emailAddress
      firstName
      lastName
      passwordUpdatedAt
      isSupportUser
      membershipSpecs {
        memberId
        tenantId
        tenantGlobalId
        tenantName
        tenantSlug
      }
    }
  }
`;

export const useGetUserTenant = createUseQuery<GetUserTenant, never>(GET_USER_TENANT);

export const useGetUserTenantExactlyOnce = (): { userTenant: GetUserTenant | null; loading: boolean | null } => {
  const hasFinished = useRef(false);
  const [userTenant, setUserTenant] = useState<GetUserTenant | null>(null);
  const { loading } = useGetUserTenant({
    skip: hasFinished.current,
    onCompleted: (data) => {
      hasFinished.current = true;
      setUserTenant(data);
    },
    onError: () => {
      hasFinished.current = true;
    }
  });
  return { userTenant, loading };
};
