import React from 'react';

import { QueryResult } from '@apollo/client';
import { Currency, Dashboard, DataCheck, Diagram, Events, Link, RulerAlt } from '@carbon/icons-react';

import DataPanel from 'app/components/DataPanel/DataPanel';
import IntegrationPanel from 'app/components/IntegrationPanel/IntegrationPanel';
import MeasuresContent from 'app/components/MeasuresContent/MeasuresContent';
import UserManagementOverview from 'app/components/UserManagementPanel/UserManagementOverview/UserManagementOverview';

import CurrencyPage from 'app/containers/Currency/CurrencyPage';
import QuotaComponentsPage from 'app/containers/QuotaComponents/QuotaComponentsPage';
import SymonDashboardPortal from 'app/containers/Symon/SymonDashboardPortal';

import { SplitFeatures } from 'app/global/features';

import { GetMemberCount, GetMemberCountVariables } from 'app/graphql/generated/apolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import {
  CommandCenterDrawerState,
  CommandCenterMenuItem,
  CommandCenterMenuSection,
  CommandCenterMenuState
} from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export const useDefaultMenuItems = (
  getMemberCount: () => Promise<QueryResult<GetMemberCount, GetMemberCountVariables>>,
  memberCount: number
): {
  key: CommandCenterMenuItem;
  section: CommandCenterMenuSection;
  icon: JSX.Element;
  component: JSX.Element;
  title: string;
  text: string;
  availability?: string;
  drawerState: CommandCenterDrawerState;
  menuState: CommandCenterMenuState;
}[] => {
  const [isSymonEmbedEnabled] = useTreatment(SplitFeatures.SYMON_EMBED);

  return [
    {
      key: CommandCenterMenuItem.USER_MANAGEMENT,
      section: CommandCenterMenuSection.ADMIN,
      icon: <Events size={24} />,
      component: <UserManagementOverview getMemberCount={getMemberCount} />,
      title: formatMessage('USER_MANAGEMENT'),
      text: formatMessage('USER_MANAGEMENT'),
      availability: formatMessage('AVAILABLE_WITH_COUNT', { count: memberCount }),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    {
      key: CommandCenterMenuItem.DATA,
      section: CommandCenterMenuSection.ADMIN,
      icon: <DataCheck size={24} />,
      component: <DataPanel />,
      title: formatMessage('DATA'),
      text: formatMessage('DATA'),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    {
      key: CommandCenterMenuItem.INTEGRATION,
      section: CommandCenterMenuSection.ADMIN,
      icon: <Link size={24} />,
      component: <IntegrationPanel />,
      title: formatMessage('INTEGRATIONS'),
      text: formatMessage('INTEGRATIONS'),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    isSymonEmbedEnabled && {
      key: CommandCenterMenuItem.DASHBOARDS,
      section: CommandCenterMenuSection.ADMIN,
      icon: <Dashboard size={24} />,
      component: <SymonDashboardPortal />,
      title: formatMessage('DASHBOARDS'),
      text: formatMessage('DASHBOARDS'),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    {
      key: CommandCenterMenuItem.MEASURES,
      section: CommandCenterMenuSection.PLANNING_CYCLE,
      icon: <RulerAlt size={24} />,
      component: <MeasuresContent />,
      title: formatMessage('MEASURES'),
      text: formatMessage('MEASURES'),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    {
      key: CommandCenterMenuItem.CURRENCIES,
      section: CommandCenterMenuSection.PLANNING_CYCLE,
      icon: <Currency size={24} />,
      component: <CurrencyPage />,
      title: formatMessage('CURRENCIES'),
      text: formatMessage('CURRENCIES'),
      drawerState: CommandCenterDrawerState.EXPAND,
      menuState: CommandCenterMenuState.ICON_ONLY
    },
    {
      key: CommandCenterMenuItem.QUOTA_COMPONENTS,
      section: CommandCenterMenuSection.PLANNING_CYCLE,
      icon: <Diagram size={24} />,
      component: <QuotaComponentsPage />,
      title: formatMessage('QUOTA_COMPONENTS'),
      text: formatMessage('QUOTA_COMPONENTS'),
      drawerState: CommandCenterDrawerState.HALF,
      menuState: CommandCenterMenuState.FULL
    }
  ].filter(Boolean);
};
