export const writeToLocalStorageSafely = (localStorageKey: string, value: unknown) => {
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  } catch (error) {
    console.warn(`Unable to write to "${localStorageKey}"`);
  }
};

export const readFromLocalStorageSafely = <T>(localStorageKey: string): T | null => {
  try {
    const valueFromStorage = localStorage.getItem(localStorageKey);
    if (valueFromStorage !== null) {
      return JSON.parse(valueFromStorage) as T;
    }
  } catch (error) {
    console.warn(`Unable to obtain "${localStorageKey}"`);
  }
  return null;
};

export const createScopedLocalStorage = <T>(localStorageKey) => ({
  write: (value: T) => writeToLocalStorageSafely(localStorageKey, value),
  read: () => readFromLocalStorageSafely<T>(localStorageKey)
});
