import { UUID } from 'crypto';

import { ChatRoleEnum } from 'app/graphql/generated/apolloTypes';

import {
  AssistantStreamResponseKeys,
  AssistantMessageDetails,
  AssistantParsedStreamData,
  AssistantStreamResponseMessageTypes
} from 'app/models';

const MAX_TYPING_CHUNK_LENGTH = 3;
const CONVERSATION_ID_UUID_REGEX = `${AssistantStreamResponseKeys.CONVERSATION_ID}([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`;

/**
 * Extracts the conversationId from a JSON object if available and confirms it is a UUID.
 * @param jsonData - A parsed JSON object from a response chunk.
 * @returns A string representing the conversationId, or null if not present or invalid.
 */
const extractConversationId = (jsonData: Record<string, string>): string | null => {
  if (
    jsonData[AssistantStreamResponseKeys.MESSAGE_TYPE] === AssistantStreamResponseMessageTypes.SYSTEM &&
    typeof jsonData[AssistantStreamResponseKeys.TEXT] === 'string'
  ) {
    const regex = new RegExp(CONVERSATION_ID_UUID_REGEX);
    const match = regex.exec(jsonData[AssistantStreamResponseKeys.TEXT]);
    return match ? match[1] : null;
  }
  return null;
};

export const parseStreamData = (streamData: string[]): AssistantParsedStreamData => {
  let newCurrentMessage = '';
  let conversationId: string | null = null;

  streamData.forEach((item) => {
    const dataEntries = item.split(AssistantStreamResponseKeys.DATA).filter((entry) => entry.trim() !== '');
    dataEntries.forEach((entry) => {
      try {
        const parsedData = JSON.parse(entry);
        const lastTextChunk = parsedData[AssistantStreamResponseKeys.TEXT] || '';

        // Concatenate content messages
        if (
          AssistantStreamResponseKeys.MESSAGE_TYPE in parsedData &&
          parsedData[AssistantStreamResponseKeys.MESSAGE_TYPE] === AssistantStreamResponseMessageTypes.CONTENT
        ) {
          newCurrentMessage = newCurrentMessage.concat(lastTextChunk || ''); // Safeguard against undefined text
        }

        // Attempt to extract conversationId if not already retrieved
        if (!conversationId) {
          conversationId = extractConversationId(parsedData);
        }
      } catch (error) {
        console.error('Failed to process stream data entry:', entry, error);
        return;
      }
    });
  });

  return {
    ...(newCurrentMessage && { content: newCurrentMessage }),
    ...(conversationId && { conversationId })
  };
};

export const updateMessages = (
  prevMessages: AssistantMessageDetails[],
  newMessage: string,
  activeConversation: string,
  inputMessageKey: UUID
): AssistantMessageDetails[] => {
  const updatedMessages = prevMessages.filter(
    (message) => message.content.role !== ChatRoleEnum.ai || message.inputMessageKey !== inputMessageKey
  );

  updatedMessages.push({
    content: { message: newMessage, role: ChatRoleEnum.ai },
    conversationId: activeConversation,
    inputMessageKey
  });

  return updatedMessages;
};

export const getTypingEffectChunks = (message: string): string[] => {
  let index = 0;
  let chunks = [];
  let accumulatedText = '';

  while (index < message.length) {
    let chunkSize = MAX_TYPING_CHUNK_LENGTH;
    let chunk = message.substring(index, Math.min(index + chunkSize, message.length));
    accumulatedText += chunk;
    chunks.push(accumulatedText);
    index += chunkSize;
  }

  return chunks;
};
