import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import { AccountRedirectInput } from 'app/graphql/generated/apolloTypes';
import { REDIRECT_ACCOUNT_UNIT } from 'app/graphql/mutations/redirectAccountUnit';

export const redirectAccountUnit = async (input: AccountRedirectInput): Promise<void> => {
  // eslint-disable-next-line no-restricted-syntax
  await apolloClient.mutate({
    mutation: REDIRECT_ACCOUNT_UNIT,
    variables: { input }
  });
};
