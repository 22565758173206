import React, { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { DocumentImport } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import MultiStepDialogFooter from 'components/Dialog/MultiStepDialogFooter/MultiStepDialogFooter';

import DataMappingController from 'app/components/DataMappingDrillIn/DataMappingController';
import DataMappingDrillIn from 'app/components/DataMappingDrillIn/DataMappingDrillIn';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';
import { usePublishFileToDB } from 'app/graphql/hooks/usePublishFileToDB';

import useShowToast from 'app/hooks/useShowToast';

import { DataMappingType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportFileMappingContainer.module.pcss';

const b = block(style);

interface ImportFileMappingContainerProps {
  onClose?: (event: SyntheticEvent<HTMLElement>) => void;
  fileType: FileTypeEnum;
  handleShouldRefetch?: Dispatch<SetStateAction<boolean>>;
}

const ImportFileMappingContainer: React.FC<ImportFileMappingContainerProps> = ({
  onClose,
  fileType,
  handleShouldRefetch
}: ImportFileMappingContainerProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const { selectedBattleCardId } = useBattleCard();
  const { selectedPlanningCycle } = useScope();
  const showToast = useShowToast();

  const getRowData = () => {
    const rowData = [];
    if (gridApi) {
      gridApi.forEachNode((node) => rowData.push(node.data));
    }
    return rowData;
  };
  const {
    isAllColumnsMapped,
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus,
    mappingProperties,
    missingRequiredFields,
    resetFileUploadValues
  } = useData();

  const canSubmit = selectedTable && isAllColumnsMapped && missingRequiredFields.length === 0;

  const [publishFileToDB, { loading: publishingFileToDb }] = usePublishFileToDB({
    selectedTable,
    setSelectedTable,
    setFileUploadInProgress,
    pollForProcessingStatus
  });

  const onSubmit = (e) => {
    setIsSubmitted(true);
    if (canSubmit) {
      onPublish(e);
    }
  };

  const onPublish = async (e) => {
    const publishedFile = await publishFileToDB({
      variables: {
        input: {
          fileId: selectedTable.tableId,
          fieldNames: mappingProperties,
          fileType,
          planningCycleId: selectedPlanningCycle.id,
          battlecardId: +selectedBattleCardId || null
        }
      }
    });
    if (publishedFile?.data?.publishFileToDB) {
      showToast(formatMessage('PUBLISHING_IN_PROGRESS'), 'success');
      resetFileUploadValues();
      handleShouldRefetch?.(true);
      onClose(e);
    }
  };

  return (
    <>
      <div className={b()} data-testid="import-file-mapping-container">
        <DataMappingController
          data-testid="data-mapping-controller"
          rowData={getRowData()}
          controllerType={DataMappingType.DATA_TABLE}
          withSpacing={false}
          isDialogMapping={true}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          fileType={fileType}
        />
        <HTMLHeading
          bold
          tagLevel="h5"
          text={formatMessage('FILE_PREVIEW')}
          className={b('heading')}
          data-testid="data-mapping-heading"
        />
        <DataMappingDrillIn
          data-testid="data-mapping-drill-in"
          setGridApi={setGridApi}
          dataMappingType={DataMappingType.DATA_TABLE}
          withSpacing={false}
          fileType={fileType}
        />
      </div>
      <MultiStepDialogFooter
        confirmButtonLoading={publishingFileToDb}
        onSubmit={onSubmit}
        confirmButtonText={formatMessage('IMPORT')}
        confirmButtonIcon={<DocumentImport />}
        disableConfirm={publishingFileToDb}
        isFinalStep
        showBack={false}
        onClose={onClose}
      />
    </>
  );
};

export default ImportFileMappingContainer;
