import { SortDirection } from 'app/graphql/generated/apolloTypes';
import { ScenarioSummary } from 'app/graphql/hooks/useScenarioSummaries';

const MOST_RECENT_SCENARIO_COUNT = 5;

export const getRecentScenarios = (selectedScenarioId: number, scenarioSummaries: ScenarioSummary[]) => {
  if (!scenarioSummaries) return [];

  const nonCurrentScenarios = scenarioSummaries.filter((scenario) => scenario.deploymentModelId !== selectedScenarioId);
  const sortedScenarios = sortScenarios(nonCurrentScenarios, 'deploymentModelLastOpenedAt', SortDirection.desc);

  return sortedScenarios.slice(0, MOST_RECENT_SCENARIO_COUNT);
};

export const sortScenarios = (
  scenarioSummaries: ScenarioSummary[],
  sortBy: keyof ScenarioSummary,
  sortOrder: SortDirection
) => {
  if (!scenarioSummaries) return [];

  const polarity = sortOrder === SortDirection.asc ? 1 : -1;

  return [...scenarioSummaries].sort((scenarioA, scenarioB) => {
    if (scenarioA[sortBy] > scenarioB[sortBy]) return 1 * polarity;
    if (scenarioA[sortBy] < scenarioB[sortBy]) return -1 * polarity;
    return scenarioA.deploymentModelName.localeCompare(scenarioB.deploymentModelName);
  });
};
