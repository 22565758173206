import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTenantWideInfo } from 'app/graphql/generated/apolloTypes';

export const GET_TENANT_WIDE_INFO = gql`
  query GetTenantWideInfo {
    getUserSpec {
      subjectId
      emailAddress
      firstName
      lastName
      passwordUpdatedAt
      isSupportUser
      membershipSpecs {
        memberId
        tenantId
        tenantGlobalId
        tenantName
        tenantSlug
        systemRoleName
        planningCycles {
          planningCycleId
          deploymentModels {
            deploymentModelId
            deploymentModelType
            deploymentModelName
            isPublished: isPrimaryScenario
          }
        }
      }
    }
    getCurrencies {
      currencyCode
      currencyName
    }
  }
`;

export const useGetTenantWideInfo = createUseQuery<GetTenantWideInfo, never>(GET_TENANT_WIDE_INFO);
