import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from 'app/containers/App/AuthApolloWrapper/AuthApolloWrapper';

import {
  GetTerritoryRulesWithPrimarySeller_getTerritoryRules,
  GetTerritoryRulesWithPrimarySeller_getTerritoryRules_territoryRules,
  GetTerritoryRulesWithPrimarySellerVariables
} from 'app/graphql/generated/apolloTypes';
import { GET_TERRITORY_RULES_WITH_PRIMARY_SELLER } from 'app/graphql/queries/getTerritoryRuleWithPrimarySeller';

import showToast from 'utils/helpers/showToast';
import { formatMessage } from 'utils/messages/utils';

export const getTerritoryRulesWithPrimarySeller = async (
  variables: GetTerritoryRulesWithPrimarySellerVariables
): Promise<{ items: GetTerritoryRulesWithPrimarySeller_getTerritoryRules_territoryRules[]; totalCount: number }> => {
  try {
    const result: ApolloQueryResult<{
      getTerritoryRules: GetTerritoryRulesWithPrimarySeller_getTerritoryRules;
      // eslint-disable-next-line no-restricted-syntax
    }> = await apolloClient.query({
      query: GET_TERRITORY_RULES_WITH_PRIMARY_SELLER,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables
    });

    return {
      items: result?.data?.getTerritoryRules.territoryRules,
      totalCount: result?.data?.getTerritoryRules.totalCount
    };
  } catch (error) {
    // eslint-disable-next-line deprecation/deprecation
    showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    throw error;
  }
};
