import { useQuery } from '@apollo/client';

import { GetLookupsForPlanningCycle, GetLookupsForPlanningCycleVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_LOOKUPS_FOR_PLANNING_CYCLE } from 'app/graphql/queries/getLookupsForPlanningCycle';

import useShowToast from 'app/hooks/useShowToast';

import { Lookup } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface UseGetLookupsForPlanningCycleResults {
  data: Lookup[];
  loading: boolean;
  refetch: () => void;
}

export const useGetLookupsForPlanningCycle = (
  variables: GetLookupsForPlanningCycleVariables
): UseGetLookupsForPlanningCycleResults => {
  const showToast = useShowToast();

  const { data, loading, refetch } = useQuery<GetLookupsForPlanningCycle, GetLookupsForPlanningCycleVariables>(
    GET_LOOKUPS_FOR_PLANNING_CYCLE,
    {
      fetchPolicy: 'no-cache',
      variables,
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        showToast(formatMessage('MAP_PINS_ERROR'), 'danger');
      }
    }
  );

  return {
    data: data?.getPlanningCycleSpec.lookups?.map((lookup) => ({
      ...lookup,
      metadata: {
        tableData: JSON.parse(lookup?.metadata?.tableData),
        tableSchema: JSON.parse(lookup?.metadata?.tableSchema)
      }
    })),
    loading,
    refetch
  };
};
