import React, { useEffect, useRef } from 'react';

import { GridApi } from '@ag-grid-community/core';
import { InputGroup } from '@blueprintjs/core';
import { Add, Search } from '@carbon/icons-react';
import dayjs from 'dayjs';

import TextButton from 'components/Buttons/TextButton/TextButton';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildSheetsSummaryColumnDef from 'app/components/DataPanel/SheetsPanel/buildSheetsSummaryColumnDef';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';
import { DATE_FORMAT } from 'app/global/variables';

import { useUpsertSheet } from 'app/graphql/mutations/upsertSheet';
import { GET_DATA_SHEETS, useGetDataSheetsLazy } from 'app/graphql/queries/getDataSheets';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { DataPanelViews, DeploymentModelPhase, SheetType } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import style from './SheetsPanel.module.pcss';

const b = block(style);

const FormatDate = (params) => {
  return <div>{dayjs(params.value).format(DATE_FORMAT)}</div>;
};

const SheetsPanel: React.FC = () => {
  const { selectedDeploymentModelId } = useScope();
  const { allDataSheets, setAllDataSheets, setSelectedSheet, setSheetDefinitions, setSelectedDataView } = useData();
  const [isBreakdownOfQuotaEnabled] = useTreatment(SplitFeatures.BREAKDOWN_OF_QUOTA_BY_HIERARCHIES);
  const deploymentModelPhase = usePhase();
  const sheetsLoadingContainerRef = useRef(null);
  const showToast = useShowToast();

  const [getDataSheets, { data: sheetsList, loading: loadingDataSheets }] = useGetDataSheetsLazy({
    variables: {
      deploymentModelId: selectedDeploymentModelId,
      isTQM: deploymentModelPhase === DeploymentModelPhase.manage ? true : false
    },
    fetchPolicy: 'network-only'
  });

  const [upsertSheet, { data: upsertSheetData, loading: upsertSheetLoading }] = useUpsertSheet({
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('CREATE_QUOTA_SHEET_ERROR'), 'danger');
    },
    refetchQueries: [
      {
        query: GET_DATA_SHEETS,
        variables: {
          deploymentModelId: selectedDeploymentModelId,
          isTQM: deploymentModelPhase === DeploymentModelPhase.manage ? true : false
        }
      }
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      const newSheetId = upsertSheetData.upsertSheet.sheetIds[0];
      setSelectedSheet(sheetsList.getDeploymentModelSpec.dataSheets.find((sheet) => sheet.sheetId === newSheetId));
      setSheetDefinitions([]);
      setSelectedDataView(DataPanelViews.SHEET_DETAIL);
      showToast(formatMessage('QUOTA_SHEET_CREATED'), 'success');
    }
  });

  useEffect(() => {
    if (sheetsList?.getDeploymentModelSpec?.dataSheets?.length > 0) {
      const dataSheetLists = sheetsList?.getDeploymentModelSpec?.dataSheets;
      isBreakdownOfQuotaEnabled
        ? setAllDataSheets(dataSheetLists)
        : setAllDataSheets(
            dataSheetLists.filter((dataSheet) => dataSheet.sheetType !== SheetType.QUOTA_DISTRIBUTION_SHEET)
          );
    }
  }, [sheetsList]);

  useEffect(() => {
    getDataSheets();
  }, []);

  const onSheetSelectionChanged = (params) => {
    const sheetGridApi = params?.api as GridApi;
    setSelectedDataView(DataPanelViews.SHEET_DETAIL);
    setSheetDefinitions([]);
    setSelectedSheet(sheetGridApi?.getSelectedNodes()[0]?.data);
  };

  const handleCreateSheet = (): void => {
    upsertSheet({
      variables: {
        input: {
          deploymentModelId: selectedDeploymentModelId,
          dataSheets: [
            {
              sheetType: SheetType.QUOTA_SHEET
            }
          ]
        }
      }
    });
  };

  return (
    <div className={b()}>
      <div className={b('toolbar')}>
        <div className={b('searchbar')}>
          <InputGroup
            placeholder={formatMessage('SEARCH')}
            rightElement={
              <div className={b('searchIcon')}>
                <Search />
              </div>
            }
          />
        </div>
        {deploymentModelPhase === DeploymentModelPhase.plan && (
          <TextButton
            testId="add-sheet"
            type="button"
            text={formatMessage('CREATE_QUOTA_SHEET')}
            intent="primary"
            icon={<Add size={24} />}
            large={false}
            loading={upsertSheetLoading}
            onClick={handleCreateSheet}
          />
        )}
      </div>
      <div className={b('sheetsGrid')} ref={sheetsLoadingContainerRef}>
        <div className={`ag-theme-alpine ${b('grid')}`}>
          {loadingDataSheets || sheetsList?.getDeploymentModelSpec?.dataSheets?.length > 0 ? (
            <AdvancedGrid
              data={JSON.stringify(allDataSheets)}
              columnDefs={buildSheetsSummaryColumnDef(FormatDate)}
              onSelectionChanged={onSheetSelectionChanged}
              rowSelection="single"
              data-testid="sheets-summary-grid"
              showGridLoading={loadingDataSheets}
              gridWidth={sheetsLoadingContainerRef?.current?.offsetWidth}
              gridHeight={sheetsLoadingContainerRef?.current?.offsetHeight}
            />
          ) : (
            <div className={b('noData')} data-testid="sheets-grid-empty">
              {formatMessage('EMPTY_GRID')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SheetsPanel;
