export const writeToSessionStorageSafely = (sessionStorageKey: string, value: unknown) => {
  try {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
  } catch (error) {
    console.warn(`Unable to write to "${sessionStorageKey}"`);
  }
};

export const readFromSessionStorageSafely = <T>(sessionStorageKey: string): T | null => {
  try {
    const valueFromStorage = sessionStorage.getItem(sessionStorageKey);
    if (valueFromStorage !== null) {
      return JSON.parse(valueFromStorage) as T;
    }
  } catch (error) {
    console.warn(`Unable to obtain "${sessionStorageKey}"`);
  }
  return null;
};

export const createScopedSessionStorage = <T>(sessionStorageKey) => ({
  write: (value: T) => writeToSessionStorageSafely(sessionStorageKey, value),
  read: () => readFromSessionStorageSafely<T>(sessionStorageKey)
});
