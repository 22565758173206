import gql from 'graphql-tag';

export const GET_AGGREGATED_ACTIVITIES_FOR_TERRITORY_WITH_MEASURE_VALUE = gql`
  query GetAggregatedActivitiesForTerritoryWithMeasureValue(
    $battlecardId: Int
    $territoryGroupId: Int
    $territoryId: String
    $quotaComponentId: Int!
    $hierarchies: [String]!
    $measures: [Int]!
    $startRow: Int!
    $endRow: Int!
    $sorting: SortModel
    $filtering: [GetHierarchyFilterModel]
    $ruleId: Int
  ) {
    getAggregatedActivitiesForTerritoryWithMeasureValue(
      input: {
        battlecardId: $battlecardId
        territoryGroupId: $territoryGroupId
        territoryId: $territoryId
        quotaComponentId: $quotaComponentId
        hierarchies: $hierarchies
        measures: $measures
        startRow: $startRow
        endRow: $endRow
        sorting: $sorting
        filtering: $filtering
        ruleId: $ruleId
      }
    ) {
      territoryAggregatedActivitiesCount
      territoryAggregatedActivitiesTotals {
        measureId
        measureName
        measureValue
      }
      territoryAggregatedActivitiesList {
        hierarchies {
          name
          type
          val
          id
          key
        }
        measures {
          measureId
          measureName
          measureValue
        }
        rebalancingPermissions {
          isValidForRebalance
          hierarchyIncludedIn {
            territoryId
            territoryGroupId
            ruleId
          }
        }
      }
    }
  }
`;
