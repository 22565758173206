import { ColDef } from '@ag-grid-community/core';

import ExpandableHeaderCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExpandableHeaderCellRenderer/ExpandableHeaderCellRenderer';
import ExtraItemTagCellRenderer from 'app/components/AdvancedGrid/CellRenderers/ExtraItemTagCellRenderer/ExtraItemTagCellRenderer';
import FooterCellRenderer from 'app/components/AdvancedGrid/CellRenderers/FooterCellRenderer/FooterCellRenderer';
import TextRenderer from 'app/components/AdvancedGrid/CellRenderers/TextRenderer/TextRenderer';
import { formatDataForExtraTagCellRenderer } from 'app/components/AdvancedGrid/GridHelper';

import {
  MEASURES_GRID_COLUMN_WIDTH,
  MEASURES_GRID_COLUMN_WIDTH_LARGE,
  MEASURES_GRID_COLUMN_WIDTH_SMALL
} from 'app/global/variables';

import {
  GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents,
  ISheetDefinitionDef
} from 'app/graphql/generated/apolloTypes';

import { GridFields, GridHeaders, MeasureFormatNumberType, MeasureType, QuotaComponentEditableType } from 'app/models';

import { formatNumber } from 'utils/messages/utils';

const buildTerritoryQuotaDrillInColumnDef = (
  selectedQuotaComponentId: number,
  sheetDefinitions: ISheetDefinitionDef[],
  headerClassNames: string,
  mockBreakdownMonths: string[],
  cellClassNames: string,
  currency: string,
  isManageQBTSortFilterEnabled?: boolean
): ColDef[] => {
  const sortable = isManageQBTSortFilterEnabled;
  const valueFormatter = (value, currency) => {
    if (!value) return '';
    return formatNumber(value, { style: 'currency', currency });
  };

  const columnDefs = [];

  columnDefs.push(
    {
      headerName: GridHeaders.ACCOUNT_KEY,
      field: GridFields.ACCOUNT_KEY,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      headerClass: headerClassNames,
      flex: 1,
      sortable,
      cellRendererFramework: TextRenderer
    },
    {
      headerName: GridHeaders.ACCOUNT_NAME,
      field: GridFields.ACCOUNT_NAME,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE,
      headerClass: headerClassNames,
      flex: 1,
      sortable,
      cellRendererFramework: TextRenderer
    },
    {
      headerName: GridHeaders.ADJUSTMENT_TYPE,
      field: GridFields.ADJUSTMENT_TYPE,
      minWidth: MEASURES_GRID_COLUMN_WIDTH,
      flex: 1,
      headerClass: headerClassNames,
      sortable
    },
    {
      headerName: GridHeaders.ACCOUNT_MOVED_FROM,
      field: GridFields.ACCOUNT_MOVED_FROM,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE,
      flex: 1,
      headerClass: headerClassNames,
      sortable
    },
    {
      headerName: GridHeaders.ACCOUNT_MOVED_TO,
      field: GridFields.ACCOUNT_MOVED_TO,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE,
      flex: 1,
      headerClass: headerClassNames,
      sortable
    },
    {
      headerName: GridHeaders.ALIGNMENT_EFFECTIVE_DATE,
      field: GridFields.ALIGNMENT_EFFECTIVE_DATE,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_LARGE,
      flex: 1,
      headerClass: headerClassNames,
      cellRendererFramework: ExtraItemTagCellRenderer,
      cellRendererParams: (params) => {
        const handleFormattingAlignmentDate = (date) => `${date?.periodStartDate} - ${date?.periodEndDate}`;
        const alignmentParams = formatDataForExtraTagCellRenderer(
          params,
          GridFields.ALIGNMENT_EFFECTIVE_DATE,
          handleFormattingAlignmentDate
        );
        return alignmentParams;
      }
    }
  );

  const sheetDefinitionsForOtherMeasures = [];
  const sheetDefinitionsForAccountQuotaAndTerritoryQuotaAdjustmentMeasure = [];
  const getSelectedQCForSheetDefinition = (sheetDefinition) => {
    return sheetDefinition?.quotaComponents?.find(
      (qc) => qc.quotaComponentId === selectedQuotaComponentId
    ) as GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents;
  };

  sheetDefinitions.forEach((sheetDefinition) =>
    (sheetDefinition.measureName === MeasureType.TERRITORY_QUOTA_ADJUSTMENT ||
    sheetDefinition.measureName === MeasureType.ACCOUNT_QUOTA
      ? sheetDefinitionsForAccountQuotaAndTerritoryQuotaAdjustmentMeasure
      : sheetDefinitionsForOtherMeasures
    ).push(sheetDefinition)
  );

  sheetDefinitionsForOtherMeasures.forEach((sheetDefinition) => {
    const sheetDefinitionForSelectedQC = sheetDefinition?.quotaComponents?.find(
      (qc) => qc.quotaComponentId === selectedQuotaComponentId
    ) as GetSheetDefinitions_getDeploymentModelSpec_dataSheets_sheetDefinitions_quotaComponents;
    const sheetDefinitionForSelectedQCRole = sheetDefinitionForSelectedQC?.roles[0];
    columnDefs.push({
      headerName: sheetDefinition.measureName,
      hide: sheetDefinitionForSelectedQCRole?.visible === QuotaComponentEditableType.FALSE,
      editable: sheetDefinitionForSelectedQCRole?.editable === QuotaComponentEditableType.TRUE,
      field: sheetDefinition.measureName,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      flex: 1,
      valueFormatter: (params) => valueFormatter(params.value, currency)
    });
  });

  const shouldShowQuota = sheetDefinitionsForAccountQuotaAndTerritoryQuotaAdjustmentMeasure.every((sheetDefinition) => {
    const selectedQCForSheetDefinition = getSelectedQCForSheetDefinition(sheetDefinition);
    return selectedQCForSheetDefinition?.roles[0]?.visible === QuotaComponentEditableType.TRUE;
  });

  // only render quota columns if both account quota and territory quota adjustment measure exist in the Account quota distribution sheet
  if (shouldShowQuota) {
    columnDefs.push({
      headerName: GridHeaders.QUOTA,
      field: GridFields.QUOTA,
      minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
      headerComponentFramework: ExpandableHeaderCellRenderer,
      flex: 1,
      headerClass: headerClassNames,
      cellClass: cellClassNames,
      valueFormatter: (params) => valueFormatter(params.value, currency),
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            frameworkComponent: FooterCellRenderer,
            params: {
              formatNumber,
              currency,
              measureType: MeasureFormatNumberType.CURRENCY
            }
          };
        }
        return '';
      }
    });

    // only render breakdown months columns if both account quota and territory quota adjustment measure are set to visible in the Account quota distribution sheet
    mockBreakdownMonths.forEach((month) => {
      columnDefs.push({
        headerName: month,
        field: month,
        minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
        flex: 1,
        headerComponentFramework: ExpandableHeaderCellRenderer,
        headerComponentParams: {
          areColumnsHidden: true
        },
        cellClass: cellClassNames,
        valueFormatter: (params) => valueFormatter(params.value, currency),
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: FooterCellRenderer,
              params: {
                formatNumber,
                currency,
                measureType: MeasureFormatNumberType.CURRENCY
              }
            };
          }
          return { frameworkComponent: '' };
        }
      });
    });
  }

  return columnDefs;
};

export default buildTerritoryQuotaDrillInColumnDef;
