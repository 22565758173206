import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/Dialog/Dialog';
import DialogFooter from 'components/Dialog/DialogFooter/DialogFooter';

import FormTextArea from 'app/components/FormFields/FormTextArea/FormTextArea';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import HierarchyAttributesFormFields from 'app/components/HierarchyAttributesFormFields/HierarchyAttributesFormFields';

import { useScope } from 'app/contexts/scopeProvider';

import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';

import { SplitFeatures } from 'app/global/features';

import {
  GetRootHierarchies_getRootHierarchies,
  UpsertCustomerAccountHierarchy,
  UpsertCustomerAccountHierarchyVariables,
  UpsertCustomHierarchy,
  UpsertCustomHierarchyVariables
} from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { UPSERT_CUSTOM_HIERARCHY, UPSERT_CUSTOMER_ACCOUNT_HIERARCHY } from 'app/graphql/mutations/upsertHierarchy';
import { GET_PINNED_HIERARCHIES } from 'app/graphql/queries/getPinnedHierarchies';
import { GET_ROOT_HIERARCHIES } from 'app/graphql/queries/getRootHierarchies';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { HierarchyAttributeType, HierarchyType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './HierarchyEditDialog.module.pcss';
import validationsSchema from './validationsSchema';

const b = block(style);

interface AttributeValues {
  name: string;
  type: {
    key: string;
    value: string;
  };
  editable: boolean;
}

const defaultCustomAttributes = ['Key', 'ParentKey', 'Name', 'EffectiveDate', 'EndDate'];
const defaultCustomerAccountAttributes = [
  ...defaultCustomAttributes,
  'City',
  'StateProvince',
  'Address1',
  'Address2',
  'Country',
  'ZipPostal',
  'Industry'
];
export const formatAttributesToJSON = (attributes: AttributeValues[], hierarchyType: string): string =>
  JSON.stringify({
    properties: attributes
      .filter((att) => {
        if (hierarchyType === HierarchyType.CustomerAccountHierarchy) {
          return !defaultCustomerAccountAttributes.includes(att.name);
        }

        return !defaultCustomAttributes.includes(att.name);
      })
      .map((attribute) => ({
        name: attribute.name,
        type: attribute.type.value,
        editable: attribute.editable,
        value: ''
      }))
  });

export interface HierarchyFormValues {
  hierarchyName: string;
  description: string;
  key: string;
  name: string;
  parentKey: string | null;
  attributes: AttributeValues[];
}

export interface CustomerAccountHierarchyFormValues extends HierarchyFormValues {
  city: string;
  stateProvince: string;
  address1: string;
  address2: string;
  country: string;
  zipPostal: string;
  industry: string;
}

interface HierarchyEditDialogProps {
  isOpen: boolean;
  hierarchyToEdit: GetRootHierarchies_getRootHierarchies | null;
}

const attributeTypeDropDownData = [
  { key: formatMessage('TEXT'), value: HierarchyAttributeType.TEXT },
  { key: formatMessage('NUMERIC'), value: HierarchyAttributeType.NUMERIC }
];

export const attributeTypeDropDown = {
  theme: 'default',
  items: attributeTypeDropDownData,
  defaultItem: attributeTypeDropDownData[0],
  menuName: 'attributeType'
};

const HierarchyEditDialog: React.FC<HierarchyEditDialogProps> = ({
  isOpen,
  hierarchyToEdit
}: HierarchyEditDialogProps) => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();

  const [showError, setShowError] = useState<boolean>(false);

  const { rootKey, rootName, rootHierarchyId, hierarchyType, attributes } = hierarchyToEdit;

  const { setShowEditHierarchyDialog } = useFileUpload();
  const [isCustomHierarchyAttributesOn] = useTreatment(SplitFeatures.CUSTOM_HIERARCHY_ATTRIBUTES_V2);
  const showToast = useShowToast();

  const formatAttributeType = (attributeType: string) => {
    switch (attributeType) {
      case HierarchyAttributeType.TEXT:
        return formatMessage('TEXT');
      case HierarchyAttributeType.DATE:
        return formatMessage('DATE');
      default:
        return formatMessage('NUMERIC');
    }
  };

  const formatAttributes = () =>
    attributes.map((attribute) => ({
      name: attribute.name,
      type: {
        key: formatAttributeType(attribute.type),
        value: attribute.type
      },
      editable: attribute.editable
    }));

  const defaultInitialFormValues: HierarchyFormValues = {
    hierarchyName: rootName,
    description: '',
    key: rootKey,
    name: '',
    parentKey: null,
    attributes: formatAttributes()
  };

  const customerAccountInitialFormValues = {
    city: '',
    stateProvince: '',
    address1: '',
    address2: '',
    country: '',
    zipPostal: '',
    industry: ''
  };

  const initialFormValues: CustomerAccountHierarchyFormValues | HierarchyFormValues =
    hierarchyType === HierarchyType.CustomerAccountHierarchy
      ? { ...defaultInitialFormValues, ...customerAccountInitialFormValues }
      : defaultInitialFormValues;

  const [upsertCustomHierarchy, { loading: upsertCustomHierarchyLoading, error: upsertCustomHierarchyError }] =
    useMutation<UpsertCustomHierarchy, UpsertCustomHierarchyVariables>(UPSERT_CUSTOM_HIERARCHY, {
      onCompleted() {
        showToast(formatMessage('EDIT_HIERARCHY_SUCCESS'), 'success');
        setShowEditHierarchyDialog(false);
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        setShowError(true);
      }
    });

  const [
    upsertCustomerAccountHierarchy,
    { loading: upsertCustomerAccountHierarchyLoading, error: upsertCustomerAccountHierarchyError }
  ] = useMutation<UpsertCustomerAccountHierarchy, UpsertCustomerAccountHierarchyVariables>(
    UPSERT_CUSTOMER_ACCOUNT_HIERARCHY,
    {
      onCompleted() {
        showToast(formatMessage('EDIT_HIERARCHY_SUCCESS'), 'success');
        setShowEditHierarchyDialog(false);
      },
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
        setShowError(true);
      }
    }
  );

  const submitForm = (values, actions) => {
    actions.setSubmitting(false);
    const { hierarchyName, attributes } = values;
    const variables = {
      planningCycleId: selectedPlanningCycle?.id,
      hierarchyId: rootHierarchyId,
      version: 1,
      name: hierarchyName,
      key: rootKey,
      parentKey: null,
      rootId: rootHierarchyId,
      customProperties: formatAttributesToJSON(attributes, hierarchyType)
    };

    if (hierarchyType === HierarchyType.CustomHierarchy) {
      upsertCustomHierarchy({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ROOT_HIERARCHIES,
            variables: { planningCycleId: selectedPlanningCycle?.id }
          },
          {
            query: GET_PINNED_HIERARCHIES,
            variables: { deploymentModelId: selectedDeploymentModelId, includeFirstLevelMemberCount: false }
          }
        ]
      });
    }
    if (hierarchyType === HierarchyType.CustomerAccountHierarchy) {
      upsertCustomerAccountHierarchy({
        // for root hierarchy, the customer account number is always the same
        variables: { ...variables, customerAccountNumber: 'Customer Account ID' },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ROOT_HIERARCHIES,
            variables: { planningCycleId: selectedPlanningCycle?.id }
          },
          {
            query: GET_PINNED_HIERARCHIES,
            variables: { deploymentModelId: selectedDeploymentModelId, includeFirstLevelMemberCount: false }
          }
        ]
      });
    }
  };

  const confirmDisabled = hierarchyType !== HierarchyType.CustomHierarchy && !isCustomHierarchyAttributesOn;

  return (
    <Dialog
      isOpen={isOpen}
      title={formatMessage('EDIT_WITH_VALUE', { value: rootName })}
      data-testid="hierarchy-edit-dialog"
      showDialogFooter={false}
    >
      <Formik initialValues={initialFormValues} validationSchema={validationsSchema} onSubmit={submitForm}>
        {({ handleSubmit }) => {
          return (
            <Form>
              <div className={b('name')}>
                <Field
                  label={formatMessage('HIERARCHY_NAME')}
                  name="hierarchyName"
                  type="text"
                  component={FormTextInputGroup}
                  disabled={
                    hierarchyType === HierarchyType.GeographicTerritoryHierarchy ||
                    hierarchyType === HierarchyType.CustomerAccountHierarchy
                  }
                />
              </div>
              <div className={b('description')}>
                <Field label={formatMessage('DESCRIPTION')} name="description" component={FormTextArea} disabled />
              </div>
              <HierarchyAttributesFormFields
                data-testid="attribute-form-fields"
                hierarchyId={rootHierarchyId}
                hierarchyType={hierarchyType}
              />
              {(showError || !!upsertCustomHierarchyError || !!upsertCustomerAccountHierarchyError) && (
                <div className={b('errorMessage')} data-testid="dialog-error-message">
                  {upsertCustomHierarchyError?.message ||
                    upsertCustomerAccountHierarchyError?.message ||
                    formatMessage('EDIT_HIERARCHY_ERROR')}
                </div>
              )}
              <DialogFooter
                cancelButtonText={formatMessage('CANCEL')}
                confirmButtonText={formatMessage('SAVE')}
                disableConfirm={
                  upsertCustomHierarchyLoading || upsertCustomerAccountHierarchyLoading || confirmDisabled
                }
                disableCancel={false}
                confirmButtonLoading={upsertCustomHierarchyLoading || upsertCustomerAccountHierarchyLoading}
                onSubmit={() => handleSubmit()}
                onClose={() => setShowEditHierarchyDialog(false)}
              />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default HierarchyEditDialog;
