import React, { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Toaster, Intent } from '@blueprintjs/core';
import { WarningFilled, CheckmarkFilled, InformationFilled } from '@carbon/icons-react';
import { isWithinWorker } from 'app/workers/WorkerHelpers';

import Icon from 'components/Icon/Icon';

const MAX_TOASTS = 1;

const loaf = isWithinWorker()
  ? {}
  : {
      top: Toaster.create({
        position: 'top',
        maxToasts: MAX_TOASTS
      }),
      'top-right': Toaster.create({
        position: 'top-right',
        maxToasts: MAX_TOASTS
      })
    };

/** @deprecated useShowToast instead */
const showToast = (
  message: ReactNode,
  intent: Intent,
  position: 'top' | 'top-right' = 'top-right',
  timeout?: number
): React.ReactNode => {
  const getIcon = () => {
    switch (intent) {
      case 'success':
        return <CheckmarkFilled />;
      case 'danger':
        return <WarningFilled />;
      default:
        return <InformationFilled />;
    }
  };

  return loaf[position].show({
    message,
    intent,
    icon: <Icon icon={getIcon()} />,
    timeout
  });
};

// eslint-disable-next-line deprecation/deprecation
export default showToast;
