export const replaceFormulaIdsWithNames = (measureFormula: string, measureNameById: Map<number, string>) =>
  measureFormula.replace(/{[^}]*}/g, (measureIdWithBraces) => {
    const measureId = Number(measureIdWithBraces.slice(1, -1));
    if (!measureId) {
      return measureIdWithBraces;
    }
    const measureName = measureNameById.get(measureId);
    if (!measureName) {
      return measureIdWithBraces;
    }
    return `{${measureName}}`;
  });

export const replaceFormulaNamesWithIds = (measureFormula: string, measureIdByName: Map<string, number>) =>
  measureFormula.replace(/{[^}]*}/g, (measureNameWithBraces) => {
    const measureName = measureNameWithBraces.slice(1, -1);
    if (!measureName) {
      return measureNameWithBraces;
    }
    const measureId = measureIdByName.get(measureName);
    if (!measureId) {
      return measureNameWithBraces;
    }
    return `{${measureId}}`;
  });

export const replaceBracesWithQuotes = (measureFormula: string) =>
  measureFormula.replace(/{[^}]*}/g, (measureWithBraces) => {
    const measure = measureWithBraces.slice(1, -1);
    if (!measure) {
      return measureWithBraces;
    }

    return `"${measure}"`;
  });

export const formatFormulaToDisplayFormat = (measureFormula: string) => {
  // find all the periodicity measures
  const periodicMeasures = measureFormula?.match(/Periodicity\((\{[^}]+},\s*\d{4}(\.[^)]+)?\))/g);

  if (!periodicMeasures) {
    return measureFormula;
  }

  // convert periodicity measures, eg. Periodicity({Allocation %}, 2022.H1.Q2) -> {Allocation %.2022.H1.Q2}
  periodicMeasures.forEach((measure) => {
    const params = measure.slice(12, -1).split(',');
    const convertedMeasure = `{${params[0].slice(1, -1)}.${params[1].trim()}}`;
    measureFormula = measureFormula.replace(measure, convertedMeasure);
  });

  return measureFormula;
};

export const formatFormulaToPeriodicityFunction = (measureFormula: string) => {
  // find all the instances of measure names, i.e., strings wrapped in {}
  // and keep only the measures that have dots in the name (periodicity with format eg. {Measure Name.2022.H1.Q1})
  const measures = measureFormula.match(/{([^}^.]+\.\d{4}(?:\.\w+){0,4})}/g);
  const filteredMeasures = measures?.filter((measure) => measure.includes('.'));

  // convert periodic measures {Measure Name.2022.H1.Q1} -> Periodicity({Measure Name}, 2022.H1.Q1)
  filteredMeasures?.forEach((measure) => {
    const periodicityParams = measure.slice(1, -1).split('.');
    const measureName = periodicityParams[0];
    const periods = periodicityParams.slice(1).join('.');
    const convertedMeasure = `Periodicity({${measureName}}, ${periods})`;
    measureFormula = measureFormula.replace(measure, convertedMeasure);
  });

  return measureFormula;
};

export const handleFormatCode = (editorRef, diagnosticCount, formatCode) => {
  const doc = editorRef?.current?.view?.state.doc.toString();
  // If the formula is empty or there are any linting errors, do not run formatCode
  if (
    doc?.replace(/\s/g, '') === '' ||
    (editorRef?.current?.view && diagnosticCount(editorRef.current.view.state) > 0)
  ) {
    return;
  }
  const formattedCode = formatCode(doc);

  editorRef?.current?.view?.dispatch({
    changes: {
      from: 0,
      to: editorRef.current?.view?.state?.doc?.length || 0,
      insert: formattedCode || ''
    },
    selection: {
      // The default prettier code formatter inserts a new line at the end.
      anchor: formattedCode ? formattedCode.length - 1 : 0
    }
  });
};

export const checkDiagnosticCount = (editorRef, diagnosticCount, setFormulaErrorCount) => {
  if (editorRef?.current?.view) {
    const errorCount = diagnosticCount(editorRef.current.view.state);
    setFormulaErrorCount(errorCount);
  }
};
