import React from 'react';

import { Button } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './RoundIconButton.module.pcss';

const b = block(style);

interface RoundIconButtonProps {
  disabled?: boolean;
  icon?: JSX.Element;
  outlined?: boolean;
  intent?: 'primary';
  small?: boolean;
  large?: boolean;
  onClick?: () => void;
}

const RoundIconButton: React.FC<RoundIconButtonProps> = ({
  icon,
  intent,
  small,
  large,
  onClick
}: RoundIconButtonProps) => {
  return (
    <span className={b({ primary: intent === 'primary' })}>
      <Button
        minimal
        type="button"
        small={small}
        large={large}
        intent={intent}
        icon={icon}
        outlined={false}
        onClick={onClick}
      />
    </span>
  );
};

export default RoundIconButton;
