import { useState } from 'react';

import { TargetRuleComponent } from 'app/graphql/generated/apolloTypes';
import { useReassignRuleHierarchies } from 'app/graphql/mutations/reassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { formatMessage } from 'utils/messages/utils';

interface Input {
  onSubmit: () => void;
  currentTerritoryGroupTypeId: number;
}

export const useAccountReassign = ({
  onSubmit,
  currentTerritoryGroupTypeId
}: Input): {
  toggleDynamicReassignPreference: () => void;
  reassignHierarchiesDynamically: (targetRuleId: number, sourceHierarchyIds: number[]) => Promise<void>;
  shouldAlwaysDynamicReassign: boolean;
  didSimpleReassignFail: boolean;
} => {
  const showToast = useShowToast();
  const [shouldAlwaysDynamicReassign, setShouldAlwaysDynamicReassign] = useState(
    () => !!JSON.parse(sessionStorage.getItem('shouldAlwaysDynamicReassign'))
  );

  const [reassignRuleHierarchies, { error }] = useReassignRuleHierarchies({
    onCompleted: () => {
      showToast(formatMessage('REASSIGN_ACCOUNT_SUCCESS_TOAST'), 'success');
      onSubmit();
    }
  });
  const didSimpleReassignFail = error?.knownError?.errorCode === 'CANNOT_SIMPLY_REASSIGN_DIMENSIONS';

  const reassignHierarchiesDynamically = async (targetRuleId: number, sourceHierarchyIds: number[]) => {
    const targetRuleComponent =
      didSimpleReassignFail || shouldAlwaysDynamicReassign
        ? TargetRuleComponent.SimpleDimensionOrModifier
        : TargetRuleComponent.SimpleDimensionOrThrow;

    await reassignRuleHierarchies({
      variables: {
        input: {
          scopingTerritoryGroupId: currentTerritoryGroupTypeId,
          sourceHierarchyIds,
          targetRuleId,
          targetRuleComponent
        }
      }
    });
  };

  const toggleDynamicReassignPreference = () => {
    sessionStorage.setItem('shouldAlwaysDynamicReassign', JSON.stringify(!shouldAlwaysDynamicReassign));
    setShouldAlwaysDynamicReassign(!shouldAlwaysDynamicReassign);
  };

  return {
    toggleDynamicReassignPreference,
    reassignHierarchiesDynamically,
    shouldAlwaysDynamicReassign,
    didSimpleReassignFail
  };
};
