import { MutableRefObject } from 'react';

import { ColDef, ICellRendererParams } from '@ag-grid-community/core';

import { getActivityGridCellRenderer } from 'app/components/DataTray/AggregatedActivitiesGrid/ActivityGridCellRenderer';

import { GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList } from 'app/graphql/generated/apolloTypes';

import { ActivityDrillInColumnNames } from 'app/models';

import { formatNumber } from 'utils/messages/utils';

const buildActivityDrillInColumnDef = (
  data:
    | string[]
    | GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList[],
  currency: string,
  isAggregatedActivities = false,
  totalCount?: number,
  rowRef?: MutableRefObject<HTMLDivElement>
): ColDef[] => {
  const columnDefs = [];
  const activityMeasureNames = [
    ActivityDrillInColumnNames.SALES,
    ActivityDrillInColumnNames.PRIOR_YEAR_SALES,
    ActivityDrillInColumnNames.TRAILING_TWELVE_MONTHS
  ];

  const getCellRenderer = function (params, key) {
    if (activityMeasureNames.includes(key as ActivityDrillInColumnNames)) {
      return {
        frameworkComponent: currencyRenderer,
        params
      };
    } else if (isAggregatedActivities) {
      return {
        frameworkComponent: getActivityGridCellRenderer(totalCount, rowRef),
        params
      };
    } else {
      return {
        frameworkComponent: ''
      };
    }
  };

  const currencyFormatter = function (params) {
    const key = params?.colDef?.field;
    return params?.data?.[key] ? formatNumber(+params?.data[key], { style: 'currency', currency }) : null;
  };

  const getValueFormatter = (key) => {
    return activityMeasureNames.includes(key as ActivityDrillInColumnNames) ? currencyFormatter : null;
  };

  const currencyRenderer = (params) => {
    const key = params.colDef.field;
    if (params.data?.[key]) {
      return formatNumber(params.data[key], { style: 'currency', currency });
    }
    return '';
  };

  if (data[0]) {
    let keys = [];
    if (typeof data[0] === 'string') {
      keys = Object.keys(JSON.parse(data[0]));
    } else {
      // getAggregatedActivitiesForTerritoryWithMeasureValue api does not return JSON array
      data[0]?.hierarchies?.forEach((hierarchy) => {
        keys.push(hierarchy.name);
      });
      // adding keys for measure
      data[0]?.measures?.forEach((measure) => {
        keys.push(measure.measureName);
      });
    }

    const tquuidIndex = keys.findIndex((key) => key === 'Tquuid');
    // Tqquid is not returned by getAggregatedActivities
    if (tquuidIndex >= 0) {
      keys.splice(tquuidIndex, 1);
    }

    keys.forEach((key) => {
      columnDefs.push({
        headerName: key,
        field: key,
        sortable: true,
        flex: 1,
        minWidth: 200,
        filter: isAggregatedActivities ? 'filterHelper' : true,
        valueFormatter: getValueFormatter(key),
        cellRendererSelector: (params: ICellRendererParams) => getCellRenderer(params, key)
      });
    });
  }

  return columnDefs;
};

export default buildActivityDrillInColumnDef;
