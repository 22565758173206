import React, { useEffect, useRef, useState } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import TextButton from 'components/Buttons/TextButton/TextButton';

import useOutsideClick from 'app/hooks/useOutsideClick';

import { HierarchySpec } from 'app/models';

interface AddHierarchyButtonProps {
  text: string;
  icon: JSX.Element;
  disabled?: boolean;
  hierarchies: HierarchySpec[];
  disabledHierarchies: string[];
  onSelect: (hierarchy: HierarchySpec) => void;
}

const AddHierarchyButton: React.FC<AddHierarchyButtonProps> = ({
  text,
  icon,
  disabled,
  hierarchies,
  disabledHierarchies,
  onSelect
}: AddHierarchyButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef<HTMLDivElement | null>(null);
  const clickedOutside = useOutsideClick(selectRef);

  useEffect(() => {
    if (clickedOutside) {
      setIsOpen(false);
    }
  }, [clickedOutside]);

  return (
    <div ref={selectRef}>
      <Popover2
        isOpen={isOpen}
        content={
          <>
            {hierarchies.map((hierarchy) => {
              const isDisabled = disabledHierarchies?.includes(hierarchy?.rootName);
              return (
                <MenuItem
                  text={hierarchy?.rootName}
                  disabled={isDisabled}
                  onClick={() => {
                    onSelect(hierarchy);
                    setIsOpen(false);
                  }}
                  data-testid={`add-hierarchy-menu-item-${hierarchy?.rootName}`}
                  key={hierarchy?.rootKey}
                />
              );
            })}
          </>
        }
        interactionKind="click"
        placement="bottom-start"
        usePortal={false}
        minimal
      >
        <TextButton
          onClick={() => setIsOpen(true)}
          icon={icon}
          text={text}
          disabled={disabled}
          type="button"
          testId={`add-hierarchy-btn-${text}`}
        />
      </Popover2>
    </div>
  );
};

export default AddHierarchyButton;
