import React, { useRef, useState, useEffect } from 'react';

import ExpandedPlanTargetsDrawer from 'app/components/TerritoriesAndPlanTargets/ExpandedPlanTargetsDrawer/ExpandedPlanTargetsDrawer';
import ExpandedPlanTargetsTree from 'app/components/TerritoriesAndPlanTargets/ExpandedPlanTargetsTree/ExpandedPlanTargetsTree';
import {
  getNumTerritories,
  getTerritoryMeasureTotalsV2
} from 'app/components/TerritoriesAndPlanTargets/territoryUtils';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { RequestAllocateTopDownInput, AllocateTopDownVariables } from 'app/graphql/generated/apolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useRequestAllocateTopDown } from 'app/graphql/mutations/requestAllocateTopDown';

import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  DeploymentModelPhase,
  JobStatus,
  MeasureType,
  PeriodicGroupMeasure,
  PlanTargetsMeasureAllowed,
  TabIds,
  onAllocateParams
} from 'app/models';

import block from 'utils/bem-css-modules';
import { getAllocatedMeasureId, getMeasureByType } from 'utils/helpers/measureUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './ExpandedPlanTargets.module.pcss';

const b = block(style);

interface AllocationQueryVariables {
  battlecardId: number; // TODO TQP-1147 change to battleCardId
  quotaComponentId: number;
  clearQuotaAdjustment: boolean;
  territoryGroupId?: number;
  preserveSeasonality: boolean;
}

interface ExpandedPlanTargetsProps {
  battleCardId: string;
}

const ExpandedPlanTargets: React.FC<ExpandedPlanTargetsProps> = ({ battleCardId }: ExpandedPlanTargetsProps) => {
  const { selectedPlanningCycle, selectedDeploymentModelId } = useScope();
  const { selectedQuotaComponentId } = useBattleCard();
  const {
    selectedPillIdPlanTargets,
    planTargetsLookupMap,
    planTargetsTreeLookupMap,
    battleCardAllocationLookupMap,
    sheetMeasuresData,
    getPlanTargets,
    planTargetsErrorLookupMap,
    onUpdatePlanTargets,
    setTopdownAllocationJobStatusLookupMap,
    topdownAllocationJobStatusLookupMap
  } = usePlanTargets();
  const showToast = useShowToast();

  const planTargetsRef = useRef(null);
  const animateTimerRef = useRef(null);

  const [isBreakdownOfQuotaEnabled] = useTreatment(SplitFeatures.BREAKDOWN_OF_QUOTA_BY_HIERARCHIES);
  const [isTDAAsyncEnabled] = useTreatment(SplitFeatures.TDA_ASYNC);

  const [animate, setAnimate] = useState(false);
  const [territoryCount, setTerritoryCount] = useState<number>();
  const [battleCardMeasureTotals, setBattleCardMeasureTotals] = useState<PeriodicGroupMeasure[]>();
  const [isTerritoryGroupDataLoading, setIsTerritoryGroupDataLoading] = useState(false);
  const deploymentModelPhase = usePhase();
  const { selectedDataTrayTab } = useDataTray();
  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;

  const planTargets = planTargetsLookupMap?.[battleCardId];
  const planTargetsTree = planTargetsTreeLookupMap?.[battleCardId];
  const battleCardAllocationData = battleCardAllocationLookupMap?.[battleCardId];

  const planTargetsError = planTargetsErrorLookupMap?.[battleCardId];

  useEffect(() => {
    getPlanTargets(battleCardId, selectedQuotaComponentId, selectedPlanningCycle?.id, selectedDeploymentModelId);
  }, [selectedQuotaComponentId, selectedDeploymentModelId]);

  const getTerritoryGroupData = async () => {
    if (selectedPillIdPlanTargets) {
      setIsTerritoryGroupDataLoading(true);

      try {
        if (!isTQM) {
          // get territory group territory count and measure totals in parallel for quota grid
          if (selectedPillIdPlanTargets === 'battlecard') {
            if (!selectedDataTrayTab) {
              return;
            }

            // The format for selectedDataTrayTab is allocate-quotaXXXXX with XXXXX being the sheetId
            const selectedSheetId = selectedDataTrayTab.replace(TabIds.QUOTA, '');

            const [numTerritories, totals] = await Promise.all([
              getNumTerritories(battleCardId, null),
              getTerritoryMeasureTotalsV2(+battleCardId, selectedQuotaComponentId, Number(selectedSheetId))
            ]);
            setTerritoryCount(numTerritories);
            setBattleCardMeasureTotals(totals);
          } else {
            const numTerritories = await getNumTerritories(battleCardId, selectedPillIdPlanTargets);
            setTerritoryCount(numTerritories);
          }
        } else {
          // currently, TQM does not use measures totals so there is no need to call getTerritoryMeasureTotals
          const numTerritories = await getNumTerritories(
            battleCardId,
            selectedPillIdPlanTargets === 'battlecard' ? null : selectedPillIdPlanTargets
          );
          setTerritoryCount(numTerritories);
        }
      } catch (err) {
        showToast(formatMessage('TERRITORY_GROUP_ERROR'), 'danger');
      } finally {
        setIsTerritoryGroupDataLoading(false);
      }
    }
  };

  useEffect(() => {
    getTerritoryGroupData();
  }, [selectedPillIdPlanTargets, selectedQuotaComponentId]);

  const [requestAllocateTopDown] = useRequestAllocateTopDown({
    onCompleted() {
      (async () => {
        setAnimate(true);
      })();
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      const updatedTopAllocationJobStatusLookupMap = topdownAllocationJobStatusLookupMap.map((job) => {
        if (job.battlecardId === +battleCardId && job.quotaComponentId === selectedQuotaComponentId) {
          return { ...job, jobStatus: JobStatus.FAILED };
        } else {
          return job;
        }
      });
      setTopdownAllocationJobStatusLookupMap(updatedTopAllocationJobStatusLookupMap);
    }
  });

  useEffect(() => {
    if (planTargetsError) {
      showToast(formatMessage('TERRITORY_GROUPS_ERROR'), 'danger');
    }
  }, [planTargetsError]);

  // reset animation value back to false after the animation finished
  useEffect(() => {
    if (animate) {
      animateTimerRef.current = setTimeout(() => setAnimate(false), 1500);
    }
    return () => {
      if (animateTimerRef.current) {
        clearTimeout(animateTimerRef.current);
      }
    };
  }, [animate]);

  // populate targetItem according to whether a battle card or territory group is selected
  const selectedTerritoryGroup = planTargets && planTargets[selectedPillIdPlanTargets];
  let selectedTargetItem;
  if (selectedPillIdPlanTargets === 'battlecard') {
    const allocatedTopDownValue = battleCardMeasureTotals
      ? getMeasureByType(battleCardMeasureTotals, MeasureType.ALLOCATE_TD)?.measureValue?.value
      : null;

    const updatedQuotaValue = battleCardMeasureTotals
      ? getMeasureByType(battleCardMeasureTotals, MeasureType.UPDATED_QUOTA)?.measureValue?.value
      : null;

    const quotaAdjustmentValue = battleCardMeasureTotals
      ? getMeasureByType(battleCardMeasureTotals, MeasureType.QUOTA_ADJUSTMENT)?.measureValue?.value
      : null;

    selectedTargetItem = {
      name: battleCardAllocationData?.battlecardName,
      territoryGroupId: null,
      allocatedTopDownValue,
      updatedQuotaValue,
      quotaAdjustmentValue
    };
  } else if (selectedTerritoryGroup) {
    selectedTargetItem = {
      name: selectedTerritoryGroup.name,
      territoryGroupId: selectedTerritoryGroup.territoryGroupId,
      allocatedTopDownValue: getMeasureByType(selectedTerritoryGroup.measures, MeasureType.ALLOCATE_TD)?.measureValue,
      updatedQuotaValue: getMeasureByType(selectedTerritoryGroup.measures, MeasureType.UPDATED_QUOTA)?.measureValue,
      quotaAdjustmentValue: getMeasureByType(selectedTerritoryGroup.measures, MeasureType.QUOTA_ADJUSTMENT)
        ?.measureValue
    };
  }

  const onAllocate = async ({
    amount,
    measureToProrateBy,
    clearQuotaAdjustment,
    preserveSeasonality,
    hierarchyTarget
  }: onAllocateParams) => {
    if (!selectedPlanningCycle?.id) {
      return;
    }

    let allocatedMeasureId;
    if (sheetMeasuresData) {
      allocatedMeasureId = getAllocatedMeasureId(sheetMeasuresData);
    }

    // common variables shared by allocateEvenSplit and allocateProrated
    const allocationVariables: AllocationQueryVariables = {
      battlecardId: +battleCardId,
      quotaComponentId: selectedQuotaComponentId,
      clearQuotaAdjustment,
      preserveSeasonality
    };

    // if performing allocation on a territory group, pass territoryGroupId into query variables
    if (selectedTargetItem.territoryGroupId) {
      allocationVariables.territoryGroupId = +selectedTargetItem.territoryGroupId;
    }

    const isEvenSplit = measureToProrateBy.key === PlanTargetsMeasureAllowed.EVENSPLIT;
    const allocateTopDownRequestVariables: RequestAllocateTopDownInput | AllocateTopDownVariables = {
      ...allocationVariables,
      measureId: allocatedMeasureId,
      measureIdToProrateBy: isEvenSplit ? null : measureToProrateBy.value
    };
    if (amount) {
      allocateTopDownRequestVariables.amount = amount;
    } else if (hierarchyTarget) {
      allocateTopDownRequestVariables.hierarchyTarget = hierarchyTarget;
    }

    if (isTDAAsyncEnabled && isBreakdownOfQuotaEnabled) {
      await requestAllocateTopDown({
        variables: {
          input: allocateTopDownRequestVariables
        }
      });
      setTopdownAllocationJobStatusLookupMap([
        ...topdownAllocationJobStatusLookupMap,
        {
          battlecardId: +battleCardId,
          quotaComponentId: selectedQuotaComponentId,
          jobStatus: JobStatus.PENDING,
          selectedPillId: selectedPillIdPlanTargets !== 'battlecard' ? +selectedPillIdPlanTargets : null
        }
      ]);
    }

    if (!isTDAAsyncEnabled) {
      getPlanTargets(battleCardId, selectedQuotaComponentId, selectedPlanningCycle?.id, selectedDeploymentModelId);
      onUpdatePlanTargets();
    }
  };

  return (
    <div className={b()} ref={planTargetsRef} data-testid="expanded-plan-targets">
      <div className={b('treeViewContainer')}>
        <div className={b('treeView')}>
          <ExpandedPlanTargetsTree
            battleCardAllocationData={battleCardAllocationData}
            territoryGroupAllocationData={planTargetsTree}
            animateTerritoryGroup={animate}
            battleCardId={battleCardId}
            data-testid="expanded-plan-targets-tree"
          />
        </div>
      </div>
      <ExpandedPlanTargetsDrawer
        title={selectedTargetItem?.name}
        isLoading={isTerritoryGroupDataLoading}
        allocatedTopDownValue={selectedTargetItem?.allocatedTopDownValue}
        updatedQuotaValue={selectedTargetItem?.updatedQuotaValue}
        quotaAdjustmentValue={selectedTargetItem?.quotaAdjustmentValue}
        territoryCount={territoryCount}
        measures={sheetMeasuresData}
        onAllocate={onAllocate}
        businessTarget={battleCardAllocationData?.newBusinessTarget}
        data-testid="expanded-plan-targets-drawer"
      />
    </div>
  );
};

export default ExpandedPlanTargets;
