import React, { useEffect, useState } from 'react';

import { Divider } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';
import { CalloutV2, Intent } from '@varicent/components';
import { Field } from 'formik';

import SelectMenu from 'components/SelectMenu/SelectMenu';

import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';
import DataTypeTagList from 'app/components/DataTypeTagList/DataTypeTagList';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import { useScope } from 'app/contexts/scopeProvider';

import { SplitFeatures } from 'app/global/features';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';
import { useGetRootHierarchiesLazy } from 'app/graphql/queries/getRootHierarchies';

import useTreatment from 'app/hooks/useTreatment';

import { ConfigurationDialogType, HierarchyType, TableType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ImportSymonDataFileType.module.pcss';

const b = block(style);

type ImportSymonDataFileTypeProps = {
  fileUploadType: FileTypeEnum;
  rootHierarchyId?: number | null;
  dialogType?: ConfigurationDialogType;
  handleDisabledNext?: (value: boolean) => void;
};

const ImportSymonDataFileType: React.FC<ImportSymonDataFileTypeProps> = ({
  fileUploadType,
  dialogType = ConfigurationDialogType.IMPORT,
  handleDisabledNext,
  rootHierarchyId
}) => {
  const [isImportHierarchiesSymonOn] = useTreatment(SplitFeatures.IMPORT_HIERARCHIES_SYMON);

  const { selectedPlanningCycle } = useScope();
  const [showCallout, setShowCallout] = useState<boolean>(true);
  const [symonUploadFileType, setSymonUploadFileType] = useState([
    {
      key: formatMessage('ACTIVITY'),
      value: TableType.ACTIVITY.toString()
    }
  ]);
  const { mappingFields, mappingFieldsLoading } = useMappingFields(
    selectedPlanningCycle.id,
    fileUploadType,
    rootHierarchyId
  );

  const [loadHierarchies, { data: hierarchyData }] = useGetRootHierarchiesLazy({
    variables: { planningCycleId: selectedPlanningCycle?.id },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    handleDisabledNext?.(false);
  }, [handleDisabledNext]);

  useEffect(() => {
    loadHierarchies();
  }, [selectedPlanningCycle]);

  useEffect(() => {
    if (hierarchyData && isImportHierarchiesSymonOn) {
      const hierarchies = hierarchyData.getRootHierarchies
        .filter((hierarchy) => hierarchy.hierarchyType !== HierarchyType.GeographicTerritoryHierarchy)
        .map((hierarchy) => ({
          key: hierarchy.rootName,
          value: hierarchy.hierarchyType,
          rootHierarchyId: hierarchy.rootHierarchyId
        }));
      setSymonUploadFileType([...symonUploadFileType, ...hierarchies]);
    }
  }, [hierarchyData]);

  return (
    <div className={b()} data-testid="import-data-file-select">
      <div className={b('fileTypeInput')} data-testid="file-component-select">
        <label htmlFor="dataTableType" className={b('label')}>
          {formatMessage('FILE_TYPE')}
        </label>
        <Field
          id="dataTableType"
          name="dataTableType"
          items={symonUploadFileType}
          theme="default"
          showErrors={false}
          component={SelectMenu}
        />
      </div>
      <DataTypeTagList fileUploadType={fileUploadType} fieldsLoading={mappingFieldsLoading} fields={mappingFields} />
      {showCallout && fileUploadType === FileTypeEnum.Activity && !mappingFieldsLoading && (
        <CalloutV2
          className={b('callout')}
          intent={Intent.PRIMARY}
          action={() => setShowCallout(false)}
          actionIcon={Close}
          actionText={formatMessage('CLOSE')}
          hideActionText={true}
          data-testid="import-activity-callout"
        >
          {formatMessage('IMPORT_ACTIVITY_FILE_CALLOUT_MESSAGE')}
        </CalloutV2>
      )}
      {dialogType === ConfigurationDialogType.IMPORT && (
        <>
          <Divider />
          <div className={b('destinationFieldContainer')}>
            <label htmlFor="dataTableType" className={b('subTitle')}>
              {formatMessage('DESTINATION')}
            </label>
            <div data-testid="import-config-message">{formatMessage('IMPORT_CONFIG_MESSAGE')}</div>
            <div className={b('destinationInput')} data-testid="destination-table-input">
              <Field
                label={formatMessage('DESTINATION_TABLE')}
                name="destinationTable"
                type="text"
                component={FormTextInputGroup}
                placeHolder="<Table Name>"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportSymonDataFileType;
