import React, { FC } from 'react';

import { MenuItem, Checkbox, Spinner } from '@blueprintjs/core';

import block from 'utils/bem-css-modules';

import style from './CheckableMenuItem.module.pcss';

const b = block(style);

interface CheckableMenuItemProps {
  text: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  isLoading?: boolean;
  'data-testid': string;
}

const CheckableMenuItem: FC<CheckableMenuItemProps> = ({
  text,
  checked,
  'data-testid': dataTestId,
  isLoading = false,
  onChange
}) => (
  <MenuItem
    tagName="label"
    shouldDismissPopover={false}
    className={b()}
    textClassName={b('text')}
    data-testid={dataTestId}
    text={
      <>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onChange(e.currentTarget.checked);
          }}
          label={text}
          className={b('checkbox')}
          tagName="span"
        />
        <Spinner intent="primary" size={18} className={b('spinner', { isLoading })} />
      </>
    }
  />
);
export default CheckableMenuItem;
