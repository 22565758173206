import { gql } from '@apollo/client';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { PERIODIC_MEASURES } from 'app/graphql/fragments/periodicMeasures';
import { GetTerritoryQuotaTotals, GetTerritoryQuotaTotalsVariables } from 'app/graphql/generated/apolloTypes';

// This will replace the GET_TERRITORY_MEASURES_TOTALS above once FF BREAKDOWN_OF_QUOTA_BY_HIERARCHIES is complete, we should rename this file too
export const GET_TERRITORY_QUOTA_TOTALS = gql`
  ${PERIODIC_MEASURES}
  query GetTerritoryQuotaTotals($battlecardId: Int, $territoryGroupId: Int, $quotaComponentId: Int!, $sheetId: Int) {
    getTerritoryQuota(
      input: {
        battlecardId: $battlecardId
        territoryGroupId: $territoryGroupId
        quotaComponentId: $quotaComponentId
        sheetId: $sheetId
      }
    ) {
      periodicTotals {
        ...PeriodicMeasuresFragment
      }
    }
  }
`;

export const useGetTerritoryQuotaTotalsLazy = createUseLazyQuery<
  GetTerritoryQuotaTotals,
  GetTerritoryQuotaTotalsVariables
>(GET_TERRITORY_QUOTA_TOTALS);
