import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { PlansList, PlansListVariables } from 'app/graphql/generated/apolloTypes';

export const GET_PLANNING_CYCLES = gql`
  query PlansList($tenantId: Int!) {
    getPlanningCycles(tenantId: $tenantId) {
      tenantId
      planningCycleId
      planningCycleName
      planningCycleComment
      planningCycleSlug
      planningCycleDuration
      planningCyclePeriodicity
      planningCycleStartDate
      planningCycleArchived
      currencyCode
      cloneId
    }
  }
`;

export const useGetPlanningCycles = createUseQuery<PlansList, PlansListVariables>(GET_PLANNING_CYCLES);
